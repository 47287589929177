import { FC, useState } from "react";
import { Collapse } from "react-bootstrap";
import { BsTextCenter, BsTextLeft, BsTextRight } from "react-icons/bs";
import { TamanhoMensagemEnu } from "../../../../models";

type Props = {
  currentSize: TamanhoMensagemEnu;
  onSizeChange: (size: TamanhoMensagemEnu) => void;
}

const MessageSizeSelector : FC<Props> = ( { currentSize, onSizeChange } ) => {
  const [open, setOpen] = useState(false);
  const [ current, setCurrent ] = useState(currentSize);

  const handleSizeChange = (size: TamanhoMensagemEnu) => {
    setCurrent(size);
    onSizeChange(size);
    setOpen(false);
  };
  
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <span className="fw-bold fs-4 me-3">Tamanho do Texto:</span>
        <button className="btn btn-primary" onClick={() => setOpen(!open)} aria-controls="collapse-text" aria-expanded={open}>
          {current === TamanhoMensagemEnu.CONCISO && <BsTextLeft className="me-2 fs-4" />}
          {current === TamanhoMensagemEnu.BALANCEADO && <BsTextCenter className="me-2 fs-4" />}
          {current === TamanhoMensagemEnu.EXTENSO && <BsTextRight className="me-2 fs-4" />}
          {current}
        </button>
      </div>
      <div className='d-flex justify-content-center' style={{ maxWidth: '100vw' }}>
        <Collapse in={open}>
          <div id="collapse-text">
            <div className='card mt-4'>
              <div className='card-body p-2 fs-3'>
                <div></div>
                <div
                  className={`d-flex justify-content-left align-items-center p-2 mb-2 ${current === TamanhoMensagemEnu.CONCISO ? 'bg-secondary text-white' : 'bg-light'}`}
                  onClick={() => handleSizeChange(TamanhoMensagemEnu.CONCISO)}
                  style={{ cursor: 'pointer' }}
                >
                  <BsTextLeft className="me-3" /> Texto curto e direto ao ponto.
                </div>
                <div className='separator my-1'></div>
                <div
                  className={`d-flex justify-content-left align-items-center p-2 mb-2 ${current === TamanhoMensagemEnu.BALANCEADO ? 'bg-secondary text-white' : 'bg-light'}`}
                  onClick={() => handleSizeChange(TamanhoMensagemEnu.BALANCEADO)}
                  style={{ cursor: 'pointer' }}
                >
                  <BsTextCenter className="me-3" /> Texto com um equilíbrio entre concisão e detalhe.
                </div>
                <div className='separator my-1'></div>
                <div
                  className={`d-flex justify-content-left align-items-center p-2 mb-2 ${current === TamanhoMensagemEnu.EXTENSO ? 'bg-secondary text-white' : 'bg-light'}`}
                  onClick={() => handleSizeChange(TamanhoMensagemEnu.EXTENSO)}
                  style={{ cursor: 'pointer' }}
                >
                  <BsTextRight className="me-3" /> Texto detalhado e abrangente.
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  )
}

export { MessageSizeSelector };
