import clsx from 'clsx';
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { BackAndNextButtons } from '../../../../../_lapide/components/buttons/BackAndNextButtons';
import { ComponentLoading } from '../../../../../_lapide/components/component-loading/ComponentLoading';
import LocationSelector from '../../cidades/components/LocationSelector';
import { ICidadeEstado, IbgeCidade, makeCidadeEstado } from '../../cidades/core/_models';
import { findOrCreateCidade } from '../../cidades/core/_requests';
import { AssistenteMensagemStepEnum, useAssistenteMensagem } from '../AssistenteMensagemProvider';

const StepSelecionarCidade = () => {
  const { cidades, selecionarCidade, goToPage, isLoading, isEditing, closeAssistente, handler } = useAssistenteMensagem();
  const [showCidadeSeletor, setShowCidadeSeletor] = useState(false);
  const [ibgeCIdadeSelected, setIbgeCidadeSelected] = useState<IbgeCidade | undefined>();

  const handleCidadeSelected = async (cidade: ICidadeEstado) => {
    await selecionarCidade(cidade);
  };

  const handleNext = async () => {
    if (!showCidadeSeletor) {
      setShowCidadeSeletor(true);
      return;
    }

    if (ibgeCIdadeSelected) {
      const cidade = await findOrCreateCidade(ibgeCIdadeSelected);

      if (cidade) {
        selecionarCidade(await makeCidadeEstado(cidade));
      }
    }
  };

  const handleBack = async () => {
    if (showCidadeSeletor && cidades && cidades.length > 0) {
      setShowCidadeSeletor(false);
      return;
    }

    if (isEditing) {
      closeAssistente();
    }

    if (handler.mensagemGerada) {
      goToPage(AssistenteMensagemStepEnum.EXIBIR_MENSAGEM, handler);
    } else {
      goToPage(AssistenteMensagemStepEnum.MENSAGEM_USUARIO, handler);
    }
  };

  const handleSelectCidade = (ibgeCIdade: IbgeCidade) => {
    setIbgeCidadeSelected(ibgeCIdade);
  };

  useEffect(() => {
    if (!isLoading && cidades && cidades.length === 0) {
      setShowCidadeSeletor(true);
    }
  }, [cidades, isLoading]);

  return (
    <>
      <div className="card" style={{ minHeight: '70vh', maxHeight: '70vh' }}>
        <div className="card-body">
          <>
            <h2 className="card-title text-center mb-4 mt-3">De onde você escreve?</h2>
            {!isLoading && !showCidadeSeletor && cidades && (
              <PerfectScrollbar style={{ maxHeight: '55vh' }}>
                <div className="d-flex flex-column w-100 justify-content-center">
                  {cidades.map((cidadeEstado, index) => (
                    <button
                      key={index}
                      className={clsx(
                        'btn w-100 text-nowrap p-4 mb-4',
                        index % 2 === 0 ? 'btn-primary' : 'btn-warning text-dark fw-bold'
                      )}
                      onClick={() => handleCidadeSelected(cidadeEstado)}
                    >
                      {cidadeEstado.descricao}
                    </button>
                  ))}
                </div>
              </PerfectScrollbar>
            )}

            {!isLoading && !!showCidadeSeletor && (
              <LocationSelector isDisabled={false} onSelectCidade={handleSelectCidade} />
            )}
          </>

          {isLoading && (
            <div className="text-center mb-5 mt-3 p-20">
              <ComponentLoading />
            </div>
          )}
        </div>
      </div>

      <BackAndNextButtons
        className="p-2"
        nextVisible={true}
        nextText={!showCidadeSeletor ? 'Outra' : !ibgeCIdadeSelected ? 'Selecione' : 'Avançar'}
        onGoNext={handleNext}
        nextEnabled={!showCidadeSeletor || !!ibgeCIdadeSelected}
        backVisible={true}
        onGoBack={handleBack}
      />
    </>
  );
};

export { StepSelecionarCidade };

