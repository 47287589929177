import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import "react-perfect-scrollbar/dist/css/styles.css";
import { PerguntaOpcaoResposta } from '../../../../../models';
import { usePergunta } from "../PerguntaProvider";

const RespostaOpcoes = () => {
  const { opcoesRespostas, salvarRespostaOpcao, perguntaSelected } = usePergunta()

  const handleRespostaSelected =  async (opcao: PerguntaOpcaoResposta) => {
    await salvarRespostaOpcao(opcao)
  }

  return (
    <>
      { perguntaSelected && opcoesRespostas.length > 0 &&
        <PerfectScrollbar>
          <div className="flex-nowrap w-100 justify-content-center">
            {opcoesRespostas.map((resposta, index) => (
              <button
                key={index}
                className={
                  clsx("btn w-100 text-nowrap p-4 mb-4",
                    index%2 === 0 ? "btn-primary" : "btn-warning text-dark fw-bold"
                  )
                }
                onClick={() => handleRespostaSelected(resposta)}
              >
                {resposta.opcaoResposta}
              </button>
            ))}
          </div>
        </PerfectScrollbar>
      }
    </>
  )
}

export { RespostaOpcoes };

