import clsx from "clsx";
import { FC, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { SearchInput } from "../../../../../_lapide/components/input/SearchInput";
import { NivelRelacionamentoEnum } from "../../../../../models";
import { useTipoRelacionamento } from "../TipoRelaciomentoProvider";

const niveisRelacionamento: Map<string, NivelRelacionamentoEnum> = new Map([
  ['Parente Próximo', NivelRelacionamentoEnum.PARENTE_PROXIMO],
  ['Parente', NivelRelacionamentoEnum.PARENTE_DISTANTE],
  ['Amigo', NivelRelacionamentoEnum.AMIGO],
  ['Trabalho', NivelRelacionamentoEnum.TRABALHO],
  ['Comunidade', NivelRelacionamentoEnum.COMUNIDADE],
])

type Props= {
  className?: string
}

const TipoRelacionamentoFilter : FC<Props> = ({ className }) => {
  const [filtroSelecionado, setFiltroSelecionado] = useState<string | null>(null)
  const { filtrarTiposRelacionamentosPorNivel, setFiltroTexto } = useTipoRelacionamento()
  const [ showFilters, setShowFilters ] = useState(false)
  
  const handleFiltroSelecionado = async (selectedKey: string | null) => {
    if (selectedKey) {
      const selectedNivel = niveisRelacionamento.get(selectedKey);
      setFiltroSelecionado(selectedKey);
      await filtrarTiposRelacionamentosPorNivel(selectedNivel);
    } else {
      setFiltroSelecionado(null);
      await filtrarTiposRelacionamentosPorNivel(undefined)
    }
  }

  const handleFilterClick = async () => {
    if (showFilters) {
      await handleFiltroSelecionado(null);
    }

    setShowFilters(!showFilters)
  };

  return (
    <div>
      <div className={clsx("mb-2 text-center", className)}>
        <SearchInput onTextChange={setFiltroTexto} onFilterClick={handleFilterClick} />
      </div>
      { showFilters &&
        <div className="rounded border border-gray-300 p-2 mx-2 d-flex justify-content-center">
          <Dropdown onSelect={handleFiltroSelecionado}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="text-dark fw-bold">
              {filtroSelecionado ? filtroSelecionado : 'Selecione um Nível'}
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-dark fs-4">
              <Dropdown.Item key={null}>
                Todos
              </Dropdown.Item>
              <Dropdown.Divider />
              {Array.from(niveisRelacionamento).map(([key, value]) => (
                <Dropdown.Item key={key} eventKey={key}>
                  {key}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      }
    </div>
  )
}

export { TipoRelacionamentoFilter };

