import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';

moment.locale('pt-br');

type Props = {
  className: string;
  name: string;
  message: string;
  avatarUrl?: string;
  coverPhotoUrl?: string;
  dataPostagem: string;
  horaPostagem: string;
  cidade: string;
  estado: string;
};

const MensagemMuralLayout: React.FC<Props> = ({
  className,
  name,
  message,
  avatarUrl,
  coverPhotoUrl,
  dataPostagem,
  horaPostagem,
  cidade,
  estado
}) => {
  const local = `${cidade}/${estado}`;
  const dataHoraPostagem = moment(`${dataPostagem} ${horaPostagem}`, 'YYYY-MM-DD HH:mm');
  const dataExtenso = moment(dataHoraPostagem).calendar();
  const [ img, setImg ] = useState<string>();

  useEffect(() => {
    if (avatarUrl) {
      setImg(avatarUrl)
    }
  }, [avatarUrl])
  
  return (
    <div className={`card ${className}`}>
      <div className='card-header d-flex flex-column align-items-center'>
        <div className='symbol symbol-90px mt-2'>
          <img src={img} alt='' />
        </div>
        <div className='mt-3'>
          <span className='text-gray-800 text-hover-primary fs-1 fw-bold'>
            {name}
          </span>
        </div>
        <div className='mt-1'>
          <span className='text-gray-400 fw-semibold fs-3'>{`${local}`}</span>
        </div>
        <div className='mt-1'>
          <span className='text-gray-400 fw-semibold fs-3'>{`${dataExtenso}`}</span>
        </div>
        <div className='separator mt-1'></div>
      </div>
      {coverPhotoUrl &&
        <div className='px-3 mb-3'>
          <img src={coverPhotoUrl} className="img-fluid rounded w-100" alt={`Homenagem de ${name}`} style={{ objectFit: 'cover' }} />
        </div>
      }
      <div className='card-body px-3 py-2'>
        <div className='mb-7'>
          <p
            className='mensagem-mural text-gray-800 text-center fs-1'
            style={{ whiteSpace: 'pre-line' }}
          >
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export { MensagemMuralLayout };

