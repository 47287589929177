import { Storage } from "@aws-amplify/storage"
import { FC, useEffect, useState } from "react"
import { BsCaretRight } from "react-icons/bs"
import { toAbsoluteUrl } from "../../../../../_metronic/helpers"
import { MensagemMural, TipoFoto } from "../../../../../models"
import { getMensagemMuralFalecimentoFoto } from "../core/_requests"

type Props = {
  mensagemMural: MensagemMural,
  onClick: () => {}
}

const MensagemMuralNextCard : FC<Props> = ( { mensagemMural, onClick } ) => {
  const [ imgUrl, setImgUrl ] = useState<string>(toAbsoluteUrl('/media/avatars/blank.png'));
  const [ local, setLocal ] = useState<string>()

  useEffect(() => {
    const loadProfilePhoto = async (mensagem: MensagemMural) => {
      const mensagemFoto = await getMensagemMuralFalecimentoFoto(mensagem.id, TipoFoto.PROFILE);

      if (mensagemFoto) {
        const foto = await mensagemFoto.FalecimentoFoto;

        if (foto) {
          const imgUrl = await Storage.get(foto.fileKey, { level: "public" } )
          setImgUrl(imgUrl)
        }
      }
    }

    const loadLocal =async (mensagem:MensagemMural) => {
      if (mensagem.cidade && mensagem.estado) {
        setLocal(`${mensagem.cidade}/${mensagem.estado}`)
      }
    }

    setImgUrl(toAbsoluteUrl('/media/avatars/blank.png'));
    loadProfilePhoto(mensagemMural);
    loadLocal(mensagemMural);
  }, [mensagemMural])
  
  return (
    <div className="card" onClick={onClick}>
      <div className="card-body p-1">
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-50px me-2'>
            <img src={imgUrl} alt='profile' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <label className='text-dark fw-bold text-hover-primary fs-3'>
              { mensagemMural.nomeUsuario }
            </label>
            <span className='text-gray-400 fw-semibold fs-5'>{`${local}`}</span>
          </div>
          <div className='border-end mx-2'></div>
          <div className='ms-2'>
            <BsCaretRight size={30} />
          </div>
        </div>
      </div>
    </div>
  )
}

export { MensagemMuralNextCard }

