import imageCompression from 'browser-image-compression';
import { FC, useCallback, useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import { useDropzone } from "react-dropzone";
import Cropper, { Area } from 'react-easy-crop';
import { FaTrashAlt } from 'react-icons/fa';
import 'react-image-crop/dist/ReactCrop.css';
import Swal from 'sweetalert2';
import { Photo } from "./PhotoModels";

type Props = {
  onPhotosChange: (photos: Photo[]) => void
  initialPhotos?: Photo[]
  maxPhotos?: number;
  dropZoneText: string;
  cropShape?: 'rect' | 'round';
  widthPx?: number;
  heightPx?: number;
}

const PhotoUpload: FC<Props> = ({ onPhotosChange, initialPhotos = [], maxPhotos: initialMaxPhotos = 1, dropZoneText, cropShape = 'rect', widthPx = 100, heightPx = 100 }) => {
  const [photos, setPhotos] = useState<Photo[]>(initialPhotos);
  const [maxPhotos] = useState<number>(initialMaxPhotos);
  const [showCropModal, setShowCropModal] = useState(false);
  const [tempPhoto, setTempPhoto] = useState<Photo | null>(null);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  };

  const getCroppedImg = async (imageSrc: string, crop: Area): Promise<{ url: string, file: File }> => {
    const image = new Image();
    image.src = imageSrc;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx?.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx?.getImageData(0, 0, safeArea, safeArea);

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx?.putImageData(
      data!,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - crop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - crop.y)
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], "cropped.jpeg", { type: 'image/jpeg' });
          const url = URL.createObjectURL(file);
          resolve({ url, file });
        } else {
          reject(new Error('Canvas is empty'));
        }
      }, 'image/jpeg');
    });
  };

  const uploadPhoto = useCallback(async (file: File) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const compressedFileUrl = URL.createObjectURL(compressedFile);
      const newPhoto = { file: compressedFile, url: compressedFileUrl, isCropped: false };

      setPhotos((prevPhotos) => {
        const updatedPhotos = [...prevPhotos, newPhoto];
        return updatedPhotos;
      });

      if (maxPhotos === 1) {
        handleOpenModal(newPhoto)
      }
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  }, [maxPhotos]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const remainingSlots = maxPhotos - photos.length;
    const filesToUpload = acceptedFiles.slice(0, remainingSlots);

    filesToUpload.forEach(file => uploadPhoto(file));

    if (acceptedFiles.length > remainingSlots) {
      Swal.fire({
        icon: 'info',
        text: remainingSlots > 0 ? `O limite de fotos para adicionar é ${maxPhotos}` : 'Você já atingiu a quantidade de máxima de fotos.',
      });
    }
  }, [photos, maxPhotos, uploadPhoto]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': []
    },
    multiple: true,
    maxFiles: maxPhotos,
    onDropRejected(fileRejections, event) {
      const remainingSlots = maxPhotos - photos.length;

      if (fileRejections.length > remainingSlots) {
        Swal.fire({
          icon: 'info',
          text: remainingSlots > 0 ? `O limite de fotos para adicionar é ${maxPhotos}` : 'Você já atingiu a quantidade de máxima de fotos.',
        });
      }
    },
  });

  const handleOpenModal = (photo: Photo) => {
    setTempPhoto(photo);
    setShowCropModal(true);
  };

  const handleCloseModal = () => {
    setShowCropModal(false);
  };

  const getOriginalPhoto = (photo: Photo) => {
    return photo.originalPhoto ?? photo;
  }

  const handleSaveCroppedPhoto = async () => {
    if (tempPhoto && croppedArea) {
      const originalPhoto = getOriginalPhoto(tempPhoto);
      const { url, file } = await getCroppedImg(originalPhoto.url, croppedArea);
      const croppedPhoto = { url, file, isCropped: true, originalPhoto } as Photo;

      setPhotos((prevPhotos) => {
        const updatedPhotos = prevPhotos.map((p) => p === tempPhoto ? croppedPhoto : p);
        return updatedPhotos;
      });
    }
    handleCloseModal();
  };

  const removePhoto = (index: number) => {
    const newPhotos = photos.filter((_, i) => i !== index);
    setPhotos(newPhotos);
  };

  useEffect(() => {
    onPhotosChange(photos);
  }, [photos, onPhotosChange]);

  return (
    <>
      <div className="text-center">
        {maxPhotos - photos.length > 0 && (
          <div {...getRootProps()} className="dropzone fs-3" style={{ borderStyle: 'dashed', width: '100%', maxWidth: '600px', margin: '0 auto' }}>
            <input {...getInputProps()} />
            <p>{dropZoneText}</p>
          </div>
        )}
        <div className="d-flex flex-wrap justify-content-center">
          {photos.map((photo, index) => (
            <div key={index} className="position-relative m-2 p-2 border rounded bg-light" style={{ width: `${widthPx + 20}px` }}>
              <div>
                <img
                  src={photo.url}
                  alt={`uploaded ${index}`}
                  className={cropShape === 'round' ? 'img-fluid rounded-circle' : 'img-thumbnail rounded'}
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
              </div>
              <div
                className="position-absolute d-flex justify-content-center align-items-center bg-white rounded-circle"
                style={{
                  top: '5px',
                  right: '5px',
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  width: '30px',
                  height: '30px',
                }}
                onClick={() => removePhoto(index)}
              >
                <FaTrashAlt />
              </div>
              <button
                className="btn btn-block mt-2 btn-primary fs-3"
                onClick={() => handleOpenModal(photo)}>
                Editar
              </button>
            </div>
          ))}
        </div>

        <Modal show={showCropModal} onHide={handleSaveCroppedPhoto} centered>
          <Modal.Header closeButton>
            <Modal.Title>Recorte a foto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {tempPhoto && (
              <div className="position-relative w-100 mx-auto h-300px">
                <Cropper
                  image={getOriginalPhoto(tempPhoto).url}
                  crop={crop}
                  zoom={zoom}
                  aspect={widthPx / heightPx}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                  cropShape={cropShape}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSaveCroppedPhoto} className='fs-3 me-2'>
              Recortar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export { PhotoUpload };

