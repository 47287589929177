import { FC, useEffect } from "react"
import { MensagemMural } from "../../../../../models"
import { LoginFlowStepEnum, useLoginFlow } from "../../../auth/components/LoginFlow"
import { useAssistenteMensagem } from "../../assistente-mensagem/AssistenteMensagemProvider"
import { MensagemMuralStepEnum, useMensagemMural } from "../MensagemMuralProvider"
import { StepRevisao } from "./steps"
import { StepCodeVerification } from "./steps/StepCodeVerification"
import { StepEmail } from "./steps/StepEmail"

type Props = {
  onClose: () => void
  onNewPost: (mensagem: MensagemMural) => void
}

const MensagemMuralSteps : FC<Props> = ({onClose, onNewPost}) => {
  const {
    currentMensagemMuralStep,
    setCurrentMensagemMuralStep,
  } = useMensagemMural()

  const {
    setUserName,
    setUserCidade,
    // setUserBlobImage,
    // setUserImageFileName,
    currentLoginFlowStep,
  } = useLoginFlow();

  const { handler, mensagemMural } = useAssistenteMensagem()


  useEffect(() => {
    if (currentMensagemMuralStep === MensagemMuralStepEnum.EMAIL && (currentLoginFlowStep === LoginFlowStepEnum.SIGNUP_CODE_VALIDATION || currentLoginFlowStep === LoginFlowStepEnum.FORGOT_PASSWORD_CODE_VALIDATION)) {
      setCurrentMensagemMuralStep(MensagemMuralStepEnum.CODE_VERIFICATION)
    }

  }, [currentLoginFlowStep, currentMensagemMuralStep, setCurrentMensagemMuralStep])

  useEffect(() => {
    if (currentLoginFlowStep === LoginFlowStepEnum.LOGED && currentMensagemMuralStep === MensagemMuralStepEnum.CODE_VERIFICATION) {
      setCurrentMensagemMuralStep(MensagemMuralStepEnum.REVISAO)
    }
  }, [currentLoginFlowStep, currentMensagemMuralStep, setCurrentMensagemMuralStep])
  

  useEffect(() => {
    if (handler?.cidadeEstado) {
      setUserCidade(handler.cidadeEstado)
    }
  }, [handler?.cidadeEstado, setUserCidade])

  useEffect(() => {
    if (handler?.nomeUsuario) {
      setUserName(handler.nomeUsuario)
    }
  }, [handler?.nomeUsuario, setUserName])

  useEffect(() => {
    if (mensagemMural) {
      onNewPost(mensagemMural)
      onClose()
    }
  }, [mensagemMural, onNewPost, onClose])

  return (
    <>
      {currentMensagemMuralStep === MensagemMuralStepEnum.EMAIL && <StepEmail /> }
      {currentMensagemMuralStep === MensagemMuralStepEnum.CODE_VERIFICATION && <StepCodeVerification /> }
      {currentMensagemMuralStep === MensagemMuralStepEnum.REVISAO && <StepRevisao /> }
    </>
  )
}

export { MensagemMuralSteps }

