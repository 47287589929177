import { FC, useEffect, useState } from 'react';

import Select from 'react-select';
import { EstadoSelect } from './core/_models';
import { getAllEstados } from './core/_requests';

type Props = {
    className: string
    isDisabled: boolean | false
    onSelectEstado: (value: EstadoSelect) => void
}

const EstadoSelector: FC<Props> = ({className, isDisabled, onSelectEstado}) => {
  const [ estados, setEstados ] = useState<EstadoSelect[]>([]);  
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const loadEstados = async () => {
    setIsLoading(true)
    
    await getAllEstados().then(result => {
      if (result.error === false) {
        const estadosData = result.data!.map(item => ({
            value: item,
            label: `${item.sigla} - ${item.nome}`
        }));
        setEstados(estadosData)
      }
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleSelected = (newValue: EstadoSelect) => {
    onSelectEstado(newValue)
  }

  useEffect(() => {
    loadEstados();
        
  }, [isDisabled])

  return (
    <>
      <Select
        className={`basic-single ${className}`}
        classNamePrefix="select"
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        options={estados}
        onChange={(newValue) => handleSelected(newValue as EstadoSelect) }
      />
    </>
  );
};

export { EstadoSelector };

