import clsx from 'clsx';
import React, { ReactNode, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Footer } from './components/footer/Footer';

type Props = {
  main: ReactNode
  search: ReactNode
}

const MainLayout: React.FC<Props> = ( { main, search } ) => {
  const [maxPageHeight, setMaxPageHeight] = useState(0);

  useEffect(() => {
    const updateMaxPageHeight = () => {
      setMaxPageHeight(Math.floor(window.innerHeight * 0.90 / 100) * 100 + 50);
    };

    updateMaxPageHeight(); // Atualize a altura máxima da página inicialmente

    // Atualize a altura máxima da página sempre que a janela for redimensionada
    window.addEventListener('resize', updateMaxPageHeight);

    return () => {
      window.removeEventListener('resize', updateMaxPageHeight);
    };
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-6 position-relative">
          <div id='main-content' className={clsx("scroll-y", `mh-${maxPageHeight}px`)}>
            {main}
          </div>
        </div>
        {  !isMobile &&
          <div className="col-xl-6">
              <div className='mw-50 flex-column'>
                <div className='flex-column-fluid'>
                  <div className='hover-scroll-overlay-y'>
                    {search}
                  </div>
                </div>
                <div className='flex-column-auto pt-2 pb-6 px-6'>
                  <Footer />
                </div>
              </div>
          </div>
        }
      </div>
    </div>
  );
}

export { MainLayout };

