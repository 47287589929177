import imageCompression, { Options } from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';

export const compressImage = async (imageFile: File, options: Options) : Promise<Blob|undefined> => {
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
}

export const compressBlob = async (filename: string, picFileBlob: Blob) : Promise<{compressedBlob: Blob|undefined, extension: string, uuid: string}> => {
  const lastDot = filename.lastIndexOf('.');
  const extension = filename.substring(lastDot + 1)

  const file = new File([picFileBlob], 'compressed-image.jpg', { type: 'image/jpeg' });
  const compressedBlob = await compressImage(file, {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 100,
    useWebWorker: true,
  });

  return { compressedBlob, extension, uuid:`${uuidv4()}` }
}