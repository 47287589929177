import { isMobile } from 'react-device-detect';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthPage } from '../pages/auth/AuthPage';
import { HomePage } from '../pages/home/HomePage';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to='/coming-soon' />} />

      {/* Redirect to Auth if not authenticated */}
      <Route path='/*' element={<Navigate to='/coming-soon' />} />

      {/* Pages */}
      <Route path='login/*' element={<AuthPage />} />
      { isMobile && <Route path='home/*' element={ <HomePage /> } /> }
      
      {/* Page Not Found */}
      <Route path='*' element={<Navigate to='/coming-soon' />} />
    </Routes>
  )
}

export { PublicRoutes };

