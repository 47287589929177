import { AnonymousUser, Falecimento, OpenAiAssistant, OpenAiRun, OpenAiThread } from "../../../../models";
import { IOpenAiMessages, IOpenAiRun, IOpenAiThread } from "./core/_models";
import { createAndRunThreadMessage, createOpenAiAssistant, createOpenAiThread, createOpenAiThreadMessage, createOpenAiThreadRun, findOpenAiAssistant, findOpenAiThread, getOpenAiThreadMessageList, retrieveOpenAiThreadRun, saveOpenAiAssistant, saveOpenAiRun, saveOpenAiThread } from "./core/_requests";

class OpenAiHandler {
  thread: IOpenAiThread | null = null;
  assistant: OpenAiAssistant | null = null;
  run: IOpenAiRun | null = null;
  messages: IOpenAiMessages | null = null;

  async createAssistant(instructions: string, assistantName: string) : Promise<OpenAiAssistant | undefined> {
    const assistant = await createOpenAiAssistant(instructions, assistantName)

    if (assistant) {
      this.assistant = await saveOpenAiAssistant(assistant);
      return this.assistant;
    }

    return undefined;
  }
  
  async createThread(anonymousUser: AnonymousUser, openAiAssistant: OpenAiAssistant) : Promise<OpenAiThread | null> {
    this.thread = await createOpenAiThread();

    if (this.thread) {
      return await saveOpenAiThread(this.thread, openAiAssistant, anonymousUser)
    }

    return null;
  }

  async getOpenAiThread(anonymousUser: AnonymousUser) : Promise<OpenAiThread | null> {
    return await findOpenAiThread(anonymousUser);
  }

  async getOpenAiAssistant(falecimento: Falecimento) : Promise<OpenAiAssistant|undefined|null>{
    return await findOpenAiAssistant(falecimento);
  }

  async createMessage(threadId: string, instructions: string) : Promise<string|null> {
    const result = await createOpenAiThreadMessage(threadId, instructions)
    return result;
  }

  async createRun(assistentemensagemID: string, anonymousUser: AnonymousUser, threadId: string, openAiAssistant: OpenAiAssistant, instructions: string) : Promise<OpenAiRun | null> {
    this.run = await createOpenAiThreadRun(threadId, openAiAssistant.assistantId, instructions);

    if (this.run) {
      return await saveOpenAiRun(assistentemensagemID, anonymousUser, openAiAssistant, this.run)
    }

    return null;
  }

  async createAndRunOpenAiThreadMessage(assistentemensagemID: string, anonymousUser: AnonymousUser, openAiAssistant: OpenAiAssistant, messages: string[]) : Promise<OpenAiRun | null> {
    this.run = await createAndRunThreadMessage(openAiAssistant.assistantId, messages);

    if (this.run) {
      return await saveOpenAiRun(assistentemensagemID, anonymousUser, openAiAssistant, this.run)
    }

    return null;
  }
  
  async retrieveRun(assistentemensagemID: string, anonymousUser: AnonymousUser, openAiRun: OpenAiRun, openAiAssistant: OpenAiAssistant) : Promise<OpenAiRun | null> {
    if (!openAiRun.thread_id) return null;
        
    this.run = await retrieveOpenAiThreadRun(openAiRun.thread_id, openAiRun.runId);

    if (this.run) {
      return await saveOpenAiRun(assistentemensagemID, anonymousUser, openAiAssistant, this.run)
    }

    return null;
  }

  async getThreadMessages (openAiRun: OpenAiRun) : Promise<IOpenAiMessages | null> {
    if (!openAiRun.thread_id) return null;

    this.messages = await getOpenAiThreadMessageList(openAiRun.thread_id)

    return this.messages;
  }
}

export { OpenAiHandler };

