import { DataStore } from "aws-amplify"
import { AnonymousUser, Falecimento, RelUsuarioFalecimento, TipoRelacionamento, User } from "../../../../../models"

export const createRelUsuarioFalecimento = async (
    anonymousUser: AnonymousUser,
    falecimento: Falecimento,
    tipoRelacionamento: TipoRelacionamento,
    user?: User) : Promise<RelUsuarioFalecimento> => {

  return await DataStore.save(new RelUsuarioFalecimento({
      AnonymousUser: anonymousUser,
      Falecimento: falecimento,
      nivelRelacionamento: tipoRelacionamento.nivelRelaciomento,
      relUsuarioFalecimentoAnonymousUserId: anonymousUser.id,
      relUsuarioFalecimentoFalecimentoId: falecimento.id,
      relUsuarioFalecimentoTipoRelacionamentoId: tipoRelacionamento.id,
      TipoRelacionamento: tipoRelacionamento,
      relUsuarioFalecimentoUserId: user?.id,
      User: user,
    })
  )
}

export const updateRelUsuarioFalecimento = async (
  relUsuarioFalecido: RelUsuarioFalecimento,
  anonymousUser: AnonymousUser,
  falecimento: Falecimento,
  tipoRelacionamento: TipoRelacionamento,
  user?: User) : Promise<RelUsuarioFalecimento> => {

const toUpdate = await DataStore.query(RelUsuarioFalecimento, relUsuarioFalecido.id);

if (!toUpdate) {
  throw new Error('Relacionamento inválido');
}

return await DataStore.save(
  RelUsuarioFalecimento.copyOf(toUpdate, updated => {
    updated.AnonymousUser = anonymousUser;
    updated.Falecimento = falecimento;
    updated.nivelRelacionamento = tipoRelacionamento.nivelRelaciomento;
    updated.relUsuarioFalecimentoAnonymousUserId = anonymousUser.id;
    updated.relUsuarioFalecimentoFalecimentoId = falecimento.id;
    updated.relUsuarioFalecimentoTipoRelacionamentoId = tipoRelacionamento.id;
    updated.TipoRelacionamento = tipoRelacionamento;
    updated.relUsuarioFalecimentoUserId = user?.id;
    updated.User = user;
  })
)
}