import { toAbsoluteUrl } from "../../../_metronic/helpers"
import { LoginFlow, LoginFlowProvider } from "./components/LoginFlow"

const LoginPage = () => {
  
  return (
    <LoginFlowProvider>
      <div className="text-center d-block d-md-none mb-4">
        <img alt="Logo" src={toAbsoluteUrl('/media/logos/main-in-line.png')} className="img-fluid p-5"/>
      </div>
      <LoginFlow />
    </LoginFlowProvider>
  )
}

export { LoginPage }

