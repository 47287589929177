import { API } from 'aws-amplify'
import { FC, createContext, useContext } from "react"
import { WithChildren } from "../../../_metronic/helpers"

type OpenAIContextProps = {
  createThread: () => Promise<{threadId: string}>,
}

const initAuthContextPropsState = {
  createThread:async () => { return  { threadId: '' } },
}

const OpenAIContext = createContext<OpenAIContextProps>(initAuthContextPropsState)

const useOpenAI = () => {
  return useContext(OpenAIContext)
}

const OpenAIProvider: FC<WithChildren> = ({children}) => {
  const createThread = async () : Promise<{ threadId: string }> => {
    const apiInit = {
      body: {
        content: 'A instrução vai aqui'
      },
    };
  
    return API.post("openai", `/threads`, apiInit)
      .then((response: any) => {
        return { threadId: response }
      })
      .catch((error: any) => {
        console.log("error", JSON.stringify(error))
          return { threadId: '' }
      })
  }

  return (
    <OpenAIContext.Provider value ={
      {
        createThread,
      }
    }>
      {children}
    </OpenAIContext.Provider>
    
  )
}

export { OpenAIProvider, useOpenAI }
