/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreatePostImageInput = {
  id?: string | null,
  imageKey: string,
  context: string,
  postID: string,
  _version?: number | null,
};

export type ModelPostImageConditionInput = {
  imageKey?: ModelStringInput | null,
  context?: ModelStringInput | null,
  postID?: ModelIDInput | null,
  and?: Array< ModelPostImageConditionInput | null > | null,
  or?: Array< ModelPostImageConditionInput | null > | null,
  not?: ModelPostImageConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type PostImage = {
  __typename: "PostImage",
  id: string,
  imageKey: string,
  context: string,
  postID: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePostImageInput = {
  id: string,
  imageKey?: string | null,
  context?: string | null,
  postID?: string | null,
  _version?: number | null,
};

export type DeletePostImageInput = {
  id: string,
  _version?: number | null,
};

export type CreatePostInput = {
  id?: string | null,
  postType: PostTypeEnum,
  postAction: PostActionEnum,
  text: string,
  url: string,
  datePost: string,
  timePost: string,
  resourceId: string,
  _version?: number | null,
  postUserId: string,
};

export enum PostTypeEnum {
  HOMENAGEM = "HOMENAGEM",
  MEMORIAL = "MEMORIAL",
}


export enum PostActionEnum {
  CREATED = "CREATED",
  UPDATED = "UPDATED",
}


export type ModelPostConditionInput = {
  postType?: ModelPostTypeEnumInput | null,
  postAction?: ModelPostActionEnumInput | null,
  text?: ModelStringInput | null,
  url?: ModelStringInput | null,
  datePost?: ModelStringInput | null,
  timePost?: ModelStringInput | null,
  resourceId?: ModelStringInput | null,
  and?: Array< ModelPostConditionInput | null > | null,
  or?: Array< ModelPostConditionInput | null > | null,
  not?: ModelPostConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  postUserId?: ModelIDInput | null,
};

export type ModelPostTypeEnumInput = {
  eq?: PostTypeEnum | null,
  ne?: PostTypeEnum | null,
};

export type ModelPostActionEnumInput = {
  eq?: PostActionEnum | null,
  ne?: PostActionEnum | null,
};

export type Post = {
  __typename: "Post",
  id: string,
  postType: PostTypeEnum,
  postAction: PostActionEnum,
  text: string,
  url: string,
  datePost: string,
  timePost: string,
  User: User,
  PostImages?: ModelPostImageConnection | null,
  resourceId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  postUserId: string,
};

export type User = {
  __typename: "User",
  id: string,
  email: string,
  Funerarias?: ModelUserFunerariaConnection | null,
  nome?: string | null,
  status?: UserStatusEnum | null,
  avatar?: string | null,
  imageKey?: string | null,
  Cidade?: Cidade | null,
  role?: UserRoleEnum | null,
  memorials?: ModelMemorialUserConnection | null,
  sexo?: SexoEnum | null,
  lastIpAddress?: string | null,
  anonymousUserId?: string | null,
  FalecimentoFotos?: ModelFalecimentoFotoConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  userCidadeId?: string | null,
};

export type ModelUserFunerariaConnection = {
  __typename: "ModelUserFunerariaConnection",
  items:  Array<UserFuneraria | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UserFuneraria = {
  __typename: "UserFuneraria",
  id: string,
  userId: string,
  funerariaId: string,
  user: User,
  funeraria: Funeraria,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Funeraria = {
  __typename: "Funeraria",
  id: string,
  cnpj: string,
  nome: string,
  logradouro: string,
  cep: string,
  telefone1?: string | null,
  telefone2?: string | null,
  email?: string | null,
  site?: string | null,
  users?: ModelUserFunerariaConnection | null,
  Cidade: Cidade,
  velorios?: ModelVelorioFunerariaConnection | null,
  cemiterios?: ModelCemiterioFunerariaConnection | null,
  cidade?: string | null,
  estado?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  funerariaCidadeId: string,
};

export type Cidade = {
  __typename: "Cidade",
  id: string,
  nome: string,
  Estado?: Estado | null,
  idIbge: string,
  Microrregiao?: Microrregiao | null,
  Mesorregiao?: Mesorregiao | null,
  Regiao?: Regiao | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  cidadeEstadoId?: string | null,
  cidadeMicrorregiaoId?: string | null,
  cidadeMesorregiaoId?: string | null,
  cidadeRegiaoId?: string | null,
};

export type Estado = {
  __typename: "Estado",
  id: string,
  nome: string,
  sigla: string,
  idIbge: string,
  Regiao?: Regiao | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  estadoRegiaoId?: string | null,
};

export type Regiao = {
  __typename: "Regiao",
  id: string,
  sigla: string,
  nome: string,
  idIbge: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Microrregiao = {
  __typename: "Microrregiao",
  id: string,
  nome: string,
  idIbge: string,
  Mesorregiao?: Mesorregiao | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  microrregiaoMesorregiaoId?: string | null,
};

export type Mesorregiao = {
  __typename: "Mesorregiao",
  id: string,
  nome: string,
  idIbge: string,
  Estado?: Estado | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  mesorregiaoEstadoId?: string | null,
};

export type ModelVelorioFunerariaConnection = {
  __typename: "ModelVelorioFunerariaConnection",
  items:  Array<VelorioFuneraria | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type VelorioFuneraria = {
  __typename: "VelorioFuneraria",
  id: string,
  velorioId: string,
  funerariaId: string,
  velorio: Velorio,
  funeraria: Funeraria,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Velorio = {
  __typename: "Velorio",
  id: string,
  nome: string,
  logradouro: string,
  cep?: string | null,
  Cidade?: Cidade | null,
  bairro?: string | null,
  Owner?: User | null,
  Funerarias?: ModelVelorioFunerariaConnection | null,
  cemiterios?: ModelCemiterioVelorioConnection | null,
  cidade?: string | null,
  estado?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  velorioCidadeId?: string | null,
  velorioOwnerId?: string | null,
};

export type ModelCemiterioVelorioConnection = {
  __typename: "ModelCemiterioVelorioConnection",
  items:  Array<CemiterioVelorio | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type CemiterioVelorio = {
  __typename: "CemiterioVelorio",
  id: string,
  cemiterioId: string,
  velorioId: string,
  cemiterio: Cemiterio,
  velorio: Velorio,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Cemiterio = {
  __typename: "Cemiterio",
  id: string,
  nome: string,
  logradouro: string,
  cep?: string | null,
  bairro?: string | null,
  Cidade?: Cidade | null,
  Owner?: User | null,
  Funerarias?: ModelCemiterioFunerariaConnection | null,
  Velorios?: ModelCemiterioVelorioConnection | null,
  cidade?: string | null,
  estado?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  cemiterioCidadeId?: string | null,
  cemiterioOwnerId?: string | null,
};

export type ModelCemiterioFunerariaConnection = {
  __typename: "ModelCemiterioFunerariaConnection",
  items:  Array<CemiterioFuneraria | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type CemiterioFuneraria = {
  __typename: "CemiterioFuneraria",
  id: string,
  cemiterioId: string,
  funerariaId: string,
  cemiterio: Cemiterio,
  funeraria: Funeraria,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum UserStatusEnum {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}


export enum UserRoleEnum {
  BACKOFFICE = "BACKOFFICE",
  USER = "USER",
  ADMIN = "ADMIN",
}


export type ModelMemorialUserConnection = {
  __typename: "ModelMemorialUserConnection",
  items:  Array<MemorialUser | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type MemorialUser = {
  __typename: "MemorialUser",
  id: string,
  memorialId: string,
  userId: string,
  memorial: Memorial,
  user: User,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Memorial = {
  __typename: "Memorial",
  id: string,
  url: string,
  Administradores?: ModelMemorialUserConnection | null,
  SuperAdmin?: User | null,
  ConviteAdministracaoMemorials?: ModelConviteAdministracaoMemorialConnection | null,
  Criador?: User | null,
  Falecimento: Falecimento,
  emailSuperAdmin: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  memorialSuperAdminId?: string | null,
  memorialCriadorId?: string | null,
  memorialFalecimentoId: string,
};

export type ModelConviteAdministracaoMemorialConnection = {
  __typename: "ModelConviteAdministracaoMemorialConnection",
  items:  Array<ConviteAdministracaoMemorial | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ConviteAdministracaoMemorial = {
  __typename: "ConviteAdministracaoMemorial",
  id: string,
  memorialID: string,
  email: string,
  status: ConviteAdministracaoMemorialStatusEnum,
  dataEnvio: string,
  dataAceite?: string | null,
  Convidado?: User | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  conviteAdministracaoMemorialConvidadoId?: string | null,
};

export enum ConviteAdministracaoMemorialStatusEnum {
  ACEITO = "ACEITO",
  PENDENTE = "PENDENTE",
  RECUSADO = "RECUSADO",
}


export type Falecimento = {
  __typename: "Falecimento",
  id: string,
  nome: string,
  sobrenome?: string | null,
  dataFalecimento: string,
  Funeraria?: Funeraria | null,
  searchName: string,
  idade: number,
  tempoDeVida: string,
  meses: number,
  Velorio?: Velorio | null,
  dataInicioVelorio?: string | null,
  horaInicioVelorio?: string | null,
  dataFimVelorio?: string | null,
  horaFimVelorio?: string | null,
  dataSepultamento?: string | null,
  horaSepultamento?: string | null,
  salaVelorio?: string | null,
  Cemiterio?: Cemiterio | null,
  Cidade?: Cidade | null,
  sobre?: string | null,
  imageKey?: string | null,
  apelido?: string | null,
  cidade?: string | null,
  estado?: string | null,
  sexo?: SexoEnum | null,
  dataNascimento: string,
  faixaEtaria?: FaixaEtariaEnum | null,
  OpenAiAssistant?: OpenAiAssistant | null,
  FalecimentoFotos?: ModelFalecimentoFotoConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  falecimentoFunerariaId?: string | null,
  falecimentoVelorioId?: string | null,
  falecimentoCemiterioId?: string | null,
  falecimentoCidadeId?: string | null,
  falecimentoOpenAiAssistantId?: string | null,
};

export enum SexoEnum {
  MASCULINO = "MASCULINO",
  FEMININO = "FEMININO",
}


export enum FaixaEtariaEnum {
  INFANCIA_BEBE = "INFANCIA_BEBE",
  INFANCIA_CRIANCA_PEQUENA = "INFANCIA_CRIANCA_PEQUENA",
  INFANCIA_CRIANCA_GRANDE = "INFANCIA_CRIANCA_GRANDE",
  ADOLESCENCIA_PRE_ADOLESCENTE = "ADOLESCENCIA_PRE_ADOLESCENTE",
  ADOLESCENCIA_ADOLESCENTE = "ADOLESCENCIA_ADOLESCENTE",
  ADULTO_JOVEM_ADULTO = "ADULTO_JOVEM_ADULTO",
  ADULTO_ADULTO_JOVEM = "ADULTO_ADULTO_JOVEM",
  ADULTO_MEIA_IDADE = "ADULTO_MEIA_IDADE",
  TERCEIRA_IDADE_IDOSO_JOVEM = "TERCEIRA_IDADE_IDOSO_JOVEM",
  TERCEIRA_IDADE_IDOSO = "TERCEIRA_IDADE_IDOSO",
  TERCEIRA_IDADE_MUITO_IDOSO = "TERCEIRA_IDADE_MUITO_IDOSO",
}


export type OpenAiAssistant = {
  __typename: "OpenAiAssistant",
  id: string,
  assistantId: string,
  object: string,
  model: string,
  instructions: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelFalecimentoFotoConnection = {
  __typename: "ModelFalecimentoFotoConnection",
  items:  Array<FalecimentoFoto | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type FalecimentoFoto = {
  __typename: "FalecimentoFoto",
  id: string,
  fileKey: string,
  status: ContentStatusEnum,
  tipo: TipoFoto,
  falecimentoID: string,
  userID: string,
  isCover?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum ContentStatusEnum {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REMOVED = "REMOVED",
  DELETED = "DELETED",
}


export enum TipoFoto {
  PROFILE = "PROFILE",
  COVER = "COVER",
  NORMAL = "NORMAL",
  CROP = "CROP",
  TOTAL = "TOTAL",
}


export type ModelPostImageConnection = {
  __typename: "ModelPostImageConnection",
  items:  Array<PostImage | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdatePostInput = {
  id: string,
  postType?: PostTypeEnum | null,
  postAction?: PostActionEnum | null,
  text?: string | null,
  url?: string | null,
  datePost?: string | null,
  timePost?: string | null,
  resourceId?: string | null,
  _version?: number | null,
  postUserId?: string | null,
};

export type DeletePostInput = {
  id: string,
  _version?: number | null,
};

export type CreateMensagemMuralEngajamentoInput = {
  id?: string | null,
  views: number,
  _version?: number | null,
  mensagemMuralEngajamentoMensagemMuralId: string,
};

export type ModelMensagemMuralEngajamentoConditionInput = {
  views?: ModelIntInput | null,
  and?: Array< ModelMensagemMuralEngajamentoConditionInput | null > | null,
  or?: Array< ModelMensagemMuralEngajamentoConditionInput | null > | null,
  not?: ModelMensagemMuralEngajamentoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  mensagemMuralEngajamentoMensagemMuralId?: ModelIDInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type MensagemMuralEngajamento = {
  __typename: "MensagemMuralEngajamento",
  id: string,
  MensagemMural: MensagemMural,
  views: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  mensagemMuralEngajamentoMensagemMuralId: string,
};

export type MensagemMural = {
  __typename: "MensagemMural",
  id: string,
  mensagem: string,
  dataPostagem: string,
  horaPostagem: string,
  Cidade?: Cidade | null,
  Falecimento: Falecimento,
  User?: User | null,
  status?: MensagemMuralStatusEnum | null,
  AnonymousUser?: AnonymousUser | null,
  AprovadoPor?: User | null,
  dataAprovacao?: string | null,
  cidade?: string | null,
  estado?: string | null,
  nomeUsuario?: string | null,
  tipoMensagem?: TipoMensagemEnum | null,
  Memorial?: Memorial | null,
  resumo?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  mensagemMuralCidadeId?: string | null,
  mensagemMuralFalecimentoId: string,
  mensagemMuralUserId?: string | null,
  mensagemMuralAnonymousUserId?: string | null,
  mensagemMuralAprovadoPorId?: string | null,
  mensagemMuralMemorialId?: string | null,
};

export enum MensagemMuralStatusEnum {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REMOVED = "REMOVED",
  DELETED = "DELETED",
}


export type AnonymousUser = {
  __typename: "AnonymousUser",
  id: string,
  nome?: string | null,
  avatar?: string | null,
  email?: string | null,
  ip?: string | null,
  Cidade?: Cidade | null,
  sexo?: SexoEnum | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  anonymousUserCidadeId?: string | null,
};

export enum TipoMensagemEnum {
  GERADA = "GERADA",
  ESCRITA = "ESCRITA",
  GERADA_EDITADA = "GERADA_EDITADA",
}


export type UpdateMensagemMuralEngajamentoInput = {
  id: string,
  views?: number | null,
  _version?: number | null,
  mensagemMuralEngajamentoMensagemMuralId?: string | null,
};

export type DeleteMensagemMuralEngajamentoInput = {
  id: string,
  _version?: number | null,
};

export type CreateMensagemMuralViewsInput = {
  id?: string | null,
  dataVisualizacao: string,
  horaVisualizacao: string,
  _version?: number | null,
  mensagemMuralViewsUserId: string,
  mensagemMuralViewsMensagemMuralId: string,
};

export type ModelMensagemMuralViewsConditionInput = {
  dataVisualizacao?: ModelStringInput | null,
  horaVisualizacao?: ModelStringInput | null,
  and?: Array< ModelMensagemMuralViewsConditionInput | null > | null,
  or?: Array< ModelMensagemMuralViewsConditionInput | null > | null,
  not?: ModelMensagemMuralViewsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  mensagemMuralViewsUserId?: ModelIDInput | null,
  mensagemMuralViewsMensagemMuralId?: ModelIDInput | null,
};

export type MensagemMuralViews = {
  __typename: "MensagemMuralViews",
  id: string,
  User: User,
  MensagemMural: MensagemMural,
  dataVisualizacao: string,
  horaVisualizacao: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  mensagemMuralViewsUserId: string,
  mensagemMuralViewsMensagemMuralId: string,
};

export type UpdateMensagemMuralViewsInput = {
  id: string,
  dataVisualizacao?: string | null,
  horaVisualizacao?: string | null,
  _version?: number | null,
  mensagemMuralViewsUserId?: string | null,
  mensagemMuralViewsMensagemMuralId?: string | null,
};

export type DeleteMensagemMuralViewsInput = {
  id: string,
  _version?: number | null,
};

export type CreateMensagemMuralFalecimentoFotoInput = {
  id?: string | null,
  tipoFoto: TipoFoto,
  isCover?: boolean | null,
  _version?: number | null,
  mensagemMuralFalecimentoFotoMensagemMuralId: string,
  mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
};

export type ModelMensagemMuralFalecimentoFotoConditionInput = {
  tipoFoto?: ModelTipoFotoInput | null,
  isCover?: ModelBooleanInput | null,
  and?: Array< ModelMensagemMuralFalecimentoFotoConditionInput | null > | null,
  or?: Array< ModelMensagemMuralFalecimentoFotoConditionInput | null > | null,
  not?: ModelMensagemMuralFalecimentoFotoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  mensagemMuralFalecimentoFotoMensagemMuralId?: ModelIDInput | null,
  mensagemMuralFalecimentoFotoFalecimentoFotoId?: ModelIDInput | null,
};

export type ModelTipoFotoInput = {
  eq?: TipoFoto | null,
  ne?: TipoFoto | null,
};

export type MensagemMuralFalecimentoFoto = {
  __typename: "MensagemMuralFalecimentoFoto",
  id: string,
  MensagemMural: MensagemMural,
  FalecimentoFoto: FalecimentoFoto,
  tipoFoto: TipoFoto,
  isCover?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  mensagemMuralFalecimentoFotoMensagemMuralId: string,
  mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
};

export type UpdateMensagemMuralFalecimentoFotoInput = {
  id: string,
  tipoFoto?: TipoFoto | null,
  isCover?: boolean | null,
  _version?: number | null,
  mensagemMuralFalecimentoFotoMensagemMuralId?: string | null,
  mensagemMuralFalecimentoFotoFalecimentoFotoId?: string | null,
};

export type DeleteMensagemMuralFalecimentoFotoInput = {
  id: string,
  _version?: number | null,
};

export type CreateFalecimentoFotoInput = {
  id?: string | null,
  fileKey: string,
  status: ContentStatusEnum,
  tipo: TipoFoto,
  falecimentoID: string,
  userID: string,
  isCover?: boolean | null,
  _version?: number | null,
};

export type ModelFalecimentoFotoConditionInput = {
  fileKey?: ModelStringInput | null,
  status?: ModelContentStatusEnumInput | null,
  tipo?: ModelTipoFotoInput | null,
  falecimentoID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  isCover?: ModelBooleanInput | null,
  and?: Array< ModelFalecimentoFotoConditionInput | null > | null,
  or?: Array< ModelFalecimentoFotoConditionInput | null > | null,
  not?: ModelFalecimentoFotoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelContentStatusEnumInput = {
  eq?: ContentStatusEnum | null,
  ne?: ContentStatusEnum | null,
};

export type UpdateFalecimentoFotoInput = {
  id: string,
  fileKey?: string | null,
  status?: ContentStatusEnum | null,
  tipo?: TipoFoto | null,
  falecimentoID?: string | null,
  userID?: string | null,
  isCover?: boolean | null,
  _version?: number | null,
};

export type DeleteFalecimentoFotoInput = {
  id: string,
  _version?: number | null,
};

export type CreateOpenAiGeneratedMessageInput = {
  id?: string | null,
  falecimentoId: string,
  anonymousUserId: string,
  messageId: string,
  runId: string,
  threadId: string,
  created_at: number,
  contentTextValue: string,
  assistantId: string,
  _version?: number | null,
};

export type ModelOpenAiGeneratedMessageConditionInput = {
  falecimentoId?: ModelStringInput | null,
  anonymousUserId?: ModelStringInput | null,
  messageId?: ModelStringInput | null,
  runId?: ModelStringInput | null,
  threadId?: ModelStringInput | null,
  created_at?: ModelIntInput | null,
  contentTextValue?: ModelStringInput | null,
  assistantId?: ModelStringInput | null,
  and?: Array< ModelOpenAiGeneratedMessageConditionInput | null > | null,
  or?: Array< ModelOpenAiGeneratedMessageConditionInput | null > | null,
  not?: ModelOpenAiGeneratedMessageConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type OpenAiGeneratedMessage = {
  __typename: "OpenAiGeneratedMessage",
  id: string,
  falecimentoId: string,
  anonymousUserId: string,
  messageId: string,
  runId: string,
  threadId: string,
  created_at: number,
  contentTextValue: string,
  assistantId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateOpenAiGeneratedMessageInput = {
  id: string,
  falecimentoId?: string | null,
  anonymousUserId?: string | null,
  messageId?: string | null,
  runId?: string | null,
  threadId?: string | null,
  created_at?: number | null,
  contentTextValue?: string | null,
  assistantId?: string | null,
  _version?: number | null,
};

export type DeleteOpenAiGeneratedMessageInput = {
  id: string,
  _version?: number | null,
};

export type CreateOpenAiRunInput = {
  id?: string | null,
  runId: string,
  object?: string | null,
  created_at?: number | null,
  status?: string | null,
  expires_at?: number | null,
  started_at?: number | null,
  cancelled_at?: number | null,
  failed_at?: number | null,
  completed_at?: number | null,
  model?: string | null,
  instructions?: string | null,
  assistant_id?: string | null,
  thread_id?: string | null,
  last_error?: OpenAiErrorInput | null,
  assistentemensagemID?: string | null,
  _version?: number | null,
  openAiRunOpenAiThreadId?: string | null,
  openAiRunOpenAiAssistantId?: string | null,
  openAiRunAnonymousUserId?: string | null,
};

export type OpenAiErrorInput = {
  code?: string | null,
  message?: string | null,
};

export type ModelOpenAiRunConditionInput = {
  runId?: ModelStringInput | null,
  object?: ModelStringInput | null,
  created_at?: ModelIntInput | null,
  status?: ModelStringInput | null,
  expires_at?: ModelIntInput | null,
  started_at?: ModelIntInput | null,
  cancelled_at?: ModelIntInput | null,
  failed_at?: ModelIntInput | null,
  completed_at?: ModelIntInput | null,
  model?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  assistant_id?: ModelStringInput | null,
  thread_id?: ModelStringInput | null,
  assistentemensagemID?: ModelIDInput | null,
  and?: Array< ModelOpenAiRunConditionInput | null > | null,
  or?: Array< ModelOpenAiRunConditionInput | null > | null,
  not?: ModelOpenAiRunConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  openAiRunOpenAiThreadId?: ModelIDInput | null,
  openAiRunOpenAiAssistantId?: ModelIDInput | null,
  openAiRunAnonymousUserId?: ModelIDInput | null,
};

export type OpenAiRun = {
  __typename: "OpenAiRun",
  id: string,
  OpenAiThread?: OpenAiThread | null,
  OpenAiAssistant?: OpenAiAssistant | null,
  runId: string,
  object?: string | null,
  created_at?: number | null,
  status?: string | null,
  expires_at?: number | null,
  started_at?: number | null,
  cancelled_at?: number | null,
  failed_at?: number | null,
  completed_at?: number | null,
  model?: string | null,
  instructions?: string | null,
  assistant_id?: string | null,
  thread_id?: string | null,
  last_error?: OpenAiError | null,
  AnonymousUser?: AnonymousUser | null,
  assistentemensagemID?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  openAiRunOpenAiThreadId?: string | null,
  openAiRunOpenAiAssistantId?: string | null,
  openAiRunAnonymousUserId?: string | null,
};

export type OpenAiThread = {
  __typename: "OpenAiThread",
  id: string,
  threadId: string,
  object: string,
  OpenAiAssistant: OpenAiAssistant,
  AnonymousUser?: AnonymousUser | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  openAiThreadOpenAiAssistantId: string,
  openAiThreadAnonymousUserId?: string | null,
};

export type OpenAiError = {
  __typename: "OpenAiError",
  code?: string | null,
  message?: string | null,
};

export type UpdateOpenAiRunInput = {
  id: string,
  runId?: string | null,
  object?: string | null,
  created_at?: number | null,
  status?: string | null,
  expires_at?: number | null,
  started_at?: number | null,
  cancelled_at?: number | null,
  failed_at?: number | null,
  completed_at?: number | null,
  model?: string | null,
  instructions?: string | null,
  assistant_id?: string | null,
  thread_id?: string | null,
  last_error?: OpenAiErrorInput | null,
  assistentemensagemID?: string | null,
  _version?: number | null,
  openAiRunOpenAiThreadId?: string | null,
  openAiRunOpenAiAssistantId?: string | null,
  openAiRunAnonymousUserId?: string | null,
};

export type DeleteOpenAiRunInput = {
  id: string,
  _version?: number | null,
};

export type CreateOpenAiAssistantInput = {
  id?: string | null,
  assistantId: string,
  object: string,
  model: string,
  instructions: string,
  _version?: number | null,
};

export type ModelOpenAiAssistantConditionInput = {
  assistantId?: ModelStringInput | null,
  object?: ModelStringInput | null,
  model?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  and?: Array< ModelOpenAiAssistantConditionInput | null > | null,
  or?: Array< ModelOpenAiAssistantConditionInput | null > | null,
  not?: ModelOpenAiAssistantConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateOpenAiAssistantInput = {
  id: string,
  assistantId?: string | null,
  object?: string | null,
  model?: string | null,
  instructions?: string | null,
  _version?: number | null,
};

export type DeleteOpenAiAssistantInput = {
  id: string,
  _version?: number | null,
};

export type CreateAssistenteMensagemInput = {
  id?: string | null,
  tamanhoMensagem?: TamanhoMensagemEnu | null,
  tipoPessoa?: TipoPessoaEnum | null,
  emNomeDe?: string | null,
  nomeUsuario?: string | null,
  mensagemUsuario?: string | null,
  instrucao?: string | null,
  Step?: string | null,
  Status?: AssistenteStatusEnum | null,
  mensagemReescrita?: string | null,
  mensagemGerada?: string | null,
  mensagemCorrigida?: string | null,
  mensagemEditada?: string | null,
  tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
  _version?: number | null,
  assistenteMensagemOpenAiThreadId?: string | null,
  assistenteMensagemFalecimentoId: string,
  assistenteMensagemAnonymousUserId: string,
  assistenteMensagemOpenAiAssistantId?: string | null,
  assistenteMensagemRelUsuarioFalecimentoId?: string | null,
  assistenteMensagemUserId?: string | null,
  assistenteMensagemTipoRelacionamentoId?: string | null,
  assistenteMensagemCidadeId?: string | null,
  assistenteMensagemOpenAiGeneratedMessageId?: string | null,
  assistenteMensagemMemorialId?: string | null,
};

export enum TamanhoMensagemEnu {
  CONCISO = "CONCISO",
  BALANCEADO = "BALANCEADO",
  EXTENSO = "EXTENSO",
}


export enum TipoPessoaEnum {
  PRIMEIRA = "PRIMEIRA",
  TERCEIRA = "TERCEIRA",
}


export enum AssistenteStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
}


export enum TipoMensagemSelecionadaEnum {
  ESCRITA_USUARIO = "ESCRITA_USUARIO",
  REESCRITA_IA = "REESCRITA_IA",
  CORRIGIDA_IA = "CORRIGIDA_IA",
  GERADA_IA = "GERADA_IA",
  EDITADA_MANUAL = "EDITADA_MANUAL",
}


export type ModelAssistenteMensagemConditionInput = {
  tamanhoMensagem?: ModelTamanhoMensagemEnuInput | null,
  tipoPessoa?: ModelTipoPessoaEnumInput | null,
  emNomeDe?: ModelStringInput | null,
  nomeUsuario?: ModelStringInput | null,
  mensagemUsuario?: ModelStringInput | null,
  instrucao?: ModelStringInput | null,
  Step?: ModelStringInput | null,
  Status?: ModelAssistenteStatusEnumInput | null,
  mensagemReescrita?: ModelStringInput | null,
  mensagemGerada?: ModelStringInput | null,
  mensagemCorrigida?: ModelStringInput | null,
  mensagemEditada?: ModelStringInput | null,
  tipoMensagemSelecionada?: ModelTipoMensagemSelecionadaEnumInput | null,
  and?: Array< ModelAssistenteMensagemConditionInput | null > | null,
  or?: Array< ModelAssistenteMensagemConditionInput | null > | null,
  not?: ModelAssistenteMensagemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  assistenteMensagemOpenAiThreadId?: ModelIDInput | null,
  assistenteMensagemFalecimentoId?: ModelIDInput | null,
  assistenteMensagemAnonymousUserId?: ModelIDInput | null,
  assistenteMensagemOpenAiAssistantId?: ModelIDInput | null,
  assistenteMensagemRelUsuarioFalecimentoId?: ModelIDInput | null,
  assistenteMensagemUserId?: ModelIDInput | null,
  assistenteMensagemTipoRelacionamentoId?: ModelIDInput | null,
  assistenteMensagemCidadeId?: ModelIDInput | null,
  assistenteMensagemOpenAiGeneratedMessageId?: ModelIDInput | null,
  assistenteMensagemMemorialId?: ModelIDInput | null,
};

export type ModelTamanhoMensagemEnuInput = {
  eq?: TamanhoMensagemEnu | null,
  ne?: TamanhoMensagemEnu | null,
};

export type ModelTipoPessoaEnumInput = {
  eq?: TipoPessoaEnum | null,
  ne?: TipoPessoaEnum | null,
};

export type ModelAssistenteStatusEnumInput = {
  eq?: AssistenteStatusEnum | null,
  ne?: AssistenteStatusEnum | null,
};

export type ModelTipoMensagemSelecionadaEnumInput = {
  eq?: TipoMensagemSelecionadaEnum | null,
  ne?: TipoMensagemSelecionadaEnum | null,
};

export type AssistenteMensagem = {
  __typename: "AssistenteMensagem",
  id: string,
  OpenAiThread?: OpenAiThread | null,
  Falecimento: Falecimento,
  AnonymousUser: AnonymousUser,
  OpenAiAssistant?: OpenAiAssistant | null,
  Respostas?: ModelRespostaConnection | null,
  RelUsuarioFalecimento?: RelUsuarioFalecimento | null,
  tamanhoMensagem?: TamanhoMensagemEnu | null,
  tipoPessoa?: TipoPessoaEnum | null,
  emNomeDe?: string | null,
  OpenAiRuns?: ModelOpenAiRunConnection | null,
  nomeUsuario?: string | null,
  User?: User | null,
  TipoRelacionamento?: TipoRelacionamento | null,
  mensagemUsuario?: string | null,
  Cidade?: Cidade | null,
  OpenAiGeneratedMessage?: OpenAiGeneratedMessage | null,
  instrucao?: string | null,
  Step?: string | null,
  Status?: AssistenteStatusEnum | null,
  Memorial?: Memorial | null,
  mensagemReescrita?: string | null,
  mensagemGerada?: string | null,
  mensagemCorrigida?: string | null,
  mensagemEditada?: string | null,
  tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  assistenteMensagemOpenAiThreadId?: string | null,
  assistenteMensagemFalecimentoId: string,
  assistenteMensagemAnonymousUserId: string,
  assistenteMensagemOpenAiAssistantId?: string | null,
  assistenteMensagemRelUsuarioFalecimentoId?: string | null,
  assistenteMensagemUserId?: string | null,
  assistenteMensagemTipoRelacionamentoId?: string | null,
  assistenteMensagemCidadeId?: string | null,
  assistenteMensagemOpenAiGeneratedMessageId?: string | null,
  assistenteMensagemMemorialId?: string | null,
};

export type ModelRespostaConnection = {
  __typename: "ModelRespostaConnection",
  items:  Array<Resposta | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Resposta = {
  __typename: "Resposta",
  id: string,
  pergunta: string,
  resposta: string,
  AnonymousUser?: AnonymousUser | null,
  User?: User | null,
  Falecimento: Falecimento,
  tipoPergunta: TipoPerguntaEnum,
  viviamJuntos?: boolean | null,
  Pergunta?: Pergunta | null,
  PerguntaOpcaoResposta?: PerguntaOpcaoResposta | null,
  assistentemensagemID?: string | null,
  skiped?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  respostaAnonymousUserId?: string | null,
  respostaUserId?: string | null,
  respostaFalecimentoId: string,
  respostaPerguntaId?: string | null,
  respostaPerguntaOpcaoRespostaId?: string | null,
};

export enum TipoPerguntaEnum {
  RELACIONAMENTO = "RELACIONAMENTO",
  ESPECIFICA_FALECIDO = "ESPECIFICA_FALECIDO",
  ESPECIFICA_USUARIO = "ESPECIFICA_USUARIO",
}


export type Pergunta = {
  __typename: "Pergunta",
  id: string,
  pergunta: string,
  feminino?: string | null,
  tipoPergunta: TipoPerguntaEnum,
  tipoResposta: TipoRespostaEnum,
  respostaTipoViviamJuntos: boolean,
  limitaTipoRelacionamento: boolean,
  limitaFaixaEtaria: boolean,
  limitaNivelRelacionamento: boolean,
  modeloResposta?: string | null,
  active?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum TipoRespostaEnum {
  OPCOES = "OPCOES",
  TEXTO_ABERTO = "TEXTO_ABERTO",
  UMA_PALAVRA = "UMA_PALAVRA",
  POUCAS_PALAVRAS = "POUCAS_PALAVRAS",
  SIM_NAO = "SIM_NAO",
}


export type PerguntaOpcaoResposta = {
  __typename: "PerguntaOpcaoResposta",
  id: string,
  opcaoResposta: string,
  Pergunta: Pergunta,
  viviamJuntos?: boolean | null,
  active?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  perguntaOpcaoRespostaPerguntaId: string,
};

export type RelUsuarioFalecimento = {
  __typename: "RelUsuarioFalecimento",
  id: string,
  nivelRelacionamento: NivelRelacionamentoEnum,
  Falecimento: Falecimento,
  AnonymousUser: AnonymousUser,
  User?: User | null,
  TipoRelacionamento: TipoRelacionamento,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  relUsuarioFalecimentoFalecimentoId: string,
  relUsuarioFalecimentoAnonymousUserId: string,
  relUsuarioFalecimentoUserId?: string | null,
  relUsuarioFalecimentoTipoRelacionamentoId: string,
};

export enum NivelRelacionamentoEnum {
  PARENTE_PROXIMO = "PARENTE_PROXIMO",
  PARENTE_DISTANTE = "PARENTE_DISTANTE",
  AMIGO = "AMIGO",
  TRABALHO = "TRABALHO",
  COMUNIDADE = "COMUNIDADE",
  AMIGO_PROXIMO = "AMIGO_PROXIMO",
  VIVIAM_JUNTOS = "VIVIAM_JUNTOS",
}


export type TipoRelacionamento = {
  __typename: "TipoRelacionamento",
  id: string,
  nivelRelaciomento: NivelRelacionamentoEnum,
  titulo: string,
  sexo?: SexoEnum | null,
  prioridade?: number | null,
  active?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelOpenAiRunConnection = {
  __typename: "ModelOpenAiRunConnection",
  items:  Array<OpenAiRun | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateAssistenteMensagemInput = {
  id: string,
  tamanhoMensagem?: TamanhoMensagemEnu | null,
  tipoPessoa?: TipoPessoaEnum | null,
  emNomeDe?: string | null,
  nomeUsuario?: string | null,
  mensagemUsuario?: string | null,
  instrucao?: string | null,
  Step?: string | null,
  Status?: AssistenteStatusEnum | null,
  mensagemReescrita?: string | null,
  mensagemGerada?: string | null,
  mensagemCorrigida?: string | null,
  mensagemEditada?: string | null,
  tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
  _version?: number | null,
  assistenteMensagemOpenAiThreadId?: string | null,
  assistenteMensagemFalecimentoId?: string | null,
  assistenteMensagemAnonymousUserId?: string | null,
  assistenteMensagemOpenAiAssistantId?: string | null,
  assistenteMensagemRelUsuarioFalecimentoId?: string | null,
  assistenteMensagemUserId?: string | null,
  assistenteMensagemTipoRelacionamentoId?: string | null,
  assistenteMensagemCidadeId?: string | null,
  assistenteMensagemOpenAiGeneratedMessageId?: string | null,
  assistenteMensagemMemorialId?: string | null,
};

export type DeleteAssistenteMensagemInput = {
  id: string,
  _version?: number | null,
};

export type CreateOpenAiThreadInput = {
  id?: string | null,
  threadId: string,
  object: string,
  _version?: number | null,
  openAiThreadOpenAiAssistantId: string,
  openAiThreadAnonymousUserId?: string | null,
};

export type ModelOpenAiThreadConditionInput = {
  threadId?: ModelStringInput | null,
  object?: ModelStringInput | null,
  and?: Array< ModelOpenAiThreadConditionInput | null > | null,
  or?: Array< ModelOpenAiThreadConditionInput | null > | null,
  not?: ModelOpenAiThreadConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  openAiThreadOpenAiAssistantId?: ModelIDInput | null,
  openAiThreadAnonymousUserId?: ModelIDInput | null,
};

export type UpdateOpenAiThreadInput = {
  id: string,
  threadId?: string | null,
  object?: string | null,
  _version?: number | null,
  openAiThreadOpenAiAssistantId?: string | null,
  openAiThreadAnonymousUserId?: string | null,
};

export type DeleteOpenAiThreadInput = {
  id: string,
  _version?: number | null,
};

export type CreatePerguntaFaixaEtariaInput = {
  id?: string | null,
  faixaEtaria: FaixaEtariaEnum,
  active?: boolean | null,
  _version?: number | null,
  perguntaFaixaEtariaPerguntaId: string,
};

export type ModelPerguntaFaixaEtariaConditionInput = {
  faixaEtaria?: ModelFaixaEtariaEnumInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPerguntaFaixaEtariaConditionInput | null > | null,
  or?: Array< ModelPerguntaFaixaEtariaConditionInput | null > | null,
  not?: ModelPerguntaFaixaEtariaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  perguntaFaixaEtariaPerguntaId?: ModelIDInput | null,
};

export type ModelFaixaEtariaEnumInput = {
  eq?: FaixaEtariaEnum | null,
  ne?: FaixaEtariaEnum | null,
};

export type PerguntaFaixaEtaria = {
  __typename: "PerguntaFaixaEtaria",
  id: string,
  faixaEtaria: FaixaEtariaEnum,
  Pergunta: Pergunta,
  active?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  perguntaFaixaEtariaPerguntaId: string,
};

export type UpdatePerguntaFaixaEtariaInput = {
  id: string,
  faixaEtaria?: FaixaEtariaEnum | null,
  active?: boolean | null,
  _version?: number | null,
  perguntaFaixaEtariaPerguntaId?: string | null,
};

export type DeletePerguntaFaixaEtariaInput = {
  id: string,
  _version?: number | null,
};

export type CreatePerguntaTipoRelacionamentoInput = {
  id?: string | null,
  active?: boolean | null,
  _version?: number | null,
  perguntaTipoRelacionamentoTipoRelacionamentoId: string,
  perguntaTipoRelacionamentoPerguntaId: string,
};

export type ModelPerguntaTipoRelacionamentoConditionInput = {
  active?: ModelBooleanInput | null,
  and?: Array< ModelPerguntaTipoRelacionamentoConditionInput | null > | null,
  or?: Array< ModelPerguntaTipoRelacionamentoConditionInput | null > | null,
  not?: ModelPerguntaTipoRelacionamentoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  perguntaTipoRelacionamentoTipoRelacionamentoId?: ModelIDInput | null,
  perguntaTipoRelacionamentoPerguntaId?: ModelIDInput | null,
};

export type PerguntaTipoRelacionamento = {
  __typename: "PerguntaTipoRelacionamento",
  id: string,
  TipoRelacionamento: TipoRelacionamento,
  Pergunta: Pergunta,
  active?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  perguntaTipoRelacionamentoTipoRelacionamentoId: string,
  perguntaTipoRelacionamentoPerguntaId: string,
};

export type UpdatePerguntaTipoRelacionamentoInput = {
  id: string,
  active?: boolean | null,
  _version?: number | null,
  perguntaTipoRelacionamentoTipoRelacionamentoId?: string | null,
  perguntaTipoRelacionamentoPerguntaId?: string | null,
};

export type DeletePerguntaTipoRelacionamentoInput = {
  id: string,
  _version?: number | null,
};

export type CreateRelUsuarioFalecimentoInput = {
  id?: string | null,
  nivelRelacionamento: NivelRelacionamentoEnum,
  _version?: number | null,
  relUsuarioFalecimentoFalecimentoId: string,
  relUsuarioFalecimentoAnonymousUserId: string,
  relUsuarioFalecimentoUserId?: string | null,
  relUsuarioFalecimentoTipoRelacionamentoId: string,
};

export type ModelRelUsuarioFalecimentoConditionInput = {
  nivelRelacionamento?: ModelNivelRelacionamentoEnumInput | null,
  and?: Array< ModelRelUsuarioFalecimentoConditionInput | null > | null,
  or?: Array< ModelRelUsuarioFalecimentoConditionInput | null > | null,
  not?: ModelRelUsuarioFalecimentoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  relUsuarioFalecimentoFalecimentoId?: ModelIDInput | null,
  relUsuarioFalecimentoAnonymousUserId?: ModelIDInput | null,
  relUsuarioFalecimentoUserId?: ModelIDInput | null,
  relUsuarioFalecimentoTipoRelacionamentoId?: ModelIDInput | null,
};

export type ModelNivelRelacionamentoEnumInput = {
  eq?: NivelRelacionamentoEnum | null,
  ne?: NivelRelacionamentoEnum | null,
};

export type UpdateRelUsuarioFalecimentoInput = {
  id: string,
  nivelRelacionamento?: NivelRelacionamentoEnum | null,
  _version?: number | null,
  relUsuarioFalecimentoFalecimentoId?: string | null,
  relUsuarioFalecimentoAnonymousUserId?: string | null,
  relUsuarioFalecimentoUserId?: string | null,
  relUsuarioFalecimentoTipoRelacionamentoId?: string | null,
};

export type DeleteRelUsuarioFalecimentoInput = {
  id: string,
  _version?: number | null,
};

export type CreateRespostaInput = {
  id?: string | null,
  pergunta: string,
  resposta: string,
  tipoPergunta: TipoPerguntaEnum,
  viviamJuntos?: boolean | null,
  assistentemensagemID?: string | null,
  skiped?: boolean | null,
  _version?: number | null,
  respostaAnonymousUserId?: string | null,
  respostaUserId?: string | null,
  respostaFalecimentoId: string,
  respostaPerguntaId?: string | null,
  respostaPerguntaOpcaoRespostaId?: string | null,
};

export type ModelRespostaConditionInput = {
  pergunta?: ModelStringInput | null,
  resposta?: ModelStringInput | null,
  tipoPergunta?: ModelTipoPerguntaEnumInput | null,
  viviamJuntos?: ModelBooleanInput | null,
  assistentemensagemID?: ModelIDInput | null,
  skiped?: ModelBooleanInput | null,
  and?: Array< ModelRespostaConditionInput | null > | null,
  or?: Array< ModelRespostaConditionInput | null > | null,
  not?: ModelRespostaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  respostaAnonymousUserId?: ModelIDInput | null,
  respostaUserId?: ModelIDInput | null,
  respostaFalecimentoId?: ModelIDInput | null,
  respostaPerguntaId?: ModelIDInput | null,
  respostaPerguntaOpcaoRespostaId?: ModelIDInput | null,
};

export type ModelTipoPerguntaEnumInput = {
  eq?: TipoPerguntaEnum | null,
  ne?: TipoPerguntaEnum | null,
};

export type UpdateRespostaInput = {
  id: string,
  pergunta?: string | null,
  resposta?: string | null,
  tipoPergunta?: TipoPerguntaEnum | null,
  viviamJuntos?: boolean | null,
  assistentemensagemID?: string | null,
  skiped?: boolean | null,
  _version?: number | null,
  respostaAnonymousUserId?: string | null,
  respostaUserId?: string | null,
  respostaFalecimentoId?: string | null,
  respostaPerguntaId?: string | null,
  respostaPerguntaOpcaoRespostaId?: string | null,
};

export type DeleteRespostaInput = {
  id: string,
  _version?: number | null,
};

export type CreatePerguntaOpcaoRespostaInput = {
  id?: string | null,
  opcaoResposta: string,
  viviamJuntos?: boolean | null,
  active?: boolean | null,
  _version?: number | null,
  perguntaOpcaoRespostaPerguntaId: string,
};

export type ModelPerguntaOpcaoRespostaConditionInput = {
  opcaoResposta?: ModelStringInput | null,
  viviamJuntos?: ModelBooleanInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPerguntaOpcaoRespostaConditionInput | null > | null,
  or?: Array< ModelPerguntaOpcaoRespostaConditionInput | null > | null,
  not?: ModelPerguntaOpcaoRespostaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  perguntaOpcaoRespostaPerguntaId?: ModelIDInput | null,
};

export type UpdatePerguntaOpcaoRespostaInput = {
  id: string,
  opcaoResposta?: string | null,
  viviamJuntos?: boolean | null,
  active?: boolean | null,
  _version?: number | null,
  perguntaOpcaoRespostaPerguntaId?: string | null,
};

export type DeletePerguntaOpcaoRespostaInput = {
  id: string,
  _version?: number | null,
};

export type CreatePerguntaNivelRelacionamentoInput = {
  id?: string | null,
  nivelRelacionamento: NivelRelacionamentoEnum,
  active?: boolean | null,
  _version?: number | null,
  perguntaNivelRelacionamentoPerguntaId: string,
};

export type ModelPerguntaNivelRelacionamentoConditionInput = {
  nivelRelacionamento?: ModelNivelRelacionamentoEnumInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPerguntaNivelRelacionamentoConditionInput | null > | null,
  or?: Array< ModelPerguntaNivelRelacionamentoConditionInput | null > | null,
  not?: ModelPerguntaNivelRelacionamentoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  perguntaNivelRelacionamentoPerguntaId?: ModelIDInput | null,
};

export type PerguntaNivelRelacionamento = {
  __typename: "PerguntaNivelRelacionamento",
  id: string,
  nivelRelacionamento: NivelRelacionamentoEnum,
  Pergunta: Pergunta,
  active?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  perguntaNivelRelacionamentoPerguntaId: string,
};

export type UpdatePerguntaNivelRelacionamentoInput = {
  id: string,
  nivelRelacionamento?: NivelRelacionamentoEnum | null,
  active?: boolean | null,
  _version?: number | null,
  perguntaNivelRelacionamentoPerguntaId?: string | null,
};

export type DeletePerguntaNivelRelacionamentoInput = {
  id: string,
  _version?: number | null,
};

export type CreatePerguntaInput = {
  id?: string | null,
  pergunta: string,
  feminino?: string | null,
  tipoPergunta: TipoPerguntaEnum,
  tipoResposta: TipoRespostaEnum,
  respostaTipoViviamJuntos: boolean,
  limitaTipoRelacionamento: boolean,
  limitaFaixaEtaria: boolean,
  limitaNivelRelacionamento: boolean,
  modeloResposta?: string | null,
  active?: boolean | null,
  _version?: number | null,
};

export type ModelPerguntaConditionInput = {
  pergunta?: ModelStringInput | null,
  feminino?: ModelStringInput | null,
  tipoPergunta?: ModelTipoPerguntaEnumInput | null,
  tipoResposta?: ModelTipoRespostaEnumInput | null,
  respostaTipoViviamJuntos?: ModelBooleanInput | null,
  limitaTipoRelacionamento?: ModelBooleanInput | null,
  limitaFaixaEtaria?: ModelBooleanInput | null,
  limitaNivelRelacionamento?: ModelBooleanInput | null,
  modeloResposta?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPerguntaConditionInput | null > | null,
  or?: Array< ModelPerguntaConditionInput | null > | null,
  not?: ModelPerguntaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTipoRespostaEnumInput = {
  eq?: TipoRespostaEnum | null,
  ne?: TipoRespostaEnum | null,
};

export type UpdatePerguntaInput = {
  id: string,
  pergunta?: string | null,
  feminino?: string | null,
  tipoPergunta?: TipoPerguntaEnum | null,
  tipoResposta?: TipoRespostaEnum | null,
  respostaTipoViviamJuntos?: boolean | null,
  limitaTipoRelacionamento?: boolean | null,
  limitaFaixaEtaria?: boolean | null,
  limitaNivelRelacionamento?: boolean | null,
  modeloResposta?: string | null,
  active?: boolean | null,
  _version?: number | null,
};

export type DeletePerguntaInput = {
  id: string,
  _version?: number | null,
};

export type CreateTipoRelacionamentoInput = {
  id?: string | null,
  nivelRelaciomento: NivelRelacionamentoEnum,
  titulo: string,
  sexo?: SexoEnum | null,
  prioridade?: number | null,
  active?: string | null,
  _version?: number | null,
};

export type ModelTipoRelacionamentoConditionInput = {
  nivelRelaciomento?: ModelNivelRelacionamentoEnumInput | null,
  titulo?: ModelStringInput | null,
  sexo?: ModelSexoEnumInput | null,
  prioridade?: ModelIntInput | null,
  active?: ModelStringInput | null,
  and?: Array< ModelTipoRelacionamentoConditionInput | null > | null,
  or?: Array< ModelTipoRelacionamentoConditionInput | null > | null,
  not?: ModelTipoRelacionamentoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSexoEnumInput = {
  eq?: SexoEnum | null,
  ne?: SexoEnum | null,
};

export type UpdateTipoRelacionamentoInput = {
  id: string,
  nivelRelaciomento?: NivelRelacionamentoEnum | null,
  titulo?: string | null,
  sexo?: SexoEnum | null,
  prioridade?: number | null,
  active?: string | null,
  _version?: number | null,
};

export type DeleteTipoRelacionamentoInput = {
  id: string,
  _version?: number | null,
};

export type CreateFalecimentoCidadeInput = {
  id?: string | null,
  QtdMensagens: number,
  nomeCidade?: string | null,
  siglaEstado?: string | null,
  _version?: number | null,
  falecimentoCidadeFalecimentoId: string,
  falecimentoCidadeCidadeId: string,
};

export type ModelFalecimentoCidadeConditionInput = {
  QtdMensagens?: ModelIntInput | null,
  nomeCidade?: ModelStringInput | null,
  siglaEstado?: ModelStringInput | null,
  and?: Array< ModelFalecimentoCidadeConditionInput | null > | null,
  or?: Array< ModelFalecimentoCidadeConditionInput | null > | null,
  not?: ModelFalecimentoCidadeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  falecimentoCidadeFalecimentoId?: ModelIDInput | null,
  falecimentoCidadeCidadeId?: ModelIDInput | null,
};

export type FalecimentoCidade = {
  __typename: "FalecimentoCidade",
  id: string,
  Falecimento: Falecimento,
  Cidade: Cidade,
  QtdMensagens: number,
  nomeCidade?: string | null,
  siglaEstado?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  falecimentoCidadeFalecimentoId: string,
  falecimentoCidadeCidadeId: string,
};

export type UpdateFalecimentoCidadeInput = {
  id: string,
  QtdMensagens?: number | null,
  nomeCidade?: string | null,
  siglaEstado?: string | null,
  _version?: number | null,
  falecimentoCidadeFalecimentoId?: string | null,
  falecimentoCidadeCidadeId?: string | null,
};

export type DeleteFalecimentoCidadeInput = {
  id: string,
  _version?: number | null,
};

export type CreateConviteAdministracaoMemorialInput = {
  id?: string | null,
  memorialID: string,
  email: string,
  status: ConviteAdministracaoMemorialStatusEnum,
  dataEnvio: string,
  dataAceite?: string | null,
  _version?: number | null,
  conviteAdministracaoMemorialConvidadoId?: string | null,
};

export type ModelConviteAdministracaoMemorialConditionInput = {
  memorialID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  status?: ModelConviteAdministracaoMemorialStatusEnumInput | null,
  dataEnvio?: ModelStringInput | null,
  dataAceite?: ModelStringInput | null,
  and?: Array< ModelConviteAdministracaoMemorialConditionInput | null > | null,
  or?: Array< ModelConviteAdministracaoMemorialConditionInput | null > | null,
  not?: ModelConviteAdministracaoMemorialConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  conviteAdministracaoMemorialConvidadoId?: ModelIDInput | null,
};

export type ModelConviteAdministracaoMemorialStatusEnumInput = {
  eq?: ConviteAdministracaoMemorialStatusEnum | null,
  ne?: ConviteAdministracaoMemorialStatusEnum | null,
};

export type UpdateConviteAdministracaoMemorialInput = {
  id: string,
  memorialID?: string | null,
  email?: string | null,
  status?: ConviteAdministracaoMemorialStatusEnum | null,
  dataEnvio?: string | null,
  dataAceite?: string | null,
  _version?: number | null,
  conviteAdministracaoMemorialConvidadoId?: string | null,
};

export type DeleteConviteAdministracaoMemorialInput = {
  id: string,
  _version?: number | null,
};

export type CreateMemorialInput = {
  id?: string | null,
  url: string,
  emailSuperAdmin: string,
  _version?: number | null,
  memorialSuperAdminId?: string | null,
  memorialCriadorId?: string | null,
  memorialFalecimentoId: string,
};

export type ModelMemorialConditionInput = {
  url?: ModelStringInput | null,
  emailSuperAdmin?: ModelStringInput | null,
  and?: Array< ModelMemorialConditionInput | null > | null,
  or?: Array< ModelMemorialConditionInput | null > | null,
  not?: ModelMemorialConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  memorialSuperAdminId?: ModelIDInput | null,
  memorialCriadorId?: ModelIDInput | null,
  memorialFalecimentoId?: ModelIDInput | null,
};

export type UpdateMemorialInput = {
  id: string,
  url?: string | null,
  emailSuperAdmin?: string | null,
  _version?: number | null,
  memorialSuperAdminId?: string | null,
  memorialCriadorId?: string | null,
  memorialFalecimentoId?: string | null,
};

export type DeleteMemorialInput = {
  id: string,
  _version?: number | null,
};

export type CreateAnonymousUserInput = {
  id?: string | null,
  nome?: string | null,
  avatar?: string | null,
  email?: string | null,
  ip?: string | null,
  sexo?: SexoEnum | null,
  _version?: number | null,
  anonymousUserCidadeId?: string | null,
};

export type ModelAnonymousUserConditionInput = {
  nome?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  email?: ModelStringInput | null,
  ip?: ModelStringInput | null,
  sexo?: ModelSexoEnumInput | null,
  and?: Array< ModelAnonymousUserConditionInput | null > | null,
  or?: Array< ModelAnonymousUserConditionInput | null > | null,
  not?: ModelAnonymousUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  anonymousUserCidadeId?: ModelIDInput | null,
};

export type UpdateAnonymousUserInput = {
  id: string,
  nome?: string | null,
  avatar?: string | null,
  email?: string | null,
  ip?: string | null,
  sexo?: SexoEnum | null,
  _version?: number | null,
  anonymousUserCidadeId?: string | null,
};

export type DeleteAnonymousUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateMensagemMuralInput = {
  id?: string | null,
  mensagem: string,
  dataPostagem: string,
  horaPostagem: string,
  status?: MensagemMuralStatusEnum | null,
  dataAprovacao?: string | null,
  cidade?: string | null,
  estado?: string | null,
  nomeUsuario?: string | null,
  tipoMensagem?: TipoMensagemEnum | null,
  resumo?: string | null,
  _version?: number | null,
  mensagemMuralCidadeId?: string | null,
  mensagemMuralFalecimentoId: string,
  mensagemMuralUserId?: string | null,
  mensagemMuralAnonymousUserId?: string | null,
  mensagemMuralAprovadoPorId?: string | null,
  mensagemMuralMemorialId?: string | null,
};

export type ModelMensagemMuralConditionInput = {
  mensagem?: ModelStringInput | null,
  dataPostagem?: ModelStringInput | null,
  horaPostagem?: ModelStringInput | null,
  status?: ModelMensagemMuralStatusEnumInput | null,
  dataAprovacao?: ModelStringInput | null,
  cidade?: ModelStringInput | null,
  estado?: ModelStringInput | null,
  nomeUsuario?: ModelStringInput | null,
  tipoMensagem?: ModelTipoMensagemEnumInput | null,
  resumo?: ModelStringInput | null,
  and?: Array< ModelMensagemMuralConditionInput | null > | null,
  or?: Array< ModelMensagemMuralConditionInput | null > | null,
  not?: ModelMensagemMuralConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  mensagemMuralCidadeId?: ModelIDInput | null,
  mensagemMuralFalecimentoId?: ModelIDInput | null,
  mensagemMuralUserId?: ModelIDInput | null,
  mensagemMuralAnonymousUserId?: ModelIDInput | null,
  mensagemMuralAprovadoPorId?: ModelIDInput | null,
  mensagemMuralMemorialId?: ModelIDInput | null,
};

export type ModelMensagemMuralStatusEnumInput = {
  eq?: MensagemMuralStatusEnum | null,
  ne?: MensagemMuralStatusEnum | null,
};

export type ModelTipoMensagemEnumInput = {
  eq?: TipoMensagemEnum | null,
  ne?: TipoMensagemEnum | null,
};

export type UpdateMensagemMuralInput = {
  id: string,
  mensagem?: string | null,
  dataPostagem?: string | null,
  horaPostagem?: string | null,
  status?: MensagemMuralStatusEnum | null,
  dataAprovacao?: string | null,
  cidade?: string | null,
  estado?: string | null,
  nomeUsuario?: string | null,
  tipoMensagem?: TipoMensagemEnum | null,
  resumo?: string | null,
  _version?: number | null,
  mensagemMuralCidadeId?: string | null,
  mensagemMuralFalecimentoId?: string | null,
  mensagemMuralUserId?: string | null,
  mensagemMuralAnonymousUserId?: string | null,
  mensagemMuralAprovadoPorId?: string | null,
  mensagemMuralMemorialId?: string | null,
};

export type DeleteMensagemMuralInput = {
  id: string,
  _version?: number | null,
};

export type CreateCemiterioInput = {
  id?: string | null,
  nome: string,
  logradouro: string,
  cep?: string | null,
  bairro?: string | null,
  cidade?: string | null,
  estado?: string | null,
  _version?: number | null,
  cemiterioCidadeId?: string | null,
  cemiterioOwnerId?: string | null,
};

export type ModelCemiterioConditionInput = {
  nome?: ModelStringInput | null,
  logradouro?: ModelStringInput | null,
  cep?: ModelStringInput | null,
  bairro?: ModelStringInput | null,
  cidade?: ModelStringInput | null,
  estado?: ModelStringInput | null,
  and?: Array< ModelCemiterioConditionInput | null > | null,
  or?: Array< ModelCemiterioConditionInput | null > | null,
  not?: ModelCemiterioConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  cemiterioCidadeId?: ModelIDInput | null,
  cemiterioOwnerId?: ModelIDInput | null,
};

export type UpdateCemiterioInput = {
  id: string,
  nome?: string | null,
  logradouro?: string | null,
  cep?: string | null,
  bairro?: string | null,
  cidade?: string | null,
  estado?: string | null,
  _version?: number | null,
  cemiterioCidadeId?: string | null,
  cemiterioOwnerId?: string | null,
};

export type DeleteCemiterioInput = {
  id: string,
  _version?: number | null,
};

export type CreateMesorregiaoInput = {
  id?: string | null,
  nome: string,
  idIbge: string,
  _version?: number | null,
  mesorregiaoEstadoId?: string | null,
};

export type ModelMesorregiaoConditionInput = {
  nome?: ModelStringInput | null,
  idIbge?: ModelStringInput | null,
  and?: Array< ModelMesorregiaoConditionInput | null > | null,
  or?: Array< ModelMesorregiaoConditionInput | null > | null,
  not?: ModelMesorregiaoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  mesorregiaoEstadoId?: ModelIDInput | null,
};

export type UpdateMesorregiaoInput = {
  id: string,
  nome?: string | null,
  idIbge?: string | null,
  _version?: number | null,
  mesorregiaoEstadoId?: string | null,
};

export type DeleteMesorregiaoInput = {
  id: string,
  _version?: number | null,
};

export type CreateMicrorregiaoInput = {
  id?: string | null,
  nome: string,
  idIbge: string,
  _version?: number | null,
  microrregiaoMesorregiaoId?: string | null,
};

export type ModelMicrorregiaoConditionInput = {
  nome?: ModelStringInput | null,
  idIbge?: ModelStringInput | null,
  and?: Array< ModelMicrorregiaoConditionInput | null > | null,
  or?: Array< ModelMicrorregiaoConditionInput | null > | null,
  not?: ModelMicrorregiaoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  microrregiaoMesorregiaoId?: ModelIDInput | null,
};

export type UpdateMicrorregiaoInput = {
  id: string,
  nome?: string | null,
  idIbge?: string | null,
  _version?: number | null,
  microrregiaoMesorregiaoId?: string | null,
};

export type DeleteMicrorregiaoInput = {
  id: string,
  _version?: number | null,
};

export type CreateRegiaoInput = {
  id?: string | null,
  sigla: string,
  nome: string,
  idIbge: string,
  _version?: number | null,
};

export type ModelRegiaoConditionInput = {
  sigla?: ModelStringInput | null,
  nome?: ModelStringInput | null,
  idIbge?: ModelStringInput | null,
  and?: Array< ModelRegiaoConditionInput | null > | null,
  or?: Array< ModelRegiaoConditionInput | null > | null,
  not?: ModelRegiaoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateRegiaoInput = {
  id: string,
  sigla?: string | null,
  nome?: string | null,
  idIbge?: string | null,
  _version?: number | null,
};

export type DeleteRegiaoInput = {
  id: string,
  _version?: number | null,
};

export type CreateEstadoInput = {
  id?: string | null,
  nome: string,
  sigla: string,
  idIbge: string,
  _version?: number | null,
  estadoRegiaoId?: string | null,
};

export type ModelEstadoConditionInput = {
  nome?: ModelStringInput | null,
  sigla?: ModelStringInput | null,
  idIbge?: ModelStringInput | null,
  and?: Array< ModelEstadoConditionInput | null > | null,
  or?: Array< ModelEstadoConditionInput | null > | null,
  not?: ModelEstadoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  estadoRegiaoId?: ModelIDInput | null,
};

export type UpdateEstadoInput = {
  id: string,
  nome?: string | null,
  sigla?: string | null,
  idIbge?: string | null,
  _version?: number | null,
  estadoRegiaoId?: string | null,
};

export type DeleteEstadoInput = {
  id: string,
  _version?: number | null,
};

export type CreateCidadeInput = {
  id?: string | null,
  nome: string,
  idIbge: string,
  _version?: number | null,
  cidadeEstadoId?: string | null,
  cidadeMicrorregiaoId?: string | null,
  cidadeMesorregiaoId?: string | null,
  cidadeRegiaoId?: string | null,
};

export type ModelCidadeConditionInput = {
  nome?: ModelStringInput | null,
  idIbge?: ModelStringInput | null,
  and?: Array< ModelCidadeConditionInput | null > | null,
  or?: Array< ModelCidadeConditionInput | null > | null,
  not?: ModelCidadeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  cidadeEstadoId?: ModelIDInput | null,
  cidadeMicrorregiaoId?: ModelIDInput | null,
  cidadeMesorregiaoId?: ModelIDInput | null,
  cidadeRegiaoId?: ModelIDInput | null,
};

export type UpdateCidadeInput = {
  id: string,
  nome?: string | null,
  idIbge?: string | null,
  _version?: number | null,
  cidadeEstadoId?: string | null,
  cidadeMicrorregiaoId?: string | null,
  cidadeMesorregiaoId?: string | null,
  cidadeRegiaoId?: string | null,
};

export type DeleteCidadeInput = {
  id: string,
  _version?: number | null,
};

export type CreateVelorioInput = {
  id?: string | null,
  nome: string,
  logradouro: string,
  cep?: string | null,
  bairro?: string | null,
  cidade?: string | null,
  estado?: string | null,
  _version?: number | null,
  velorioCidadeId?: string | null,
  velorioOwnerId?: string | null,
};

export type ModelVelorioConditionInput = {
  nome?: ModelStringInput | null,
  logradouro?: ModelStringInput | null,
  cep?: ModelStringInput | null,
  bairro?: ModelStringInput | null,
  cidade?: ModelStringInput | null,
  estado?: ModelStringInput | null,
  and?: Array< ModelVelorioConditionInput | null > | null,
  or?: Array< ModelVelorioConditionInput | null > | null,
  not?: ModelVelorioConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  velorioCidadeId?: ModelIDInput | null,
  velorioOwnerId?: ModelIDInput | null,
};

export type UpdateVelorioInput = {
  id: string,
  nome?: string | null,
  logradouro?: string | null,
  cep?: string | null,
  bairro?: string | null,
  cidade?: string | null,
  estado?: string | null,
  _version?: number | null,
  velorioCidadeId?: string | null,
  velorioOwnerId?: string | null,
};

export type DeleteVelorioInput = {
  id: string,
  _version?: number | null,
};

export type CreateFalecimentoInput = {
  id?: string | null,
  nome: string,
  sobrenome?: string | null,
  dataFalecimento: string,
  searchName: string,
  idade: number,
  tempoDeVida: string,
  meses: number,
  dataInicioVelorio?: string | null,
  horaInicioVelorio?: string | null,
  dataFimVelorio?: string | null,
  horaFimVelorio?: string | null,
  dataSepultamento?: string | null,
  horaSepultamento?: string | null,
  salaVelorio?: string | null,
  sobre?: string | null,
  imageKey?: string | null,
  apelido?: string | null,
  cidade?: string | null,
  estado?: string | null,
  sexo?: SexoEnum | null,
  dataNascimento: string,
  faixaEtaria?: FaixaEtariaEnum | null,
  _version?: number | null,
  falecimentoFunerariaId?: string | null,
  falecimentoVelorioId?: string | null,
  falecimentoCemiterioId?: string | null,
  falecimentoCidadeId?: string | null,
  falecimentoOpenAiAssistantId?: string | null,
};

export type ModelFalecimentoConditionInput = {
  nome?: ModelStringInput | null,
  sobrenome?: ModelStringInput | null,
  dataFalecimento?: ModelStringInput | null,
  searchName?: ModelStringInput | null,
  idade?: ModelIntInput | null,
  tempoDeVida?: ModelStringInput | null,
  meses?: ModelIntInput | null,
  dataInicioVelorio?: ModelStringInput | null,
  horaInicioVelorio?: ModelStringInput | null,
  dataFimVelorio?: ModelStringInput | null,
  horaFimVelorio?: ModelStringInput | null,
  dataSepultamento?: ModelStringInput | null,
  horaSepultamento?: ModelStringInput | null,
  salaVelorio?: ModelStringInput | null,
  sobre?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  apelido?: ModelStringInput | null,
  cidade?: ModelStringInput | null,
  estado?: ModelStringInput | null,
  sexo?: ModelSexoEnumInput | null,
  dataNascimento?: ModelStringInput | null,
  faixaEtaria?: ModelFaixaEtariaEnumInput | null,
  and?: Array< ModelFalecimentoConditionInput | null > | null,
  or?: Array< ModelFalecimentoConditionInput | null > | null,
  not?: ModelFalecimentoConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  falecimentoFunerariaId?: ModelIDInput | null,
  falecimentoVelorioId?: ModelIDInput | null,
  falecimentoCemiterioId?: ModelIDInput | null,
  falecimentoCidadeId?: ModelIDInput | null,
  falecimentoOpenAiAssistantId?: ModelIDInput | null,
};

export type UpdateFalecimentoInput = {
  id: string,
  nome?: string | null,
  sobrenome?: string | null,
  dataFalecimento?: string | null,
  searchName?: string | null,
  idade?: number | null,
  tempoDeVida?: string | null,
  meses?: number | null,
  dataInicioVelorio?: string | null,
  horaInicioVelorio?: string | null,
  dataFimVelorio?: string | null,
  horaFimVelorio?: string | null,
  dataSepultamento?: string | null,
  horaSepultamento?: string | null,
  salaVelorio?: string | null,
  sobre?: string | null,
  imageKey?: string | null,
  apelido?: string | null,
  cidade?: string | null,
  estado?: string | null,
  sexo?: SexoEnum | null,
  dataNascimento?: string | null,
  faixaEtaria?: FaixaEtariaEnum | null,
  _version?: number | null,
  falecimentoFunerariaId?: string | null,
  falecimentoVelorioId?: string | null,
  falecimentoCemiterioId?: string | null,
  falecimentoCidadeId?: string | null,
  falecimentoOpenAiAssistantId?: string | null,
};

export type DeleteFalecimentoInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  email: string,
  nome?: string | null,
  status?: UserStatusEnum | null,
  avatar?: string | null,
  imageKey?: string | null,
  role?: UserRoleEnum | null,
  sexo?: SexoEnum | null,
  lastIpAddress?: string | null,
  anonymousUserId?: string | null,
  _version?: number | null,
  userCidadeId?: string | null,
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  nome?: ModelStringInput | null,
  status?: ModelUserStatusEnumInput | null,
  avatar?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  role?: ModelUserRoleEnumInput | null,
  sexo?: ModelSexoEnumInput | null,
  lastIpAddress?: ModelStringInput | null,
  anonymousUserId?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  userCidadeId?: ModelIDInput | null,
};

export type ModelUserStatusEnumInput = {
  eq?: UserStatusEnum | null,
  ne?: UserStatusEnum | null,
};

export type ModelUserRoleEnumInput = {
  eq?: UserRoleEnum | null,
  ne?: UserRoleEnum | null,
};

export type UpdateUserInput = {
  id: string,
  email?: string | null,
  nome?: string | null,
  status?: UserStatusEnum | null,
  avatar?: string | null,
  imageKey?: string | null,
  role?: UserRoleEnum | null,
  sexo?: SexoEnum | null,
  lastIpAddress?: string | null,
  anonymousUserId?: string | null,
  _version?: number | null,
  userCidadeId?: string | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateFunerariaInput = {
  id?: string | null,
  cnpj: string,
  nome: string,
  logradouro: string,
  cep: string,
  telefone1?: string | null,
  telefone2?: string | null,
  email?: string | null,
  site?: string | null,
  cidade?: string | null,
  estado?: string | null,
  _version?: number | null,
  funerariaCidadeId: string,
};

export type ModelFunerariaConditionInput = {
  cnpj?: ModelStringInput | null,
  nome?: ModelStringInput | null,
  logradouro?: ModelStringInput | null,
  cep?: ModelStringInput | null,
  telefone1?: ModelStringInput | null,
  telefone2?: ModelStringInput | null,
  email?: ModelStringInput | null,
  site?: ModelStringInput | null,
  cidade?: ModelStringInput | null,
  estado?: ModelStringInput | null,
  and?: Array< ModelFunerariaConditionInput | null > | null,
  or?: Array< ModelFunerariaConditionInput | null > | null,
  not?: ModelFunerariaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  funerariaCidadeId?: ModelIDInput | null,
};

export type UpdateFunerariaInput = {
  id: string,
  cnpj?: string | null,
  nome?: string | null,
  logradouro?: string | null,
  cep?: string | null,
  telefone1?: string | null,
  telefone2?: string | null,
  email?: string | null,
  site?: string | null,
  cidade?: string | null,
  estado?: string | null,
  _version?: number | null,
  funerariaCidadeId?: string | null,
};

export type DeleteFunerariaInput = {
  id: string,
  _version?: number | null,
};

export type CreateMemorialUserInput = {
  id?: string | null,
  memorialId: string,
  userId: string,
  _version?: number | null,
};

export type ModelMemorialUserConditionInput = {
  memorialId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelMemorialUserConditionInput | null > | null,
  or?: Array< ModelMemorialUserConditionInput | null > | null,
  not?: ModelMemorialUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateMemorialUserInput = {
  id: string,
  memorialId?: string | null,
  userId?: string | null,
  _version?: number | null,
};

export type DeleteMemorialUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateCemiterioFunerariaInput = {
  id?: string | null,
  cemiterioId: string,
  funerariaId: string,
  _version?: number | null,
};

export type ModelCemiterioFunerariaConditionInput = {
  cemiterioId?: ModelIDInput | null,
  funerariaId?: ModelIDInput | null,
  and?: Array< ModelCemiterioFunerariaConditionInput | null > | null,
  or?: Array< ModelCemiterioFunerariaConditionInput | null > | null,
  not?: ModelCemiterioFunerariaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateCemiterioFunerariaInput = {
  id: string,
  cemiterioId?: string | null,
  funerariaId?: string | null,
  _version?: number | null,
};

export type DeleteCemiterioFunerariaInput = {
  id: string,
  _version?: number | null,
};

export type CreateCemiterioVelorioInput = {
  id?: string | null,
  cemiterioId: string,
  velorioId: string,
  _version?: number | null,
};

export type ModelCemiterioVelorioConditionInput = {
  cemiterioId?: ModelIDInput | null,
  velorioId?: ModelIDInput | null,
  and?: Array< ModelCemiterioVelorioConditionInput | null > | null,
  or?: Array< ModelCemiterioVelorioConditionInput | null > | null,
  not?: ModelCemiterioVelorioConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateCemiterioVelorioInput = {
  id: string,
  cemiterioId?: string | null,
  velorioId?: string | null,
  _version?: number | null,
};

export type DeleteCemiterioVelorioInput = {
  id: string,
  _version?: number | null,
};

export type CreateVelorioFunerariaInput = {
  id?: string | null,
  velorioId: string,
  funerariaId: string,
  _version?: number | null,
};

export type ModelVelorioFunerariaConditionInput = {
  velorioId?: ModelIDInput | null,
  funerariaId?: ModelIDInput | null,
  and?: Array< ModelVelorioFunerariaConditionInput | null > | null,
  or?: Array< ModelVelorioFunerariaConditionInput | null > | null,
  not?: ModelVelorioFunerariaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateVelorioFunerariaInput = {
  id: string,
  velorioId?: string | null,
  funerariaId?: string | null,
  _version?: number | null,
};

export type DeleteVelorioFunerariaInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserFunerariaInput = {
  id?: string | null,
  userId: string,
  funerariaId: string,
  _version?: number | null,
};

export type ModelUserFunerariaConditionInput = {
  userId?: ModelIDInput | null,
  funerariaId?: ModelIDInput | null,
  and?: Array< ModelUserFunerariaConditionInput | null > | null,
  or?: Array< ModelUserFunerariaConditionInput | null > | null,
  not?: ModelUserFunerariaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateUserFunerariaInput = {
  id: string,
  userId?: string | null,
  funerariaId?: string | null,
  _version?: number | null,
};

export type DeleteUserFunerariaInput = {
  id: string,
  _version?: number | null,
};

export type ModelMensagemMuralFilterInput = {
  id?: ModelIDInput | null,
  mensagem?: ModelStringInput | null,
  dataPostagem?: ModelStringInput | null,
  horaPostagem?: ModelStringInput | null,
  status?: ModelMensagemMuralStatusEnumInput | null,
  dataAprovacao?: ModelStringInput | null,
  cidade?: ModelStringInput | null,
  estado?: ModelStringInput | null,
  nomeUsuario?: ModelStringInput | null,
  tipoMensagem?: ModelTipoMensagemEnumInput | null,
  resumo?: ModelStringInput | null,
  and?: Array< ModelMensagemMuralFilterInput | null > | null,
  or?: Array< ModelMensagemMuralFilterInput | null > | null,
  not?: ModelMensagemMuralFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  mensagemMuralCidadeId?: ModelIDInput | null,
  mensagemMuralFalecimentoId?: ModelIDInput | null,
  mensagemMuralUserId?: ModelIDInput | null,
  mensagemMuralAnonymousUserId?: ModelIDInput | null,
  mensagemMuralAprovadoPorId?: ModelIDInput | null,
  mensagemMuralMemorialId?: ModelIDInput | null,
};

export type ModelMensagemMuralConnection = {
  __typename: "ModelMensagemMuralConnection",
  items:  Array<MensagemMural | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPostFilterInput = {
  id?: ModelIDInput | null,
  postType?: ModelPostTypeEnumInput | null,
  postAction?: ModelPostActionEnumInput | null,
  text?: ModelStringInput | null,
  url?: ModelStringInput | null,
  datePost?: ModelStringInput | null,
  timePost?: ModelStringInput | null,
  resourceId?: ModelStringInput | null,
  and?: Array< ModelPostFilterInput | null > | null,
  or?: Array< ModelPostFilterInput | null > | null,
  not?: ModelPostFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  postUserId?: ModelIDInput | null,
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPostImageFilterInput = {
  id?: ModelIDInput | null,
  imageKey?: ModelStringInput | null,
  context?: ModelStringInput | null,
  postID?: ModelIDInput | null,
  and?: Array< ModelPostImageFilterInput | null > | null,
  or?: Array< ModelPostImageFilterInput | null > | null,
  not?: ModelPostImageFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelMensagemMuralEngajamentoFilterInput = {
  id?: ModelIDInput | null,
  views?: ModelIntInput | null,
  and?: Array< ModelMensagemMuralEngajamentoFilterInput | null > | null,
  or?: Array< ModelMensagemMuralEngajamentoFilterInput | null > | null,
  not?: ModelMensagemMuralEngajamentoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  mensagemMuralEngajamentoMensagemMuralId?: ModelIDInput | null,
};

export type ModelMensagemMuralEngajamentoConnection = {
  __typename: "ModelMensagemMuralEngajamentoConnection",
  items:  Array<MensagemMuralEngajamento | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMensagemMuralViewsFilterInput = {
  id?: ModelIDInput | null,
  dataVisualizacao?: ModelStringInput | null,
  horaVisualizacao?: ModelStringInput | null,
  and?: Array< ModelMensagemMuralViewsFilterInput | null > | null,
  or?: Array< ModelMensagemMuralViewsFilterInput | null > | null,
  not?: ModelMensagemMuralViewsFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  mensagemMuralViewsUserId?: ModelIDInput | null,
  mensagemMuralViewsMensagemMuralId?: ModelIDInput | null,
};

export type ModelMensagemMuralViewsConnection = {
  __typename: "ModelMensagemMuralViewsConnection",
  items:  Array<MensagemMuralViews | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMensagemMuralFalecimentoFotoFilterInput = {
  id?: ModelIDInput | null,
  tipoFoto?: ModelTipoFotoInput | null,
  isCover?: ModelBooleanInput | null,
  and?: Array< ModelMensagemMuralFalecimentoFotoFilterInput | null > | null,
  or?: Array< ModelMensagemMuralFalecimentoFotoFilterInput | null > | null,
  not?: ModelMensagemMuralFalecimentoFotoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  mensagemMuralFalecimentoFotoMensagemMuralId?: ModelIDInput | null,
  mensagemMuralFalecimentoFotoFalecimentoFotoId?: ModelIDInput | null,
};

export type ModelMensagemMuralFalecimentoFotoConnection = {
  __typename: "ModelMensagemMuralFalecimentoFotoConnection",
  items:  Array<MensagemMuralFalecimentoFoto | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFalecimentoFotoFilterInput = {
  id?: ModelIDInput | null,
  fileKey?: ModelStringInput | null,
  status?: ModelContentStatusEnumInput | null,
  tipo?: ModelTipoFotoInput | null,
  falecimentoID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  isCover?: ModelBooleanInput | null,
  and?: Array< ModelFalecimentoFotoFilterInput | null > | null,
  or?: Array< ModelFalecimentoFotoFilterInput | null > | null,
  not?: ModelFalecimentoFotoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOpenAiGeneratedMessageFilterInput = {
  id?: ModelIDInput | null,
  falecimentoId?: ModelStringInput | null,
  anonymousUserId?: ModelStringInput | null,
  messageId?: ModelStringInput | null,
  runId?: ModelStringInput | null,
  threadId?: ModelStringInput | null,
  created_at?: ModelIntInput | null,
  contentTextValue?: ModelStringInput | null,
  assistantId?: ModelStringInput | null,
  and?: Array< ModelOpenAiGeneratedMessageFilterInput | null > | null,
  or?: Array< ModelOpenAiGeneratedMessageFilterInput | null > | null,
  not?: ModelOpenAiGeneratedMessageFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOpenAiGeneratedMessageConnection = {
  __typename: "ModelOpenAiGeneratedMessageConnection",
  items:  Array<OpenAiGeneratedMessage | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOpenAiRunFilterInput = {
  id?: ModelIDInput | null,
  runId?: ModelStringInput | null,
  object?: ModelStringInput | null,
  created_at?: ModelIntInput | null,
  status?: ModelStringInput | null,
  expires_at?: ModelIntInput | null,
  started_at?: ModelIntInput | null,
  cancelled_at?: ModelIntInput | null,
  failed_at?: ModelIntInput | null,
  completed_at?: ModelIntInput | null,
  model?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  assistant_id?: ModelStringInput | null,
  thread_id?: ModelStringInput | null,
  assistentemensagemID?: ModelIDInput | null,
  and?: Array< ModelOpenAiRunFilterInput | null > | null,
  or?: Array< ModelOpenAiRunFilterInput | null > | null,
  not?: ModelOpenAiRunFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  openAiRunOpenAiThreadId?: ModelIDInput | null,
  openAiRunOpenAiAssistantId?: ModelIDInput | null,
  openAiRunAnonymousUserId?: ModelIDInput | null,
};

export type ModelOpenAiAssistantFilterInput = {
  id?: ModelIDInput | null,
  assistantId?: ModelStringInput | null,
  object?: ModelStringInput | null,
  model?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  and?: Array< ModelOpenAiAssistantFilterInput | null > | null,
  or?: Array< ModelOpenAiAssistantFilterInput | null > | null,
  not?: ModelOpenAiAssistantFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOpenAiAssistantConnection = {
  __typename: "ModelOpenAiAssistantConnection",
  items:  Array<OpenAiAssistant | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAssistenteMensagemFilterInput = {
  id?: ModelIDInput | null,
  tamanhoMensagem?: ModelTamanhoMensagemEnuInput | null,
  tipoPessoa?: ModelTipoPessoaEnumInput | null,
  emNomeDe?: ModelStringInput | null,
  nomeUsuario?: ModelStringInput | null,
  mensagemUsuario?: ModelStringInput | null,
  instrucao?: ModelStringInput | null,
  Step?: ModelStringInput | null,
  Status?: ModelAssistenteStatusEnumInput | null,
  mensagemReescrita?: ModelStringInput | null,
  mensagemGerada?: ModelStringInput | null,
  mensagemCorrigida?: ModelStringInput | null,
  mensagemEditada?: ModelStringInput | null,
  tipoMensagemSelecionada?: ModelTipoMensagemSelecionadaEnumInput | null,
  and?: Array< ModelAssistenteMensagemFilterInput | null > | null,
  or?: Array< ModelAssistenteMensagemFilterInput | null > | null,
  not?: ModelAssistenteMensagemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  assistenteMensagemOpenAiThreadId?: ModelIDInput | null,
  assistenteMensagemFalecimentoId?: ModelIDInput | null,
  assistenteMensagemAnonymousUserId?: ModelIDInput | null,
  assistenteMensagemOpenAiAssistantId?: ModelIDInput | null,
  assistenteMensagemRelUsuarioFalecimentoId?: ModelIDInput | null,
  assistenteMensagemUserId?: ModelIDInput | null,
  assistenteMensagemTipoRelacionamentoId?: ModelIDInput | null,
  assistenteMensagemCidadeId?: ModelIDInput | null,
  assistenteMensagemOpenAiGeneratedMessageId?: ModelIDInput | null,
  assistenteMensagemMemorialId?: ModelIDInput | null,
};

export type ModelAssistenteMensagemConnection = {
  __typename: "ModelAssistenteMensagemConnection",
  items:  Array<AssistenteMensagem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOpenAiThreadFilterInput = {
  id?: ModelIDInput | null,
  threadId?: ModelStringInput | null,
  object?: ModelStringInput | null,
  and?: Array< ModelOpenAiThreadFilterInput | null > | null,
  or?: Array< ModelOpenAiThreadFilterInput | null > | null,
  not?: ModelOpenAiThreadFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  openAiThreadOpenAiAssistantId?: ModelIDInput | null,
  openAiThreadAnonymousUserId?: ModelIDInput | null,
};

export type ModelOpenAiThreadConnection = {
  __typename: "ModelOpenAiThreadConnection",
  items:  Array<OpenAiThread | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPerguntaFaixaEtariaFilterInput = {
  id?: ModelIDInput | null,
  faixaEtaria?: ModelFaixaEtariaEnumInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPerguntaFaixaEtariaFilterInput | null > | null,
  or?: Array< ModelPerguntaFaixaEtariaFilterInput | null > | null,
  not?: ModelPerguntaFaixaEtariaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  perguntaFaixaEtariaPerguntaId?: ModelIDInput | null,
};

export type ModelPerguntaFaixaEtariaConnection = {
  __typename: "ModelPerguntaFaixaEtariaConnection",
  items:  Array<PerguntaFaixaEtaria | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPerguntaTipoRelacionamentoFilterInput = {
  id?: ModelIDInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPerguntaTipoRelacionamentoFilterInput | null > | null,
  or?: Array< ModelPerguntaTipoRelacionamentoFilterInput | null > | null,
  not?: ModelPerguntaTipoRelacionamentoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  perguntaTipoRelacionamentoTipoRelacionamentoId?: ModelIDInput | null,
  perguntaTipoRelacionamentoPerguntaId?: ModelIDInput | null,
};

export type ModelPerguntaTipoRelacionamentoConnection = {
  __typename: "ModelPerguntaTipoRelacionamentoConnection",
  items:  Array<PerguntaTipoRelacionamento | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRelUsuarioFalecimentoFilterInput = {
  id?: ModelIDInput | null,
  nivelRelacionamento?: ModelNivelRelacionamentoEnumInput | null,
  and?: Array< ModelRelUsuarioFalecimentoFilterInput | null > | null,
  or?: Array< ModelRelUsuarioFalecimentoFilterInput | null > | null,
  not?: ModelRelUsuarioFalecimentoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  relUsuarioFalecimentoFalecimentoId?: ModelIDInput | null,
  relUsuarioFalecimentoAnonymousUserId?: ModelIDInput | null,
  relUsuarioFalecimentoUserId?: ModelIDInput | null,
  relUsuarioFalecimentoTipoRelacionamentoId?: ModelIDInput | null,
};

export type ModelRelUsuarioFalecimentoConnection = {
  __typename: "ModelRelUsuarioFalecimentoConnection",
  items:  Array<RelUsuarioFalecimento | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRespostaFilterInput = {
  id?: ModelIDInput | null,
  pergunta?: ModelStringInput | null,
  resposta?: ModelStringInput | null,
  tipoPergunta?: ModelTipoPerguntaEnumInput | null,
  viviamJuntos?: ModelBooleanInput | null,
  assistentemensagemID?: ModelIDInput | null,
  skiped?: ModelBooleanInput | null,
  and?: Array< ModelRespostaFilterInput | null > | null,
  or?: Array< ModelRespostaFilterInput | null > | null,
  not?: ModelRespostaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  respostaAnonymousUserId?: ModelIDInput | null,
  respostaUserId?: ModelIDInput | null,
  respostaFalecimentoId?: ModelIDInput | null,
  respostaPerguntaId?: ModelIDInput | null,
  respostaPerguntaOpcaoRespostaId?: ModelIDInput | null,
};

export type ModelPerguntaOpcaoRespostaFilterInput = {
  id?: ModelIDInput | null,
  opcaoResposta?: ModelStringInput | null,
  viviamJuntos?: ModelBooleanInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPerguntaOpcaoRespostaFilterInput | null > | null,
  or?: Array< ModelPerguntaOpcaoRespostaFilterInput | null > | null,
  not?: ModelPerguntaOpcaoRespostaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  perguntaOpcaoRespostaPerguntaId?: ModelIDInput | null,
};

export type ModelPerguntaOpcaoRespostaConnection = {
  __typename: "ModelPerguntaOpcaoRespostaConnection",
  items:  Array<PerguntaOpcaoResposta | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPerguntaNivelRelacionamentoFilterInput = {
  id?: ModelIDInput | null,
  nivelRelacionamento?: ModelNivelRelacionamentoEnumInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPerguntaNivelRelacionamentoFilterInput | null > | null,
  or?: Array< ModelPerguntaNivelRelacionamentoFilterInput | null > | null,
  not?: ModelPerguntaNivelRelacionamentoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  perguntaNivelRelacionamentoPerguntaId?: ModelIDInput | null,
};

export type ModelPerguntaNivelRelacionamentoConnection = {
  __typename: "ModelPerguntaNivelRelacionamentoConnection",
  items:  Array<PerguntaNivelRelacionamento | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPerguntaFilterInput = {
  id?: ModelIDInput | null,
  pergunta?: ModelStringInput | null,
  feminino?: ModelStringInput | null,
  tipoPergunta?: ModelTipoPerguntaEnumInput | null,
  tipoResposta?: ModelTipoRespostaEnumInput | null,
  respostaTipoViviamJuntos?: ModelBooleanInput | null,
  limitaTipoRelacionamento?: ModelBooleanInput | null,
  limitaFaixaEtaria?: ModelBooleanInput | null,
  limitaNivelRelacionamento?: ModelBooleanInput | null,
  modeloResposta?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPerguntaFilterInput | null > | null,
  or?: Array< ModelPerguntaFilterInput | null > | null,
  not?: ModelPerguntaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPerguntaConnection = {
  __typename: "ModelPerguntaConnection",
  items:  Array<Pergunta | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTipoRelacionamentoFilterInput = {
  id?: ModelIDInput | null,
  nivelRelaciomento?: ModelNivelRelacionamentoEnumInput | null,
  titulo?: ModelStringInput | null,
  sexo?: ModelSexoEnumInput | null,
  prioridade?: ModelIntInput | null,
  active?: ModelStringInput | null,
  and?: Array< ModelTipoRelacionamentoFilterInput | null > | null,
  or?: Array< ModelTipoRelacionamentoFilterInput | null > | null,
  not?: ModelTipoRelacionamentoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTipoRelacionamentoConnection = {
  __typename: "ModelTipoRelacionamentoConnection",
  items:  Array<TipoRelacionamento | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFalecimentoCidadeFilterInput = {
  id?: ModelIDInput | null,
  QtdMensagens?: ModelIntInput | null,
  nomeCidade?: ModelStringInput | null,
  siglaEstado?: ModelStringInput | null,
  and?: Array< ModelFalecimentoCidadeFilterInput | null > | null,
  or?: Array< ModelFalecimentoCidadeFilterInput | null > | null,
  not?: ModelFalecimentoCidadeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  falecimentoCidadeFalecimentoId?: ModelIDInput | null,
  falecimentoCidadeCidadeId?: ModelIDInput | null,
};

export type ModelFalecimentoCidadeConnection = {
  __typename: "ModelFalecimentoCidadeConnection",
  items:  Array<FalecimentoCidade | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelConviteAdministracaoMemorialFilterInput = {
  id?: ModelIDInput | null,
  memorialID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  status?: ModelConviteAdministracaoMemorialStatusEnumInput | null,
  dataEnvio?: ModelStringInput | null,
  dataAceite?: ModelStringInput | null,
  and?: Array< ModelConviteAdministracaoMemorialFilterInput | null > | null,
  or?: Array< ModelConviteAdministracaoMemorialFilterInput | null > | null,
  not?: ModelConviteAdministracaoMemorialFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  conviteAdministracaoMemorialConvidadoId?: ModelIDInput | null,
};

export type ModelMemorialFilterInput = {
  id?: ModelIDInput | null,
  url?: ModelStringInput | null,
  emailSuperAdmin?: ModelStringInput | null,
  and?: Array< ModelMemorialFilterInput | null > | null,
  or?: Array< ModelMemorialFilterInput | null > | null,
  not?: ModelMemorialFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  memorialSuperAdminId?: ModelIDInput | null,
  memorialCriadorId?: ModelIDInput | null,
  memorialFalecimentoId?: ModelIDInput | null,
};

export type ModelMemorialConnection = {
  __typename: "ModelMemorialConnection",
  items:  Array<Memorial | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAnonymousUserFilterInput = {
  id?: ModelIDInput | null,
  nome?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  email?: ModelStringInput | null,
  ip?: ModelStringInput | null,
  sexo?: ModelSexoEnumInput | null,
  and?: Array< ModelAnonymousUserFilterInput | null > | null,
  or?: Array< ModelAnonymousUserFilterInput | null > | null,
  not?: ModelAnonymousUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  anonymousUserCidadeId?: ModelIDInput | null,
};

export type ModelAnonymousUserConnection = {
  __typename: "ModelAnonymousUserConnection",
  items:  Array<AnonymousUser | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCemiterioFilterInput = {
  id?: ModelIDInput | null,
  nome?: ModelStringInput | null,
  logradouro?: ModelStringInput | null,
  cep?: ModelStringInput | null,
  bairro?: ModelStringInput | null,
  cidade?: ModelStringInput | null,
  estado?: ModelStringInput | null,
  and?: Array< ModelCemiterioFilterInput | null > | null,
  or?: Array< ModelCemiterioFilterInput | null > | null,
  not?: ModelCemiterioFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  cemiterioCidadeId?: ModelIDInput | null,
  cemiterioOwnerId?: ModelIDInput | null,
};

export type ModelCemiterioConnection = {
  __typename: "ModelCemiterioConnection",
  items:  Array<Cemiterio | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMesorregiaoFilterInput = {
  id?: ModelIDInput | null,
  nome?: ModelStringInput | null,
  idIbge?: ModelStringInput | null,
  and?: Array< ModelMesorregiaoFilterInput | null > | null,
  or?: Array< ModelMesorregiaoFilterInput | null > | null,
  not?: ModelMesorregiaoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  mesorregiaoEstadoId?: ModelIDInput | null,
};

export type ModelMesorregiaoConnection = {
  __typename: "ModelMesorregiaoConnection",
  items:  Array<Mesorregiao | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMicrorregiaoFilterInput = {
  id?: ModelIDInput | null,
  nome?: ModelStringInput | null,
  idIbge?: ModelStringInput | null,
  and?: Array< ModelMicrorregiaoFilterInput | null > | null,
  or?: Array< ModelMicrorregiaoFilterInput | null > | null,
  not?: ModelMicrorregiaoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  microrregiaoMesorregiaoId?: ModelIDInput | null,
};

export type ModelMicrorregiaoConnection = {
  __typename: "ModelMicrorregiaoConnection",
  items:  Array<Microrregiao | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRegiaoFilterInput = {
  id?: ModelIDInput | null,
  sigla?: ModelStringInput | null,
  nome?: ModelStringInput | null,
  idIbge?: ModelStringInput | null,
  and?: Array< ModelRegiaoFilterInput | null > | null,
  or?: Array< ModelRegiaoFilterInput | null > | null,
  not?: ModelRegiaoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRegiaoConnection = {
  __typename: "ModelRegiaoConnection",
  items:  Array<Regiao | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelEstadoFilterInput = {
  id?: ModelIDInput | null,
  nome?: ModelStringInput | null,
  sigla?: ModelStringInput | null,
  idIbge?: ModelStringInput | null,
  and?: Array< ModelEstadoFilterInput | null > | null,
  or?: Array< ModelEstadoFilterInput | null > | null,
  not?: ModelEstadoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  estadoRegiaoId?: ModelIDInput | null,
};

export type ModelEstadoConnection = {
  __typename: "ModelEstadoConnection",
  items:  Array<Estado | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCidadeFilterInput = {
  id?: ModelIDInput | null,
  nome?: ModelStringInput | null,
  idIbge?: ModelStringInput | null,
  and?: Array< ModelCidadeFilterInput | null > | null,
  or?: Array< ModelCidadeFilterInput | null > | null,
  not?: ModelCidadeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  cidadeEstadoId?: ModelIDInput | null,
  cidadeMicrorregiaoId?: ModelIDInput | null,
  cidadeMesorregiaoId?: ModelIDInput | null,
  cidadeRegiaoId?: ModelIDInput | null,
};

export type ModelCidadeConnection = {
  __typename: "ModelCidadeConnection",
  items:  Array<Cidade | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelVelorioFilterInput = {
  id?: ModelIDInput | null,
  nome?: ModelStringInput | null,
  logradouro?: ModelStringInput | null,
  cep?: ModelStringInput | null,
  bairro?: ModelStringInput | null,
  cidade?: ModelStringInput | null,
  estado?: ModelStringInput | null,
  and?: Array< ModelVelorioFilterInput | null > | null,
  or?: Array< ModelVelorioFilterInput | null > | null,
  not?: ModelVelorioFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  velorioCidadeId?: ModelIDInput | null,
  velorioOwnerId?: ModelIDInput | null,
};

export type ModelVelorioConnection = {
  __typename: "ModelVelorioConnection",
  items:  Array<Velorio | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFalecimentoFilterInput = {
  id?: ModelIDInput | null,
  nome?: ModelStringInput | null,
  sobrenome?: ModelStringInput | null,
  dataFalecimento?: ModelStringInput | null,
  searchName?: ModelStringInput | null,
  idade?: ModelIntInput | null,
  tempoDeVida?: ModelStringInput | null,
  meses?: ModelIntInput | null,
  dataInicioVelorio?: ModelStringInput | null,
  horaInicioVelorio?: ModelStringInput | null,
  dataFimVelorio?: ModelStringInput | null,
  horaFimVelorio?: ModelStringInput | null,
  dataSepultamento?: ModelStringInput | null,
  horaSepultamento?: ModelStringInput | null,
  salaVelorio?: ModelStringInput | null,
  sobre?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  apelido?: ModelStringInput | null,
  cidade?: ModelStringInput | null,
  estado?: ModelStringInput | null,
  sexo?: ModelSexoEnumInput | null,
  dataNascimento?: ModelStringInput | null,
  faixaEtaria?: ModelFaixaEtariaEnumInput | null,
  and?: Array< ModelFalecimentoFilterInput | null > | null,
  or?: Array< ModelFalecimentoFilterInput | null > | null,
  not?: ModelFalecimentoFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  falecimentoFunerariaId?: ModelIDInput | null,
  falecimentoVelorioId?: ModelIDInput | null,
  falecimentoCemiterioId?: ModelIDInput | null,
  falecimentoCidadeId?: ModelIDInput | null,
  falecimentoOpenAiAssistantId?: ModelIDInput | null,
};

export type ModelFalecimentoConnection = {
  __typename: "ModelFalecimentoConnection",
  items:  Array<Falecimento | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  nome?: ModelStringInput | null,
  status?: ModelUserStatusEnumInput | null,
  avatar?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  role?: ModelUserRoleEnumInput | null,
  sexo?: ModelSexoEnumInput | null,
  lastIpAddress?: ModelStringInput | null,
  anonymousUserId?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  userCidadeId?: ModelIDInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFunerariaFilterInput = {
  id?: ModelIDInput | null,
  cnpj?: ModelStringInput | null,
  nome?: ModelStringInput | null,
  logradouro?: ModelStringInput | null,
  cep?: ModelStringInput | null,
  telefone1?: ModelStringInput | null,
  telefone2?: ModelStringInput | null,
  email?: ModelStringInput | null,
  site?: ModelStringInput | null,
  cidade?: ModelStringInput | null,
  estado?: ModelStringInput | null,
  and?: Array< ModelFunerariaFilterInput | null > | null,
  or?: Array< ModelFunerariaFilterInput | null > | null,
  not?: ModelFunerariaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  funerariaCidadeId?: ModelIDInput | null,
};

export type ModelFunerariaConnection = {
  __typename: "ModelFunerariaConnection",
  items:  Array<Funeraria | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMemorialUserFilterInput = {
  id?: ModelIDInput | null,
  memorialId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelMemorialUserFilterInput | null > | null,
  or?: Array< ModelMemorialUserFilterInput | null > | null,
  not?: ModelMemorialUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCemiterioFunerariaFilterInput = {
  id?: ModelIDInput | null,
  cemiterioId?: ModelIDInput | null,
  funerariaId?: ModelIDInput | null,
  and?: Array< ModelCemiterioFunerariaFilterInput | null > | null,
  or?: Array< ModelCemiterioFunerariaFilterInput | null > | null,
  not?: ModelCemiterioFunerariaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCemiterioVelorioFilterInput = {
  id?: ModelIDInput | null,
  cemiterioId?: ModelIDInput | null,
  velorioId?: ModelIDInput | null,
  and?: Array< ModelCemiterioVelorioFilterInput | null > | null,
  or?: Array< ModelCemiterioVelorioFilterInput | null > | null,
  not?: ModelCemiterioVelorioFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelVelorioFunerariaFilterInput = {
  id?: ModelIDInput | null,
  velorioId?: ModelIDInput | null,
  funerariaId?: ModelIDInput | null,
  and?: Array< ModelVelorioFunerariaFilterInput | null > | null,
  or?: Array< ModelVelorioFunerariaFilterInput | null > | null,
  not?: ModelVelorioFunerariaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserFunerariaFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  funerariaId?: ModelIDInput | null,
  and?: Array< ModelUserFunerariaFilterInput | null > | null,
  or?: Array< ModelUserFunerariaFilterInput | null > | null,
  not?: ModelUserFunerariaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPostImageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  imageKey?: ModelSubscriptionStringInput | null,
  context?: ModelSubscriptionStringInput | null,
  postID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPostImageFilterInput | null > | null,
  or?: Array< ModelSubscriptionPostImageFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionPostFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  postType?: ModelSubscriptionStringInput | null,
  postAction?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  datePost?: ModelSubscriptionStringInput | null,
  timePost?: ModelSubscriptionStringInput | null,
  resourceId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPostFilterInput | null > | null,
  or?: Array< ModelSubscriptionPostFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMensagemMuralEngajamentoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  views?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionMensagemMuralEngajamentoFilterInput | null > | null,
  or?: Array< ModelSubscriptionMensagemMuralEngajamentoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionMensagemMuralViewsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  dataVisualizacao?: ModelSubscriptionStringInput | null,
  horaVisualizacao?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMensagemMuralViewsFilterInput | null > | null,
  or?: Array< ModelSubscriptionMensagemMuralViewsFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMensagemMuralFalecimentoFotoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tipoFoto?: ModelSubscriptionStringInput | null,
  isCover?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionMensagemMuralFalecimentoFotoFilterInput | null > | null,
  or?: Array< ModelSubscriptionMensagemMuralFalecimentoFotoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFalecimentoFotoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  fileKey?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  tipo?: ModelSubscriptionStringInput | null,
  falecimentoID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  isCover?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionFalecimentoFotoFilterInput | null > | null,
  or?: Array< ModelSubscriptionFalecimentoFotoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOpenAiGeneratedMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  falecimentoId?: ModelSubscriptionStringInput | null,
  anonymousUserId?: ModelSubscriptionStringInput | null,
  messageId?: ModelSubscriptionStringInput | null,
  runId?: ModelSubscriptionStringInput | null,
  threadId?: ModelSubscriptionStringInput | null,
  created_at?: ModelSubscriptionIntInput | null,
  contentTextValue?: ModelSubscriptionStringInput | null,
  assistantId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOpenAiGeneratedMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionOpenAiGeneratedMessageFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOpenAiRunFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  runId?: ModelSubscriptionStringInput | null,
  object?: ModelSubscriptionStringInput | null,
  created_at?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  expires_at?: ModelSubscriptionIntInput | null,
  started_at?: ModelSubscriptionIntInput | null,
  cancelled_at?: ModelSubscriptionIntInput | null,
  failed_at?: ModelSubscriptionIntInput | null,
  completed_at?: ModelSubscriptionIntInput | null,
  model?: ModelSubscriptionStringInput | null,
  instructions?: ModelSubscriptionStringInput | null,
  assistant_id?: ModelSubscriptionStringInput | null,
  thread_id?: ModelSubscriptionStringInput | null,
  assistentemensagemID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionOpenAiRunFilterInput | null > | null,
  or?: Array< ModelSubscriptionOpenAiRunFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOpenAiAssistantFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  assistantId?: ModelSubscriptionStringInput | null,
  object?: ModelSubscriptionStringInput | null,
  model?: ModelSubscriptionStringInput | null,
  instructions?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOpenAiAssistantFilterInput | null > | null,
  or?: Array< ModelSubscriptionOpenAiAssistantFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAssistenteMensagemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tamanhoMensagem?: ModelSubscriptionStringInput | null,
  tipoPessoa?: ModelSubscriptionStringInput | null,
  emNomeDe?: ModelSubscriptionStringInput | null,
  nomeUsuario?: ModelSubscriptionStringInput | null,
  mensagemUsuario?: ModelSubscriptionStringInput | null,
  instrucao?: ModelSubscriptionStringInput | null,
  Step?: ModelSubscriptionStringInput | null,
  Status?: ModelSubscriptionStringInput | null,
  mensagemReescrita?: ModelSubscriptionStringInput | null,
  mensagemGerada?: ModelSubscriptionStringInput | null,
  mensagemCorrigida?: ModelSubscriptionStringInput | null,
  mensagemEditada?: ModelSubscriptionStringInput | null,
  tipoMensagemSelecionada?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAssistenteMensagemFilterInput | null > | null,
  or?: Array< ModelSubscriptionAssistenteMensagemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOpenAiThreadFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  threadId?: ModelSubscriptionStringInput | null,
  object?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOpenAiThreadFilterInput | null > | null,
  or?: Array< ModelSubscriptionOpenAiThreadFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPerguntaFaixaEtariaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  faixaEtaria?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionPerguntaFaixaEtariaFilterInput | null > | null,
  or?: Array< ModelSubscriptionPerguntaFaixaEtariaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPerguntaTipoRelacionamentoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionPerguntaTipoRelacionamentoFilterInput | null > | null,
  or?: Array< ModelSubscriptionPerguntaTipoRelacionamentoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRelUsuarioFalecimentoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nivelRelacionamento?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRelUsuarioFalecimentoFilterInput | null > | null,
  or?: Array< ModelSubscriptionRelUsuarioFalecimentoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRespostaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pergunta?: ModelSubscriptionStringInput | null,
  resposta?: ModelSubscriptionStringInput | null,
  tipoPergunta?: ModelSubscriptionStringInput | null,
  viviamJuntos?: ModelSubscriptionBooleanInput | null,
  assistentemensagemID?: ModelSubscriptionIDInput | null,
  skiped?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionRespostaFilterInput | null > | null,
  or?: Array< ModelSubscriptionRespostaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPerguntaOpcaoRespostaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  opcaoResposta?: ModelSubscriptionStringInput | null,
  viviamJuntos?: ModelSubscriptionBooleanInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionPerguntaOpcaoRespostaFilterInput | null > | null,
  or?: Array< ModelSubscriptionPerguntaOpcaoRespostaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPerguntaNivelRelacionamentoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nivelRelacionamento?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionPerguntaNivelRelacionamentoFilterInput | null > | null,
  or?: Array< ModelSubscriptionPerguntaNivelRelacionamentoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPerguntaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pergunta?: ModelSubscriptionStringInput | null,
  feminino?: ModelSubscriptionStringInput | null,
  tipoPergunta?: ModelSubscriptionStringInput | null,
  tipoResposta?: ModelSubscriptionStringInput | null,
  respostaTipoViviamJuntos?: ModelSubscriptionBooleanInput | null,
  limitaTipoRelacionamento?: ModelSubscriptionBooleanInput | null,
  limitaFaixaEtaria?: ModelSubscriptionBooleanInput | null,
  limitaNivelRelacionamento?: ModelSubscriptionBooleanInput | null,
  modeloResposta?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionPerguntaFilterInput | null > | null,
  or?: Array< ModelSubscriptionPerguntaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTipoRelacionamentoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nivelRelaciomento?: ModelSubscriptionStringInput | null,
  titulo?: ModelSubscriptionStringInput | null,
  sexo?: ModelSubscriptionStringInput | null,
  prioridade?: ModelSubscriptionIntInput | null,
  active?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTipoRelacionamentoFilterInput | null > | null,
  or?: Array< ModelSubscriptionTipoRelacionamentoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFalecimentoCidadeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  QtdMensagens?: ModelSubscriptionIntInput | null,
  nomeCidade?: ModelSubscriptionStringInput | null,
  siglaEstado?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFalecimentoCidadeFilterInput | null > | null,
  or?: Array< ModelSubscriptionFalecimentoCidadeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionConviteAdministracaoMemorialFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  memorialID?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  dataEnvio?: ModelSubscriptionStringInput | null,
  dataAceite?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConviteAdministracaoMemorialFilterInput | null > | null,
  or?: Array< ModelSubscriptionConviteAdministracaoMemorialFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMemorialFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  url?: ModelSubscriptionStringInput | null,
  emailSuperAdmin?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMemorialFilterInput | null > | null,
  or?: Array< ModelSubscriptionMemorialFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAnonymousUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nome?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  ip?: ModelSubscriptionStringInput | null,
  sexo?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAnonymousUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionAnonymousUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMensagemMuralFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  mensagem?: ModelSubscriptionStringInput | null,
  dataPostagem?: ModelSubscriptionStringInput | null,
  horaPostagem?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  dataAprovacao?: ModelSubscriptionStringInput | null,
  cidade?: ModelSubscriptionStringInput | null,
  estado?: ModelSubscriptionStringInput | null,
  nomeUsuario?: ModelSubscriptionStringInput | null,
  tipoMensagem?: ModelSubscriptionStringInput | null,
  resumo?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMensagemMuralFilterInput | null > | null,
  or?: Array< ModelSubscriptionMensagemMuralFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCemiterioFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nome?: ModelSubscriptionStringInput | null,
  logradouro?: ModelSubscriptionStringInput | null,
  cep?: ModelSubscriptionStringInput | null,
  bairro?: ModelSubscriptionStringInput | null,
  cidade?: ModelSubscriptionStringInput | null,
  estado?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCemiterioFilterInput | null > | null,
  or?: Array< ModelSubscriptionCemiterioFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMesorregiaoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nome?: ModelSubscriptionStringInput | null,
  idIbge?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMesorregiaoFilterInput | null > | null,
  or?: Array< ModelSubscriptionMesorregiaoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMicrorregiaoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nome?: ModelSubscriptionStringInput | null,
  idIbge?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMicrorregiaoFilterInput | null > | null,
  or?: Array< ModelSubscriptionMicrorregiaoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRegiaoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sigla?: ModelSubscriptionStringInput | null,
  nome?: ModelSubscriptionStringInput | null,
  idIbge?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRegiaoFilterInput | null > | null,
  or?: Array< ModelSubscriptionRegiaoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionEstadoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nome?: ModelSubscriptionStringInput | null,
  sigla?: ModelSubscriptionStringInput | null,
  idIbge?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEstadoFilterInput | null > | null,
  or?: Array< ModelSubscriptionEstadoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCidadeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nome?: ModelSubscriptionStringInput | null,
  idIbge?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCidadeFilterInput | null > | null,
  or?: Array< ModelSubscriptionCidadeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionVelorioFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nome?: ModelSubscriptionStringInput | null,
  logradouro?: ModelSubscriptionStringInput | null,
  cep?: ModelSubscriptionStringInput | null,
  bairro?: ModelSubscriptionStringInput | null,
  cidade?: ModelSubscriptionStringInput | null,
  estado?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVelorioFilterInput | null > | null,
  or?: Array< ModelSubscriptionVelorioFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFalecimentoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nome?: ModelSubscriptionStringInput | null,
  sobrenome?: ModelSubscriptionStringInput | null,
  dataFalecimento?: ModelSubscriptionStringInput | null,
  searchName?: ModelSubscriptionStringInput | null,
  idade?: ModelSubscriptionIntInput | null,
  tempoDeVida?: ModelSubscriptionStringInput | null,
  meses?: ModelSubscriptionIntInput | null,
  dataInicioVelorio?: ModelSubscriptionStringInput | null,
  horaInicioVelorio?: ModelSubscriptionStringInput | null,
  dataFimVelorio?: ModelSubscriptionStringInput | null,
  horaFimVelorio?: ModelSubscriptionStringInput | null,
  dataSepultamento?: ModelSubscriptionStringInput | null,
  horaSepultamento?: ModelSubscriptionStringInput | null,
  salaVelorio?: ModelSubscriptionStringInput | null,
  sobre?: ModelSubscriptionStringInput | null,
  imageKey?: ModelSubscriptionStringInput | null,
  apelido?: ModelSubscriptionStringInput | null,
  cidade?: ModelSubscriptionStringInput | null,
  estado?: ModelSubscriptionStringInput | null,
  sexo?: ModelSubscriptionStringInput | null,
  dataNascimento?: ModelSubscriptionStringInput | null,
  faixaEtaria?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFalecimentoFilterInput | null > | null,
  or?: Array< ModelSubscriptionFalecimentoFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  nome?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  imageKey?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  sexo?: ModelSubscriptionStringInput | null,
  lastIpAddress?: ModelSubscriptionStringInput | null,
  anonymousUserId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFunerariaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cnpj?: ModelSubscriptionStringInput | null,
  nome?: ModelSubscriptionStringInput | null,
  logradouro?: ModelSubscriptionStringInput | null,
  cep?: ModelSubscriptionStringInput | null,
  telefone1?: ModelSubscriptionStringInput | null,
  telefone2?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  site?: ModelSubscriptionStringInput | null,
  cidade?: ModelSubscriptionStringInput | null,
  estado?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFunerariaFilterInput | null > | null,
  or?: Array< ModelSubscriptionFunerariaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMemorialUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  memorialId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionMemorialUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionMemorialUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCemiterioFunerariaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cemiterioId?: ModelSubscriptionIDInput | null,
  funerariaId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCemiterioFunerariaFilterInput | null > | null,
  or?: Array< ModelSubscriptionCemiterioFunerariaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCemiterioVelorioFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cemiterioId?: ModelSubscriptionIDInput | null,
  velorioId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCemiterioVelorioFilterInput | null > | null,
  or?: Array< ModelSubscriptionCemiterioVelorioFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionVelorioFunerariaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  velorioId?: ModelSubscriptionIDInput | null,
  funerariaId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionVelorioFunerariaFilterInput | null > | null,
  or?: Array< ModelSubscriptionVelorioFunerariaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserFunerariaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  funerariaId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUserFunerariaFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFunerariaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreatePostImageMutationVariables = {
  input: CreatePostImageInput,
  condition?: ModelPostImageConditionInput | null,
};

export type CreatePostImageMutation = {
  createPostImage?:  {
    __typename: "PostImage",
    id: string,
    imageKey: string,
    context: string,
    postID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePostImageMutationVariables = {
  input: UpdatePostImageInput,
  condition?: ModelPostImageConditionInput | null,
};

export type UpdatePostImageMutation = {
  updatePostImage?:  {
    __typename: "PostImage",
    id: string,
    imageKey: string,
    context: string,
    postID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePostImageMutationVariables = {
  input: DeletePostImageInput,
  condition?: ModelPostImageConditionInput | null,
};

export type DeletePostImageMutation = {
  deletePostImage?:  {
    __typename: "PostImage",
    id: string,
    imageKey: string,
    context: string,
    postID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "Post",
    id: string,
    postType: PostTypeEnum,
    postAction: PostActionEnum,
    text: string,
    url: string,
    datePost: string,
    timePost: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    PostImages?:  {
      __typename: "ModelPostImageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    resourceId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    postUserId: string,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "Post",
    id: string,
    postType: PostTypeEnum,
    postAction: PostActionEnum,
    text: string,
    url: string,
    datePost: string,
    timePost: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    PostImages?:  {
      __typename: "ModelPostImageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    resourceId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    postUserId: string,
  } | null,
};

export type DeletePostMutationVariables = {
  input: DeletePostInput,
  condition?: ModelPostConditionInput | null,
};

export type DeletePostMutation = {
  deletePost?:  {
    __typename: "Post",
    id: string,
    postType: PostTypeEnum,
    postAction: PostActionEnum,
    text: string,
    url: string,
    datePost: string,
    timePost: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    PostImages?:  {
      __typename: "ModelPostImageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    resourceId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    postUserId: string,
  } | null,
};

export type CreateMensagemMuralEngajamentoMutationVariables = {
  input: CreateMensagemMuralEngajamentoInput,
  condition?: ModelMensagemMuralEngajamentoConditionInput | null,
};

export type CreateMensagemMuralEngajamentoMutation = {
  createMensagemMuralEngajamento?:  {
    __typename: "MensagemMuralEngajamento",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    views: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralEngajamentoMensagemMuralId: string,
  } | null,
};

export type UpdateMensagemMuralEngajamentoMutationVariables = {
  input: UpdateMensagemMuralEngajamentoInput,
  condition?: ModelMensagemMuralEngajamentoConditionInput | null,
};

export type UpdateMensagemMuralEngajamentoMutation = {
  updateMensagemMuralEngajamento?:  {
    __typename: "MensagemMuralEngajamento",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    views: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralEngajamentoMensagemMuralId: string,
  } | null,
};

export type DeleteMensagemMuralEngajamentoMutationVariables = {
  input: DeleteMensagemMuralEngajamentoInput,
  condition?: ModelMensagemMuralEngajamentoConditionInput | null,
};

export type DeleteMensagemMuralEngajamentoMutation = {
  deleteMensagemMuralEngajamento?:  {
    __typename: "MensagemMuralEngajamento",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    views: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralEngajamentoMensagemMuralId: string,
  } | null,
};

export type CreateMensagemMuralViewsMutationVariables = {
  input: CreateMensagemMuralViewsInput,
  condition?: ModelMensagemMuralViewsConditionInput | null,
};

export type CreateMensagemMuralViewsMutation = {
  createMensagemMuralViews?:  {
    __typename: "MensagemMuralViews",
    id: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    dataVisualizacao: string,
    horaVisualizacao: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralViewsUserId: string,
    mensagemMuralViewsMensagemMuralId: string,
  } | null,
};

export type UpdateMensagemMuralViewsMutationVariables = {
  input: UpdateMensagemMuralViewsInput,
  condition?: ModelMensagemMuralViewsConditionInput | null,
};

export type UpdateMensagemMuralViewsMutation = {
  updateMensagemMuralViews?:  {
    __typename: "MensagemMuralViews",
    id: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    dataVisualizacao: string,
    horaVisualizacao: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralViewsUserId: string,
    mensagemMuralViewsMensagemMuralId: string,
  } | null,
};

export type DeleteMensagemMuralViewsMutationVariables = {
  input: DeleteMensagemMuralViewsInput,
  condition?: ModelMensagemMuralViewsConditionInput | null,
};

export type DeleteMensagemMuralViewsMutation = {
  deleteMensagemMuralViews?:  {
    __typename: "MensagemMuralViews",
    id: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    dataVisualizacao: string,
    horaVisualizacao: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralViewsUserId: string,
    mensagemMuralViewsMensagemMuralId: string,
  } | null,
};

export type CreateMensagemMuralFalecimentoFotoMutationVariables = {
  input: CreateMensagemMuralFalecimentoFotoInput,
  condition?: ModelMensagemMuralFalecimentoFotoConditionInput | null,
};

export type CreateMensagemMuralFalecimentoFotoMutation = {
  createMensagemMuralFalecimentoFoto?:  {
    __typename: "MensagemMuralFalecimentoFoto",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    FalecimentoFoto:  {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    tipoFoto: TipoFoto,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralFalecimentoFotoMensagemMuralId: string,
    mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
  } | null,
};

export type UpdateMensagemMuralFalecimentoFotoMutationVariables = {
  input: UpdateMensagemMuralFalecimentoFotoInput,
  condition?: ModelMensagemMuralFalecimentoFotoConditionInput | null,
};

export type UpdateMensagemMuralFalecimentoFotoMutation = {
  updateMensagemMuralFalecimentoFoto?:  {
    __typename: "MensagemMuralFalecimentoFoto",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    FalecimentoFoto:  {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    tipoFoto: TipoFoto,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralFalecimentoFotoMensagemMuralId: string,
    mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
  } | null,
};

export type DeleteMensagemMuralFalecimentoFotoMutationVariables = {
  input: DeleteMensagemMuralFalecimentoFotoInput,
  condition?: ModelMensagemMuralFalecimentoFotoConditionInput | null,
};

export type DeleteMensagemMuralFalecimentoFotoMutation = {
  deleteMensagemMuralFalecimentoFoto?:  {
    __typename: "MensagemMuralFalecimentoFoto",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    FalecimentoFoto:  {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    tipoFoto: TipoFoto,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralFalecimentoFotoMensagemMuralId: string,
    mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
  } | null,
};

export type CreateFalecimentoFotoMutationVariables = {
  input: CreateFalecimentoFotoInput,
  condition?: ModelFalecimentoFotoConditionInput | null,
};

export type CreateFalecimentoFotoMutation = {
  createFalecimentoFoto?:  {
    __typename: "FalecimentoFoto",
    id: string,
    fileKey: string,
    status: ContentStatusEnum,
    tipo: TipoFoto,
    falecimentoID: string,
    userID: string,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFalecimentoFotoMutationVariables = {
  input: UpdateFalecimentoFotoInput,
  condition?: ModelFalecimentoFotoConditionInput | null,
};

export type UpdateFalecimentoFotoMutation = {
  updateFalecimentoFoto?:  {
    __typename: "FalecimentoFoto",
    id: string,
    fileKey: string,
    status: ContentStatusEnum,
    tipo: TipoFoto,
    falecimentoID: string,
    userID: string,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFalecimentoFotoMutationVariables = {
  input: DeleteFalecimentoFotoInput,
  condition?: ModelFalecimentoFotoConditionInput | null,
};

export type DeleteFalecimentoFotoMutation = {
  deleteFalecimentoFoto?:  {
    __typename: "FalecimentoFoto",
    id: string,
    fileKey: string,
    status: ContentStatusEnum,
    tipo: TipoFoto,
    falecimentoID: string,
    userID: string,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOpenAiGeneratedMessageMutationVariables = {
  input: CreateOpenAiGeneratedMessageInput,
  condition?: ModelOpenAiGeneratedMessageConditionInput | null,
};

export type CreateOpenAiGeneratedMessageMutation = {
  createOpenAiGeneratedMessage?:  {
    __typename: "OpenAiGeneratedMessage",
    id: string,
    falecimentoId: string,
    anonymousUserId: string,
    messageId: string,
    runId: string,
    threadId: string,
    created_at: number,
    contentTextValue: string,
    assistantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOpenAiGeneratedMessageMutationVariables = {
  input: UpdateOpenAiGeneratedMessageInput,
  condition?: ModelOpenAiGeneratedMessageConditionInput | null,
};

export type UpdateOpenAiGeneratedMessageMutation = {
  updateOpenAiGeneratedMessage?:  {
    __typename: "OpenAiGeneratedMessage",
    id: string,
    falecimentoId: string,
    anonymousUserId: string,
    messageId: string,
    runId: string,
    threadId: string,
    created_at: number,
    contentTextValue: string,
    assistantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOpenAiGeneratedMessageMutationVariables = {
  input: DeleteOpenAiGeneratedMessageInput,
  condition?: ModelOpenAiGeneratedMessageConditionInput | null,
};

export type DeleteOpenAiGeneratedMessageMutation = {
  deleteOpenAiGeneratedMessage?:  {
    __typename: "OpenAiGeneratedMessage",
    id: string,
    falecimentoId: string,
    anonymousUserId: string,
    messageId: string,
    runId: string,
    threadId: string,
    created_at: number,
    contentTextValue: string,
    assistantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOpenAiRunMutationVariables = {
  input: CreateOpenAiRunInput,
  condition?: ModelOpenAiRunConditionInput | null,
};

export type CreateOpenAiRunMutation = {
  createOpenAiRun?:  {
    __typename: "OpenAiRun",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    runId: string,
    object?: string | null,
    created_at?: number | null,
    status?: string | null,
    expires_at?: number | null,
    started_at?: number | null,
    cancelled_at?: number | null,
    failed_at?: number | null,
    completed_at?: number | null,
    model?: string | null,
    instructions?: string | null,
    assistant_id?: string | null,
    thread_id?: string | null,
    last_error?:  {
      __typename: "OpenAiError",
      code?: string | null,
      message?: string | null,
    } | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    assistentemensagemID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiRunOpenAiThreadId?: string | null,
    openAiRunOpenAiAssistantId?: string | null,
    openAiRunAnonymousUserId?: string | null,
  } | null,
};

export type UpdateOpenAiRunMutationVariables = {
  input: UpdateOpenAiRunInput,
  condition?: ModelOpenAiRunConditionInput | null,
};

export type UpdateOpenAiRunMutation = {
  updateOpenAiRun?:  {
    __typename: "OpenAiRun",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    runId: string,
    object?: string | null,
    created_at?: number | null,
    status?: string | null,
    expires_at?: number | null,
    started_at?: number | null,
    cancelled_at?: number | null,
    failed_at?: number | null,
    completed_at?: number | null,
    model?: string | null,
    instructions?: string | null,
    assistant_id?: string | null,
    thread_id?: string | null,
    last_error?:  {
      __typename: "OpenAiError",
      code?: string | null,
      message?: string | null,
    } | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    assistentemensagemID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiRunOpenAiThreadId?: string | null,
    openAiRunOpenAiAssistantId?: string | null,
    openAiRunAnonymousUserId?: string | null,
  } | null,
};

export type DeleteOpenAiRunMutationVariables = {
  input: DeleteOpenAiRunInput,
  condition?: ModelOpenAiRunConditionInput | null,
};

export type DeleteOpenAiRunMutation = {
  deleteOpenAiRun?:  {
    __typename: "OpenAiRun",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    runId: string,
    object?: string | null,
    created_at?: number | null,
    status?: string | null,
    expires_at?: number | null,
    started_at?: number | null,
    cancelled_at?: number | null,
    failed_at?: number | null,
    completed_at?: number | null,
    model?: string | null,
    instructions?: string | null,
    assistant_id?: string | null,
    thread_id?: string | null,
    last_error?:  {
      __typename: "OpenAiError",
      code?: string | null,
      message?: string | null,
    } | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    assistentemensagemID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiRunOpenAiThreadId?: string | null,
    openAiRunOpenAiAssistantId?: string | null,
    openAiRunAnonymousUserId?: string | null,
  } | null,
};

export type CreateOpenAiAssistantMutationVariables = {
  input: CreateOpenAiAssistantInput,
  condition?: ModelOpenAiAssistantConditionInput | null,
};

export type CreateOpenAiAssistantMutation = {
  createOpenAiAssistant?:  {
    __typename: "OpenAiAssistant",
    id: string,
    assistantId: string,
    object: string,
    model: string,
    instructions: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOpenAiAssistantMutationVariables = {
  input: UpdateOpenAiAssistantInput,
  condition?: ModelOpenAiAssistantConditionInput | null,
};

export type UpdateOpenAiAssistantMutation = {
  updateOpenAiAssistant?:  {
    __typename: "OpenAiAssistant",
    id: string,
    assistantId: string,
    object: string,
    model: string,
    instructions: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOpenAiAssistantMutationVariables = {
  input: DeleteOpenAiAssistantInput,
  condition?: ModelOpenAiAssistantConditionInput | null,
};

export type DeleteOpenAiAssistantMutation = {
  deleteOpenAiAssistant?:  {
    __typename: "OpenAiAssistant",
    id: string,
    assistantId: string,
    object: string,
    model: string,
    instructions: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAssistenteMensagemMutationVariables = {
  input: CreateAssistenteMensagemInput,
  condition?: ModelAssistenteMensagemConditionInput | null,
};

export type CreateAssistenteMensagemMutation = {
  createAssistenteMensagem?:  {
    __typename: "AssistenteMensagem",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Respostas?:  {
      __typename: "ModelRespostaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RelUsuarioFalecimento?:  {
      __typename: "RelUsuarioFalecimento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      relUsuarioFalecimentoFalecimentoId: string,
      relUsuarioFalecimentoAnonymousUserId: string,
      relUsuarioFalecimentoUserId?: string | null,
      relUsuarioFalecimentoTipoRelacionamentoId: string,
    } | null,
    tamanhoMensagem?: TamanhoMensagemEnu | null,
    tipoPessoa?: TipoPessoaEnum | null,
    emNomeDe?: string | null,
    OpenAiRuns?:  {
      __typename: "ModelOpenAiRunConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nomeUsuario?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento?:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mensagemUsuario?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    OpenAiGeneratedMessage?:  {
      __typename: "OpenAiGeneratedMessage",
      id: string,
      falecimentoId: string,
      anonymousUserId: string,
      messageId: string,
      runId: string,
      threadId: string,
      created_at: number,
      contentTextValue: string,
      assistantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    instrucao?: string | null,
    Step?: string | null,
    Status?: AssistenteStatusEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    mensagemReescrita?: string | null,
    mensagemGerada?: string | null,
    mensagemCorrigida?: string | null,
    mensagemEditada?: string | null,
    tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    assistenteMensagemOpenAiThreadId?: string | null,
    assistenteMensagemFalecimentoId: string,
    assistenteMensagemAnonymousUserId: string,
    assistenteMensagemOpenAiAssistantId?: string | null,
    assistenteMensagemRelUsuarioFalecimentoId?: string | null,
    assistenteMensagemUserId?: string | null,
    assistenteMensagemTipoRelacionamentoId?: string | null,
    assistenteMensagemCidadeId?: string | null,
    assistenteMensagemOpenAiGeneratedMessageId?: string | null,
    assistenteMensagemMemorialId?: string | null,
  } | null,
};

export type UpdateAssistenteMensagemMutationVariables = {
  input: UpdateAssistenteMensagemInput,
  condition?: ModelAssistenteMensagemConditionInput | null,
};

export type UpdateAssistenteMensagemMutation = {
  updateAssistenteMensagem?:  {
    __typename: "AssistenteMensagem",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Respostas?:  {
      __typename: "ModelRespostaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RelUsuarioFalecimento?:  {
      __typename: "RelUsuarioFalecimento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      relUsuarioFalecimentoFalecimentoId: string,
      relUsuarioFalecimentoAnonymousUserId: string,
      relUsuarioFalecimentoUserId?: string | null,
      relUsuarioFalecimentoTipoRelacionamentoId: string,
    } | null,
    tamanhoMensagem?: TamanhoMensagemEnu | null,
    tipoPessoa?: TipoPessoaEnum | null,
    emNomeDe?: string | null,
    OpenAiRuns?:  {
      __typename: "ModelOpenAiRunConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nomeUsuario?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento?:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mensagemUsuario?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    OpenAiGeneratedMessage?:  {
      __typename: "OpenAiGeneratedMessage",
      id: string,
      falecimentoId: string,
      anonymousUserId: string,
      messageId: string,
      runId: string,
      threadId: string,
      created_at: number,
      contentTextValue: string,
      assistantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    instrucao?: string | null,
    Step?: string | null,
    Status?: AssistenteStatusEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    mensagemReescrita?: string | null,
    mensagemGerada?: string | null,
    mensagemCorrigida?: string | null,
    mensagemEditada?: string | null,
    tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    assistenteMensagemOpenAiThreadId?: string | null,
    assistenteMensagemFalecimentoId: string,
    assistenteMensagemAnonymousUserId: string,
    assistenteMensagemOpenAiAssistantId?: string | null,
    assistenteMensagemRelUsuarioFalecimentoId?: string | null,
    assistenteMensagemUserId?: string | null,
    assistenteMensagemTipoRelacionamentoId?: string | null,
    assistenteMensagemCidadeId?: string | null,
    assistenteMensagemOpenAiGeneratedMessageId?: string | null,
    assistenteMensagemMemorialId?: string | null,
  } | null,
};

export type DeleteAssistenteMensagemMutationVariables = {
  input: DeleteAssistenteMensagemInput,
  condition?: ModelAssistenteMensagemConditionInput | null,
};

export type DeleteAssistenteMensagemMutation = {
  deleteAssistenteMensagem?:  {
    __typename: "AssistenteMensagem",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Respostas?:  {
      __typename: "ModelRespostaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RelUsuarioFalecimento?:  {
      __typename: "RelUsuarioFalecimento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      relUsuarioFalecimentoFalecimentoId: string,
      relUsuarioFalecimentoAnonymousUserId: string,
      relUsuarioFalecimentoUserId?: string | null,
      relUsuarioFalecimentoTipoRelacionamentoId: string,
    } | null,
    tamanhoMensagem?: TamanhoMensagemEnu | null,
    tipoPessoa?: TipoPessoaEnum | null,
    emNomeDe?: string | null,
    OpenAiRuns?:  {
      __typename: "ModelOpenAiRunConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nomeUsuario?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento?:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mensagemUsuario?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    OpenAiGeneratedMessage?:  {
      __typename: "OpenAiGeneratedMessage",
      id: string,
      falecimentoId: string,
      anonymousUserId: string,
      messageId: string,
      runId: string,
      threadId: string,
      created_at: number,
      contentTextValue: string,
      assistantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    instrucao?: string | null,
    Step?: string | null,
    Status?: AssistenteStatusEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    mensagemReescrita?: string | null,
    mensagemGerada?: string | null,
    mensagemCorrigida?: string | null,
    mensagemEditada?: string | null,
    tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    assistenteMensagemOpenAiThreadId?: string | null,
    assistenteMensagemFalecimentoId: string,
    assistenteMensagemAnonymousUserId: string,
    assistenteMensagemOpenAiAssistantId?: string | null,
    assistenteMensagemRelUsuarioFalecimentoId?: string | null,
    assistenteMensagemUserId?: string | null,
    assistenteMensagemTipoRelacionamentoId?: string | null,
    assistenteMensagemCidadeId?: string | null,
    assistenteMensagemOpenAiGeneratedMessageId?: string | null,
    assistenteMensagemMemorialId?: string | null,
  } | null,
};

export type CreateOpenAiThreadMutationVariables = {
  input: CreateOpenAiThreadInput,
  condition?: ModelOpenAiThreadConditionInput | null,
};

export type CreateOpenAiThreadMutation = {
  createOpenAiThread?:  {
    __typename: "OpenAiThread",
    id: string,
    threadId: string,
    object: string,
    OpenAiAssistant:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiThreadOpenAiAssistantId: string,
    openAiThreadAnonymousUserId?: string | null,
  } | null,
};

export type UpdateOpenAiThreadMutationVariables = {
  input: UpdateOpenAiThreadInput,
  condition?: ModelOpenAiThreadConditionInput | null,
};

export type UpdateOpenAiThreadMutation = {
  updateOpenAiThread?:  {
    __typename: "OpenAiThread",
    id: string,
    threadId: string,
    object: string,
    OpenAiAssistant:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiThreadOpenAiAssistantId: string,
    openAiThreadAnonymousUserId?: string | null,
  } | null,
};

export type DeleteOpenAiThreadMutationVariables = {
  input: DeleteOpenAiThreadInput,
  condition?: ModelOpenAiThreadConditionInput | null,
};

export type DeleteOpenAiThreadMutation = {
  deleteOpenAiThread?:  {
    __typename: "OpenAiThread",
    id: string,
    threadId: string,
    object: string,
    OpenAiAssistant:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiThreadOpenAiAssistantId: string,
    openAiThreadAnonymousUserId?: string | null,
  } | null,
};

export type CreatePerguntaFaixaEtariaMutationVariables = {
  input: CreatePerguntaFaixaEtariaInput,
  condition?: ModelPerguntaFaixaEtariaConditionInput | null,
};

export type CreatePerguntaFaixaEtariaMutation = {
  createPerguntaFaixaEtaria?:  {
    __typename: "PerguntaFaixaEtaria",
    id: string,
    faixaEtaria: FaixaEtariaEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaFaixaEtariaPerguntaId: string,
  } | null,
};

export type UpdatePerguntaFaixaEtariaMutationVariables = {
  input: UpdatePerguntaFaixaEtariaInput,
  condition?: ModelPerguntaFaixaEtariaConditionInput | null,
};

export type UpdatePerguntaFaixaEtariaMutation = {
  updatePerguntaFaixaEtaria?:  {
    __typename: "PerguntaFaixaEtaria",
    id: string,
    faixaEtaria: FaixaEtariaEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaFaixaEtariaPerguntaId: string,
  } | null,
};

export type DeletePerguntaFaixaEtariaMutationVariables = {
  input: DeletePerguntaFaixaEtariaInput,
  condition?: ModelPerguntaFaixaEtariaConditionInput | null,
};

export type DeletePerguntaFaixaEtariaMutation = {
  deletePerguntaFaixaEtaria?:  {
    __typename: "PerguntaFaixaEtaria",
    id: string,
    faixaEtaria: FaixaEtariaEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaFaixaEtariaPerguntaId: string,
  } | null,
};

export type CreatePerguntaTipoRelacionamentoMutationVariables = {
  input: CreatePerguntaTipoRelacionamentoInput,
  condition?: ModelPerguntaTipoRelacionamentoConditionInput | null,
};

export type CreatePerguntaTipoRelacionamentoMutation = {
  createPerguntaTipoRelacionamento?:  {
    __typename: "PerguntaTipoRelacionamento",
    id: string,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaTipoRelacionamentoTipoRelacionamentoId: string,
    perguntaTipoRelacionamentoPerguntaId: string,
  } | null,
};

export type UpdatePerguntaTipoRelacionamentoMutationVariables = {
  input: UpdatePerguntaTipoRelacionamentoInput,
  condition?: ModelPerguntaTipoRelacionamentoConditionInput | null,
};

export type UpdatePerguntaTipoRelacionamentoMutation = {
  updatePerguntaTipoRelacionamento?:  {
    __typename: "PerguntaTipoRelacionamento",
    id: string,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaTipoRelacionamentoTipoRelacionamentoId: string,
    perguntaTipoRelacionamentoPerguntaId: string,
  } | null,
};

export type DeletePerguntaTipoRelacionamentoMutationVariables = {
  input: DeletePerguntaTipoRelacionamentoInput,
  condition?: ModelPerguntaTipoRelacionamentoConditionInput | null,
};

export type DeletePerguntaTipoRelacionamentoMutation = {
  deletePerguntaTipoRelacionamento?:  {
    __typename: "PerguntaTipoRelacionamento",
    id: string,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaTipoRelacionamentoTipoRelacionamentoId: string,
    perguntaTipoRelacionamentoPerguntaId: string,
  } | null,
};

export type CreateRelUsuarioFalecimentoMutationVariables = {
  input: CreateRelUsuarioFalecimentoInput,
  condition?: ModelRelUsuarioFalecimentoConditionInput | null,
};

export type CreateRelUsuarioFalecimentoMutation = {
  createRelUsuarioFalecimento?:  {
    __typename: "RelUsuarioFalecimento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    relUsuarioFalecimentoFalecimentoId: string,
    relUsuarioFalecimentoAnonymousUserId: string,
    relUsuarioFalecimentoUserId?: string | null,
    relUsuarioFalecimentoTipoRelacionamentoId: string,
  } | null,
};

export type UpdateRelUsuarioFalecimentoMutationVariables = {
  input: UpdateRelUsuarioFalecimentoInput,
  condition?: ModelRelUsuarioFalecimentoConditionInput | null,
};

export type UpdateRelUsuarioFalecimentoMutation = {
  updateRelUsuarioFalecimento?:  {
    __typename: "RelUsuarioFalecimento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    relUsuarioFalecimentoFalecimentoId: string,
    relUsuarioFalecimentoAnonymousUserId: string,
    relUsuarioFalecimentoUserId?: string | null,
    relUsuarioFalecimentoTipoRelacionamentoId: string,
  } | null,
};

export type DeleteRelUsuarioFalecimentoMutationVariables = {
  input: DeleteRelUsuarioFalecimentoInput,
  condition?: ModelRelUsuarioFalecimentoConditionInput | null,
};

export type DeleteRelUsuarioFalecimentoMutation = {
  deleteRelUsuarioFalecimento?:  {
    __typename: "RelUsuarioFalecimento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    relUsuarioFalecimentoFalecimentoId: string,
    relUsuarioFalecimentoAnonymousUserId: string,
    relUsuarioFalecimentoUserId?: string | null,
    relUsuarioFalecimentoTipoRelacionamentoId: string,
  } | null,
};

export type CreateRespostaMutationVariables = {
  input: CreateRespostaInput,
  condition?: ModelRespostaConditionInput | null,
};

export type CreateRespostaMutation = {
  createResposta?:  {
    __typename: "Resposta",
    id: string,
    pergunta: string,
    resposta: string,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    tipoPergunta: TipoPerguntaEnum,
    viviamJuntos?: boolean | null,
    Pergunta?:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    PerguntaOpcaoResposta?:  {
      __typename: "PerguntaOpcaoResposta",
      id: string,
      opcaoResposta: string,
      viviamJuntos?: boolean | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaOpcaoRespostaPerguntaId: string,
    } | null,
    assistentemensagemID?: string | null,
    skiped?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    respostaAnonymousUserId?: string | null,
    respostaUserId?: string | null,
    respostaFalecimentoId: string,
    respostaPerguntaId?: string | null,
    respostaPerguntaOpcaoRespostaId?: string | null,
  } | null,
};

export type UpdateRespostaMutationVariables = {
  input: UpdateRespostaInput,
  condition?: ModelRespostaConditionInput | null,
};

export type UpdateRespostaMutation = {
  updateResposta?:  {
    __typename: "Resposta",
    id: string,
    pergunta: string,
    resposta: string,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    tipoPergunta: TipoPerguntaEnum,
    viviamJuntos?: boolean | null,
    Pergunta?:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    PerguntaOpcaoResposta?:  {
      __typename: "PerguntaOpcaoResposta",
      id: string,
      opcaoResposta: string,
      viviamJuntos?: boolean | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaOpcaoRespostaPerguntaId: string,
    } | null,
    assistentemensagemID?: string | null,
    skiped?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    respostaAnonymousUserId?: string | null,
    respostaUserId?: string | null,
    respostaFalecimentoId: string,
    respostaPerguntaId?: string | null,
    respostaPerguntaOpcaoRespostaId?: string | null,
  } | null,
};

export type DeleteRespostaMutationVariables = {
  input: DeleteRespostaInput,
  condition?: ModelRespostaConditionInput | null,
};

export type DeleteRespostaMutation = {
  deleteResposta?:  {
    __typename: "Resposta",
    id: string,
    pergunta: string,
    resposta: string,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    tipoPergunta: TipoPerguntaEnum,
    viviamJuntos?: boolean | null,
    Pergunta?:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    PerguntaOpcaoResposta?:  {
      __typename: "PerguntaOpcaoResposta",
      id: string,
      opcaoResposta: string,
      viviamJuntos?: boolean | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaOpcaoRespostaPerguntaId: string,
    } | null,
    assistentemensagemID?: string | null,
    skiped?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    respostaAnonymousUserId?: string | null,
    respostaUserId?: string | null,
    respostaFalecimentoId: string,
    respostaPerguntaId?: string | null,
    respostaPerguntaOpcaoRespostaId?: string | null,
  } | null,
};

export type CreatePerguntaOpcaoRespostaMutationVariables = {
  input: CreatePerguntaOpcaoRespostaInput,
  condition?: ModelPerguntaOpcaoRespostaConditionInput | null,
};

export type CreatePerguntaOpcaoRespostaMutation = {
  createPerguntaOpcaoResposta?:  {
    __typename: "PerguntaOpcaoResposta",
    id: string,
    opcaoResposta: string,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    viviamJuntos?: boolean | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaOpcaoRespostaPerguntaId: string,
  } | null,
};

export type UpdatePerguntaOpcaoRespostaMutationVariables = {
  input: UpdatePerguntaOpcaoRespostaInput,
  condition?: ModelPerguntaOpcaoRespostaConditionInput | null,
};

export type UpdatePerguntaOpcaoRespostaMutation = {
  updatePerguntaOpcaoResposta?:  {
    __typename: "PerguntaOpcaoResposta",
    id: string,
    opcaoResposta: string,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    viviamJuntos?: boolean | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaOpcaoRespostaPerguntaId: string,
  } | null,
};

export type DeletePerguntaOpcaoRespostaMutationVariables = {
  input: DeletePerguntaOpcaoRespostaInput,
  condition?: ModelPerguntaOpcaoRespostaConditionInput | null,
};

export type DeletePerguntaOpcaoRespostaMutation = {
  deletePerguntaOpcaoResposta?:  {
    __typename: "PerguntaOpcaoResposta",
    id: string,
    opcaoResposta: string,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    viviamJuntos?: boolean | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaOpcaoRespostaPerguntaId: string,
  } | null,
};

export type CreatePerguntaNivelRelacionamentoMutationVariables = {
  input: CreatePerguntaNivelRelacionamentoInput,
  condition?: ModelPerguntaNivelRelacionamentoConditionInput | null,
};

export type CreatePerguntaNivelRelacionamentoMutation = {
  createPerguntaNivelRelacionamento?:  {
    __typename: "PerguntaNivelRelacionamento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaNivelRelacionamentoPerguntaId: string,
  } | null,
};

export type UpdatePerguntaNivelRelacionamentoMutationVariables = {
  input: UpdatePerguntaNivelRelacionamentoInput,
  condition?: ModelPerguntaNivelRelacionamentoConditionInput | null,
};

export type UpdatePerguntaNivelRelacionamentoMutation = {
  updatePerguntaNivelRelacionamento?:  {
    __typename: "PerguntaNivelRelacionamento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaNivelRelacionamentoPerguntaId: string,
  } | null,
};

export type DeletePerguntaNivelRelacionamentoMutationVariables = {
  input: DeletePerguntaNivelRelacionamentoInput,
  condition?: ModelPerguntaNivelRelacionamentoConditionInput | null,
};

export type DeletePerguntaNivelRelacionamentoMutation = {
  deletePerguntaNivelRelacionamento?:  {
    __typename: "PerguntaNivelRelacionamento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaNivelRelacionamentoPerguntaId: string,
  } | null,
};

export type CreatePerguntaMutationVariables = {
  input: CreatePerguntaInput,
  condition?: ModelPerguntaConditionInput | null,
};

export type CreatePerguntaMutation = {
  createPergunta?:  {
    __typename: "Pergunta",
    id: string,
    pergunta: string,
    feminino?: string | null,
    tipoPergunta: TipoPerguntaEnum,
    tipoResposta: TipoRespostaEnum,
    respostaTipoViviamJuntos: boolean,
    limitaTipoRelacionamento: boolean,
    limitaFaixaEtaria: boolean,
    limitaNivelRelacionamento: boolean,
    modeloResposta?: string | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePerguntaMutationVariables = {
  input: UpdatePerguntaInput,
  condition?: ModelPerguntaConditionInput | null,
};

export type UpdatePerguntaMutation = {
  updatePergunta?:  {
    __typename: "Pergunta",
    id: string,
    pergunta: string,
    feminino?: string | null,
    tipoPergunta: TipoPerguntaEnum,
    tipoResposta: TipoRespostaEnum,
    respostaTipoViviamJuntos: boolean,
    limitaTipoRelacionamento: boolean,
    limitaFaixaEtaria: boolean,
    limitaNivelRelacionamento: boolean,
    modeloResposta?: string | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePerguntaMutationVariables = {
  input: DeletePerguntaInput,
  condition?: ModelPerguntaConditionInput | null,
};

export type DeletePerguntaMutation = {
  deletePergunta?:  {
    __typename: "Pergunta",
    id: string,
    pergunta: string,
    feminino?: string | null,
    tipoPergunta: TipoPerguntaEnum,
    tipoResposta: TipoRespostaEnum,
    respostaTipoViviamJuntos: boolean,
    limitaTipoRelacionamento: boolean,
    limitaFaixaEtaria: boolean,
    limitaNivelRelacionamento: boolean,
    modeloResposta?: string | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTipoRelacionamentoMutationVariables = {
  input: CreateTipoRelacionamentoInput,
  condition?: ModelTipoRelacionamentoConditionInput | null,
};

export type CreateTipoRelacionamentoMutation = {
  createTipoRelacionamento?:  {
    __typename: "TipoRelacionamento",
    id: string,
    nivelRelaciomento: NivelRelacionamentoEnum,
    titulo: string,
    sexo?: SexoEnum | null,
    prioridade?: number | null,
    active?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTipoRelacionamentoMutationVariables = {
  input: UpdateTipoRelacionamentoInput,
  condition?: ModelTipoRelacionamentoConditionInput | null,
};

export type UpdateTipoRelacionamentoMutation = {
  updateTipoRelacionamento?:  {
    __typename: "TipoRelacionamento",
    id: string,
    nivelRelaciomento: NivelRelacionamentoEnum,
    titulo: string,
    sexo?: SexoEnum | null,
    prioridade?: number | null,
    active?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTipoRelacionamentoMutationVariables = {
  input: DeleteTipoRelacionamentoInput,
  condition?: ModelTipoRelacionamentoConditionInput | null,
};

export type DeleteTipoRelacionamentoMutation = {
  deleteTipoRelacionamento?:  {
    __typename: "TipoRelacionamento",
    id: string,
    nivelRelaciomento: NivelRelacionamentoEnum,
    titulo: string,
    sexo?: SexoEnum | null,
    prioridade?: number | null,
    active?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFalecimentoCidadeMutationVariables = {
  input: CreateFalecimentoCidadeInput,
  condition?: ModelFalecimentoCidadeConditionInput | null,
};

export type CreateFalecimentoCidadeMutation = {
  createFalecimentoCidade?:  {
    __typename: "FalecimentoCidade",
    id: string,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    QtdMensagens: number,
    nomeCidade?: string | null,
    siglaEstado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoCidadeFalecimentoId: string,
    falecimentoCidadeCidadeId: string,
  } | null,
};

export type UpdateFalecimentoCidadeMutationVariables = {
  input: UpdateFalecimentoCidadeInput,
  condition?: ModelFalecimentoCidadeConditionInput | null,
};

export type UpdateFalecimentoCidadeMutation = {
  updateFalecimentoCidade?:  {
    __typename: "FalecimentoCidade",
    id: string,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    QtdMensagens: number,
    nomeCidade?: string | null,
    siglaEstado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoCidadeFalecimentoId: string,
    falecimentoCidadeCidadeId: string,
  } | null,
};

export type DeleteFalecimentoCidadeMutationVariables = {
  input: DeleteFalecimentoCidadeInput,
  condition?: ModelFalecimentoCidadeConditionInput | null,
};

export type DeleteFalecimentoCidadeMutation = {
  deleteFalecimentoCidade?:  {
    __typename: "FalecimentoCidade",
    id: string,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    QtdMensagens: number,
    nomeCidade?: string | null,
    siglaEstado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoCidadeFalecimentoId: string,
    falecimentoCidadeCidadeId: string,
  } | null,
};

export type CreateConviteAdministracaoMemorialMutationVariables = {
  input: CreateConviteAdministracaoMemorialInput,
  condition?: ModelConviteAdministracaoMemorialConditionInput | null,
};

export type CreateConviteAdministracaoMemorialMutation = {
  createConviteAdministracaoMemorial?:  {
    __typename: "ConviteAdministracaoMemorial",
    id: string,
    memorialID: string,
    email: string,
    status: ConviteAdministracaoMemorialStatusEnum,
    dataEnvio: string,
    dataAceite?: string | null,
    Convidado?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conviteAdministracaoMemorialConvidadoId?: string | null,
  } | null,
};

export type UpdateConviteAdministracaoMemorialMutationVariables = {
  input: UpdateConviteAdministracaoMemorialInput,
  condition?: ModelConviteAdministracaoMemorialConditionInput | null,
};

export type UpdateConviteAdministracaoMemorialMutation = {
  updateConviteAdministracaoMemorial?:  {
    __typename: "ConviteAdministracaoMemorial",
    id: string,
    memorialID: string,
    email: string,
    status: ConviteAdministracaoMemorialStatusEnum,
    dataEnvio: string,
    dataAceite?: string | null,
    Convidado?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conviteAdministracaoMemorialConvidadoId?: string | null,
  } | null,
};

export type DeleteConviteAdministracaoMemorialMutationVariables = {
  input: DeleteConviteAdministracaoMemorialInput,
  condition?: ModelConviteAdministracaoMemorialConditionInput | null,
};

export type DeleteConviteAdministracaoMemorialMutation = {
  deleteConviteAdministracaoMemorial?:  {
    __typename: "ConviteAdministracaoMemorial",
    id: string,
    memorialID: string,
    email: string,
    status: ConviteAdministracaoMemorialStatusEnum,
    dataEnvio: string,
    dataAceite?: string | null,
    Convidado?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conviteAdministracaoMemorialConvidadoId?: string | null,
  } | null,
};

export type CreateMemorialMutationVariables = {
  input: CreateMemorialInput,
  condition?: ModelMemorialConditionInput | null,
};

export type CreateMemorialMutation = {
  createMemorial?:  {
    __typename: "Memorial",
    id: string,
    url: string,
    Administradores?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SuperAdmin?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    ConviteAdministracaoMemorials?:  {
      __typename: "ModelConviteAdministracaoMemorialConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Criador?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    emailSuperAdmin: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    memorialSuperAdminId?: string | null,
    memorialCriadorId?: string | null,
    memorialFalecimentoId: string,
  } | null,
};

export type UpdateMemorialMutationVariables = {
  input: UpdateMemorialInput,
  condition?: ModelMemorialConditionInput | null,
};

export type UpdateMemorialMutation = {
  updateMemorial?:  {
    __typename: "Memorial",
    id: string,
    url: string,
    Administradores?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SuperAdmin?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    ConviteAdministracaoMemorials?:  {
      __typename: "ModelConviteAdministracaoMemorialConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Criador?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    emailSuperAdmin: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    memorialSuperAdminId?: string | null,
    memorialCriadorId?: string | null,
    memorialFalecimentoId: string,
  } | null,
};

export type DeleteMemorialMutationVariables = {
  input: DeleteMemorialInput,
  condition?: ModelMemorialConditionInput | null,
};

export type DeleteMemorialMutation = {
  deleteMemorial?:  {
    __typename: "Memorial",
    id: string,
    url: string,
    Administradores?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SuperAdmin?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    ConviteAdministracaoMemorials?:  {
      __typename: "ModelConviteAdministracaoMemorialConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Criador?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    emailSuperAdmin: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    memorialSuperAdminId?: string | null,
    memorialCriadorId?: string | null,
    memorialFalecimentoId: string,
  } | null,
};

export type CreateAnonymousUserMutationVariables = {
  input: CreateAnonymousUserInput,
  condition?: ModelAnonymousUserConditionInput | null,
};

export type CreateAnonymousUserMutation = {
  createAnonymousUser?:  {
    __typename: "AnonymousUser",
    id: string,
    nome?: string | null,
    avatar?: string | null,
    email?: string | null,
    ip?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sexo?: SexoEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    anonymousUserCidadeId?: string | null,
  } | null,
};

export type UpdateAnonymousUserMutationVariables = {
  input: UpdateAnonymousUserInput,
  condition?: ModelAnonymousUserConditionInput | null,
};

export type UpdateAnonymousUserMutation = {
  updateAnonymousUser?:  {
    __typename: "AnonymousUser",
    id: string,
    nome?: string | null,
    avatar?: string | null,
    email?: string | null,
    ip?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sexo?: SexoEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    anonymousUserCidadeId?: string | null,
  } | null,
};

export type DeleteAnonymousUserMutationVariables = {
  input: DeleteAnonymousUserInput,
  condition?: ModelAnonymousUserConditionInput | null,
};

export type DeleteAnonymousUserMutation = {
  deleteAnonymousUser?:  {
    __typename: "AnonymousUser",
    id: string,
    nome?: string | null,
    avatar?: string | null,
    email?: string | null,
    ip?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sexo?: SexoEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    anonymousUserCidadeId?: string | null,
  } | null,
};

export type CreateMensagemMuralMutationVariables = {
  input: CreateMensagemMuralInput,
  condition?: ModelMensagemMuralConditionInput | null,
};

export type CreateMensagemMuralMutation = {
  createMensagemMural?:  {
    __typename: "MensagemMural",
    id: string,
    mensagem: string,
    dataPostagem: string,
    horaPostagem: string,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    status?: MensagemMuralStatusEnum | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    AprovadoPor?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    dataAprovacao?: string | null,
    cidade?: string | null,
    estado?: string | null,
    nomeUsuario?: string | null,
    tipoMensagem?: TipoMensagemEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    resumo?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralCidadeId?: string | null,
    mensagemMuralFalecimentoId: string,
    mensagemMuralUserId?: string | null,
    mensagemMuralAnonymousUserId?: string | null,
    mensagemMuralAprovadoPorId?: string | null,
    mensagemMuralMemorialId?: string | null,
  } | null,
};

export type UpdateMensagemMuralMutationVariables = {
  input: UpdateMensagemMuralInput,
  condition?: ModelMensagemMuralConditionInput | null,
};

export type UpdateMensagemMuralMutation = {
  updateMensagemMural?:  {
    __typename: "MensagemMural",
    id: string,
    mensagem: string,
    dataPostagem: string,
    horaPostagem: string,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    status?: MensagemMuralStatusEnum | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    AprovadoPor?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    dataAprovacao?: string | null,
    cidade?: string | null,
    estado?: string | null,
    nomeUsuario?: string | null,
    tipoMensagem?: TipoMensagemEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    resumo?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralCidadeId?: string | null,
    mensagemMuralFalecimentoId: string,
    mensagemMuralUserId?: string | null,
    mensagemMuralAnonymousUserId?: string | null,
    mensagemMuralAprovadoPorId?: string | null,
    mensagemMuralMemorialId?: string | null,
  } | null,
};

export type DeleteMensagemMuralMutationVariables = {
  input: DeleteMensagemMuralInput,
  condition?: ModelMensagemMuralConditionInput | null,
};

export type DeleteMensagemMuralMutation = {
  deleteMensagemMural?:  {
    __typename: "MensagemMural",
    id: string,
    mensagem: string,
    dataPostagem: string,
    horaPostagem: string,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    status?: MensagemMuralStatusEnum | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    AprovadoPor?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    dataAprovacao?: string | null,
    cidade?: string | null,
    estado?: string | null,
    nomeUsuario?: string | null,
    tipoMensagem?: TipoMensagemEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    resumo?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralCidadeId?: string | null,
    mensagemMuralFalecimentoId: string,
    mensagemMuralUserId?: string | null,
    mensagemMuralAnonymousUserId?: string | null,
    mensagemMuralAprovadoPorId?: string | null,
    mensagemMuralMemorialId?: string | null,
  } | null,
};

export type CreateCemiterioMutationVariables = {
  input: CreateCemiterioInput,
  condition?: ModelCemiterioConditionInput | null,
};

export type CreateCemiterioMutation = {
  createCemiterio?:  {
    __typename: "Cemiterio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    bairro?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Velorios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cemiterioCidadeId?: string | null,
    cemiterioOwnerId?: string | null,
  } | null,
};

export type UpdateCemiterioMutationVariables = {
  input: UpdateCemiterioInput,
  condition?: ModelCemiterioConditionInput | null,
};

export type UpdateCemiterioMutation = {
  updateCemiterio?:  {
    __typename: "Cemiterio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    bairro?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Velorios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cemiterioCidadeId?: string | null,
    cemiterioOwnerId?: string | null,
  } | null,
};

export type DeleteCemiterioMutationVariables = {
  input: DeleteCemiterioInput,
  condition?: ModelCemiterioConditionInput | null,
};

export type DeleteCemiterioMutation = {
  deleteCemiterio?:  {
    __typename: "Cemiterio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    bairro?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Velorios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cemiterioCidadeId?: string | null,
    cemiterioOwnerId?: string | null,
  } | null,
};

export type CreateMesorregiaoMutationVariables = {
  input: CreateMesorregiaoInput,
  condition?: ModelMesorregiaoConditionInput | null,
};

export type CreateMesorregiaoMutation = {
  createMesorregiao?:  {
    __typename: "Mesorregiao",
    id: string,
    nome: string,
    idIbge: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mesorregiaoEstadoId?: string | null,
  } | null,
};

export type UpdateMesorregiaoMutationVariables = {
  input: UpdateMesorregiaoInput,
  condition?: ModelMesorregiaoConditionInput | null,
};

export type UpdateMesorregiaoMutation = {
  updateMesorregiao?:  {
    __typename: "Mesorregiao",
    id: string,
    nome: string,
    idIbge: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mesorregiaoEstadoId?: string | null,
  } | null,
};

export type DeleteMesorregiaoMutationVariables = {
  input: DeleteMesorregiaoInput,
  condition?: ModelMesorregiaoConditionInput | null,
};

export type DeleteMesorregiaoMutation = {
  deleteMesorregiao?:  {
    __typename: "Mesorregiao",
    id: string,
    nome: string,
    idIbge: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mesorregiaoEstadoId?: string | null,
  } | null,
};

export type CreateMicrorregiaoMutationVariables = {
  input: CreateMicrorregiaoInput,
  condition?: ModelMicrorregiaoConditionInput | null,
};

export type CreateMicrorregiaoMutation = {
  createMicrorregiao?:  {
    __typename: "Microrregiao",
    id: string,
    nome: string,
    idIbge: string,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    microrregiaoMesorregiaoId?: string | null,
  } | null,
};

export type UpdateMicrorregiaoMutationVariables = {
  input: UpdateMicrorregiaoInput,
  condition?: ModelMicrorregiaoConditionInput | null,
};

export type UpdateMicrorregiaoMutation = {
  updateMicrorregiao?:  {
    __typename: "Microrregiao",
    id: string,
    nome: string,
    idIbge: string,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    microrregiaoMesorregiaoId?: string | null,
  } | null,
};

export type DeleteMicrorregiaoMutationVariables = {
  input: DeleteMicrorregiaoInput,
  condition?: ModelMicrorregiaoConditionInput | null,
};

export type DeleteMicrorregiaoMutation = {
  deleteMicrorregiao?:  {
    __typename: "Microrregiao",
    id: string,
    nome: string,
    idIbge: string,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    microrregiaoMesorregiaoId?: string | null,
  } | null,
};

export type CreateRegiaoMutationVariables = {
  input: CreateRegiaoInput,
  condition?: ModelRegiaoConditionInput | null,
};

export type CreateRegiaoMutation = {
  createRegiao?:  {
    __typename: "Regiao",
    id: string,
    sigla: string,
    nome: string,
    idIbge: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRegiaoMutationVariables = {
  input: UpdateRegiaoInput,
  condition?: ModelRegiaoConditionInput | null,
};

export type UpdateRegiaoMutation = {
  updateRegiao?:  {
    __typename: "Regiao",
    id: string,
    sigla: string,
    nome: string,
    idIbge: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRegiaoMutationVariables = {
  input: DeleteRegiaoInput,
  condition?: ModelRegiaoConditionInput | null,
};

export type DeleteRegiaoMutation = {
  deleteRegiao?:  {
    __typename: "Regiao",
    id: string,
    sigla: string,
    nome: string,
    idIbge: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEstadoMutationVariables = {
  input: CreateEstadoInput,
  condition?: ModelEstadoConditionInput | null,
};

export type CreateEstadoMutation = {
  createEstado?:  {
    __typename: "Estado",
    id: string,
    nome: string,
    sigla: string,
    idIbge: string,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    estadoRegiaoId?: string | null,
  } | null,
};

export type UpdateEstadoMutationVariables = {
  input: UpdateEstadoInput,
  condition?: ModelEstadoConditionInput | null,
};

export type UpdateEstadoMutation = {
  updateEstado?:  {
    __typename: "Estado",
    id: string,
    nome: string,
    sigla: string,
    idIbge: string,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    estadoRegiaoId?: string | null,
  } | null,
};

export type DeleteEstadoMutationVariables = {
  input: DeleteEstadoInput,
  condition?: ModelEstadoConditionInput | null,
};

export type DeleteEstadoMutation = {
  deleteEstado?:  {
    __typename: "Estado",
    id: string,
    nome: string,
    sigla: string,
    idIbge: string,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    estadoRegiaoId?: string | null,
  } | null,
};

export type CreateCidadeMutationVariables = {
  input: CreateCidadeInput,
  condition?: ModelCidadeConditionInput | null,
};

export type CreateCidadeMutation = {
  createCidade?:  {
    __typename: "Cidade",
    id: string,
    nome: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    idIbge: string,
    Microrregiao?:  {
      __typename: "Microrregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      microrregiaoMesorregiaoId?: string | null,
    } | null,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cidadeEstadoId?: string | null,
    cidadeMicrorregiaoId?: string | null,
    cidadeMesorregiaoId?: string | null,
    cidadeRegiaoId?: string | null,
  } | null,
};

export type UpdateCidadeMutationVariables = {
  input: UpdateCidadeInput,
  condition?: ModelCidadeConditionInput | null,
};

export type UpdateCidadeMutation = {
  updateCidade?:  {
    __typename: "Cidade",
    id: string,
    nome: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    idIbge: string,
    Microrregiao?:  {
      __typename: "Microrregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      microrregiaoMesorregiaoId?: string | null,
    } | null,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cidadeEstadoId?: string | null,
    cidadeMicrorregiaoId?: string | null,
    cidadeMesorregiaoId?: string | null,
    cidadeRegiaoId?: string | null,
  } | null,
};

export type DeleteCidadeMutationVariables = {
  input: DeleteCidadeInput,
  condition?: ModelCidadeConditionInput | null,
};

export type DeleteCidadeMutation = {
  deleteCidade?:  {
    __typename: "Cidade",
    id: string,
    nome: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    idIbge: string,
    Microrregiao?:  {
      __typename: "Microrregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      microrregiaoMesorregiaoId?: string | null,
    } | null,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cidadeEstadoId?: string | null,
    cidadeMicrorregiaoId?: string | null,
    cidadeMesorregiaoId?: string | null,
    cidadeRegiaoId?: string | null,
  } | null,
};

export type CreateVelorioMutationVariables = {
  input: CreateVelorioInput,
  condition?: ModelVelorioConditionInput | null,
};

export type CreateVelorioMutation = {
  createVelorio?:  {
    __typename: "Velorio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    bairro?: string | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    velorioCidadeId?: string | null,
    velorioOwnerId?: string | null,
  } | null,
};

export type UpdateVelorioMutationVariables = {
  input: UpdateVelorioInput,
  condition?: ModelVelorioConditionInput | null,
};

export type UpdateVelorioMutation = {
  updateVelorio?:  {
    __typename: "Velorio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    bairro?: string | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    velorioCidadeId?: string | null,
    velorioOwnerId?: string | null,
  } | null,
};

export type DeleteVelorioMutationVariables = {
  input: DeleteVelorioInput,
  condition?: ModelVelorioConditionInput | null,
};

export type DeleteVelorioMutation = {
  deleteVelorio?:  {
    __typename: "Velorio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    bairro?: string | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    velorioCidadeId?: string | null,
    velorioOwnerId?: string | null,
  } | null,
};

export type CreateFalecimentoMutationVariables = {
  input: CreateFalecimentoInput,
  condition?: ModelFalecimentoConditionInput | null,
};

export type CreateFalecimentoMutation = {
  createFalecimento?:  {
    __typename: "Falecimento",
    id: string,
    nome: string,
    sobrenome?: string | null,
    dataFalecimento: string,
    Funeraria?:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    } | null,
    searchName: string,
    idade: number,
    tempoDeVida: string,
    meses: number,
    Velorio?:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    } | null,
    dataInicioVelorio?: string | null,
    horaInicioVelorio?: string | null,
    dataFimVelorio?: string | null,
    horaFimVelorio?: string | null,
    dataSepultamento?: string | null,
    horaSepultamento?: string | null,
    salaVelorio?: string | null,
    Cemiterio?:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    } | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sobre?: string | null,
    imageKey?: string | null,
    apelido?: string | null,
    cidade?: string | null,
    estado?: string | null,
    sexo?: SexoEnum | null,
    dataNascimento: string,
    faixaEtaria?: FaixaEtariaEnum | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoFunerariaId?: string | null,
    falecimentoVelorioId?: string | null,
    falecimentoCemiterioId?: string | null,
    falecimentoCidadeId?: string | null,
    falecimentoOpenAiAssistantId?: string | null,
  } | null,
};

export type UpdateFalecimentoMutationVariables = {
  input: UpdateFalecimentoInput,
  condition?: ModelFalecimentoConditionInput | null,
};

export type UpdateFalecimentoMutation = {
  updateFalecimento?:  {
    __typename: "Falecimento",
    id: string,
    nome: string,
    sobrenome?: string | null,
    dataFalecimento: string,
    Funeraria?:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    } | null,
    searchName: string,
    idade: number,
    tempoDeVida: string,
    meses: number,
    Velorio?:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    } | null,
    dataInicioVelorio?: string | null,
    horaInicioVelorio?: string | null,
    dataFimVelorio?: string | null,
    horaFimVelorio?: string | null,
    dataSepultamento?: string | null,
    horaSepultamento?: string | null,
    salaVelorio?: string | null,
    Cemiterio?:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    } | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sobre?: string | null,
    imageKey?: string | null,
    apelido?: string | null,
    cidade?: string | null,
    estado?: string | null,
    sexo?: SexoEnum | null,
    dataNascimento: string,
    faixaEtaria?: FaixaEtariaEnum | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoFunerariaId?: string | null,
    falecimentoVelorioId?: string | null,
    falecimentoCemiterioId?: string | null,
    falecimentoCidadeId?: string | null,
    falecimentoOpenAiAssistantId?: string | null,
  } | null,
};

export type DeleteFalecimentoMutationVariables = {
  input: DeleteFalecimentoInput,
  condition?: ModelFalecimentoConditionInput | null,
};

export type DeleteFalecimentoMutation = {
  deleteFalecimento?:  {
    __typename: "Falecimento",
    id: string,
    nome: string,
    sobrenome?: string | null,
    dataFalecimento: string,
    Funeraria?:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    } | null,
    searchName: string,
    idade: number,
    tempoDeVida: string,
    meses: number,
    Velorio?:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    } | null,
    dataInicioVelorio?: string | null,
    horaInicioVelorio?: string | null,
    dataFimVelorio?: string | null,
    horaFimVelorio?: string | null,
    dataSepultamento?: string | null,
    horaSepultamento?: string | null,
    salaVelorio?: string | null,
    Cemiterio?:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    } | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sobre?: string | null,
    imageKey?: string | null,
    apelido?: string | null,
    cidade?: string | null,
    estado?: string | null,
    sexo?: SexoEnum | null,
    dataNascimento: string,
    faixaEtaria?: FaixaEtariaEnum | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoFunerariaId?: string | null,
    falecimentoVelorioId?: string | null,
    falecimentoCemiterioId?: string | null,
    falecimentoCidadeId?: string | null,
    falecimentoOpenAiAssistantId?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    email: string,
    Funerarias?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nome?: string | null,
    status?: UserStatusEnum | null,
    avatar?: string | null,
    imageKey?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    role?: UserRoleEnum | null,
    memorials?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sexo?: SexoEnum | null,
    lastIpAddress?: string | null,
    anonymousUserId?: string | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCidadeId?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    Funerarias?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nome?: string | null,
    status?: UserStatusEnum | null,
    avatar?: string | null,
    imageKey?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    role?: UserRoleEnum | null,
    memorials?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sexo?: SexoEnum | null,
    lastIpAddress?: string | null,
    anonymousUserId?: string | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCidadeId?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    Funerarias?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nome?: string | null,
    status?: UserStatusEnum | null,
    avatar?: string | null,
    imageKey?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    role?: UserRoleEnum | null,
    memorials?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sexo?: SexoEnum | null,
    lastIpAddress?: string | null,
    anonymousUserId?: string | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCidadeId?: string | null,
  } | null,
};

export type CreateFunerariaMutationVariables = {
  input: CreateFunerariaInput,
  condition?: ModelFunerariaConditionInput | null,
};

export type CreateFunerariaMutation = {
  createFuneraria?:  {
    __typename: "Funeraria",
    id: string,
    cnpj: string,
    nome: string,
    logradouro: string,
    cep: string,
    telefone1?: string | null,
    telefone2?: string | null,
    email?: string | null,
    site?: string | null,
    users?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    velorios?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    funerariaCidadeId: string,
  } | null,
};

export type UpdateFunerariaMutationVariables = {
  input: UpdateFunerariaInput,
  condition?: ModelFunerariaConditionInput | null,
};

export type UpdateFunerariaMutation = {
  updateFuneraria?:  {
    __typename: "Funeraria",
    id: string,
    cnpj: string,
    nome: string,
    logradouro: string,
    cep: string,
    telefone1?: string | null,
    telefone2?: string | null,
    email?: string | null,
    site?: string | null,
    users?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    velorios?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    funerariaCidadeId: string,
  } | null,
};

export type DeleteFunerariaMutationVariables = {
  input: DeleteFunerariaInput,
  condition?: ModelFunerariaConditionInput | null,
};

export type DeleteFunerariaMutation = {
  deleteFuneraria?:  {
    __typename: "Funeraria",
    id: string,
    cnpj: string,
    nome: string,
    logradouro: string,
    cep: string,
    telefone1?: string | null,
    telefone2?: string | null,
    email?: string | null,
    site?: string | null,
    users?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    velorios?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    funerariaCidadeId: string,
  } | null,
};

export type CreateMemorialUserMutationVariables = {
  input: CreateMemorialUserInput,
  condition?: ModelMemorialUserConditionInput | null,
};

export type CreateMemorialUserMutation = {
  createMemorialUser?:  {
    __typename: "MemorialUser",
    id: string,
    memorialId: string,
    userId: string,
    memorial:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    },
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMemorialUserMutationVariables = {
  input: UpdateMemorialUserInput,
  condition?: ModelMemorialUserConditionInput | null,
};

export type UpdateMemorialUserMutation = {
  updateMemorialUser?:  {
    __typename: "MemorialUser",
    id: string,
    memorialId: string,
    userId: string,
    memorial:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    },
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMemorialUserMutationVariables = {
  input: DeleteMemorialUserInput,
  condition?: ModelMemorialUserConditionInput | null,
};

export type DeleteMemorialUserMutation = {
  deleteMemorialUser?:  {
    __typename: "MemorialUser",
    id: string,
    memorialId: string,
    userId: string,
    memorial:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    },
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCemiterioFunerariaMutationVariables = {
  input: CreateCemiterioFunerariaInput,
  condition?: ModelCemiterioFunerariaConditionInput | null,
};

export type CreateCemiterioFunerariaMutation = {
  createCemiterioFuneraria?:  {
    __typename: "CemiterioFuneraria",
    id: string,
    cemiterioId: string,
    funerariaId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCemiterioFunerariaMutationVariables = {
  input: UpdateCemiterioFunerariaInput,
  condition?: ModelCemiterioFunerariaConditionInput | null,
};

export type UpdateCemiterioFunerariaMutation = {
  updateCemiterioFuneraria?:  {
    __typename: "CemiterioFuneraria",
    id: string,
    cemiterioId: string,
    funerariaId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCemiterioFunerariaMutationVariables = {
  input: DeleteCemiterioFunerariaInput,
  condition?: ModelCemiterioFunerariaConditionInput | null,
};

export type DeleteCemiterioFunerariaMutation = {
  deleteCemiterioFuneraria?:  {
    __typename: "CemiterioFuneraria",
    id: string,
    cemiterioId: string,
    funerariaId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCemiterioVelorioMutationVariables = {
  input: CreateCemiterioVelorioInput,
  condition?: ModelCemiterioVelorioConditionInput | null,
};

export type CreateCemiterioVelorioMutation = {
  createCemiterioVelorio?:  {
    __typename: "CemiterioVelorio",
    id: string,
    cemiterioId: string,
    velorioId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCemiterioVelorioMutationVariables = {
  input: UpdateCemiterioVelorioInput,
  condition?: ModelCemiterioVelorioConditionInput | null,
};

export type UpdateCemiterioVelorioMutation = {
  updateCemiterioVelorio?:  {
    __typename: "CemiterioVelorio",
    id: string,
    cemiterioId: string,
    velorioId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCemiterioVelorioMutationVariables = {
  input: DeleteCemiterioVelorioInput,
  condition?: ModelCemiterioVelorioConditionInput | null,
};

export type DeleteCemiterioVelorioMutation = {
  deleteCemiterioVelorio?:  {
    __typename: "CemiterioVelorio",
    id: string,
    cemiterioId: string,
    velorioId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateVelorioFunerariaMutationVariables = {
  input: CreateVelorioFunerariaInput,
  condition?: ModelVelorioFunerariaConditionInput | null,
};

export type CreateVelorioFunerariaMutation = {
  createVelorioFuneraria?:  {
    __typename: "VelorioFuneraria",
    id: string,
    velorioId: string,
    funerariaId: string,
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateVelorioFunerariaMutationVariables = {
  input: UpdateVelorioFunerariaInput,
  condition?: ModelVelorioFunerariaConditionInput | null,
};

export type UpdateVelorioFunerariaMutation = {
  updateVelorioFuneraria?:  {
    __typename: "VelorioFuneraria",
    id: string,
    velorioId: string,
    funerariaId: string,
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteVelorioFunerariaMutationVariables = {
  input: DeleteVelorioFunerariaInput,
  condition?: ModelVelorioFunerariaConditionInput | null,
};

export type DeleteVelorioFunerariaMutation = {
  deleteVelorioFuneraria?:  {
    __typename: "VelorioFuneraria",
    id: string,
    velorioId: string,
    funerariaId: string,
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserFunerariaMutationVariables = {
  input: CreateUserFunerariaInput,
  condition?: ModelUserFunerariaConditionInput | null,
};

export type CreateUserFunerariaMutation = {
  createUserFuneraria?:  {
    __typename: "UserFuneraria",
    id: string,
    userId: string,
    funerariaId: string,
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserFunerariaMutationVariables = {
  input: UpdateUserFunerariaInput,
  condition?: ModelUserFunerariaConditionInput | null,
};

export type UpdateUserFunerariaMutation = {
  updateUserFuneraria?:  {
    __typename: "UserFuneraria",
    id: string,
    userId: string,
    funerariaId: string,
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserFunerariaMutationVariables = {
  input: DeleteUserFunerariaInput,
  condition?: ModelUserFunerariaConditionInput | null,
};

export type DeleteUserFunerariaMutation = {
  deleteUserFuneraria?:  {
    __typename: "UserFuneraria",
    id: string,
    userId: string,
    funerariaId: string,
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CustomListMensagemMuralsQueryVariables = {
  filter?: ModelMensagemMuralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListMensagemMuralsQuery = {
  listMensagemMurals?:  {
    __typename: "ModelMensagemMuralConnection",
    items:  Array< {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      User?:  {
        __typename: "User",
        imageKey?: string | null,
        nome?: string | null,
        email: string,
      } | null,
      AnonymousUser?:  {
        __typename: "AnonymousUser",
        avatar?: string | null,
        nome?: string | null,
        email?: string | null,
      } | null,
      Cidade?:  {
        __typename: "Cidade",
        nome: string,
        Estado?:  {
          __typename: "Estado",
          sigla: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomSyncMensagemMuralsQueryVariables = {
  filter?: ModelMensagemMuralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type CustomSyncMensagemMuralsQuery = {
  syncMensagemMurals?:  {
    __typename: "ModelMensagemMuralConnection",
    items:  Array< {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      User?:  {
        __typename: "User",
        imageKey?: string | null,
        nome?: string | null,
        email: string,
      } | null,
      AnonymousUser?:  {
        __typename: "AnonymousUser",
        avatar?: string | null,
        nome?: string | null,
        email?: string | null,
      } | null,
      Cidade?:  {
        __typename: "Cidade",
        nome: string,
        Estado?:  {
          __typename: "Estado",
          sigla: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomSyncMensagemMuralsFeedQueryVariables = {
  filter?: ModelMensagemMuralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type CustomSyncMensagemMuralsFeedQuery = {
  syncMensagemMurals?:  {
    __typename: "ModelMensagemMuralConnection",
    items:  Array< {
      __typename: "MensagemMural",
      id: string,
      nomeUsuario?: string | null,
      resumo?: string | null,
      cidade?: string | null,
      estado?: string | null,
      dataPostagem: string,
      horaPostagem: string,
      tipoMensagem?: TipoMensagemEnum | null,
      mensagemMuralUserId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomSyncPostsFeedQueryVariables = {
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type CustomSyncPostsFeedQuery = {
  syncPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      postType: PostTypeEnum,
      postAction: PostActionEnum,
      text: string,
      url: string,
      datePost: string,
      timePost: string,
      resourceId: string,
      createdAt: string,
      updatedAt: string,
      postUserId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPostImageQueryVariables = {
  id: string,
};

export type GetPostImageQuery = {
  getPostImage?:  {
    __typename: "PostImage",
    id: string,
    imageKey: string,
    context: string,
    postID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPostImagesQueryVariables = {
  filter?: ModelPostImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostImagesQuery = {
  listPostImages?:  {
    __typename: "ModelPostImageConnection",
    items:  Array< {
      __typename: "PostImage",
      id: string,
      imageKey: string,
      context: string,
      postID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPostImagesQueryVariables = {
  filter?: ModelPostImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPostImagesQuery = {
  syncPostImages?:  {
    __typename: "ModelPostImageConnection",
    items:  Array< {
      __typename: "PostImage",
      id: string,
      imageKey: string,
      context: string,
      postID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PostImagesByPostIDQueryVariables = {
  postID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostImagesByPostIDQuery = {
  postImagesByPostID?:  {
    __typename: "ModelPostImageConnection",
    items:  Array< {
      __typename: "PostImage",
      id: string,
      imageKey: string,
      context: string,
      postID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPostQueryVariables = {
  id: string,
};

export type GetPostQuery = {
  getPost?:  {
    __typename: "Post",
    id: string,
    postType: PostTypeEnum,
    postAction: PostActionEnum,
    text: string,
    url: string,
    datePost: string,
    timePost: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    PostImages?:  {
      __typename: "ModelPostImageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    resourceId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    postUserId: string,
  } | null,
};

export type ListPostsQueryVariables = {
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsQuery = {
  listPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      postType: PostTypeEnum,
      postAction: PostActionEnum,
      text: string,
      url: string,
      datePost: string,
      timePost: string,
      resourceId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      postUserId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPostsQueryVariables = {
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPostsQuery = {
  syncPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      postType: PostTypeEnum,
      postAction: PostActionEnum,
      text: string,
      url: string,
      datePost: string,
      timePost: string,
      resourceId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      postUserId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMensagemMuralEngajamentoQueryVariables = {
  id: string,
};

export type GetMensagemMuralEngajamentoQuery = {
  getMensagemMuralEngajamento?:  {
    __typename: "MensagemMuralEngajamento",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    views: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralEngajamentoMensagemMuralId: string,
  } | null,
};

export type ListMensagemMuralEngajamentosQueryVariables = {
  filter?: ModelMensagemMuralEngajamentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMensagemMuralEngajamentosQuery = {
  listMensagemMuralEngajamentos?:  {
    __typename: "ModelMensagemMuralEngajamentoConnection",
    items:  Array< {
      __typename: "MensagemMuralEngajamento",
      id: string,
      views: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralEngajamentoMensagemMuralId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMensagemMuralEngajamentosQueryVariables = {
  filter?: ModelMensagemMuralEngajamentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMensagemMuralEngajamentosQuery = {
  syncMensagemMuralEngajamentos?:  {
    __typename: "ModelMensagemMuralEngajamentoConnection",
    items:  Array< {
      __typename: "MensagemMuralEngajamento",
      id: string,
      views: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralEngajamentoMensagemMuralId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMensagemMuralViewsQueryVariables = {
  id: string,
};

export type GetMensagemMuralViewsQuery = {
  getMensagemMuralViews?:  {
    __typename: "MensagemMuralViews",
    id: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    dataVisualizacao: string,
    horaVisualizacao: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralViewsUserId: string,
    mensagemMuralViewsMensagemMuralId: string,
  } | null,
};

export type ListMensagemMuralViewsQueryVariables = {
  filter?: ModelMensagemMuralViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMensagemMuralViewsQuery = {
  listMensagemMuralViews?:  {
    __typename: "ModelMensagemMuralViewsConnection",
    items:  Array< {
      __typename: "MensagemMuralViews",
      id: string,
      dataVisualizacao: string,
      horaVisualizacao: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralViewsUserId: string,
      mensagemMuralViewsMensagemMuralId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMensagemMuralViewsQueryVariables = {
  filter?: ModelMensagemMuralViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMensagemMuralViewsQuery = {
  syncMensagemMuralViews?:  {
    __typename: "ModelMensagemMuralViewsConnection",
    items:  Array< {
      __typename: "MensagemMuralViews",
      id: string,
      dataVisualizacao: string,
      horaVisualizacao: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralViewsUserId: string,
      mensagemMuralViewsMensagemMuralId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMensagemMuralFalecimentoFotoQueryVariables = {
  id: string,
};

export type GetMensagemMuralFalecimentoFotoQuery = {
  getMensagemMuralFalecimentoFoto?:  {
    __typename: "MensagemMuralFalecimentoFoto",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    FalecimentoFoto:  {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    tipoFoto: TipoFoto,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralFalecimentoFotoMensagemMuralId: string,
    mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
  } | null,
};

export type ListMensagemMuralFalecimentoFotosQueryVariables = {
  filter?: ModelMensagemMuralFalecimentoFotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMensagemMuralFalecimentoFotosQuery = {
  listMensagemMuralFalecimentoFotos?:  {
    __typename: "ModelMensagemMuralFalecimentoFotoConnection",
    items:  Array< {
      __typename: "MensagemMuralFalecimentoFoto",
      id: string,
      tipoFoto: TipoFoto,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralFalecimentoFotoMensagemMuralId: string,
      mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMensagemMuralFalecimentoFotosQueryVariables = {
  filter?: ModelMensagemMuralFalecimentoFotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMensagemMuralFalecimentoFotosQuery = {
  syncMensagemMuralFalecimentoFotos?:  {
    __typename: "ModelMensagemMuralFalecimentoFotoConnection",
    items:  Array< {
      __typename: "MensagemMuralFalecimentoFoto",
      id: string,
      tipoFoto: TipoFoto,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralFalecimentoFotoMensagemMuralId: string,
      mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFalecimentoFotoQueryVariables = {
  id: string,
};

export type GetFalecimentoFotoQuery = {
  getFalecimentoFoto?:  {
    __typename: "FalecimentoFoto",
    id: string,
    fileKey: string,
    status: ContentStatusEnum,
    tipo: TipoFoto,
    falecimentoID: string,
    userID: string,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFalecimentoFotosQueryVariables = {
  filter?: ModelFalecimentoFotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFalecimentoFotosQuery = {
  listFalecimentoFotos?:  {
    __typename: "ModelFalecimentoFotoConnection",
    items:  Array< {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFalecimentoFotosQueryVariables = {
  filter?: ModelFalecimentoFotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFalecimentoFotosQuery = {
  syncFalecimentoFotos?:  {
    __typename: "ModelFalecimentoFotoConnection",
    items:  Array< {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FalecimentoFotosByFalecimentoIDQueryVariables = {
  falecimentoID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFalecimentoFotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FalecimentoFotosByFalecimentoIDQuery = {
  falecimentoFotosByFalecimentoID?:  {
    __typename: "ModelFalecimentoFotoConnection",
    items:  Array< {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FalecimentoFotosByUserIDQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFalecimentoFotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FalecimentoFotosByUserIDQuery = {
  falecimentoFotosByUserID?:  {
    __typename: "ModelFalecimentoFotoConnection",
    items:  Array< {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOpenAiGeneratedMessageQueryVariables = {
  id: string,
};

export type GetOpenAiGeneratedMessageQuery = {
  getOpenAiGeneratedMessage?:  {
    __typename: "OpenAiGeneratedMessage",
    id: string,
    falecimentoId: string,
    anonymousUserId: string,
    messageId: string,
    runId: string,
    threadId: string,
    created_at: number,
    contentTextValue: string,
    assistantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOpenAiGeneratedMessagesQueryVariables = {
  filter?: ModelOpenAiGeneratedMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOpenAiGeneratedMessagesQuery = {
  listOpenAiGeneratedMessages?:  {
    __typename: "ModelOpenAiGeneratedMessageConnection",
    items:  Array< {
      __typename: "OpenAiGeneratedMessage",
      id: string,
      falecimentoId: string,
      anonymousUserId: string,
      messageId: string,
      runId: string,
      threadId: string,
      created_at: number,
      contentTextValue: string,
      assistantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOpenAiGeneratedMessagesQueryVariables = {
  filter?: ModelOpenAiGeneratedMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOpenAiGeneratedMessagesQuery = {
  syncOpenAiGeneratedMessages?:  {
    __typename: "ModelOpenAiGeneratedMessageConnection",
    items:  Array< {
      __typename: "OpenAiGeneratedMessage",
      id: string,
      falecimentoId: string,
      anonymousUserId: string,
      messageId: string,
      runId: string,
      threadId: string,
      created_at: number,
      contentTextValue: string,
      assistantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOpenAiRunQueryVariables = {
  id: string,
};

export type GetOpenAiRunQuery = {
  getOpenAiRun?:  {
    __typename: "OpenAiRun",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    runId: string,
    object?: string | null,
    created_at?: number | null,
    status?: string | null,
    expires_at?: number | null,
    started_at?: number | null,
    cancelled_at?: number | null,
    failed_at?: number | null,
    completed_at?: number | null,
    model?: string | null,
    instructions?: string | null,
    assistant_id?: string | null,
    thread_id?: string | null,
    last_error?:  {
      __typename: "OpenAiError",
      code?: string | null,
      message?: string | null,
    } | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    assistentemensagemID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiRunOpenAiThreadId?: string | null,
    openAiRunOpenAiAssistantId?: string | null,
    openAiRunAnonymousUserId?: string | null,
  } | null,
};

export type ListOpenAiRunsQueryVariables = {
  filter?: ModelOpenAiRunFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOpenAiRunsQuery = {
  listOpenAiRuns?:  {
    __typename: "ModelOpenAiRunConnection",
    items:  Array< {
      __typename: "OpenAiRun",
      id: string,
      runId: string,
      object?: string | null,
      created_at?: number | null,
      status?: string | null,
      expires_at?: number | null,
      started_at?: number | null,
      cancelled_at?: number | null,
      failed_at?: number | null,
      completed_at?: number | null,
      model?: string | null,
      instructions?: string | null,
      assistant_id?: string | null,
      thread_id?: string | null,
      assistentemensagemID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiRunOpenAiThreadId?: string | null,
      openAiRunOpenAiAssistantId?: string | null,
      openAiRunAnonymousUserId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOpenAiRunsQueryVariables = {
  filter?: ModelOpenAiRunFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOpenAiRunsQuery = {
  syncOpenAiRuns?:  {
    __typename: "ModelOpenAiRunConnection",
    items:  Array< {
      __typename: "OpenAiRun",
      id: string,
      runId: string,
      object?: string | null,
      created_at?: number | null,
      status?: string | null,
      expires_at?: number | null,
      started_at?: number | null,
      cancelled_at?: number | null,
      failed_at?: number | null,
      completed_at?: number | null,
      model?: string | null,
      instructions?: string | null,
      assistant_id?: string | null,
      thread_id?: string | null,
      assistentemensagemID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiRunOpenAiThreadId?: string | null,
      openAiRunOpenAiAssistantId?: string | null,
      openAiRunAnonymousUserId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OpenAiRunsByAssistentemensagemIDQueryVariables = {
  assistentemensagemID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOpenAiRunFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OpenAiRunsByAssistentemensagemIDQuery = {
  openAiRunsByAssistentemensagemID?:  {
    __typename: "ModelOpenAiRunConnection",
    items:  Array< {
      __typename: "OpenAiRun",
      id: string,
      runId: string,
      object?: string | null,
      created_at?: number | null,
      status?: string | null,
      expires_at?: number | null,
      started_at?: number | null,
      cancelled_at?: number | null,
      failed_at?: number | null,
      completed_at?: number | null,
      model?: string | null,
      instructions?: string | null,
      assistant_id?: string | null,
      thread_id?: string | null,
      assistentemensagemID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiRunOpenAiThreadId?: string | null,
      openAiRunOpenAiAssistantId?: string | null,
      openAiRunAnonymousUserId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOpenAiAssistantQueryVariables = {
  id: string,
};

export type GetOpenAiAssistantQuery = {
  getOpenAiAssistant?:  {
    __typename: "OpenAiAssistant",
    id: string,
    assistantId: string,
    object: string,
    model: string,
    instructions: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOpenAiAssistantsQueryVariables = {
  filter?: ModelOpenAiAssistantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOpenAiAssistantsQuery = {
  listOpenAiAssistants?:  {
    __typename: "ModelOpenAiAssistantConnection",
    items:  Array< {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOpenAiAssistantsQueryVariables = {
  filter?: ModelOpenAiAssistantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOpenAiAssistantsQuery = {
  syncOpenAiAssistants?:  {
    __typename: "ModelOpenAiAssistantConnection",
    items:  Array< {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAssistenteMensagemQueryVariables = {
  id: string,
};

export type GetAssistenteMensagemQuery = {
  getAssistenteMensagem?:  {
    __typename: "AssistenteMensagem",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Respostas?:  {
      __typename: "ModelRespostaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RelUsuarioFalecimento?:  {
      __typename: "RelUsuarioFalecimento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      relUsuarioFalecimentoFalecimentoId: string,
      relUsuarioFalecimentoAnonymousUserId: string,
      relUsuarioFalecimentoUserId?: string | null,
      relUsuarioFalecimentoTipoRelacionamentoId: string,
    } | null,
    tamanhoMensagem?: TamanhoMensagemEnu | null,
    tipoPessoa?: TipoPessoaEnum | null,
    emNomeDe?: string | null,
    OpenAiRuns?:  {
      __typename: "ModelOpenAiRunConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nomeUsuario?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento?:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mensagemUsuario?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    OpenAiGeneratedMessage?:  {
      __typename: "OpenAiGeneratedMessage",
      id: string,
      falecimentoId: string,
      anonymousUserId: string,
      messageId: string,
      runId: string,
      threadId: string,
      created_at: number,
      contentTextValue: string,
      assistantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    instrucao?: string | null,
    Step?: string | null,
    Status?: AssistenteStatusEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    mensagemReescrita?: string | null,
    mensagemGerada?: string | null,
    mensagemCorrigida?: string | null,
    mensagemEditada?: string | null,
    tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    assistenteMensagemOpenAiThreadId?: string | null,
    assistenteMensagemFalecimentoId: string,
    assistenteMensagemAnonymousUserId: string,
    assistenteMensagemOpenAiAssistantId?: string | null,
    assistenteMensagemRelUsuarioFalecimentoId?: string | null,
    assistenteMensagemUserId?: string | null,
    assistenteMensagemTipoRelacionamentoId?: string | null,
    assistenteMensagemCidadeId?: string | null,
    assistenteMensagemOpenAiGeneratedMessageId?: string | null,
    assistenteMensagemMemorialId?: string | null,
  } | null,
};

export type ListAssistenteMensagemsQueryVariables = {
  filter?: ModelAssistenteMensagemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAssistenteMensagemsQuery = {
  listAssistenteMensagems?:  {
    __typename: "ModelAssistenteMensagemConnection",
    items:  Array< {
      __typename: "AssistenteMensagem",
      id: string,
      tamanhoMensagem?: TamanhoMensagemEnu | null,
      tipoPessoa?: TipoPessoaEnum | null,
      emNomeDe?: string | null,
      nomeUsuario?: string | null,
      mensagemUsuario?: string | null,
      instrucao?: string | null,
      Step?: string | null,
      Status?: AssistenteStatusEnum | null,
      mensagemReescrita?: string | null,
      mensagemGerada?: string | null,
      mensagemCorrigida?: string | null,
      mensagemEditada?: string | null,
      tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      assistenteMensagemOpenAiThreadId?: string | null,
      assistenteMensagemFalecimentoId: string,
      assistenteMensagemAnonymousUserId: string,
      assistenteMensagemOpenAiAssistantId?: string | null,
      assistenteMensagemRelUsuarioFalecimentoId?: string | null,
      assistenteMensagemUserId?: string | null,
      assistenteMensagemTipoRelacionamentoId?: string | null,
      assistenteMensagemCidadeId?: string | null,
      assistenteMensagemOpenAiGeneratedMessageId?: string | null,
      assistenteMensagemMemorialId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAssistenteMensagemsQueryVariables = {
  filter?: ModelAssistenteMensagemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAssistenteMensagemsQuery = {
  syncAssistenteMensagems?:  {
    __typename: "ModelAssistenteMensagemConnection",
    items:  Array< {
      __typename: "AssistenteMensagem",
      id: string,
      tamanhoMensagem?: TamanhoMensagemEnu | null,
      tipoPessoa?: TipoPessoaEnum | null,
      emNomeDe?: string | null,
      nomeUsuario?: string | null,
      mensagemUsuario?: string | null,
      instrucao?: string | null,
      Step?: string | null,
      Status?: AssistenteStatusEnum | null,
      mensagemReescrita?: string | null,
      mensagemGerada?: string | null,
      mensagemCorrigida?: string | null,
      mensagemEditada?: string | null,
      tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      assistenteMensagemOpenAiThreadId?: string | null,
      assistenteMensagemFalecimentoId: string,
      assistenteMensagemAnonymousUserId: string,
      assistenteMensagemOpenAiAssistantId?: string | null,
      assistenteMensagemRelUsuarioFalecimentoId?: string | null,
      assistenteMensagemUserId?: string | null,
      assistenteMensagemTipoRelacionamentoId?: string | null,
      assistenteMensagemCidadeId?: string | null,
      assistenteMensagemOpenAiGeneratedMessageId?: string | null,
      assistenteMensagemMemorialId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOpenAiThreadQueryVariables = {
  id: string,
};

export type GetOpenAiThreadQuery = {
  getOpenAiThread?:  {
    __typename: "OpenAiThread",
    id: string,
    threadId: string,
    object: string,
    OpenAiAssistant:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiThreadOpenAiAssistantId: string,
    openAiThreadAnonymousUserId?: string | null,
  } | null,
};

export type ListOpenAiThreadsQueryVariables = {
  filter?: ModelOpenAiThreadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOpenAiThreadsQuery = {
  listOpenAiThreads?:  {
    __typename: "ModelOpenAiThreadConnection",
    items:  Array< {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOpenAiThreadsQueryVariables = {
  filter?: ModelOpenAiThreadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOpenAiThreadsQuery = {
  syncOpenAiThreads?:  {
    __typename: "ModelOpenAiThreadConnection",
    items:  Array< {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPerguntaFaixaEtariaQueryVariables = {
  id: string,
};

export type GetPerguntaFaixaEtariaQuery = {
  getPerguntaFaixaEtaria?:  {
    __typename: "PerguntaFaixaEtaria",
    id: string,
    faixaEtaria: FaixaEtariaEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaFaixaEtariaPerguntaId: string,
  } | null,
};

export type ListPerguntaFaixaEtariasQueryVariables = {
  filter?: ModelPerguntaFaixaEtariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPerguntaFaixaEtariasQuery = {
  listPerguntaFaixaEtarias?:  {
    __typename: "ModelPerguntaFaixaEtariaConnection",
    items:  Array< {
      __typename: "PerguntaFaixaEtaria",
      id: string,
      faixaEtaria: FaixaEtariaEnum,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaFaixaEtariaPerguntaId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPerguntaFaixaEtariasQueryVariables = {
  filter?: ModelPerguntaFaixaEtariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPerguntaFaixaEtariasQuery = {
  syncPerguntaFaixaEtarias?:  {
    __typename: "ModelPerguntaFaixaEtariaConnection",
    items:  Array< {
      __typename: "PerguntaFaixaEtaria",
      id: string,
      faixaEtaria: FaixaEtariaEnum,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaFaixaEtariaPerguntaId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPerguntaTipoRelacionamentoQueryVariables = {
  id: string,
};

export type GetPerguntaTipoRelacionamentoQuery = {
  getPerguntaTipoRelacionamento?:  {
    __typename: "PerguntaTipoRelacionamento",
    id: string,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaTipoRelacionamentoTipoRelacionamentoId: string,
    perguntaTipoRelacionamentoPerguntaId: string,
  } | null,
};

export type ListPerguntaTipoRelacionamentosQueryVariables = {
  filter?: ModelPerguntaTipoRelacionamentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPerguntaTipoRelacionamentosQuery = {
  listPerguntaTipoRelacionamentos?:  {
    __typename: "ModelPerguntaTipoRelacionamentoConnection",
    items:  Array< {
      __typename: "PerguntaTipoRelacionamento",
      id: string,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaTipoRelacionamentoTipoRelacionamentoId: string,
      perguntaTipoRelacionamentoPerguntaId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPerguntaTipoRelacionamentosQueryVariables = {
  filter?: ModelPerguntaTipoRelacionamentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPerguntaTipoRelacionamentosQuery = {
  syncPerguntaTipoRelacionamentos?:  {
    __typename: "ModelPerguntaTipoRelacionamentoConnection",
    items:  Array< {
      __typename: "PerguntaTipoRelacionamento",
      id: string,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaTipoRelacionamentoTipoRelacionamentoId: string,
      perguntaTipoRelacionamentoPerguntaId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRelUsuarioFalecimentoQueryVariables = {
  id: string,
};

export type GetRelUsuarioFalecimentoQuery = {
  getRelUsuarioFalecimento?:  {
    __typename: "RelUsuarioFalecimento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    relUsuarioFalecimentoFalecimentoId: string,
    relUsuarioFalecimentoAnonymousUserId: string,
    relUsuarioFalecimentoUserId?: string | null,
    relUsuarioFalecimentoTipoRelacionamentoId: string,
  } | null,
};

export type ListRelUsuarioFalecimentosQueryVariables = {
  filter?: ModelRelUsuarioFalecimentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRelUsuarioFalecimentosQuery = {
  listRelUsuarioFalecimentos?:  {
    __typename: "ModelRelUsuarioFalecimentoConnection",
    items:  Array< {
      __typename: "RelUsuarioFalecimento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      relUsuarioFalecimentoFalecimentoId: string,
      relUsuarioFalecimentoAnonymousUserId: string,
      relUsuarioFalecimentoUserId?: string | null,
      relUsuarioFalecimentoTipoRelacionamentoId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRelUsuarioFalecimentosQueryVariables = {
  filter?: ModelRelUsuarioFalecimentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRelUsuarioFalecimentosQuery = {
  syncRelUsuarioFalecimentos?:  {
    __typename: "ModelRelUsuarioFalecimentoConnection",
    items:  Array< {
      __typename: "RelUsuarioFalecimento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      relUsuarioFalecimentoFalecimentoId: string,
      relUsuarioFalecimentoAnonymousUserId: string,
      relUsuarioFalecimentoUserId?: string | null,
      relUsuarioFalecimentoTipoRelacionamentoId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRespostaQueryVariables = {
  id: string,
};

export type GetRespostaQuery = {
  getResposta?:  {
    __typename: "Resposta",
    id: string,
    pergunta: string,
    resposta: string,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    tipoPergunta: TipoPerguntaEnum,
    viviamJuntos?: boolean | null,
    Pergunta?:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    PerguntaOpcaoResposta?:  {
      __typename: "PerguntaOpcaoResposta",
      id: string,
      opcaoResposta: string,
      viviamJuntos?: boolean | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaOpcaoRespostaPerguntaId: string,
    } | null,
    assistentemensagemID?: string | null,
    skiped?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    respostaAnonymousUserId?: string | null,
    respostaUserId?: string | null,
    respostaFalecimentoId: string,
    respostaPerguntaId?: string | null,
    respostaPerguntaOpcaoRespostaId?: string | null,
  } | null,
};

export type ListRespostasQueryVariables = {
  filter?: ModelRespostaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRespostasQuery = {
  listRespostas?:  {
    __typename: "ModelRespostaConnection",
    items:  Array< {
      __typename: "Resposta",
      id: string,
      pergunta: string,
      resposta: string,
      tipoPergunta: TipoPerguntaEnum,
      viviamJuntos?: boolean | null,
      assistentemensagemID?: string | null,
      skiped?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      respostaAnonymousUserId?: string | null,
      respostaUserId?: string | null,
      respostaFalecimentoId: string,
      respostaPerguntaId?: string | null,
      respostaPerguntaOpcaoRespostaId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRespostasQueryVariables = {
  filter?: ModelRespostaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRespostasQuery = {
  syncRespostas?:  {
    __typename: "ModelRespostaConnection",
    items:  Array< {
      __typename: "Resposta",
      id: string,
      pergunta: string,
      resposta: string,
      tipoPergunta: TipoPerguntaEnum,
      viviamJuntos?: boolean | null,
      assistentemensagemID?: string | null,
      skiped?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      respostaAnonymousUserId?: string | null,
      respostaUserId?: string | null,
      respostaFalecimentoId: string,
      respostaPerguntaId?: string | null,
      respostaPerguntaOpcaoRespostaId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RespostasByAssistentemensagemIDQueryVariables = {
  assistentemensagemID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRespostaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RespostasByAssistentemensagemIDQuery = {
  respostasByAssistentemensagemID?:  {
    __typename: "ModelRespostaConnection",
    items:  Array< {
      __typename: "Resposta",
      id: string,
      pergunta: string,
      resposta: string,
      tipoPergunta: TipoPerguntaEnum,
      viviamJuntos?: boolean | null,
      assistentemensagemID?: string | null,
      skiped?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      respostaAnonymousUserId?: string | null,
      respostaUserId?: string | null,
      respostaFalecimentoId: string,
      respostaPerguntaId?: string | null,
      respostaPerguntaOpcaoRespostaId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPerguntaOpcaoRespostaQueryVariables = {
  id: string,
};

export type GetPerguntaOpcaoRespostaQuery = {
  getPerguntaOpcaoResposta?:  {
    __typename: "PerguntaOpcaoResposta",
    id: string,
    opcaoResposta: string,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    viviamJuntos?: boolean | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaOpcaoRespostaPerguntaId: string,
  } | null,
};

export type ListPerguntaOpcaoRespostasQueryVariables = {
  filter?: ModelPerguntaOpcaoRespostaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPerguntaOpcaoRespostasQuery = {
  listPerguntaOpcaoRespostas?:  {
    __typename: "ModelPerguntaOpcaoRespostaConnection",
    items:  Array< {
      __typename: "PerguntaOpcaoResposta",
      id: string,
      opcaoResposta: string,
      viviamJuntos?: boolean | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaOpcaoRespostaPerguntaId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPerguntaOpcaoRespostasQueryVariables = {
  filter?: ModelPerguntaOpcaoRespostaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPerguntaOpcaoRespostasQuery = {
  syncPerguntaOpcaoRespostas?:  {
    __typename: "ModelPerguntaOpcaoRespostaConnection",
    items:  Array< {
      __typename: "PerguntaOpcaoResposta",
      id: string,
      opcaoResposta: string,
      viviamJuntos?: boolean | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaOpcaoRespostaPerguntaId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPerguntaNivelRelacionamentoQueryVariables = {
  id: string,
};

export type GetPerguntaNivelRelacionamentoQuery = {
  getPerguntaNivelRelacionamento?:  {
    __typename: "PerguntaNivelRelacionamento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaNivelRelacionamentoPerguntaId: string,
  } | null,
};

export type ListPerguntaNivelRelacionamentosQueryVariables = {
  filter?: ModelPerguntaNivelRelacionamentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPerguntaNivelRelacionamentosQuery = {
  listPerguntaNivelRelacionamentos?:  {
    __typename: "ModelPerguntaNivelRelacionamentoConnection",
    items:  Array< {
      __typename: "PerguntaNivelRelacionamento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaNivelRelacionamentoPerguntaId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPerguntaNivelRelacionamentosQueryVariables = {
  filter?: ModelPerguntaNivelRelacionamentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPerguntaNivelRelacionamentosQuery = {
  syncPerguntaNivelRelacionamentos?:  {
    __typename: "ModelPerguntaNivelRelacionamentoConnection",
    items:  Array< {
      __typename: "PerguntaNivelRelacionamento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaNivelRelacionamentoPerguntaId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPerguntaQueryVariables = {
  id: string,
};

export type GetPerguntaQuery = {
  getPergunta?:  {
    __typename: "Pergunta",
    id: string,
    pergunta: string,
    feminino?: string | null,
    tipoPergunta: TipoPerguntaEnum,
    tipoResposta: TipoRespostaEnum,
    respostaTipoViviamJuntos: boolean,
    limitaTipoRelacionamento: boolean,
    limitaFaixaEtaria: boolean,
    limitaNivelRelacionamento: boolean,
    modeloResposta?: string | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPerguntasQueryVariables = {
  filter?: ModelPerguntaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPerguntasQuery = {
  listPerguntas?:  {
    __typename: "ModelPerguntaConnection",
    items:  Array< {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPerguntasQueryVariables = {
  filter?: ModelPerguntaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPerguntasQuery = {
  syncPerguntas?:  {
    __typename: "ModelPerguntaConnection",
    items:  Array< {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTipoRelacionamentoQueryVariables = {
  id: string,
};

export type GetTipoRelacionamentoQuery = {
  getTipoRelacionamento?:  {
    __typename: "TipoRelacionamento",
    id: string,
    nivelRelaciomento: NivelRelacionamentoEnum,
    titulo: string,
    sexo?: SexoEnum | null,
    prioridade?: number | null,
    active?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTipoRelacionamentosQueryVariables = {
  filter?: ModelTipoRelacionamentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTipoRelacionamentosQuery = {
  listTipoRelacionamentos?:  {
    __typename: "ModelTipoRelacionamentoConnection",
    items:  Array< {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTipoRelacionamentosQueryVariables = {
  filter?: ModelTipoRelacionamentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTipoRelacionamentosQuery = {
  syncTipoRelacionamentos?:  {
    __typename: "ModelTipoRelacionamentoConnection",
    items:  Array< {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFalecimentoCidadeQueryVariables = {
  id: string,
};

export type GetFalecimentoCidadeQuery = {
  getFalecimentoCidade?:  {
    __typename: "FalecimentoCidade",
    id: string,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    QtdMensagens: number,
    nomeCidade?: string | null,
    siglaEstado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoCidadeFalecimentoId: string,
    falecimentoCidadeCidadeId: string,
  } | null,
};

export type ListFalecimentoCidadesQueryVariables = {
  filter?: ModelFalecimentoCidadeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFalecimentoCidadesQuery = {
  listFalecimentoCidades?:  {
    __typename: "ModelFalecimentoCidadeConnection",
    items:  Array< {
      __typename: "FalecimentoCidade",
      id: string,
      QtdMensagens: number,
      nomeCidade?: string | null,
      siglaEstado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoCidadeFalecimentoId: string,
      falecimentoCidadeCidadeId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFalecimentoCidadesQueryVariables = {
  filter?: ModelFalecimentoCidadeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFalecimentoCidadesQuery = {
  syncFalecimentoCidades?:  {
    __typename: "ModelFalecimentoCidadeConnection",
    items:  Array< {
      __typename: "FalecimentoCidade",
      id: string,
      QtdMensagens: number,
      nomeCidade?: string | null,
      siglaEstado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoCidadeFalecimentoId: string,
      falecimentoCidadeCidadeId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetConviteAdministracaoMemorialQueryVariables = {
  id: string,
};

export type GetConviteAdministracaoMemorialQuery = {
  getConviteAdministracaoMemorial?:  {
    __typename: "ConviteAdministracaoMemorial",
    id: string,
    memorialID: string,
    email: string,
    status: ConviteAdministracaoMemorialStatusEnum,
    dataEnvio: string,
    dataAceite?: string | null,
    Convidado?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conviteAdministracaoMemorialConvidadoId?: string | null,
  } | null,
};

export type ListConviteAdministracaoMemorialsQueryVariables = {
  filter?: ModelConviteAdministracaoMemorialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConviteAdministracaoMemorialsQuery = {
  listConviteAdministracaoMemorials?:  {
    __typename: "ModelConviteAdministracaoMemorialConnection",
    items:  Array< {
      __typename: "ConviteAdministracaoMemorial",
      id: string,
      memorialID: string,
      email: string,
      status: ConviteAdministracaoMemorialStatusEnum,
      dataEnvio: string,
      dataAceite?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conviteAdministracaoMemorialConvidadoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConviteAdministracaoMemorialsQueryVariables = {
  filter?: ModelConviteAdministracaoMemorialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConviteAdministracaoMemorialsQuery = {
  syncConviteAdministracaoMemorials?:  {
    __typename: "ModelConviteAdministracaoMemorialConnection",
    items:  Array< {
      __typename: "ConviteAdministracaoMemorial",
      id: string,
      memorialID: string,
      email: string,
      status: ConviteAdministracaoMemorialStatusEnum,
      dataEnvio: string,
      dataAceite?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conviteAdministracaoMemorialConvidadoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConviteAdministracaoMemorialsByMemorialIDQueryVariables = {
  memorialID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConviteAdministracaoMemorialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConviteAdministracaoMemorialsByMemorialIDQuery = {
  conviteAdministracaoMemorialsByMemorialID?:  {
    __typename: "ModelConviteAdministracaoMemorialConnection",
    items:  Array< {
      __typename: "ConviteAdministracaoMemorial",
      id: string,
      memorialID: string,
      email: string,
      status: ConviteAdministracaoMemorialStatusEnum,
      dataEnvio: string,
      dataAceite?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conviteAdministracaoMemorialConvidadoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMemorialQueryVariables = {
  id: string,
};

export type GetMemorialQuery = {
  getMemorial?:  {
    __typename: "Memorial",
    id: string,
    url: string,
    Administradores?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SuperAdmin?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    ConviteAdministracaoMemorials?:  {
      __typename: "ModelConviteAdministracaoMemorialConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Criador?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    emailSuperAdmin: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    memorialSuperAdminId?: string | null,
    memorialCriadorId?: string | null,
    memorialFalecimentoId: string,
  } | null,
};

export type ListMemorialsQueryVariables = {
  filter?: ModelMemorialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMemorialsQuery = {
  listMemorials?:  {
    __typename: "ModelMemorialConnection",
    items:  Array< {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMemorialsQueryVariables = {
  filter?: ModelMemorialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMemorialsQuery = {
  syncMemorials?:  {
    __typename: "ModelMemorialConnection",
    items:  Array< {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAnonymousUserQueryVariables = {
  id: string,
};

export type GetAnonymousUserQuery = {
  getAnonymousUser?:  {
    __typename: "AnonymousUser",
    id: string,
    nome?: string | null,
    avatar?: string | null,
    email?: string | null,
    ip?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sexo?: SexoEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    anonymousUserCidadeId?: string | null,
  } | null,
};

export type ListAnonymousUsersQueryVariables = {
  filter?: ModelAnonymousUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnonymousUsersQuery = {
  listAnonymousUsers?:  {
    __typename: "ModelAnonymousUserConnection",
    items:  Array< {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAnonymousUsersQueryVariables = {
  filter?: ModelAnonymousUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAnonymousUsersQuery = {
  syncAnonymousUsers?:  {
    __typename: "ModelAnonymousUserConnection",
    items:  Array< {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMensagemMuralQueryVariables = {
  id: string,
};

export type GetMensagemMuralQuery = {
  getMensagemMural?:  {
    __typename: "MensagemMural",
    id: string,
    mensagem: string,
    dataPostagem: string,
    horaPostagem: string,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    status?: MensagemMuralStatusEnum | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    AprovadoPor?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    dataAprovacao?: string | null,
    cidade?: string | null,
    estado?: string | null,
    nomeUsuario?: string | null,
    tipoMensagem?: TipoMensagemEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    resumo?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralCidadeId?: string | null,
    mensagemMuralFalecimentoId: string,
    mensagemMuralUserId?: string | null,
    mensagemMuralAnonymousUserId?: string | null,
    mensagemMuralAprovadoPorId?: string | null,
    mensagemMuralMemorialId?: string | null,
  } | null,
};

export type ListMensagemMuralsQueryVariables = {
  filter?: ModelMensagemMuralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMensagemMuralsQuery = {
  listMensagemMurals?:  {
    __typename: "ModelMensagemMuralConnection",
    items:  Array< {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMensagemMuralsQueryVariables = {
  filter?: ModelMensagemMuralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMensagemMuralsQuery = {
  syncMensagemMurals?:  {
    __typename: "ModelMensagemMuralConnection",
    items:  Array< {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCemiterioQueryVariables = {
  id: string,
};

export type GetCemiterioQuery = {
  getCemiterio?:  {
    __typename: "Cemiterio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    bairro?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Velorios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cemiterioCidadeId?: string | null,
    cemiterioOwnerId?: string | null,
  } | null,
};

export type ListCemiteriosQueryVariables = {
  filter?: ModelCemiterioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCemiteriosQuery = {
  listCemiterios?:  {
    __typename: "ModelCemiterioConnection",
    items:  Array< {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCemiteriosQueryVariables = {
  filter?: ModelCemiterioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCemiteriosQuery = {
  syncCemiterios?:  {
    __typename: "ModelCemiterioConnection",
    items:  Array< {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMesorregiaoQueryVariables = {
  id: string,
};

export type GetMesorregiaoQuery = {
  getMesorregiao?:  {
    __typename: "Mesorregiao",
    id: string,
    nome: string,
    idIbge: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mesorregiaoEstadoId?: string | null,
  } | null,
};

export type ListMesorregiaosQueryVariables = {
  filter?: ModelMesorregiaoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMesorregiaosQuery = {
  listMesorregiaos?:  {
    __typename: "ModelMesorregiaoConnection",
    items:  Array< {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMesorregiaosQueryVariables = {
  filter?: ModelMesorregiaoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMesorregiaosQuery = {
  syncMesorregiaos?:  {
    __typename: "ModelMesorregiaoConnection",
    items:  Array< {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMicrorregiaoQueryVariables = {
  id: string,
};

export type GetMicrorregiaoQuery = {
  getMicrorregiao?:  {
    __typename: "Microrregiao",
    id: string,
    nome: string,
    idIbge: string,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    microrregiaoMesorregiaoId?: string | null,
  } | null,
};

export type ListMicrorregiaosQueryVariables = {
  filter?: ModelMicrorregiaoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMicrorregiaosQuery = {
  listMicrorregiaos?:  {
    __typename: "ModelMicrorregiaoConnection",
    items:  Array< {
      __typename: "Microrregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      microrregiaoMesorregiaoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMicrorregiaosQueryVariables = {
  filter?: ModelMicrorregiaoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMicrorregiaosQuery = {
  syncMicrorregiaos?:  {
    __typename: "ModelMicrorregiaoConnection",
    items:  Array< {
      __typename: "Microrregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      microrregiaoMesorregiaoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRegiaoQueryVariables = {
  id: string,
};

export type GetRegiaoQuery = {
  getRegiao?:  {
    __typename: "Regiao",
    id: string,
    sigla: string,
    nome: string,
    idIbge: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRegiaosQueryVariables = {
  filter?: ModelRegiaoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegiaosQuery = {
  listRegiaos?:  {
    __typename: "ModelRegiaoConnection",
    items:  Array< {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRegiaosQueryVariables = {
  filter?: ModelRegiaoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRegiaosQuery = {
  syncRegiaos?:  {
    __typename: "ModelRegiaoConnection",
    items:  Array< {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEstadoQueryVariables = {
  id: string,
};

export type GetEstadoQuery = {
  getEstado?:  {
    __typename: "Estado",
    id: string,
    nome: string,
    sigla: string,
    idIbge: string,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    estadoRegiaoId?: string | null,
  } | null,
};

export type ListEstadosQueryVariables = {
  filter?: ModelEstadoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEstadosQuery = {
  listEstados?:  {
    __typename: "ModelEstadoConnection",
    items:  Array< {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEstadosQueryVariables = {
  filter?: ModelEstadoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEstadosQuery = {
  syncEstados?:  {
    __typename: "ModelEstadoConnection",
    items:  Array< {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCidadeQueryVariables = {
  id: string,
};

export type GetCidadeQuery = {
  getCidade?:  {
    __typename: "Cidade",
    id: string,
    nome: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    idIbge: string,
    Microrregiao?:  {
      __typename: "Microrregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      microrregiaoMesorregiaoId?: string | null,
    } | null,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cidadeEstadoId?: string | null,
    cidadeMicrorregiaoId?: string | null,
    cidadeMesorregiaoId?: string | null,
    cidadeRegiaoId?: string | null,
  } | null,
};

export type ListCidadesQueryVariables = {
  filter?: ModelCidadeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCidadesQuery = {
  listCidades?:  {
    __typename: "ModelCidadeConnection",
    items:  Array< {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCidadesQueryVariables = {
  filter?: ModelCidadeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCidadesQuery = {
  syncCidades?:  {
    __typename: "ModelCidadeConnection",
    items:  Array< {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVelorioQueryVariables = {
  id: string,
};

export type GetVelorioQuery = {
  getVelorio?:  {
    __typename: "Velorio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    bairro?: string | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    velorioCidadeId?: string | null,
    velorioOwnerId?: string | null,
  } | null,
};

export type ListVeloriosQueryVariables = {
  filter?: ModelVelorioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVeloriosQuery = {
  listVelorios?:  {
    __typename: "ModelVelorioConnection",
    items:  Array< {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVeloriosQueryVariables = {
  filter?: ModelVelorioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVeloriosQuery = {
  syncVelorios?:  {
    __typename: "ModelVelorioConnection",
    items:  Array< {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFalecimentoQueryVariables = {
  id: string,
};

export type GetFalecimentoQuery = {
  getFalecimento?:  {
    __typename: "Falecimento",
    id: string,
    nome: string,
    sobrenome?: string | null,
    dataFalecimento: string,
    Funeraria?:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    } | null,
    searchName: string,
    idade: number,
    tempoDeVida: string,
    meses: number,
    Velorio?:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    } | null,
    dataInicioVelorio?: string | null,
    horaInicioVelorio?: string | null,
    dataFimVelorio?: string | null,
    horaFimVelorio?: string | null,
    dataSepultamento?: string | null,
    horaSepultamento?: string | null,
    salaVelorio?: string | null,
    Cemiterio?:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    } | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sobre?: string | null,
    imageKey?: string | null,
    apelido?: string | null,
    cidade?: string | null,
    estado?: string | null,
    sexo?: SexoEnum | null,
    dataNascimento: string,
    faixaEtaria?: FaixaEtariaEnum | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoFunerariaId?: string | null,
    falecimentoVelorioId?: string | null,
    falecimentoCemiterioId?: string | null,
    falecimentoCidadeId?: string | null,
    falecimentoOpenAiAssistantId?: string | null,
  } | null,
};

export type ListFalecimentosQueryVariables = {
  filter?: ModelFalecimentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFalecimentosQuery = {
  listFalecimentos?:  {
    __typename: "ModelFalecimentoConnection",
    items:  Array< {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFalecimentosQueryVariables = {
  filter?: ModelFalecimentoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFalecimentosQuery = {
  syncFalecimentos?:  {
    __typename: "ModelFalecimentoConnection",
    items:  Array< {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    email: string,
    Funerarias?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nome?: string | null,
    status?: UserStatusEnum | null,
    avatar?: string | null,
    imageKey?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    role?: UserRoleEnum | null,
    memorials?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sexo?: SexoEnum | null,
    lastIpAddress?: string | null,
    anonymousUserId?: string | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCidadeId?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFunerariaQueryVariables = {
  id: string,
};

export type GetFunerariaQuery = {
  getFuneraria?:  {
    __typename: "Funeraria",
    id: string,
    cnpj: string,
    nome: string,
    logradouro: string,
    cep: string,
    telefone1?: string | null,
    telefone2?: string | null,
    email?: string | null,
    site?: string | null,
    users?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    velorios?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    funerariaCidadeId: string,
  } | null,
};

export type ListFunerariasQueryVariables = {
  filter?: ModelFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFunerariasQuery = {
  listFunerarias?:  {
    __typename: "ModelFunerariaConnection",
    items:  Array< {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFunerariasQueryVariables = {
  filter?: ModelFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFunerariasQuery = {
  syncFunerarias?:  {
    __typename: "ModelFunerariaConnection",
    items:  Array< {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMemorialUserQueryVariables = {
  id: string,
};

export type GetMemorialUserQuery = {
  getMemorialUser?:  {
    __typename: "MemorialUser",
    id: string,
    memorialId: string,
    userId: string,
    memorial:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    },
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMemorialUsersQueryVariables = {
  filter?: ModelMemorialUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMemorialUsersQuery = {
  listMemorialUsers?:  {
    __typename: "ModelMemorialUserConnection",
    items:  Array< {
      __typename: "MemorialUser",
      id: string,
      memorialId: string,
      userId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMemorialUsersQueryVariables = {
  filter?: ModelMemorialUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMemorialUsersQuery = {
  syncMemorialUsers?:  {
    __typename: "ModelMemorialUserConnection",
    items:  Array< {
      __typename: "MemorialUser",
      id: string,
      memorialId: string,
      userId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MemorialUsersByMemorialIdQueryVariables = {
  memorialId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemorialUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemorialUsersByMemorialIdQuery = {
  memorialUsersByMemorialId?:  {
    __typename: "ModelMemorialUserConnection",
    items:  Array< {
      __typename: "MemorialUser",
      id: string,
      memorialId: string,
      userId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MemorialUsersByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemorialUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemorialUsersByUserIdQuery = {
  memorialUsersByUserId?:  {
    __typename: "ModelMemorialUserConnection",
    items:  Array< {
      __typename: "MemorialUser",
      id: string,
      memorialId: string,
      userId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCemiterioFunerariaQueryVariables = {
  id: string,
};

export type GetCemiterioFunerariaQuery = {
  getCemiterioFuneraria?:  {
    __typename: "CemiterioFuneraria",
    id: string,
    cemiterioId: string,
    funerariaId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCemiterioFunerariasQueryVariables = {
  filter?: ModelCemiterioFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCemiterioFunerariasQuery = {
  listCemiterioFunerarias?:  {
    __typename: "ModelCemiterioFunerariaConnection",
    items:  Array< {
      __typename: "CemiterioFuneraria",
      id: string,
      cemiterioId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCemiterioFunerariasQueryVariables = {
  filter?: ModelCemiterioFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCemiterioFunerariasQuery = {
  syncCemiterioFunerarias?:  {
    __typename: "ModelCemiterioFunerariaConnection",
    items:  Array< {
      __typename: "CemiterioFuneraria",
      id: string,
      cemiterioId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CemiterioFunerariasByCemiterioIdQueryVariables = {
  cemiterioId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCemiterioFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CemiterioFunerariasByCemiterioIdQuery = {
  cemiterioFunerariasByCemiterioId?:  {
    __typename: "ModelCemiterioFunerariaConnection",
    items:  Array< {
      __typename: "CemiterioFuneraria",
      id: string,
      cemiterioId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CemiterioFunerariasByFunerariaIdQueryVariables = {
  funerariaId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCemiterioFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CemiterioFunerariasByFunerariaIdQuery = {
  cemiterioFunerariasByFunerariaId?:  {
    __typename: "ModelCemiterioFunerariaConnection",
    items:  Array< {
      __typename: "CemiterioFuneraria",
      id: string,
      cemiterioId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCemiterioVelorioQueryVariables = {
  id: string,
};

export type GetCemiterioVelorioQuery = {
  getCemiterioVelorio?:  {
    __typename: "CemiterioVelorio",
    id: string,
    cemiterioId: string,
    velorioId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCemiterioVeloriosQueryVariables = {
  filter?: ModelCemiterioVelorioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCemiterioVeloriosQuery = {
  listCemiterioVelorios?:  {
    __typename: "ModelCemiterioVelorioConnection",
    items:  Array< {
      __typename: "CemiterioVelorio",
      id: string,
      cemiterioId: string,
      velorioId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCemiterioVeloriosQueryVariables = {
  filter?: ModelCemiterioVelorioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCemiterioVeloriosQuery = {
  syncCemiterioVelorios?:  {
    __typename: "ModelCemiterioVelorioConnection",
    items:  Array< {
      __typename: "CemiterioVelorio",
      id: string,
      cemiterioId: string,
      velorioId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CemiterioVeloriosByCemiterioIdQueryVariables = {
  cemiterioId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCemiterioVelorioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CemiterioVeloriosByCemiterioIdQuery = {
  cemiterioVeloriosByCemiterioId?:  {
    __typename: "ModelCemiterioVelorioConnection",
    items:  Array< {
      __typename: "CemiterioVelorio",
      id: string,
      cemiterioId: string,
      velorioId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CemiterioVeloriosByVelorioIdQueryVariables = {
  velorioId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCemiterioVelorioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CemiterioVeloriosByVelorioIdQuery = {
  cemiterioVeloriosByVelorioId?:  {
    __typename: "ModelCemiterioVelorioConnection",
    items:  Array< {
      __typename: "CemiterioVelorio",
      id: string,
      cemiterioId: string,
      velorioId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVelorioFunerariaQueryVariables = {
  id: string,
};

export type GetVelorioFunerariaQuery = {
  getVelorioFuneraria?:  {
    __typename: "VelorioFuneraria",
    id: string,
    velorioId: string,
    funerariaId: string,
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListVelorioFunerariasQueryVariables = {
  filter?: ModelVelorioFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVelorioFunerariasQuery = {
  listVelorioFunerarias?:  {
    __typename: "ModelVelorioFunerariaConnection",
    items:  Array< {
      __typename: "VelorioFuneraria",
      id: string,
      velorioId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVelorioFunerariasQueryVariables = {
  filter?: ModelVelorioFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVelorioFunerariasQuery = {
  syncVelorioFunerarias?:  {
    __typename: "ModelVelorioFunerariaConnection",
    items:  Array< {
      __typename: "VelorioFuneraria",
      id: string,
      velorioId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type VelorioFunerariasByVelorioIdQueryVariables = {
  velorioId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVelorioFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VelorioFunerariasByVelorioIdQuery = {
  velorioFunerariasByVelorioId?:  {
    __typename: "ModelVelorioFunerariaConnection",
    items:  Array< {
      __typename: "VelorioFuneraria",
      id: string,
      velorioId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type VelorioFunerariasByFunerariaIdQueryVariables = {
  funerariaId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVelorioFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VelorioFunerariasByFunerariaIdQuery = {
  velorioFunerariasByFunerariaId?:  {
    __typename: "ModelVelorioFunerariaConnection",
    items:  Array< {
      __typename: "VelorioFuneraria",
      id: string,
      velorioId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserFunerariaQueryVariables = {
  id: string,
};

export type GetUserFunerariaQuery = {
  getUserFuneraria?:  {
    __typename: "UserFuneraria",
    id: string,
    userId: string,
    funerariaId: string,
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserFunerariasQueryVariables = {
  filter?: ModelUserFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserFunerariasQuery = {
  listUserFunerarias?:  {
    __typename: "ModelUserFunerariaConnection",
    items:  Array< {
      __typename: "UserFuneraria",
      id: string,
      userId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserFunerariasQueryVariables = {
  filter?: ModelUserFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserFunerariasQuery = {
  syncUserFunerarias?:  {
    __typename: "ModelUserFunerariaConnection",
    items:  Array< {
      __typename: "UserFuneraria",
      id: string,
      userId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UserFunerariasByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserFunerariasByUserIdQuery = {
  userFunerariasByUserId?:  {
    __typename: "ModelUserFunerariaConnection",
    items:  Array< {
      __typename: "UserFuneraria",
      id: string,
      userId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UserFunerariasByFunerariaIdQueryVariables = {
  funerariaId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFunerariaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserFunerariasByFunerariaIdQuery = {
  userFunerariasByFunerariaId?:  {
    __typename: "ModelUserFunerariaConnection",
    items:  Array< {
      __typename: "UserFuneraria",
      id: string,
      userId: string,
      funerariaId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreatePostImageSubscriptionVariables = {
  filter?: ModelSubscriptionPostImageFilterInput | null,
};

export type OnCreatePostImageSubscription = {
  onCreatePostImage?:  {
    __typename: "PostImage",
    id: string,
    imageKey: string,
    context: string,
    postID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePostImageSubscriptionVariables = {
  filter?: ModelSubscriptionPostImageFilterInput | null,
};

export type OnUpdatePostImageSubscription = {
  onUpdatePostImage?:  {
    __typename: "PostImage",
    id: string,
    imageKey: string,
    context: string,
    postID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePostImageSubscriptionVariables = {
  filter?: ModelSubscriptionPostImageFilterInput | null,
};

export type OnDeletePostImageSubscription = {
  onDeletePostImage?:  {
    __typename: "PostImage",
    id: string,
    imageKey: string,
    context: string,
    postID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnCreatePostSubscription = {
  onCreatePost?:  {
    __typename: "Post",
    id: string,
    postType: PostTypeEnum,
    postAction: PostActionEnum,
    text: string,
    url: string,
    datePost: string,
    timePost: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    PostImages?:  {
      __typename: "ModelPostImageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    resourceId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    postUserId: string,
  } | null,
};

export type OnUpdatePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnUpdatePostSubscription = {
  onUpdatePost?:  {
    __typename: "Post",
    id: string,
    postType: PostTypeEnum,
    postAction: PostActionEnum,
    text: string,
    url: string,
    datePost: string,
    timePost: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    PostImages?:  {
      __typename: "ModelPostImageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    resourceId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    postUserId: string,
  } | null,
};

export type OnDeletePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnDeletePostSubscription = {
  onDeletePost?:  {
    __typename: "Post",
    id: string,
    postType: PostTypeEnum,
    postAction: PostActionEnum,
    text: string,
    url: string,
    datePost: string,
    timePost: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    PostImages?:  {
      __typename: "ModelPostImageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    resourceId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    postUserId: string,
  } | null,
};

export type OnCreateMensagemMuralEngajamentoSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralEngajamentoFilterInput | null,
};

export type OnCreateMensagemMuralEngajamentoSubscription = {
  onCreateMensagemMuralEngajamento?:  {
    __typename: "MensagemMuralEngajamento",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    views: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralEngajamentoMensagemMuralId: string,
  } | null,
};

export type OnUpdateMensagemMuralEngajamentoSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralEngajamentoFilterInput | null,
};

export type OnUpdateMensagemMuralEngajamentoSubscription = {
  onUpdateMensagemMuralEngajamento?:  {
    __typename: "MensagemMuralEngajamento",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    views: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralEngajamentoMensagemMuralId: string,
  } | null,
};

export type OnDeleteMensagemMuralEngajamentoSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralEngajamentoFilterInput | null,
};

export type OnDeleteMensagemMuralEngajamentoSubscription = {
  onDeleteMensagemMuralEngajamento?:  {
    __typename: "MensagemMuralEngajamento",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    views: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralEngajamentoMensagemMuralId: string,
  } | null,
};

export type OnCreateMensagemMuralViewsSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralViewsFilterInput | null,
};

export type OnCreateMensagemMuralViewsSubscription = {
  onCreateMensagemMuralViews?:  {
    __typename: "MensagemMuralViews",
    id: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    dataVisualizacao: string,
    horaVisualizacao: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralViewsUserId: string,
    mensagemMuralViewsMensagemMuralId: string,
  } | null,
};

export type OnUpdateMensagemMuralViewsSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralViewsFilterInput | null,
};

export type OnUpdateMensagemMuralViewsSubscription = {
  onUpdateMensagemMuralViews?:  {
    __typename: "MensagemMuralViews",
    id: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    dataVisualizacao: string,
    horaVisualizacao: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralViewsUserId: string,
    mensagemMuralViewsMensagemMuralId: string,
  } | null,
};

export type OnDeleteMensagemMuralViewsSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralViewsFilterInput | null,
};

export type OnDeleteMensagemMuralViewsSubscription = {
  onDeleteMensagemMuralViews?:  {
    __typename: "MensagemMuralViews",
    id: string,
    User:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    dataVisualizacao: string,
    horaVisualizacao: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralViewsUserId: string,
    mensagemMuralViewsMensagemMuralId: string,
  } | null,
};

export type OnCreateMensagemMuralFalecimentoFotoSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralFalecimentoFotoFilterInput | null,
};

export type OnCreateMensagemMuralFalecimentoFotoSubscription = {
  onCreateMensagemMuralFalecimentoFoto?:  {
    __typename: "MensagemMuralFalecimentoFoto",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    FalecimentoFoto:  {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    tipoFoto: TipoFoto,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralFalecimentoFotoMensagemMuralId: string,
    mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
  } | null,
};

export type OnUpdateMensagemMuralFalecimentoFotoSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralFalecimentoFotoFilterInput | null,
};

export type OnUpdateMensagemMuralFalecimentoFotoSubscription = {
  onUpdateMensagemMuralFalecimentoFoto?:  {
    __typename: "MensagemMuralFalecimentoFoto",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    FalecimentoFoto:  {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    tipoFoto: TipoFoto,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralFalecimentoFotoMensagemMuralId: string,
    mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
  } | null,
};

export type OnDeleteMensagemMuralFalecimentoFotoSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralFalecimentoFotoFilterInput | null,
};

export type OnDeleteMensagemMuralFalecimentoFotoSubscription = {
  onDeleteMensagemMuralFalecimentoFoto?:  {
    __typename: "MensagemMuralFalecimentoFoto",
    id: string,
    MensagemMural:  {
      __typename: "MensagemMural",
      id: string,
      mensagem: string,
      dataPostagem: string,
      horaPostagem: string,
      status?: MensagemMuralStatusEnum | null,
      dataAprovacao?: string | null,
      cidade?: string | null,
      estado?: string | null,
      nomeUsuario?: string | null,
      tipoMensagem?: TipoMensagemEnum | null,
      resumo?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mensagemMuralCidadeId?: string | null,
      mensagemMuralFalecimentoId: string,
      mensagemMuralUserId?: string | null,
      mensagemMuralAnonymousUserId?: string | null,
      mensagemMuralAprovadoPorId?: string | null,
      mensagemMuralMemorialId?: string | null,
    },
    FalecimentoFoto:  {
      __typename: "FalecimentoFoto",
      id: string,
      fileKey: string,
      status: ContentStatusEnum,
      tipo: TipoFoto,
      falecimentoID: string,
      userID: string,
      isCover?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    tipoFoto: TipoFoto,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralFalecimentoFotoMensagemMuralId: string,
    mensagemMuralFalecimentoFotoFalecimentoFotoId: string,
  } | null,
};

export type OnCreateFalecimentoFotoSubscriptionVariables = {
  filter?: ModelSubscriptionFalecimentoFotoFilterInput | null,
};

export type OnCreateFalecimentoFotoSubscription = {
  onCreateFalecimentoFoto?:  {
    __typename: "FalecimentoFoto",
    id: string,
    fileKey: string,
    status: ContentStatusEnum,
    tipo: TipoFoto,
    falecimentoID: string,
    userID: string,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFalecimentoFotoSubscriptionVariables = {
  filter?: ModelSubscriptionFalecimentoFotoFilterInput | null,
};

export type OnUpdateFalecimentoFotoSubscription = {
  onUpdateFalecimentoFoto?:  {
    __typename: "FalecimentoFoto",
    id: string,
    fileKey: string,
    status: ContentStatusEnum,
    tipo: TipoFoto,
    falecimentoID: string,
    userID: string,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFalecimentoFotoSubscriptionVariables = {
  filter?: ModelSubscriptionFalecimentoFotoFilterInput | null,
};

export type OnDeleteFalecimentoFotoSubscription = {
  onDeleteFalecimentoFoto?:  {
    __typename: "FalecimentoFoto",
    id: string,
    fileKey: string,
    status: ContentStatusEnum,
    tipo: TipoFoto,
    falecimentoID: string,
    userID: string,
    isCover?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOpenAiGeneratedMessageSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiGeneratedMessageFilterInput | null,
};

export type OnCreateOpenAiGeneratedMessageSubscription = {
  onCreateOpenAiGeneratedMessage?:  {
    __typename: "OpenAiGeneratedMessage",
    id: string,
    falecimentoId: string,
    anonymousUserId: string,
    messageId: string,
    runId: string,
    threadId: string,
    created_at: number,
    contentTextValue: string,
    assistantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOpenAiGeneratedMessageSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiGeneratedMessageFilterInput | null,
};

export type OnUpdateOpenAiGeneratedMessageSubscription = {
  onUpdateOpenAiGeneratedMessage?:  {
    __typename: "OpenAiGeneratedMessage",
    id: string,
    falecimentoId: string,
    anonymousUserId: string,
    messageId: string,
    runId: string,
    threadId: string,
    created_at: number,
    contentTextValue: string,
    assistantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOpenAiGeneratedMessageSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiGeneratedMessageFilterInput | null,
};

export type OnDeleteOpenAiGeneratedMessageSubscription = {
  onDeleteOpenAiGeneratedMessage?:  {
    __typename: "OpenAiGeneratedMessage",
    id: string,
    falecimentoId: string,
    anonymousUserId: string,
    messageId: string,
    runId: string,
    threadId: string,
    created_at: number,
    contentTextValue: string,
    assistantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOpenAiRunSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiRunFilterInput | null,
};

export type OnCreateOpenAiRunSubscription = {
  onCreateOpenAiRun?:  {
    __typename: "OpenAiRun",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    runId: string,
    object?: string | null,
    created_at?: number | null,
    status?: string | null,
    expires_at?: number | null,
    started_at?: number | null,
    cancelled_at?: number | null,
    failed_at?: number | null,
    completed_at?: number | null,
    model?: string | null,
    instructions?: string | null,
    assistant_id?: string | null,
    thread_id?: string | null,
    last_error?:  {
      __typename: "OpenAiError",
      code?: string | null,
      message?: string | null,
    } | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    assistentemensagemID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiRunOpenAiThreadId?: string | null,
    openAiRunOpenAiAssistantId?: string | null,
    openAiRunAnonymousUserId?: string | null,
  } | null,
};

export type OnUpdateOpenAiRunSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiRunFilterInput | null,
};

export type OnUpdateOpenAiRunSubscription = {
  onUpdateOpenAiRun?:  {
    __typename: "OpenAiRun",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    runId: string,
    object?: string | null,
    created_at?: number | null,
    status?: string | null,
    expires_at?: number | null,
    started_at?: number | null,
    cancelled_at?: number | null,
    failed_at?: number | null,
    completed_at?: number | null,
    model?: string | null,
    instructions?: string | null,
    assistant_id?: string | null,
    thread_id?: string | null,
    last_error?:  {
      __typename: "OpenAiError",
      code?: string | null,
      message?: string | null,
    } | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    assistentemensagemID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiRunOpenAiThreadId?: string | null,
    openAiRunOpenAiAssistantId?: string | null,
    openAiRunAnonymousUserId?: string | null,
  } | null,
};

export type OnDeleteOpenAiRunSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiRunFilterInput | null,
};

export type OnDeleteOpenAiRunSubscription = {
  onDeleteOpenAiRun?:  {
    __typename: "OpenAiRun",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    runId: string,
    object?: string | null,
    created_at?: number | null,
    status?: string | null,
    expires_at?: number | null,
    started_at?: number | null,
    cancelled_at?: number | null,
    failed_at?: number | null,
    completed_at?: number | null,
    model?: string | null,
    instructions?: string | null,
    assistant_id?: string | null,
    thread_id?: string | null,
    last_error?:  {
      __typename: "OpenAiError",
      code?: string | null,
      message?: string | null,
    } | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    assistentemensagemID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiRunOpenAiThreadId?: string | null,
    openAiRunOpenAiAssistantId?: string | null,
    openAiRunAnonymousUserId?: string | null,
  } | null,
};

export type OnCreateOpenAiAssistantSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiAssistantFilterInput | null,
};

export type OnCreateOpenAiAssistantSubscription = {
  onCreateOpenAiAssistant?:  {
    __typename: "OpenAiAssistant",
    id: string,
    assistantId: string,
    object: string,
    model: string,
    instructions: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOpenAiAssistantSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiAssistantFilterInput | null,
};

export type OnUpdateOpenAiAssistantSubscription = {
  onUpdateOpenAiAssistant?:  {
    __typename: "OpenAiAssistant",
    id: string,
    assistantId: string,
    object: string,
    model: string,
    instructions: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOpenAiAssistantSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiAssistantFilterInput | null,
};

export type OnDeleteOpenAiAssistantSubscription = {
  onDeleteOpenAiAssistant?:  {
    __typename: "OpenAiAssistant",
    id: string,
    assistantId: string,
    object: string,
    model: string,
    instructions: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAssistenteMensagemSubscriptionVariables = {
  filter?: ModelSubscriptionAssistenteMensagemFilterInput | null,
};

export type OnCreateAssistenteMensagemSubscription = {
  onCreateAssistenteMensagem?:  {
    __typename: "AssistenteMensagem",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Respostas?:  {
      __typename: "ModelRespostaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RelUsuarioFalecimento?:  {
      __typename: "RelUsuarioFalecimento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      relUsuarioFalecimentoFalecimentoId: string,
      relUsuarioFalecimentoAnonymousUserId: string,
      relUsuarioFalecimentoUserId?: string | null,
      relUsuarioFalecimentoTipoRelacionamentoId: string,
    } | null,
    tamanhoMensagem?: TamanhoMensagemEnu | null,
    tipoPessoa?: TipoPessoaEnum | null,
    emNomeDe?: string | null,
    OpenAiRuns?:  {
      __typename: "ModelOpenAiRunConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nomeUsuario?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento?:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mensagemUsuario?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    OpenAiGeneratedMessage?:  {
      __typename: "OpenAiGeneratedMessage",
      id: string,
      falecimentoId: string,
      anonymousUserId: string,
      messageId: string,
      runId: string,
      threadId: string,
      created_at: number,
      contentTextValue: string,
      assistantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    instrucao?: string | null,
    Step?: string | null,
    Status?: AssistenteStatusEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    mensagemReescrita?: string | null,
    mensagemGerada?: string | null,
    mensagemCorrigida?: string | null,
    mensagemEditada?: string | null,
    tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    assistenteMensagemOpenAiThreadId?: string | null,
    assistenteMensagemFalecimentoId: string,
    assistenteMensagemAnonymousUserId: string,
    assistenteMensagemOpenAiAssistantId?: string | null,
    assistenteMensagemRelUsuarioFalecimentoId?: string | null,
    assistenteMensagemUserId?: string | null,
    assistenteMensagemTipoRelacionamentoId?: string | null,
    assistenteMensagemCidadeId?: string | null,
    assistenteMensagemOpenAiGeneratedMessageId?: string | null,
    assistenteMensagemMemorialId?: string | null,
  } | null,
};

export type OnUpdateAssistenteMensagemSubscriptionVariables = {
  filter?: ModelSubscriptionAssistenteMensagemFilterInput | null,
};

export type OnUpdateAssistenteMensagemSubscription = {
  onUpdateAssistenteMensagem?:  {
    __typename: "AssistenteMensagem",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Respostas?:  {
      __typename: "ModelRespostaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RelUsuarioFalecimento?:  {
      __typename: "RelUsuarioFalecimento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      relUsuarioFalecimentoFalecimentoId: string,
      relUsuarioFalecimentoAnonymousUserId: string,
      relUsuarioFalecimentoUserId?: string | null,
      relUsuarioFalecimentoTipoRelacionamentoId: string,
    } | null,
    tamanhoMensagem?: TamanhoMensagemEnu | null,
    tipoPessoa?: TipoPessoaEnum | null,
    emNomeDe?: string | null,
    OpenAiRuns?:  {
      __typename: "ModelOpenAiRunConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nomeUsuario?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento?:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mensagemUsuario?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    OpenAiGeneratedMessage?:  {
      __typename: "OpenAiGeneratedMessage",
      id: string,
      falecimentoId: string,
      anonymousUserId: string,
      messageId: string,
      runId: string,
      threadId: string,
      created_at: number,
      contentTextValue: string,
      assistantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    instrucao?: string | null,
    Step?: string | null,
    Status?: AssistenteStatusEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    mensagemReescrita?: string | null,
    mensagemGerada?: string | null,
    mensagemCorrigida?: string | null,
    mensagemEditada?: string | null,
    tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    assistenteMensagemOpenAiThreadId?: string | null,
    assistenteMensagemFalecimentoId: string,
    assistenteMensagemAnonymousUserId: string,
    assistenteMensagemOpenAiAssistantId?: string | null,
    assistenteMensagemRelUsuarioFalecimentoId?: string | null,
    assistenteMensagemUserId?: string | null,
    assistenteMensagemTipoRelacionamentoId?: string | null,
    assistenteMensagemCidadeId?: string | null,
    assistenteMensagemOpenAiGeneratedMessageId?: string | null,
    assistenteMensagemMemorialId?: string | null,
  } | null,
};

export type OnDeleteAssistenteMensagemSubscriptionVariables = {
  filter?: ModelSubscriptionAssistenteMensagemFilterInput | null,
};

export type OnDeleteAssistenteMensagemSubscription = {
  onDeleteAssistenteMensagem?:  {
    __typename: "AssistenteMensagem",
    id: string,
    OpenAiThread?:  {
      __typename: "OpenAiThread",
      id: string,
      threadId: string,
      object: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      openAiThreadOpenAiAssistantId: string,
      openAiThreadAnonymousUserId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Respostas?:  {
      __typename: "ModelRespostaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RelUsuarioFalecimento?:  {
      __typename: "RelUsuarioFalecimento",
      id: string,
      nivelRelacionamento: NivelRelacionamentoEnum,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      relUsuarioFalecimentoFalecimentoId: string,
      relUsuarioFalecimentoAnonymousUserId: string,
      relUsuarioFalecimentoUserId?: string | null,
      relUsuarioFalecimentoTipoRelacionamentoId: string,
    } | null,
    tamanhoMensagem?: TamanhoMensagemEnu | null,
    tipoPessoa?: TipoPessoaEnum | null,
    emNomeDe?: string | null,
    OpenAiRuns?:  {
      __typename: "ModelOpenAiRunConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nomeUsuario?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento?:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mensagemUsuario?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    OpenAiGeneratedMessage?:  {
      __typename: "OpenAiGeneratedMessage",
      id: string,
      falecimentoId: string,
      anonymousUserId: string,
      messageId: string,
      runId: string,
      threadId: string,
      created_at: number,
      contentTextValue: string,
      assistantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    instrucao?: string | null,
    Step?: string | null,
    Status?: AssistenteStatusEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    mensagemReescrita?: string | null,
    mensagemGerada?: string | null,
    mensagemCorrigida?: string | null,
    mensagemEditada?: string | null,
    tipoMensagemSelecionada?: TipoMensagemSelecionadaEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    assistenteMensagemOpenAiThreadId?: string | null,
    assistenteMensagemFalecimentoId: string,
    assistenteMensagemAnonymousUserId: string,
    assistenteMensagemOpenAiAssistantId?: string | null,
    assistenteMensagemRelUsuarioFalecimentoId?: string | null,
    assistenteMensagemUserId?: string | null,
    assistenteMensagemTipoRelacionamentoId?: string | null,
    assistenteMensagemCidadeId?: string | null,
    assistenteMensagemOpenAiGeneratedMessageId?: string | null,
    assistenteMensagemMemorialId?: string | null,
  } | null,
};

export type OnCreateOpenAiThreadSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiThreadFilterInput | null,
};

export type OnCreateOpenAiThreadSubscription = {
  onCreateOpenAiThread?:  {
    __typename: "OpenAiThread",
    id: string,
    threadId: string,
    object: string,
    OpenAiAssistant:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiThreadOpenAiAssistantId: string,
    openAiThreadAnonymousUserId?: string | null,
  } | null,
};

export type OnUpdateOpenAiThreadSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiThreadFilterInput | null,
};

export type OnUpdateOpenAiThreadSubscription = {
  onUpdateOpenAiThread?:  {
    __typename: "OpenAiThread",
    id: string,
    threadId: string,
    object: string,
    OpenAiAssistant:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiThreadOpenAiAssistantId: string,
    openAiThreadAnonymousUserId?: string | null,
  } | null,
};

export type OnDeleteOpenAiThreadSubscriptionVariables = {
  filter?: ModelSubscriptionOpenAiThreadFilterInput | null,
};

export type OnDeleteOpenAiThreadSubscription = {
  onDeleteOpenAiThread?:  {
    __typename: "OpenAiThread",
    id: string,
    threadId: string,
    object: string,
    OpenAiAssistant:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    openAiThreadOpenAiAssistantId: string,
    openAiThreadAnonymousUserId?: string | null,
  } | null,
};

export type OnCreatePerguntaFaixaEtariaSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaFaixaEtariaFilterInput | null,
};

export type OnCreatePerguntaFaixaEtariaSubscription = {
  onCreatePerguntaFaixaEtaria?:  {
    __typename: "PerguntaFaixaEtaria",
    id: string,
    faixaEtaria: FaixaEtariaEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaFaixaEtariaPerguntaId: string,
  } | null,
};

export type OnUpdatePerguntaFaixaEtariaSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaFaixaEtariaFilterInput | null,
};

export type OnUpdatePerguntaFaixaEtariaSubscription = {
  onUpdatePerguntaFaixaEtaria?:  {
    __typename: "PerguntaFaixaEtaria",
    id: string,
    faixaEtaria: FaixaEtariaEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaFaixaEtariaPerguntaId: string,
  } | null,
};

export type OnDeletePerguntaFaixaEtariaSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaFaixaEtariaFilterInput | null,
};

export type OnDeletePerguntaFaixaEtariaSubscription = {
  onDeletePerguntaFaixaEtaria?:  {
    __typename: "PerguntaFaixaEtaria",
    id: string,
    faixaEtaria: FaixaEtariaEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaFaixaEtariaPerguntaId: string,
  } | null,
};

export type OnCreatePerguntaTipoRelacionamentoSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaTipoRelacionamentoFilterInput | null,
};

export type OnCreatePerguntaTipoRelacionamentoSubscription = {
  onCreatePerguntaTipoRelacionamento?:  {
    __typename: "PerguntaTipoRelacionamento",
    id: string,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaTipoRelacionamentoTipoRelacionamentoId: string,
    perguntaTipoRelacionamentoPerguntaId: string,
  } | null,
};

export type OnUpdatePerguntaTipoRelacionamentoSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaTipoRelacionamentoFilterInput | null,
};

export type OnUpdatePerguntaTipoRelacionamentoSubscription = {
  onUpdatePerguntaTipoRelacionamento?:  {
    __typename: "PerguntaTipoRelacionamento",
    id: string,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaTipoRelacionamentoTipoRelacionamentoId: string,
    perguntaTipoRelacionamentoPerguntaId: string,
  } | null,
};

export type OnDeletePerguntaTipoRelacionamentoSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaTipoRelacionamentoFilterInput | null,
};

export type OnDeletePerguntaTipoRelacionamentoSubscription = {
  onDeletePerguntaTipoRelacionamento?:  {
    __typename: "PerguntaTipoRelacionamento",
    id: string,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaTipoRelacionamentoTipoRelacionamentoId: string,
    perguntaTipoRelacionamentoPerguntaId: string,
  } | null,
};

export type OnCreateRelUsuarioFalecimentoSubscriptionVariables = {
  filter?: ModelSubscriptionRelUsuarioFalecimentoFilterInput | null,
};

export type OnCreateRelUsuarioFalecimentoSubscription = {
  onCreateRelUsuarioFalecimento?:  {
    __typename: "RelUsuarioFalecimento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    relUsuarioFalecimentoFalecimentoId: string,
    relUsuarioFalecimentoAnonymousUserId: string,
    relUsuarioFalecimentoUserId?: string | null,
    relUsuarioFalecimentoTipoRelacionamentoId: string,
  } | null,
};

export type OnUpdateRelUsuarioFalecimentoSubscriptionVariables = {
  filter?: ModelSubscriptionRelUsuarioFalecimentoFilterInput | null,
};

export type OnUpdateRelUsuarioFalecimentoSubscription = {
  onUpdateRelUsuarioFalecimento?:  {
    __typename: "RelUsuarioFalecimento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    relUsuarioFalecimentoFalecimentoId: string,
    relUsuarioFalecimentoAnonymousUserId: string,
    relUsuarioFalecimentoUserId?: string | null,
    relUsuarioFalecimentoTipoRelacionamentoId: string,
  } | null,
};

export type OnDeleteRelUsuarioFalecimentoSubscriptionVariables = {
  filter?: ModelSubscriptionRelUsuarioFalecimentoFilterInput | null,
};

export type OnDeleteRelUsuarioFalecimentoSubscription = {
  onDeleteRelUsuarioFalecimento?:  {
    __typename: "RelUsuarioFalecimento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    AnonymousUser:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    TipoRelacionamento:  {
      __typename: "TipoRelacionamento",
      id: string,
      nivelRelaciomento: NivelRelacionamentoEnum,
      titulo: string,
      sexo?: SexoEnum | null,
      prioridade?: number | null,
      active?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    relUsuarioFalecimentoFalecimentoId: string,
    relUsuarioFalecimentoAnonymousUserId: string,
    relUsuarioFalecimentoUserId?: string | null,
    relUsuarioFalecimentoTipoRelacionamentoId: string,
  } | null,
};

export type OnCreateRespostaSubscriptionVariables = {
  filter?: ModelSubscriptionRespostaFilterInput | null,
};

export type OnCreateRespostaSubscription = {
  onCreateResposta?:  {
    __typename: "Resposta",
    id: string,
    pergunta: string,
    resposta: string,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    tipoPergunta: TipoPerguntaEnum,
    viviamJuntos?: boolean | null,
    Pergunta?:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    PerguntaOpcaoResposta?:  {
      __typename: "PerguntaOpcaoResposta",
      id: string,
      opcaoResposta: string,
      viviamJuntos?: boolean | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaOpcaoRespostaPerguntaId: string,
    } | null,
    assistentemensagemID?: string | null,
    skiped?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    respostaAnonymousUserId?: string | null,
    respostaUserId?: string | null,
    respostaFalecimentoId: string,
    respostaPerguntaId?: string | null,
    respostaPerguntaOpcaoRespostaId?: string | null,
  } | null,
};

export type OnUpdateRespostaSubscriptionVariables = {
  filter?: ModelSubscriptionRespostaFilterInput | null,
};

export type OnUpdateRespostaSubscription = {
  onUpdateResposta?:  {
    __typename: "Resposta",
    id: string,
    pergunta: string,
    resposta: string,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    tipoPergunta: TipoPerguntaEnum,
    viviamJuntos?: boolean | null,
    Pergunta?:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    PerguntaOpcaoResposta?:  {
      __typename: "PerguntaOpcaoResposta",
      id: string,
      opcaoResposta: string,
      viviamJuntos?: boolean | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaOpcaoRespostaPerguntaId: string,
    } | null,
    assistentemensagemID?: string | null,
    skiped?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    respostaAnonymousUserId?: string | null,
    respostaUserId?: string | null,
    respostaFalecimentoId: string,
    respostaPerguntaId?: string | null,
    respostaPerguntaOpcaoRespostaId?: string | null,
  } | null,
};

export type OnDeleteRespostaSubscriptionVariables = {
  filter?: ModelSubscriptionRespostaFilterInput | null,
};

export type OnDeleteRespostaSubscription = {
  onDeleteResposta?:  {
    __typename: "Resposta",
    id: string,
    pergunta: string,
    resposta: string,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    tipoPergunta: TipoPerguntaEnum,
    viviamJuntos?: boolean | null,
    Pergunta?:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    PerguntaOpcaoResposta?:  {
      __typename: "PerguntaOpcaoResposta",
      id: string,
      opcaoResposta: string,
      viviamJuntos?: boolean | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      perguntaOpcaoRespostaPerguntaId: string,
    } | null,
    assistentemensagemID?: string | null,
    skiped?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    respostaAnonymousUserId?: string | null,
    respostaUserId?: string | null,
    respostaFalecimentoId: string,
    respostaPerguntaId?: string | null,
    respostaPerguntaOpcaoRespostaId?: string | null,
  } | null,
};

export type OnCreatePerguntaOpcaoRespostaSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaOpcaoRespostaFilterInput | null,
};

export type OnCreatePerguntaOpcaoRespostaSubscription = {
  onCreatePerguntaOpcaoResposta?:  {
    __typename: "PerguntaOpcaoResposta",
    id: string,
    opcaoResposta: string,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    viviamJuntos?: boolean | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaOpcaoRespostaPerguntaId: string,
  } | null,
};

export type OnUpdatePerguntaOpcaoRespostaSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaOpcaoRespostaFilterInput | null,
};

export type OnUpdatePerguntaOpcaoRespostaSubscription = {
  onUpdatePerguntaOpcaoResposta?:  {
    __typename: "PerguntaOpcaoResposta",
    id: string,
    opcaoResposta: string,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    viviamJuntos?: boolean | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaOpcaoRespostaPerguntaId: string,
  } | null,
};

export type OnDeletePerguntaOpcaoRespostaSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaOpcaoRespostaFilterInput | null,
};

export type OnDeletePerguntaOpcaoRespostaSubscription = {
  onDeletePerguntaOpcaoResposta?:  {
    __typename: "PerguntaOpcaoResposta",
    id: string,
    opcaoResposta: string,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    viviamJuntos?: boolean | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaOpcaoRespostaPerguntaId: string,
  } | null,
};

export type OnCreatePerguntaNivelRelacionamentoSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaNivelRelacionamentoFilterInput | null,
};

export type OnCreatePerguntaNivelRelacionamentoSubscription = {
  onCreatePerguntaNivelRelacionamento?:  {
    __typename: "PerguntaNivelRelacionamento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaNivelRelacionamentoPerguntaId: string,
  } | null,
};

export type OnUpdatePerguntaNivelRelacionamentoSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaNivelRelacionamentoFilterInput | null,
};

export type OnUpdatePerguntaNivelRelacionamentoSubscription = {
  onUpdatePerguntaNivelRelacionamento?:  {
    __typename: "PerguntaNivelRelacionamento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaNivelRelacionamentoPerguntaId: string,
  } | null,
};

export type OnDeletePerguntaNivelRelacionamentoSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaNivelRelacionamentoFilterInput | null,
};

export type OnDeletePerguntaNivelRelacionamentoSubscription = {
  onDeletePerguntaNivelRelacionamento?:  {
    __typename: "PerguntaNivelRelacionamento",
    id: string,
    nivelRelacionamento: NivelRelacionamentoEnum,
    Pergunta:  {
      __typename: "Pergunta",
      id: string,
      pergunta: string,
      feminino?: string | null,
      tipoPergunta: TipoPerguntaEnum,
      tipoResposta: TipoRespostaEnum,
      respostaTipoViviamJuntos: boolean,
      limitaTipoRelacionamento: boolean,
      limitaFaixaEtaria: boolean,
      limitaNivelRelacionamento: boolean,
      modeloResposta?: string | null,
      active?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    perguntaNivelRelacionamentoPerguntaId: string,
  } | null,
};

export type OnCreatePerguntaSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaFilterInput | null,
};

export type OnCreatePerguntaSubscription = {
  onCreatePergunta?:  {
    __typename: "Pergunta",
    id: string,
    pergunta: string,
    feminino?: string | null,
    tipoPergunta: TipoPerguntaEnum,
    tipoResposta: TipoRespostaEnum,
    respostaTipoViviamJuntos: boolean,
    limitaTipoRelacionamento: boolean,
    limitaFaixaEtaria: boolean,
    limitaNivelRelacionamento: boolean,
    modeloResposta?: string | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePerguntaSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaFilterInput | null,
};

export type OnUpdatePerguntaSubscription = {
  onUpdatePergunta?:  {
    __typename: "Pergunta",
    id: string,
    pergunta: string,
    feminino?: string | null,
    tipoPergunta: TipoPerguntaEnum,
    tipoResposta: TipoRespostaEnum,
    respostaTipoViviamJuntos: boolean,
    limitaTipoRelacionamento: boolean,
    limitaFaixaEtaria: boolean,
    limitaNivelRelacionamento: boolean,
    modeloResposta?: string | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePerguntaSubscriptionVariables = {
  filter?: ModelSubscriptionPerguntaFilterInput | null,
};

export type OnDeletePerguntaSubscription = {
  onDeletePergunta?:  {
    __typename: "Pergunta",
    id: string,
    pergunta: string,
    feminino?: string | null,
    tipoPergunta: TipoPerguntaEnum,
    tipoResposta: TipoRespostaEnum,
    respostaTipoViviamJuntos: boolean,
    limitaTipoRelacionamento: boolean,
    limitaFaixaEtaria: boolean,
    limitaNivelRelacionamento: boolean,
    modeloResposta?: string | null,
    active?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTipoRelacionamentoSubscriptionVariables = {
  filter?: ModelSubscriptionTipoRelacionamentoFilterInput | null,
};

export type OnCreateTipoRelacionamentoSubscription = {
  onCreateTipoRelacionamento?:  {
    __typename: "TipoRelacionamento",
    id: string,
    nivelRelaciomento: NivelRelacionamentoEnum,
    titulo: string,
    sexo?: SexoEnum | null,
    prioridade?: number | null,
    active?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTipoRelacionamentoSubscriptionVariables = {
  filter?: ModelSubscriptionTipoRelacionamentoFilterInput | null,
};

export type OnUpdateTipoRelacionamentoSubscription = {
  onUpdateTipoRelacionamento?:  {
    __typename: "TipoRelacionamento",
    id: string,
    nivelRelaciomento: NivelRelacionamentoEnum,
    titulo: string,
    sexo?: SexoEnum | null,
    prioridade?: number | null,
    active?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTipoRelacionamentoSubscriptionVariables = {
  filter?: ModelSubscriptionTipoRelacionamentoFilterInput | null,
};

export type OnDeleteTipoRelacionamentoSubscription = {
  onDeleteTipoRelacionamento?:  {
    __typename: "TipoRelacionamento",
    id: string,
    nivelRelaciomento: NivelRelacionamentoEnum,
    titulo: string,
    sexo?: SexoEnum | null,
    prioridade?: number | null,
    active?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFalecimentoCidadeSubscriptionVariables = {
  filter?: ModelSubscriptionFalecimentoCidadeFilterInput | null,
};

export type OnCreateFalecimentoCidadeSubscription = {
  onCreateFalecimentoCidade?:  {
    __typename: "FalecimentoCidade",
    id: string,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    QtdMensagens: number,
    nomeCidade?: string | null,
    siglaEstado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoCidadeFalecimentoId: string,
    falecimentoCidadeCidadeId: string,
  } | null,
};

export type OnUpdateFalecimentoCidadeSubscriptionVariables = {
  filter?: ModelSubscriptionFalecimentoCidadeFilterInput | null,
};

export type OnUpdateFalecimentoCidadeSubscription = {
  onUpdateFalecimentoCidade?:  {
    __typename: "FalecimentoCidade",
    id: string,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    QtdMensagens: number,
    nomeCidade?: string | null,
    siglaEstado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoCidadeFalecimentoId: string,
    falecimentoCidadeCidadeId: string,
  } | null,
};

export type OnDeleteFalecimentoCidadeSubscriptionVariables = {
  filter?: ModelSubscriptionFalecimentoCidadeFilterInput | null,
};

export type OnDeleteFalecimentoCidadeSubscription = {
  onDeleteFalecimentoCidade?:  {
    __typename: "FalecimentoCidade",
    id: string,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    QtdMensagens: number,
    nomeCidade?: string | null,
    siglaEstado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoCidadeFalecimentoId: string,
    falecimentoCidadeCidadeId: string,
  } | null,
};

export type OnCreateConviteAdministracaoMemorialSubscriptionVariables = {
  filter?: ModelSubscriptionConviteAdministracaoMemorialFilterInput | null,
};

export type OnCreateConviteAdministracaoMemorialSubscription = {
  onCreateConviteAdministracaoMemorial?:  {
    __typename: "ConviteAdministracaoMemorial",
    id: string,
    memorialID: string,
    email: string,
    status: ConviteAdministracaoMemorialStatusEnum,
    dataEnvio: string,
    dataAceite?: string | null,
    Convidado?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conviteAdministracaoMemorialConvidadoId?: string | null,
  } | null,
};

export type OnUpdateConviteAdministracaoMemorialSubscriptionVariables = {
  filter?: ModelSubscriptionConviteAdministracaoMemorialFilterInput | null,
};

export type OnUpdateConviteAdministracaoMemorialSubscription = {
  onUpdateConviteAdministracaoMemorial?:  {
    __typename: "ConviteAdministracaoMemorial",
    id: string,
    memorialID: string,
    email: string,
    status: ConviteAdministracaoMemorialStatusEnum,
    dataEnvio: string,
    dataAceite?: string | null,
    Convidado?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conviteAdministracaoMemorialConvidadoId?: string | null,
  } | null,
};

export type OnDeleteConviteAdministracaoMemorialSubscriptionVariables = {
  filter?: ModelSubscriptionConviteAdministracaoMemorialFilterInput | null,
};

export type OnDeleteConviteAdministracaoMemorialSubscription = {
  onDeleteConviteAdministracaoMemorial?:  {
    __typename: "ConviteAdministracaoMemorial",
    id: string,
    memorialID: string,
    email: string,
    status: ConviteAdministracaoMemorialStatusEnum,
    dataEnvio: string,
    dataAceite?: string | null,
    Convidado?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conviteAdministracaoMemorialConvidadoId?: string | null,
  } | null,
};

export type OnCreateMemorialSubscriptionVariables = {
  filter?: ModelSubscriptionMemorialFilterInput | null,
};

export type OnCreateMemorialSubscription = {
  onCreateMemorial?:  {
    __typename: "Memorial",
    id: string,
    url: string,
    Administradores?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SuperAdmin?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    ConviteAdministracaoMemorials?:  {
      __typename: "ModelConviteAdministracaoMemorialConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Criador?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    emailSuperAdmin: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    memorialSuperAdminId?: string | null,
    memorialCriadorId?: string | null,
    memorialFalecimentoId: string,
  } | null,
};

export type OnUpdateMemorialSubscriptionVariables = {
  filter?: ModelSubscriptionMemorialFilterInput | null,
};

export type OnUpdateMemorialSubscription = {
  onUpdateMemorial?:  {
    __typename: "Memorial",
    id: string,
    url: string,
    Administradores?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SuperAdmin?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    ConviteAdministracaoMemorials?:  {
      __typename: "ModelConviteAdministracaoMemorialConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Criador?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    emailSuperAdmin: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    memorialSuperAdminId?: string | null,
    memorialCriadorId?: string | null,
    memorialFalecimentoId: string,
  } | null,
};

export type OnDeleteMemorialSubscriptionVariables = {
  filter?: ModelSubscriptionMemorialFilterInput | null,
};

export type OnDeleteMemorialSubscription = {
  onDeleteMemorial?:  {
    __typename: "Memorial",
    id: string,
    url: string,
    Administradores?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SuperAdmin?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    ConviteAdministracaoMemorials?:  {
      __typename: "ModelConviteAdministracaoMemorialConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Criador?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    emailSuperAdmin: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    memorialSuperAdminId?: string | null,
    memorialCriadorId?: string | null,
    memorialFalecimentoId: string,
  } | null,
};

export type OnCreateAnonymousUserSubscriptionVariables = {
  filter?: ModelSubscriptionAnonymousUserFilterInput | null,
};

export type OnCreateAnonymousUserSubscription = {
  onCreateAnonymousUser?:  {
    __typename: "AnonymousUser",
    id: string,
    nome?: string | null,
    avatar?: string | null,
    email?: string | null,
    ip?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sexo?: SexoEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    anonymousUserCidadeId?: string | null,
  } | null,
};

export type OnUpdateAnonymousUserSubscriptionVariables = {
  filter?: ModelSubscriptionAnonymousUserFilterInput | null,
};

export type OnUpdateAnonymousUserSubscription = {
  onUpdateAnonymousUser?:  {
    __typename: "AnonymousUser",
    id: string,
    nome?: string | null,
    avatar?: string | null,
    email?: string | null,
    ip?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sexo?: SexoEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    anonymousUserCidadeId?: string | null,
  } | null,
};

export type OnDeleteAnonymousUserSubscriptionVariables = {
  filter?: ModelSubscriptionAnonymousUserFilterInput | null,
};

export type OnDeleteAnonymousUserSubscription = {
  onDeleteAnonymousUser?:  {
    __typename: "AnonymousUser",
    id: string,
    nome?: string | null,
    avatar?: string | null,
    email?: string | null,
    ip?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sexo?: SexoEnum | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    anonymousUserCidadeId?: string | null,
  } | null,
};

export type OnCreateMensagemMuralSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralFilterInput | null,
};

export type OnCreateMensagemMuralSubscription = {
  onCreateMensagemMural?:  {
    __typename: "MensagemMural",
    id: string,
    mensagem: string,
    dataPostagem: string,
    horaPostagem: string,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    status?: MensagemMuralStatusEnum | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    AprovadoPor?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    dataAprovacao?: string | null,
    cidade?: string | null,
    estado?: string | null,
    nomeUsuario?: string | null,
    tipoMensagem?: TipoMensagemEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    resumo?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralCidadeId?: string | null,
    mensagemMuralFalecimentoId: string,
    mensagemMuralUserId?: string | null,
    mensagemMuralAnonymousUserId?: string | null,
    mensagemMuralAprovadoPorId?: string | null,
    mensagemMuralMemorialId?: string | null,
  } | null,
};

export type OnUpdateMensagemMuralSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralFilterInput | null,
};

export type OnUpdateMensagemMuralSubscription = {
  onUpdateMensagemMural?:  {
    __typename: "MensagemMural",
    id: string,
    mensagem: string,
    dataPostagem: string,
    horaPostagem: string,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    status?: MensagemMuralStatusEnum | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    AprovadoPor?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    dataAprovacao?: string | null,
    cidade?: string | null,
    estado?: string | null,
    nomeUsuario?: string | null,
    tipoMensagem?: TipoMensagemEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    resumo?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralCidadeId?: string | null,
    mensagemMuralFalecimentoId: string,
    mensagemMuralUserId?: string | null,
    mensagemMuralAnonymousUserId?: string | null,
    mensagemMuralAprovadoPorId?: string | null,
    mensagemMuralMemorialId?: string | null,
  } | null,
};

export type OnDeleteMensagemMuralSubscriptionVariables = {
  filter?: ModelSubscriptionMensagemMuralFilterInput | null,
};

export type OnDeleteMensagemMuralSubscription = {
  onDeleteMensagemMural?:  {
    __typename: "MensagemMural",
    id: string,
    mensagem: string,
    dataPostagem: string,
    horaPostagem: string,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Falecimento:  {
      __typename: "Falecimento",
      id: string,
      nome: string,
      sobrenome?: string | null,
      dataFalecimento: string,
      searchName: string,
      idade: number,
      tempoDeVida: string,
      meses: number,
      dataInicioVelorio?: string | null,
      horaInicioVelorio?: string | null,
      dataFimVelorio?: string | null,
      horaFimVelorio?: string | null,
      dataSepultamento?: string | null,
      horaSepultamento?: string | null,
      salaVelorio?: string | null,
      sobre?: string | null,
      imageKey?: string | null,
      apelido?: string | null,
      cidade?: string | null,
      estado?: string | null,
      sexo?: SexoEnum | null,
      dataNascimento: string,
      faixaEtaria?: FaixaEtariaEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      falecimentoFunerariaId?: string | null,
      falecimentoVelorioId?: string | null,
      falecimentoCemiterioId?: string | null,
      falecimentoCidadeId?: string | null,
      falecimentoOpenAiAssistantId?: string | null,
    },
    User?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    status?: MensagemMuralStatusEnum | null,
    AnonymousUser?:  {
      __typename: "AnonymousUser",
      id: string,
      nome?: string | null,
      avatar?: string | null,
      email?: string | null,
      ip?: string | null,
      sexo?: SexoEnum | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      anonymousUserCidadeId?: string | null,
    } | null,
    AprovadoPor?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    dataAprovacao?: string | null,
    cidade?: string | null,
    estado?: string | null,
    nomeUsuario?: string | null,
    tipoMensagem?: TipoMensagemEnum | null,
    Memorial?:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    } | null,
    resumo?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mensagemMuralCidadeId?: string | null,
    mensagemMuralFalecimentoId: string,
    mensagemMuralUserId?: string | null,
    mensagemMuralAnonymousUserId?: string | null,
    mensagemMuralAprovadoPorId?: string | null,
    mensagemMuralMemorialId?: string | null,
  } | null,
};

export type OnCreateCemiterioSubscriptionVariables = {
  filter?: ModelSubscriptionCemiterioFilterInput | null,
};

export type OnCreateCemiterioSubscription = {
  onCreateCemiterio?:  {
    __typename: "Cemiterio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    bairro?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Velorios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cemiterioCidadeId?: string | null,
    cemiterioOwnerId?: string | null,
  } | null,
};

export type OnUpdateCemiterioSubscriptionVariables = {
  filter?: ModelSubscriptionCemiterioFilterInput | null,
};

export type OnUpdateCemiterioSubscription = {
  onUpdateCemiterio?:  {
    __typename: "Cemiterio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    bairro?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Velorios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cemiterioCidadeId?: string | null,
    cemiterioOwnerId?: string | null,
  } | null,
};

export type OnDeleteCemiterioSubscriptionVariables = {
  filter?: ModelSubscriptionCemiterioFilterInput | null,
};

export type OnDeleteCemiterioSubscription = {
  onDeleteCemiterio?:  {
    __typename: "Cemiterio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    bairro?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Velorios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cemiterioCidadeId?: string | null,
    cemiterioOwnerId?: string | null,
  } | null,
};

export type OnCreateMesorregiaoSubscriptionVariables = {
  filter?: ModelSubscriptionMesorregiaoFilterInput | null,
};

export type OnCreateMesorregiaoSubscription = {
  onCreateMesorregiao?:  {
    __typename: "Mesorregiao",
    id: string,
    nome: string,
    idIbge: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mesorregiaoEstadoId?: string | null,
  } | null,
};

export type OnUpdateMesorregiaoSubscriptionVariables = {
  filter?: ModelSubscriptionMesorregiaoFilterInput | null,
};

export type OnUpdateMesorregiaoSubscription = {
  onUpdateMesorregiao?:  {
    __typename: "Mesorregiao",
    id: string,
    nome: string,
    idIbge: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mesorregiaoEstadoId?: string | null,
  } | null,
};

export type OnDeleteMesorregiaoSubscriptionVariables = {
  filter?: ModelSubscriptionMesorregiaoFilterInput | null,
};

export type OnDeleteMesorregiaoSubscription = {
  onDeleteMesorregiao?:  {
    __typename: "Mesorregiao",
    id: string,
    nome: string,
    idIbge: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    mesorregiaoEstadoId?: string | null,
  } | null,
};

export type OnCreateMicrorregiaoSubscriptionVariables = {
  filter?: ModelSubscriptionMicrorregiaoFilterInput | null,
};

export type OnCreateMicrorregiaoSubscription = {
  onCreateMicrorregiao?:  {
    __typename: "Microrregiao",
    id: string,
    nome: string,
    idIbge: string,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    microrregiaoMesorregiaoId?: string | null,
  } | null,
};

export type OnUpdateMicrorregiaoSubscriptionVariables = {
  filter?: ModelSubscriptionMicrorregiaoFilterInput | null,
};

export type OnUpdateMicrorregiaoSubscription = {
  onUpdateMicrorregiao?:  {
    __typename: "Microrregiao",
    id: string,
    nome: string,
    idIbge: string,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    microrregiaoMesorregiaoId?: string | null,
  } | null,
};

export type OnDeleteMicrorregiaoSubscriptionVariables = {
  filter?: ModelSubscriptionMicrorregiaoFilterInput | null,
};

export type OnDeleteMicrorregiaoSubscription = {
  onDeleteMicrorregiao?:  {
    __typename: "Microrregiao",
    id: string,
    nome: string,
    idIbge: string,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    microrregiaoMesorregiaoId?: string | null,
  } | null,
};

export type OnCreateRegiaoSubscriptionVariables = {
  filter?: ModelSubscriptionRegiaoFilterInput | null,
};

export type OnCreateRegiaoSubscription = {
  onCreateRegiao?:  {
    __typename: "Regiao",
    id: string,
    sigla: string,
    nome: string,
    idIbge: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRegiaoSubscriptionVariables = {
  filter?: ModelSubscriptionRegiaoFilterInput | null,
};

export type OnUpdateRegiaoSubscription = {
  onUpdateRegiao?:  {
    __typename: "Regiao",
    id: string,
    sigla: string,
    nome: string,
    idIbge: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRegiaoSubscriptionVariables = {
  filter?: ModelSubscriptionRegiaoFilterInput | null,
};

export type OnDeleteRegiaoSubscription = {
  onDeleteRegiao?:  {
    __typename: "Regiao",
    id: string,
    sigla: string,
    nome: string,
    idIbge: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEstadoSubscriptionVariables = {
  filter?: ModelSubscriptionEstadoFilterInput | null,
};

export type OnCreateEstadoSubscription = {
  onCreateEstado?:  {
    __typename: "Estado",
    id: string,
    nome: string,
    sigla: string,
    idIbge: string,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    estadoRegiaoId?: string | null,
  } | null,
};

export type OnUpdateEstadoSubscriptionVariables = {
  filter?: ModelSubscriptionEstadoFilterInput | null,
};

export type OnUpdateEstadoSubscription = {
  onUpdateEstado?:  {
    __typename: "Estado",
    id: string,
    nome: string,
    sigla: string,
    idIbge: string,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    estadoRegiaoId?: string | null,
  } | null,
};

export type OnDeleteEstadoSubscriptionVariables = {
  filter?: ModelSubscriptionEstadoFilterInput | null,
};

export type OnDeleteEstadoSubscription = {
  onDeleteEstado?:  {
    __typename: "Estado",
    id: string,
    nome: string,
    sigla: string,
    idIbge: string,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    estadoRegiaoId?: string | null,
  } | null,
};

export type OnCreateCidadeSubscriptionVariables = {
  filter?: ModelSubscriptionCidadeFilterInput | null,
};

export type OnCreateCidadeSubscription = {
  onCreateCidade?:  {
    __typename: "Cidade",
    id: string,
    nome: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    idIbge: string,
    Microrregiao?:  {
      __typename: "Microrregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      microrregiaoMesorregiaoId?: string | null,
    } | null,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cidadeEstadoId?: string | null,
    cidadeMicrorregiaoId?: string | null,
    cidadeMesorregiaoId?: string | null,
    cidadeRegiaoId?: string | null,
  } | null,
};

export type OnUpdateCidadeSubscriptionVariables = {
  filter?: ModelSubscriptionCidadeFilterInput | null,
};

export type OnUpdateCidadeSubscription = {
  onUpdateCidade?:  {
    __typename: "Cidade",
    id: string,
    nome: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    idIbge: string,
    Microrregiao?:  {
      __typename: "Microrregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      microrregiaoMesorregiaoId?: string | null,
    } | null,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cidadeEstadoId?: string | null,
    cidadeMicrorregiaoId?: string | null,
    cidadeMesorregiaoId?: string | null,
    cidadeRegiaoId?: string | null,
  } | null,
};

export type OnDeleteCidadeSubscriptionVariables = {
  filter?: ModelSubscriptionCidadeFilterInput | null,
};

export type OnDeleteCidadeSubscription = {
  onDeleteCidade?:  {
    __typename: "Cidade",
    id: string,
    nome: string,
    Estado?:  {
      __typename: "Estado",
      id: string,
      nome: string,
      sigla: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      estadoRegiaoId?: string | null,
    } | null,
    idIbge: string,
    Microrregiao?:  {
      __typename: "Microrregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      microrregiaoMesorregiaoId?: string | null,
    } | null,
    Mesorregiao?:  {
      __typename: "Mesorregiao",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      mesorregiaoEstadoId?: string | null,
    } | null,
    Regiao?:  {
      __typename: "Regiao",
      id: string,
      sigla: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    cidadeEstadoId?: string | null,
    cidadeMicrorregiaoId?: string | null,
    cidadeMesorregiaoId?: string | null,
    cidadeRegiaoId?: string | null,
  } | null,
};

export type OnCreateVelorioSubscriptionVariables = {
  filter?: ModelSubscriptionVelorioFilterInput | null,
};

export type OnCreateVelorioSubscription = {
  onCreateVelorio?:  {
    __typename: "Velorio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    bairro?: string | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    velorioCidadeId?: string | null,
    velorioOwnerId?: string | null,
  } | null,
};

export type OnUpdateVelorioSubscriptionVariables = {
  filter?: ModelSubscriptionVelorioFilterInput | null,
};

export type OnUpdateVelorioSubscription = {
  onUpdateVelorio?:  {
    __typename: "Velorio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    bairro?: string | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    velorioCidadeId?: string | null,
    velorioOwnerId?: string | null,
  } | null,
};

export type OnDeleteVelorioSubscriptionVariables = {
  filter?: ModelSubscriptionVelorioFilterInput | null,
};

export type OnDeleteVelorioSubscription = {
  onDeleteVelorio?:  {
    __typename: "Velorio",
    id: string,
    nome: string,
    logradouro: string,
    cep?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    bairro?: string | null,
    Owner?:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    } | null,
    Funerarias?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioVelorioConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    velorioCidadeId?: string | null,
    velorioOwnerId?: string | null,
  } | null,
};

export type OnCreateFalecimentoSubscriptionVariables = {
  filter?: ModelSubscriptionFalecimentoFilterInput | null,
};

export type OnCreateFalecimentoSubscription = {
  onCreateFalecimento?:  {
    __typename: "Falecimento",
    id: string,
    nome: string,
    sobrenome?: string | null,
    dataFalecimento: string,
    Funeraria?:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    } | null,
    searchName: string,
    idade: number,
    tempoDeVida: string,
    meses: number,
    Velorio?:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    } | null,
    dataInicioVelorio?: string | null,
    horaInicioVelorio?: string | null,
    dataFimVelorio?: string | null,
    horaFimVelorio?: string | null,
    dataSepultamento?: string | null,
    horaSepultamento?: string | null,
    salaVelorio?: string | null,
    Cemiterio?:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    } | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sobre?: string | null,
    imageKey?: string | null,
    apelido?: string | null,
    cidade?: string | null,
    estado?: string | null,
    sexo?: SexoEnum | null,
    dataNascimento: string,
    faixaEtaria?: FaixaEtariaEnum | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoFunerariaId?: string | null,
    falecimentoVelorioId?: string | null,
    falecimentoCemiterioId?: string | null,
    falecimentoCidadeId?: string | null,
    falecimentoOpenAiAssistantId?: string | null,
  } | null,
};

export type OnUpdateFalecimentoSubscriptionVariables = {
  filter?: ModelSubscriptionFalecimentoFilterInput | null,
};

export type OnUpdateFalecimentoSubscription = {
  onUpdateFalecimento?:  {
    __typename: "Falecimento",
    id: string,
    nome: string,
    sobrenome?: string | null,
    dataFalecimento: string,
    Funeraria?:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    } | null,
    searchName: string,
    idade: number,
    tempoDeVida: string,
    meses: number,
    Velorio?:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    } | null,
    dataInicioVelorio?: string | null,
    horaInicioVelorio?: string | null,
    dataFimVelorio?: string | null,
    horaFimVelorio?: string | null,
    dataSepultamento?: string | null,
    horaSepultamento?: string | null,
    salaVelorio?: string | null,
    Cemiterio?:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    } | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sobre?: string | null,
    imageKey?: string | null,
    apelido?: string | null,
    cidade?: string | null,
    estado?: string | null,
    sexo?: SexoEnum | null,
    dataNascimento: string,
    faixaEtaria?: FaixaEtariaEnum | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoFunerariaId?: string | null,
    falecimentoVelorioId?: string | null,
    falecimentoCemiterioId?: string | null,
    falecimentoCidadeId?: string | null,
    falecimentoOpenAiAssistantId?: string | null,
  } | null,
};

export type OnDeleteFalecimentoSubscriptionVariables = {
  filter?: ModelSubscriptionFalecimentoFilterInput | null,
};

export type OnDeleteFalecimentoSubscription = {
  onDeleteFalecimento?:  {
    __typename: "Falecimento",
    id: string,
    nome: string,
    sobrenome?: string | null,
    dataFalecimento: string,
    Funeraria?:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    } | null,
    searchName: string,
    idade: number,
    tempoDeVida: string,
    meses: number,
    Velorio?:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    } | null,
    dataInicioVelorio?: string | null,
    horaInicioVelorio?: string | null,
    dataFimVelorio?: string | null,
    horaFimVelorio?: string | null,
    dataSepultamento?: string | null,
    horaSepultamento?: string | null,
    salaVelorio?: string | null,
    Cemiterio?:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    } | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    sobre?: string | null,
    imageKey?: string | null,
    apelido?: string | null,
    cidade?: string | null,
    estado?: string | null,
    sexo?: SexoEnum | null,
    dataNascimento: string,
    faixaEtaria?: FaixaEtariaEnum | null,
    OpenAiAssistant?:  {
      __typename: "OpenAiAssistant",
      id: string,
      assistantId: string,
      object: string,
      model: string,
      instructions: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    falecimentoFunerariaId?: string | null,
    falecimentoVelorioId?: string | null,
    falecimentoCemiterioId?: string | null,
    falecimentoCidadeId?: string | null,
    falecimentoOpenAiAssistantId?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    Funerarias?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nome?: string | null,
    status?: UserStatusEnum | null,
    avatar?: string | null,
    imageKey?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    role?: UserRoleEnum | null,
    memorials?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sexo?: SexoEnum | null,
    lastIpAddress?: string | null,
    anonymousUserId?: string | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCidadeId?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    Funerarias?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nome?: string | null,
    status?: UserStatusEnum | null,
    avatar?: string | null,
    imageKey?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    role?: UserRoleEnum | null,
    memorials?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sexo?: SexoEnum | null,
    lastIpAddress?: string | null,
    anonymousUserId?: string | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCidadeId?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    Funerarias?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nome?: string | null,
    status?: UserStatusEnum | null,
    avatar?: string | null,
    imageKey?: string | null,
    Cidade?:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    } | null,
    role?: UserRoleEnum | null,
    memorials?:  {
      __typename: "ModelMemorialUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sexo?: SexoEnum | null,
    lastIpAddress?: string | null,
    anonymousUserId?: string | null,
    FalecimentoFotos?:  {
      __typename: "ModelFalecimentoFotoConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCidadeId?: string | null,
  } | null,
};

export type OnCreateFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionFunerariaFilterInput | null,
};

export type OnCreateFunerariaSubscription = {
  onCreateFuneraria?:  {
    __typename: "Funeraria",
    id: string,
    cnpj: string,
    nome: string,
    logradouro: string,
    cep: string,
    telefone1?: string | null,
    telefone2?: string | null,
    email?: string | null,
    site?: string | null,
    users?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    velorios?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    funerariaCidadeId: string,
  } | null,
};

export type OnUpdateFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionFunerariaFilterInput | null,
};

export type OnUpdateFunerariaSubscription = {
  onUpdateFuneraria?:  {
    __typename: "Funeraria",
    id: string,
    cnpj: string,
    nome: string,
    logradouro: string,
    cep: string,
    telefone1?: string | null,
    telefone2?: string | null,
    email?: string | null,
    site?: string | null,
    users?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    velorios?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    funerariaCidadeId: string,
  } | null,
};

export type OnDeleteFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionFunerariaFilterInput | null,
};

export type OnDeleteFunerariaSubscription = {
  onDeleteFuneraria?:  {
    __typename: "Funeraria",
    id: string,
    cnpj: string,
    nome: string,
    logradouro: string,
    cep: string,
    telefone1?: string | null,
    telefone2?: string | null,
    email?: string | null,
    site?: string | null,
    users?:  {
      __typename: "ModelUserFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Cidade:  {
      __typename: "Cidade",
      id: string,
      nome: string,
      idIbge: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cidadeEstadoId?: string | null,
      cidadeMicrorregiaoId?: string | null,
      cidadeMesorregiaoId?: string | null,
      cidadeRegiaoId?: string | null,
    },
    velorios?:  {
      __typename: "ModelVelorioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cemiterios?:  {
      __typename: "ModelCemiterioFunerariaConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cidade?: string | null,
    estado?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    funerariaCidadeId: string,
  } | null,
};

export type OnCreateMemorialUserSubscriptionVariables = {
  filter?: ModelSubscriptionMemorialUserFilterInput | null,
};

export type OnCreateMemorialUserSubscription = {
  onCreateMemorialUser?:  {
    __typename: "MemorialUser",
    id: string,
    memorialId: string,
    userId: string,
    memorial:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    },
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMemorialUserSubscriptionVariables = {
  filter?: ModelSubscriptionMemorialUserFilterInput | null,
};

export type OnUpdateMemorialUserSubscription = {
  onUpdateMemorialUser?:  {
    __typename: "MemorialUser",
    id: string,
    memorialId: string,
    userId: string,
    memorial:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    },
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMemorialUserSubscriptionVariables = {
  filter?: ModelSubscriptionMemorialUserFilterInput | null,
};

export type OnDeleteMemorialUserSubscription = {
  onDeleteMemorialUser?:  {
    __typename: "MemorialUser",
    id: string,
    memorialId: string,
    userId: string,
    memorial:  {
      __typename: "Memorial",
      id: string,
      url: string,
      emailSuperAdmin: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      memorialSuperAdminId?: string | null,
      memorialCriadorId?: string | null,
      memorialFalecimentoId: string,
    },
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCemiterioFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionCemiterioFunerariaFilterInput | null,
};

export type OnCreateCemiterioFunerariaSubscription = {
  onCreateCemiterioFuneraria?:  {
    __typename: "CemiterioFuneraria",
    id: string,
    cemiterioId: string,
    funerariaId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCemiterioFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionCemiterioFunerariaFilterInput | null,
};

export type OnUpdateCemiterioFunerariaSubscription = {
  onUpdateCemiterioFuneraria?:  {
    __typename: "CemiterioFuneraria",
    id: string,
    cemiterioId: string,
    funerariaId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCemiterioFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionCemiterioFunerariaFilterInput | null,
};

export type OnDeleteCemiterioFunerariaSubscription = {
  onDeleteCemiterioFuneraria?:  {
    __typename: "CemiterioFuneraria",
    id: string,
    cemiterioId: string,
    funerariaId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCemiterioVelorioSubscriptionVariables = {
  filter?: ModelSubscriptionCemiterioVelorioFilterInput | null,
};

export type OnCreateCemiterioVelorioSubscription = {
  onCreateCemiterioVelorio?:  {
    __typename: "CemiterioVelorio",
    id: string,
    cemiterioId: string,
    velorioId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCemiterioVelorioSubscriptionVariables = {
  filter?: ModelSubscriptionCemiterioVelorioFilterInput | null,
};

export type OnUpdateCemiterioVelorioSubscription = {
  onUpdateCemiterioVelorio?:  {
    __typename: "CemiterioVelorio",
    id: string,
    cemiterioId: string,
    velorioId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCemiterioVelorioSubscriptionVariables = {
  filter?: ModelSubscriptionCemiterioVelorioFilterInput | null,
};

export type OnDeleteCemiterioVelorioSubscription = {
  onDeleteCemiterioVelorio?:  {
    __typename: "CemiterioVelorio",
    id: string,
    cemiterioId: string,
    velorioId: string,
    cemiterio:  {
      __typename: "Cemiterio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      cemiterioCidadeId?: string | null,
      cemiterioOwnerId?: string | null,
    },
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateVelorioFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionVelorioFunerariaFilterInput | null,
};

export type OnCreateVelorioFunerariaSubscription = {
  onCreateVelorioFuneraria?:  {
    __typename: "VelorioFuneraria",
    id: string,
    velorioId: string,
    funerariaId: string,
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateVelorioFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionVelorioFunerariaFilterInput | null,
};

export type OnUpdateVelorioFunerariaSubscription = {
  onUpdateVelorioFuneraria?:  {
    __typename: "VelorioFuneraria",
    id: string,
    velorioId: string,
    funerariaId: string,
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteVelorioFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionVelorioFunerariaFilterInput | null,
};

export type OnDeleteVelorioFunerariaSubscription = {
  onDeleteVelorioFuneraria?:  {
    __typename: "VelorioFuneraria",
    id: string,
    velorioId: string,
    funerariaId: string,
    velorio:  {
      __typename: "Velorio",
      id: string,
      nome: string,
      logradouro: string,
      cep?: string | null,
      bairro?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      velorioCidadeId?: string | null,
      velorioOwnerId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionUserFunerariaFilterInput | null,
};

export type OnCreateUserFunerariaSubscription = {
  onCreateUserFuneraria?:  {
    __typename: "UserFuneraria",
    id: string,
    userId: string,
    funerariaId: string,
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionUserFunerariaFilterInput | null,
};

export type OnUpdateUserFunerariaSubscription = {
  onUpdateUserFuneraria?:  {
    __typename: "UserFuneraria",
    id: string,
    userId: string,
    funerariaId: string,
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserFunerariaSubscriptionVariables = {
  filter?: ModelSubscriptionUserFunerariaFilterInput | null,
};

export type OnDeleteUserFunerariaSubscription = {
  onDeleteUserFuneraria?:  {
    __typename: "UserFuneraria",
    id: string,
    userId: string,
    funerariaId: string,
    user:  {
      __typename: "User",
      id: string,
      email: string,
      nome?: string | null,
      status?: UserStatusEnum | null,
      avatar?: string | null,
      imageKey?: string | null,
      role?: UserRoleEnum | null,
      sexo?: SexoEnum | null,
      lastIpAddress?: string | null,
      anonymousUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCidadeId?: string | null,
    },
    funeraria:  {
      __typename: "Funeraria",
      id: string,
      cnpj: string,
      nome: string,
      logradouro: string,
      cep: string,
      telefone1?: string | null,
      telefone2?: string | null,
      email?: string | null,
      site?: string | null,
      cidade?: string | null,
      estado?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      funerariaCidadeId: string,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
