import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssistenteMensagemStepEnum, enumRouteMap } from "../core/_models";

const useStepFromLocation = (): [AssistenteMensagemStepEnum | undefined, (step: AssistenteMensagemStepEnum) => void] => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<AssistenteMensagemStepEnum | undefined>(undefined);

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    const step = Object.entries(enumRouteMap).find(([, route]) => route === path)?.[0];

    if (step) {
      setCurrentStep(Number(step) as AssistenteMensagemStepEnum);
    } else {
      setCurrentStep(undefined);
    }
  }, [location]);

  const goToStep = useCallback((step: AssistenteMensagemStepEnum) => {
    const route = enumRouteMap[step];
    if (route) {
      navigate(`${route}`);
      setCurrentStep(step);
    }
  }, [navigate]);

  return [currentStep, goToStep];
};

export { useStepFromLocation };
