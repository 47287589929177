import { FC } from "react"
import { PostTypeEnum } from "../../../../../models"
import { PostProps } from "../core/_models"
import { PostHomenagem } from "./PostHomenagem/PostHomenagem"

const PostView : FC<PostProps> = ({ post }) => {
  return (
    <div className="mt-4">
      { post.postType === PostTypeEnum.HOMENAGEM && <PostHomenagem post={post} /> }
    </div>
  )
}

export { PostView }

