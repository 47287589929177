import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { NivelRelacionamentoEnum, TipoRelacionamento } from "../../../../../models";

const getTiposRelacionamentos = async () : Promise<TipoRelacionamento[]> => {
  const results = await DataStore.query(TipoRelacionamento, Predicates.ALL,
    { sort: s => (
      s.prioridade (SortDirection.ASCENDING)
    )}
  );

  return results
}

const getTiposRelacionamentosByNivel = async (nivelRelacionamentoEnum: NivelRelacionamentoEnum) : Promise<TipoRelacionamento[]> => {
  const results = await DataStore.query(TipoRelacionamento, q => q.nivelRelaciomento.eq(nivelRelacionamentoEnum),
  { sort: s => (
    s.prioridade (SortDirection.ASCENDING)
  )}
);

  return results
}

export { getTiposRelacionamentos, getTiposRelacionamentosByNivel };
