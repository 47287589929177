import { FC, useState } from "react";
import { Collapse } from "react-bootstrap";
import { BsHandThumbsUp, BsXCircle } from "react-icons/bs";

type Props = {
  currentValue: string | undefined;
  onValueChange: (value: string | undefined) => void;
  className?: string
}

const MensagemInstrucao : FC<Props> = ( { currentValue, onValueChange, className } ) => {
  const [ open, setOpen ] = useState(false);
  const [ current, setCurrent ] = useState(currentValue);
  const [ valorInstrucao, setValorInstrucao ] = useState<string>('')
  
  const handleInstrucao = (value: string|undefined) => {
    setCurrent(value)
    onValueChange(value)
    setOpen(false)
  }
  
  return (
    <div className={className}>
      <div className="d-flex justify-content-center align-items-center">
        <span className="fw-bold fs-4 me-3">Instuções:</span>
        <button className="btn btn-primary" onClick={() => setOpen(!open)} aria-controls="collapse-text" aria-expanded={open}>
          {!current && <BsXCircle className="me-2 fs-4" />}
          {!!current && <BsHandThumbsUp className="me-2 fs-4" />}
          {!current && 'SEM INSTRUÇÕES'}
          {!!current && 'COM INSTRUÇÕES'}
        </button>
      </div>
      <div className='d-flex justify-content-center'>
        <Collapse in={open}>
          <div id="collapse-text" className="w-100">
            <div className='card mt-4 w-100'>
              <div className='card-body p-2 fs-3'>
                <div
                    className={`d-flex flex-column justify-content-left align-items-start p-2 mb-2 bg-light'}`}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="d-flex align-items-center w-100 mt-2">
                      <textarea
                        onChange={(event) => setValorInstrucao(event.target.value)}
                        placeholder="Use a criatividade para passar instruções personalizadas para a nossa Inteligência Artificial"
                        className="form-control me-2 fs-4"
                        rows={4}
                      />
                      <button className="btn btn-primary fs-2" onClick={() => handleInstrucao(valorInstrucao)}>OK</button>
                    </div>
                  </div>

              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export { MensagemInstrucao };
