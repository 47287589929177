import { FC, useState } from "react";
import { Collapse } from "react-bootstrap";
import { BsBlockquoteLeft, BsPeopleFill, BsXCircle } from "react-icons/bs";

type Props = {
  nome: string,
  currentValue: string | undefined;
  onValueChange: (value: string | undefined) => void;
  className?: string
}

const AssinaturaMensagemSelector : FC<Props> = ( { currentValue, onValueChange, nome, className } ) => {
  const [ open, setOpen ] = useState(false);
  const [ current, setCurrent ] = useState(currentValue);
  const [ valorAssinatura, setValorAssinatura ] = useState<string>('')

  const handleAssinatura = (value: string|undefined) => {
    setCurrent(value)
    onValueChange(value)
    setOpen(false)
  }
  
  return (
    <div className={className}>
      <div className="d-flex justify-content-center align-items-center">
        <span className="fw-bold fs-4 me-3">Assinatura:</span>
        <button className="btn btn-primary" onClick={() => setOpen(!open)} aria-controls="collapse-text" aria-expanded={open}>
          {!current && <BsXCircle className="me-2 fs-4" />}
          {current === nome && <BsBlockquoteLeft className="me-2 fs-4" />}
          {!!current && current !== nome && <BsPeopleFill className="me-2 fs-4" />}

          {!current && 'SEM ASSINATURA'}
          {current === nome && 'EM MEU NOME'}
          {!!current && current !== nome && 'Em nome ' + current}
        </button>
      </div>
      <div className='d-flex justify-content-center'>
        <Collapse in={open}>
          <div id="collapse-text" className="w-100">
            <div className='card mt-4 w-100'>
              <div className='card-body p-2 fs-3'>
                <div></div>
                <div
                  className={`d-flex justify-content-left align-items-center p-2 mb-2 ${!current ? 'bg-secondary text-white' : 'bg-light'}`}
                  onClick={() => handleAssinatura(undefined)}
                  style={{ cursor: 'pointer' }}
                >
                  <BsXCircle className="me-3" /> Sem assinatura.
                </div>
                <div className='separator my-1'></div>
                <div
                  className={`d-flex justify-content-left align-items-center p-2 mb-2 ${current === nome ? 'bg-secondary text-white' : 'bg-light'}`}
                  onClick={() => handleAssinatura(nome)}
                  style={{ cursor: 'pointer' }}
                >
                  <BsBlockquoteLeft className="me-3" /> Em meu nome.
                </div>
                <div className='separator my-1'></div>
                <div
                    className={`d-flex flex-column justify-content-left align-items-start p-2 mb-2 ${!!current && current !== nome ? 'bg-secondary text-white' : 'bg-light'}`}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="d-flex align-items-center w-100">
                      <BsPeopleFill className="me-3" /> Em nome
                    </div>
                    <div className="d-flex align-items-center w-100 mt-2">
                      <textarea
                        onChange={(event) => setValorAssinatura(event.target.value)}
                        placeholder="Ex: da família / da associação / dos amigos"
                        className="form-control me-2 fs-4"
                        rows={2}
                      />
                      <button className="btn btn-primary fs-2" onClick={() => handleAssinatura(valorAssinatura)}>OK</button>
                    </div>
                  </div>

              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export { AssinaturaMensagemSelector };
