import { BackAndNextButtons } from "../../../../../../_lapide/components/buttons/BackAndNextButtons";
import { useLoginFlow } from "../../../../auth/components/LoginFlow";
import { LoginStep1Email } from "../../../../auth/components/login-steps/LoginStep1Email";
import { useAssistenteMensagem } from "../../../assistente-mensagem/AssistenteMensagemProvider";

const StepEmail = () => {
  const { openAssistente } = useAssistenteMensagem();
  const { isLoading, isEmailValid, executeSignIn, email } = useLoginFlow();
  
  const handleNext = async() => {
    if (isEmailValid) {
      await executeSignIn()
    }
  }

  const handleBack = async() => {
    openAssistente();
  }

  return (
    <>
      <div className="card">
        <div className="card-title px-5 pt-5">
          <h2 className="card-title text-center">Precisamos confirmar sua identidade</h2>
        </div>
        <div className="card-body d-flex flex-column justify-content-center">
          <LoginStep1Email labelEmail="Qual seu melhor email?" />
        </div>
      </div>

      <BackAndNextButtons
        className="p-2"
        nextVisible={true}
        nextText="Avançar"
        onGoNext={handleNext}
        isNextLoading={isLoading}
        nextEnabled={!!email && isEmailValid}

        backVisible={true}
        onGoBack={handleBack}
        backEnabled={!isLoading}
      />
    </>
  )
}

export { StepEmail };

