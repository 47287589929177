import { BackAndNextButtons } from "../../../../../../_lapide/components/buttons/BackAndNextButtons";
import { LoginFlowStepEnum, useLoginFlow } from "../../../../auth/components/LoginFlow";
import { LoginStep2Validation } from "../../../../auth/components/login-steps/LoginStep2Validation";
import { MensagemMuralStepEnum, useMensagemMural } from "../../MensagemMuralProvider";

const StepCodeVerification = () => {
  const { setCurrentMensagemMuralStep } = useMensagemMural();
  const { isLoading, isCodeValid, executeCodeValidation, validationCode, setCurrentLoginFlowStep } = useLoginFlow();
  
  const handleNext = async() => {
    if (isCodeValid) {
      await executeCodeValidation()
    }
  }

  const handleBack = async() => {
    setCurrentLoginFlowStep(LoginFlowStepEnum.LOGIN)
    setCurrentMensagemMuralStep(MensagemMuralStepEnum.EMAIL)
  }

  return (
    <>
      <div className="card">
        <div className="card-body d-flex flex-column justify-content-center">
          <LoginStep2Validation />
        </div>
      </div>

      <BackAndNextButtons
        className="p-2"
        nextVisible={true}
        nextText="Avançar"
        onGoNext={handleNext}
        isNextLoading={isLoading}
        nextEnabled={!!validationCode && isCodeValid}

        backVisible={true}
        onGoBack={handleBack}
        backEnabled={!isLoading}
      />
    </>
  )
}

export { StepCodeVerification };

