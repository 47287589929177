import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { Cemiterio, Falecimento, Velorio } from "../../../../models";
import { ServicoSepultamentoCard } from "./cards/ServicoSepultamentoCard";
import { ServicoVelorioCard } from "./cards/ServicoVelorioCard";

type Props = {
  falecimento: Falecimento
}

const MemorialServicos: FC<Props> = ({falecimento}) => {
  const [ localVelorio, setLocalVelorio ] = useState<Velorio | undefined>( undefined )
  const [ localCemiterio, setLocalCemiterio ] = useState<Cemiterio | undefined>( undefined )
      
  const loadLocalVelorio = async (falecimento: Falecimento) => {
    setLocalVelorio(undefined)
    
    const velorio = await falecimento.Velorio;

      if (velorio){
        setLocalVelorio(velorio);
      }
  }

  const loadLocalCemiterio = async (falecimento: Falecimento) => {
    setLocalCemiterio(undefined)
    
    const cemiterio = await falecimento.Cemiterio;

      if (cemiterio){
        setLocalCemiterio(cemiterio);
      }
  }

  useEffect(() => {
    loadLocalVelorio(falecimento)
    loadLocalCemiterio(falecimento)
  }, [falecimento])

  return (
    <>
      <div className={clsx("row mt-n5")}>
        {
          localVelorio ?
            <div className="col-12">
              <ServicoVelorioCard falecimento={falecimento} velorio={localVelorio}/>
            </div>
          : null
        }
      </div>
      <div className={clsx("row", isMobile ? "mt-3" : "mt-n5")}>
        {
          localCemiterio ?
            <div className="col-12">
              <ServicoSepultamentoCard falecimento={falecimento} cemiterio={localCemiterio}/>
            </div>
          : null
        }
      </div>
    </>
  )
}

export { MemorialServicos };

