import clsx from "clsx"
import { FC, useEffect, useState } from "react"
import { KTSVG } from "../../../_metronic/helpers"

type Props = {
  backEnabled?: boolean
  backVisible?: boolean
  backText?: string
  onGoBack?: () => Promise<void>
  isBackLoading?: boolean

  nextEnabled?: boolean
  nextVisible?: boolean
  nextText?: string
  onGoNext?: () => Promise<void>

  isNextLoading?: boolean
  className?: string
}

const BackAndNextButtons : FC<Props> = ( { backEnabled, backVisible, backText, onGoBack, nextEnabled, nextText, nextVisible, onGoNext, isNextLoading, isBackLoading, className  } ) => {
  const [isBackEnabled, setIsBackEnabled] = useState(backEnabled ?? true);
  const [isBackVisible, setIsBackVisible] = useState(backVisible ?? true);
  const [backButtonText, setBackButtonText] = useState(backText ?? "Voltar");

  const [isNextEnabled, setIsNextEnabled] = useState(nextEnabled ?? true);
  const [nextButtonText, setNextButtonText] = useState(nextText ?? "Próximo");
  const [isNextVisible, setIsNextVisible] = useState(nextVisible ?? true);

  const [nextLoading, setNextLoading] = useState(isNextLoading ?? false);
  const [backLoading, setBackLoading] = useState(isBackLoading ?? false);

  useEffect(() => {
    setIsBackEnabled(backEnabled ?? true);
  }, [backEnabled]);

  useEffect(() => {
    setIsBackVisible(backVisible ?? true);
  }, [backVisible]);

  useEffect(() => {
    setBackButtonText(backText ?? "Voltar");
  }, [backText]);

  useEffect(() => {
    setIsNextEnabled(nextEnabled ?? true);
  }, [nextEnabled]);

  useEffect(() => {
    setNextButtonText(nextText ?? "Próximo");
  }, [nextText]);

  useEffect(() => {
    setIsNextVisible(nextVisible ?? true);
  }, [nextVisible]);

  useEffect(() => {
    setNextLoading(isNextLoading ?? false);
  }, [isNextLoading]);

  useEffect(() => {
    setBackLoading(isBackLoading ?? false);
  }, [isBackLoading]);

  const handleBackClick = async () => {
    if (onGoBack) {
      await onGoBack();
    }
  }

  const handleNextClick = async () => {
    if (onGoNext) {
      await onGoNext();
    }
  }

  return (
    <div className={clsx('d-flex flex-stack', className)}>
      <div className='me-2'>
        { isBackVisible &&
          <button
            type='button'
            className='btn btn-lg btn-light-primary me-3'
            onClick={handleBackClick}
            disabled={!isBackEnabled || nextLoading || backLoading}
          >
            { backLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                {` ${backButtonText}`}
              </span>
            )}

            { !backLoading && (
              <>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr063.svg'
                  className='svg-icon-3 ms-1 me-0'
                />
                {` ${backButtonText}`}
              </>
            )}
          </button>
        }
      </div>
      <div>
        { isNextVisible &&
          <button
            type='button'
            className='btn btn-lg btn-primary'
            onClick={handleNextClick}
            disabled={!isNextEnabled || nextLoading || isBackLoading}
          >
            { nextLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {`${nextButtonText} `}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}

            { !nextLoading && (
              <>
                {`${nextButtonText} `}
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-1 me-0'
                />
              </>
            )}
          </button>
        }
      </div>
    </div>
  )
}

export { BackAndNextButtons }

