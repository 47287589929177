/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { NotifyMe } from './components/NotifyMe'

const ComingSoon = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/unitedpalms-1/14.png')})`,
        backgroundColor: '#0c343d',
      }}
    >
      {/* begin::Content */}
      {/* begin::Wrapper */}
      <div className='d-flex flex-column flex-center flex-column-fluid'>
          <div className='d-flex flex-column flex-center text-center p-10'>
            <div className="card card-flush w-lg-650px py-5">
              <div className="card-body py-15 py-lg-20">                
                
							  <h1 className="fw-bolder text-gray-900 mb-7">Página em Construção</h1>
                <div className="fw-semibold fs-6 text-gray-500 mb-7">Em breve você encontrará novos produtos e serviços que trarão
							  <br />conforto e emoções a quem perdeu seu ente querido</div>

                <div className="mb-n10">
								  <img src={toAbsoluteUrl('/media/auth/chart-graph-dark.png')} className="mw-100 mh-300px" alt="" />								
							  </div>
							
              </div>
            </div>
          </div>
        </div>
        {/* end::Wrapper */}
      {/* end::Content */}      
    </div>
  )
}

const ComingSoonPage = () => (
  <Routes>
    <Route element={<ComingSoon />}>
      <Route index element={<NotifyMe />} />
    </Route>
  </Routes>
)

export { ComingSoonPage }

