import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { AssistenteStatusEnum, Falecimento, Memorial, MensagemMural, MensagemMuralStatusEnum, TipoMensagemEnum, TipoMensagemSelecionadaEnum, TipoRelacionamento } from "../../../../models";
import { useAuth } from "../../auth";
import { ICidadeEstado } from "../cidades/core/_models";
import { carregarCidadesFalecimento } from "../falecimentos/core/_requests";
import { postMensagemMural, salvarMensagemMuralFalecimentoFoto } from "../mural/core/_requests";
import { PerguntasHandler } from "../pergunta/PerguntasHandler";
import { AssistenteMensagemContextProps, AssistenteMensagemStepEnum, IAssistenteMensagemHandler, initAssistenteMensagemContextPropsState } from "./core/_models";
import { AssistenteMensagemHandler } from "./handlers/AssistenteMensagemHandler";
import usePhotoUpload from "./hooks/usePhotoUpload";
import { useStepFromLocation } from "./hooks/useStepFromLocation";

const AssistenteMensagemContext = createContext<AssistenteMensagemContextProps>(initAssistenteMensagemContextPropsState)

const useAssistenteMensagem = () => {
  return useContext(AssistenteMensagemContext)
}

type AssistenteMensagemProps = {
  children?: ReactNode
  handler: AssistenteMensagemHandler
}

const AssistenteMensagemProvider: FC<AssistenteMensagemProps> = ({children, handler}) => {
  const [ assistenteHandler ] = useState(handler);
  const [ currentStep, goToStep ] = useStepFromLocation();
  const [ falecimento ] = useState<Falecimento|undefined>(handler.falecimento)
  const [ memorial ] = useState<Memorial|undefined>(handler.memorial)
  const [ showAssistenteMensagem, setShowAssistenteMensagem ] = useState<boolean>(initAssistenteMensagemContextPropsState.showAssistenteMensagem)
  const [ isLoading, setIsLoading ] = useState<boolean>(initAssistenteMensagemContextPropsState.isLoading)
  const [ perguntasHandler, setPerguntasHandler ] = useState<PerguntasHandler>();
  const [ qtdRespostas ] = useState(initAssistenteMensagemContextPropsState.qtdRespostas);
  const { anonymousUser, saveAnonymousUser, currentUser } = useAuth();
  const [ inicioAssistenteViewed, setInicioAssistenteViewed ] = useState<boolean>(initAssistenteMensagemContextPropsState.inicioAssistenteViewed);
  const [ mensagemViewed, setMensagemViewed ] = useState<boolean>(initAssistenteMensagemContextPropsState.mensagemViewed);
  const [ cidades, setCidades ] = useState<ICidadeEstado[] | undefined>(initAssistenteMensagemContextPropsState.cidades);
  const [ isEditing, setIsEditing ] = useState(initAssistenteMensagemContextPropsState.isEditing);
  const [ novaInstrucao, setNovaInstrucao ] = useState<string|undefined>(initAssistenteMensagemContextPropsState.novaInstrucao);
  const [ mensagemMural, setMensagemMural ] = useState<MensagemMural|undefined>(initAssistenteMensagemContextPropsState.mensagemMural);

  const { photos, setPhotos, coverPhoto, setCoverPhoto, profilePhoto, setProfilePhoto, savePhotosToS3 } = usePhotoUpload()
  
  const iniciarAssistente = () =>{
    goToPage(AssistenteMensagemStepEnum.IDENTIFICACAO_USUARIO, assistenteHandler)
    setShowAssistenteMensagem(true);
  }

  const openAssistente = () => {
    setShowAssistenteMensagem(true);
  }
  
  const closeAssistente = () => {
    setShowAssistenteMensagem(false);
    goToPage(AssistenteMensagemStepEnum.POST, assistenteHandler)
  }

  const goToPage = useCallback((step: AssistenteMensagemStepEnum, assistente: IAssistenteMensagemHandler) => {
    assistente.atualizarStep(step);
    goToStep(step)
  }, [goToStep]);

  const editarFoto = () => {
    goToPage(AssistenteMensagemStepEnum.SELECIONAR_FOTO, assistenteHandler)
    openAssistente()
    setIsEditing(true)
  }

  const editarCidade = () => {
    goToPage(AssistenteMensagemStepEnum.SELECIONAR_CIDADE, assistenteHandler)
    openAssistente()
    setIsEditing(true)
  }

  const editarNome = () => {
    goToPage(AssistenteMensagemStepEnum.IDENTIFICACAO_USUARIO, assistenteHandler)
    openAssistente()
    setIsEditing(true)
  }

  const editarMensagem = () => {
    if (assistenteHandler.mensagemGerada) {
      goToPage(AssistenteMensagemStepEnum.MODELO_TEXTO, assistenteHandler)
    } else {
      goToPage(AssistenteMensagemStepEnum.MENSAGEM_USUARIO, assistenteHandler)
    }

    openAssistente()
    setIsEditing(true)
  }

  const selecionarCidade = async (cidade: ICidadeEstado) => {
    await assistenteHandler.salvarCidade(cidade);
    
    if (isEditing) {
      closeAssistente()
    }

    goToPage(AssistenteMensagemStepEnum.SELECIONAR_FOTO, assistenteHandler);
  }

  const runStepIdentificacaoUsuario = async (nome: string) => {
    setIsLoading(true)

    assistenteHandler.nomeUsuario = nome;
    assistenteHandler.anonymousUser = anonymousUser;

    await assistenteHandler.criarOuAtualizarAssistente().finally(() => {
      setIsLoading(false)

      if (isEditing) {
        closeAssistente()
      }
      
      goToPage(AssistenteMensagemStepEnum.RELACIONAMENTO_FALECIDO, assistenteHandler);
    });
  }

  const runSelectTipoRelacionamento = async(tipoRelacionamentoSelected: TipoRelacionamento) => {
    setIsLoading(true)

    assistenteHandler.tipoRelacionamento = tipoRelacionamentoSelected;
    
    await assistenteHandler.atualizarUsuarioTipoRelacionamento()
    .then(() => {
      goToPage(AssistenteMensagemStepEnum.INICIO_ASSISTENTE, assistenteHandler);
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const goToPostPage = () => {
    if (isEditing) {
      closeAssistente();
      return;
    }
    
    goToPage(AssistenteMensagemStepEnum.SELECIONAR_CIDADE, handler)
  }

  useEffect(() => {
    if (assistenteHandler.anonymousUser) {
      saveAnonymousUser(assistenteHandler.anonymousUser)
    }

  }, [assistenteHandler.anonymousUser, saveAnonymousUser])

  const salvarMensagemUsuario = async (mensagem: string ) => {
    setIsLoading(true);
    await assistenteHandler.salvarMensagemUsuario(mensagem)
    .finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    if (anonymousUser && assistenteHandler.tipoRelacionamento) {
      setPerguntasHandler(new PerguntasHandler(assistenteHandler, assistenteHandler.tipoRelacionamento, anonymousUser, qtdRespostas))
    }
  }, [ assistenteHandler, anonymousUser, assistenteHandler.tipoRelacionamento, qtdRespostas ])

  const getTipoMensagem = () => {
    if (!!assistenteHandler.mensagemGerada && !assistenteHandler.mensagemUsuario) return TipoMensagemEnum.GERADA;
    if (!!assistenteHandler.mensagemUsuario && !assistenteHandler.mensagemGerada) return TipoMensagemEnum.ESCRITA;
    
    return TipoMensagemEnum.GERADA_EDITADA;
  }

  const postarMensagemMural = async () => {
    if (!assistenteHandler.mensagem) return;
    if (!memorial) return;
    if (!falecimento) return;
    if (!assistenteHandler.cidadeEstado) return;
    if (!currentUser) return;
    if (!assistenteHandler.nomeUsuario) return;

    setIsLoading(true);

    const falecimentoFotos = await savePhotosToS3(falecimento, currentUser);

    const {error, data} = await postMensagemMural(
      assistenteHandler.mensagem,
      memorial,
      falecimento,
      assistenteHandler.cidadeEstado.cidade,
      currentUser,
      getTipoMensagem(),
      assistenteHandler.nomeUsuario,
      assistenteHandler.tipoMensagemSelecionada === TipoMensagemSelecionadaEnum.ESCRITA_USUARIO ? MensagemMuralStatusEnum.PENDING : MensagemMuralStatusEnum.APPROVED,
    ).finally(() => {
      setIsLoading(false);
    })

    if (!error && data && falecimentoFotos.length > 0) {
      for (let index = 0; index < falecimentoFotos.length; index++) {
        const falecimentoFoto = falecimentoFotos[index];
        
        await salvarMensagemMuralFalecimentoFoto(falecimentoFoto, data);
      }
    }

    if (!error && data){
      await assistenteHandler.atualizarStatus(AssistenteStatusEnum.COMPLETED);
      setMensagemMural(data);
    }
  }

  useEffect(() => {
    if (currentStep === AssistenteMensagemStepEnum.GERAR_MENSAGEM) {
      if (assistenteHandler.mensagemGerada) {
        goToPage(AssistenteMensagemStepEnum.EXIBIR_MENSAGEM, assistenteHandler)
      }
    }
  }, [currentStep, assistenteHandler, goToPage])

  useEffect(() => {
    const handleIsGerandoMensagemChange = (newValue: boolean) => {
      if (newValue) {
        goToPage(AssistenteMensagemStepEnum.GERAR_MENSAGEM, assistenteHandler)
      }
    };

    assistenteHandler.assistenteEventEmitter.on('isGerandoMensagemChange', handleIsGerandoMensagemChange);

    return () => {
      assistenteHandler.assistenteEventEmitter.off('isGerandoMensagemChange', handleIsGerandoMensagemChange);
    };
  }, [goToPage, assistenteHandler.assistenteEventEmitter, assistenteHandler]);

  useEffect(() => {
    const handleIsErroConexaoChange = (newValue: boolean) => {
      if (newValue) {
        goToPage(AssistenteMensagemStepEnum.ERRO_CONEXAO_AI, assistenteHandler)
      }
    };

    assistenteHandler.assistenteEventEmitter.on('isErroConexao', handleIsErroConexaoChange);

    return () => {
      assistenteHandler.assistenteEventEmitter.off('isErroConexao', handleIsErroConexaoChange);
    };
  }, [goToPage, assistenteHandler.assistenteEventEmitter, assistenteHandler]);

  useEffect(() => {
    const handleIsMensagemGeradaChange = (newValue: boolean) => {
      if (newValue) {
        goToPage(AssistenteMensagemStepEnum.EXIBIR_MENSAGEM, assistenteHandler)
      }
    };

    assistenteHandler.assistenteEventEmitter.on('isMensagemGerada', handleIsMensagemGeradaChange);

    return () => {
      assistenteHandler.assistenteEventEmitter.off('isMensagemGerada', handleIsMensagemGeradaChange);
    };
  }, [goToPage, assistenteHandler.assistenteEventEmitter, assistenteHandler]);

  useEffect(() => {
    if (falecimento && currentStep === AssistenteMensagemStepEnum.SELECIONAR_CIDADE) {
      setIsLoading(true);
      carregarCidadesFalecimento(falecimento)
      .then(result => {
        setCidades(result)
      })
      .finally(() => {
        setIsLoading(false);
      })
    }
  }, [currentStep, falecimento])

  useEffect(() => {
    if (currentUser && assistenteHandler.assistenteMensagem && !assistenteHandler.assistenteMensagem.assistenteMensagemUserId) {
      assistenteHandler.salvarUsuario(currentUser).then(() => {});
    }
  }, [currentUser, assistenteHandler])
    
  useEffect(() => {
    if (assistenteHandler.step === AssistenteMensagemStepEnum.POST) {
      setShowAssistenteMensagem(false);
      return;
    }
  }, [assistenteHandler.step])

  // useEffect(() => {
  //   const restartRetriveRun = async () => {
  //     if (await assistenteHandler.restartRetrieveRun() === true) {
  //       goToPage(AssistenteMensagemStepEnum.GERAR_MENSAGEM, assistenteHandler)
  //     }
  //   }
    
  //   if (currentStep === AssistenteMensagemStepEnum.MODELO_TEXTO &&
  //       assistenteHandler.openAiRun?.status === 'queued' &&
  //       assistenteHandler.generatingMessage === false) {

  //       restartRetriveRun();
  //   }
  // }, [currentStep, assistenteHandler, goToPage])

  return (
    <AssistenteMensagemContext.Provider value={
        {
          handler: assistenteHandler,
          goToPage,
          iniciarAssistente,
          falecimento,
          memorial,
          anonymousUser,
          perguntasHandler,
          qtdRespostas,
          showAssistenteMensagem: showAssistenteMensagem,
          openAssistente,
          closeAssistente,
          isLoading,
          salvarMensagemUsuario,
          runStepIdentificacaoUsuario,
          runSelectTipoRelacionamento,
          inicioAssistenteViewed,
          setInicioAssistenteViewed,
          mensagemViewed,
          setMensagemViewed,
          cidades,
          selecionarCidade,
          photos,
          setPhotos,
          coverPhoto,
          setCoverPhoto,
          profilePhoto,
          setProfilePhoto,
          isEditing,
          editarFoto,
          editarCidade,
          editarNome,
          editarMensagem,
          novaInstrucao,
          setNovaInstrucao,
          postarMensagemMural,
          mensagemMural,
          goToPostPage,
        }
      }>
        {children}
    </AssistenteMensagemContext.Provider>
  )
}

export { AssistenteMensagemProvider, AssistenteMensagemStepEnum, useAssistenteMensagem };

