import clsx from "clsx";
import { FC, useEffect } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import "react-perfect-scrollbar/dist/css/styles.css";
import { SexoEnum, TipoRelacionamento } from "../../../../../models";
import { useTipoRelacionamento } from "../TipoRelaciomentoProvider";

type Props = {
  onSelectTipoRelacionamento(tipoRelacionamento: TipoRelacionamento) :  void
}

const TipoRelacionamentoItems: FC<Props> = ( { onSelectTipoRelacionamento } ) => {
  const { tiposRelacionamentos, tipoRelacionamentoSelected, setTipoRelacionamentoSelected, isLoading } = useTipoRelacionamento()

  const handleTipoRelacionamentoClick = (tipo: TipoRelacionamento) => {
    setTipoRelacionamentoSelected(tipo);
  };

  useEffect(() => {
    if (tipoRelacionamentoSelected) {
      onSelectTipoRelacionamento(tipoRelacionamentoSelected)
    }

  }, [ tipoRelacionamentoSelected, onSelectTipoRelacionamento ])

  return (
    <>
      { tiposRelacionamentos.length > 0 &&
        <PerfectScrollbar style={{ maxHeight: '50vh' }}>
          <div className="d-flex flex-wrap">
            {tiposRelacionamentos.map((tipo, index) => (
              <div key={index} className="col-6 mb-2 p-2">
                <button
                  className={
                    clsx(
                      "btn w-100",
                      tipo.sexo && tipo.sexo === SexoEnum.MASCULINO && "btn-primary",
                      tipo.sexo && tipo.sexo === SexoEnum.FEMININO && "btn-warning text-dark fw-bold",
                      !tipo.sexo && "btn-info"
                    )}
                  onClick={() => handleTipoRelacionamentoClick(tipo)}
                >
                  {tipo.titulo}
                </button>
              </div>
            ))}
          </div>
        </PerfectScrollbar>
      }

      { tiposRelacionamentos.length === 0 && !isLoading &&
        <div className='d-flex text-center w-100 align-content-center justify-content-center fs-4 mt-4'>
          Nenhum item encontrado
        </div>
      }
    </>
  )
}

export { TipoRelacionamentoItems };

