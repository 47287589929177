import { Dispatch, SetStateAction } from "react"
import { TamanhoMensagemEnu, TipoPessoaEnum } from "../../../../../API"
import { Photo } from "../../../../../_lapide/components/photos/PhotoModels"
import { AnonymousUser, AssistenteMensagem, AssistenteStatusEnum, Falecimento, Memorial, MensagemMural, TipoMensagemSelecionadaEnum, TipoRelacionamento } from "../../../../../models"
import { ICidadeEstado } from "../../cidades/core/_models"
import { PerguntasHandler } from "../../pergunta/PerguntasHandler"

export enum AssistenteMensagemStepEnum {
  IDENTIFICACAO_USUARIO = 1,
  RELACIONAMENTO_FALECIDO = 2,
  MENSAGEM_USUARIO = 3,
  INICIO_ASSISTENTE = 4,
  PERGUNTAS = 5,
  ASSISTENTE_MENSAGEM_USUARIO = 6,
  MODELO_TEXTO = 7,
  GERAR_MENSAGEM = 8,
  EXIBIR_MENSAGEM = 9,
  SELECIONAR_CIDADE = 10,
  SELECIONAR_FOTO = 11,
  POST = 98,
  MENSAGEM_POSTADA = 99,
  ERRO_CONEXAO_AI = 503,
}

export const enumRouteMap: { [key in AssistenteMensagemStepEnum]: string } = {
  [AssistenteMensagemStepEnum.IDENTIFICACAO_USUARIO]: 'init',
  [AssistenteMensagemStepEnum.RELACIONAMENTO_FALECIDO]: 'relacionamento',
  [AssistenteMensagemStepEnum.MENSAGEM_USUARIO]: 'mensagem-escrita',
  [AssistenteMensagemStepEnum.INICIO_ASSISTENTE]: 'selecao',
  [AssistenteMensagemStepEnum.PERGUNTAS]: 'perguntas',
  [AssistenteMensagemStepEnum.ASSISTENTE_MENSAGEM_USUARIO]: 'assistente-mensagem-escrita',
  [AssistenteMensagemStepEnum.MODELO_TEXTO]: 'modelo-texto',
  [AssistenteMensagemStepEnum.GERAR_MENSAGEM]: 'gerar-mensagem',
  [AssistenteMensagemStepEnum.EXIBIR_MENSAGEM]: 'exibir-mensagem',
  [AssistenteMensagemStepEnum.SELECIONAR_CIDADE]: 'cidade',
  [AssistenteMensagemStepEnum.SELECIONAR_FOTO]: 'foto',
  [AssistenteMensagemStepEnum.POST]: 'post',
  [AssistenteMensagemStepEnum.MENSAGEM_POSTADA]: 'mensagem-postada',
  [AssistenteMensagemStepEnum.ERRO_CONEXAO_AI]: 'erro-conexao-ai',
};

export const getRouteFromEnum = (step: AssistenteMensagemStepEnum): string => {
  return enumRouteMap[step];
};

export type AssistenteMensagemContextProps = {
  handler: IAssistenteMensagemHandler,
  goToPage(step: AssistenteMensagemStepEnum, assistente: IAssistenteMensagemHandler) : void;
  iniciarAssistente: () => void
  falecimento?: Falecimento,
  memorial?: Memorial,
  anonymousUser?: AnonymousUser,
  tipoRelacionamento?: TipoRelacionamento,
  perguntasHandler: PerguntasHandler | undefined,
  qtdRespostas: number,
  showAssistenteMensagem: boolean,
  openAssistente: () => void,
  closeAssistente: () => void,
  isLoading: boolean,
  salvarMensagemUsuario: (mensagem: string) => Promise<void>,
  runStepIdentificacaoUsuario: (nome: string) => Promise<void>,
  runSelectTipoRelacionamento: (tipoRelacionamentoSelected: TipoRelacionamento) => Promise<void>,
  inicioAssistenteViewed: boolean,
  setInicioAssistenteViewed: Dispatch<SetStateAction<boolean>>,
  cidades: ICidadeEstado[] | undefined,
  selecionarCidade: (cidade: ICidadeEstado) => Promise<void>,
  mensagemViewed: boolean,
  setMensagemViewed: Dispatch<SetStateAction<boolean>>,
  photos: Photo[],
  setPhotos: Dispatch<SetStateAction<Photo[]>>,
  coverPhoto: Photo|null,
  setCoverPhoto: Dispatch<SetStateAction<Photo|null>>,
  profilePhoto: Photo|null,
  setProfilePhoto: Dispatch<SetStateAction<Photo|null>>,
  isEditing: boolean,
  editarFoto: () => void,
  editarCidade: () => void,
  editarNome: () => void,
  editarMensagem: () => void,
  novaInstrucao: string | undefined,
  setNovaInstrucao: Dispatch<SetStateAction<string|undefined>>,
  postarMensagemMural: () => Promise<void>,
  mensagemMural: MensagemMural|undefined,
  goToPostPage: () => void,
}

export const initAssistenteMensagemContextPropsState = {
  handler: {} as IAssistenteMensagemHandler,
  goToPage: (step: AssistenteMensagemStepEnum) => {},
  iniciarAssistente: () => {},
  falecimento: undefined,
  memorial: undefined,
  anonymousUser: undefined,
  tipoRelacionamento: undefined,
  perguntasHandler: undefined,
  qtdRespostas: 5,
  showAssistenteMensagem: true,
  openAssistente: () => {},
  closeAssistente: () => {},
  isLoading: false,
  salvarMensagemUsuario: async () => {},
  runStepIdentificacaoUsuario: async () => {},
  runSelectTipoRelacionamento: async (tipoRelacionamentoSelected: TipoRelacionamento) => {},
  inicioAssistenteViewed: false,
  setInicioAssistenteViewed: () => {},
  cidades: undefined,
  selecionarCidade: async () => {},
  mensagemViewed: false,
  setMensagemViewed: async () => {},
  photos: [],
  setPhotos: () => {},
  coverPhoto: null,
  setCoverPhoto: () => {},
  profilePhoto: null,
  setProfilePhoto: () => {},
  isEditing: false,
  editarFoto: () => {},
  editarCidade: () => {},
  editarNome: () => {},
  editarMensagem: () => {},
  novaInstrucao: undefined,
  setNovaInstrucao: () => {},
  executarNovaInstrucao: async () => {},
  postarMensagemMural: async () => {},
  mensagemMural: undefined,
  goToPostPage: () => {},
}

export interface IAssistenteMensagemHandler {
  nomeUsuario: string | undefined;
  anonymousUser: AnonymousUser | null | undefined;
  tipoRelacionamento: TipoRelacionamento | null | undefined;
  cidadeEstado: ICidadeEstado | null | undefined;
  tamanhoMensagem: TamanhoMensagemEnu;
  tipoPessoa: TipoPessoaEnum;
  emNomeDe: string|undefined;
  instrucao: string|undefined;
  tipoMensagemSelecionada: TipoMensagemSelecionadaEnum;
  
  readonly mensagem: string | null | undefined;
  readonly mensagemUsuario: string | null | undefined;
  readonly mensagemGerada: string | null | undefined;
  readonly mensagemReescrita: string | null | undefined;
  readonly mensagemCorrigida: string | null | undefined;
  readonly mensagemEditadaManual: string | null | undefined;

  readonly assistenteMensagem: AssistenteMensagem | undefined;
  readonly falecimento: Falecimento;
  readonly memorial: Memorial;

  criarOuAtualizarAssistente(): Promise<void>;
  atualizarUsuarioTipoRelacionamento(): Promise<void>;
  salvarMensagemUsuario(mensagemUsuario: string): Promise<void>;
  salvarMensagemEditadaManual(texto: string): Promise<void>;
  salvarCidade(cidadeEstado: ICidadeEstado): Promise<void>;
  salvarModeloTexto(tamanhoMensagem: TamanhoMensagemEnu, tipoPessoa: TipoPessoaEnum, emNomeDe: string | undefined, instrucao: string | undefined) : Promise<void>;
  atualizarStatus(status: AssistenteStatusEnum) : Promise<void>;
  atualizarStep(step: AssistenteMensagemStepEnum) : Promise<void>;
  fazerCorrecoesGramaticaOrtografia(): Promise<void>;
  reescreverMensagemUsuario(): Promise<void>;
  gerarMensagemPelasRepostas(): Promise<void>;
  executarNovaInstrucao(): Promise<void>;

  getApelidoOuNomeFalecido(): string;
  getPerguntaTipoRelacionamento(): string;
}