import { GraphQLQuery } from '@aws-amplify/api';
import { Storage } from "@aws-amplify/storage";
import { API, DataStore } from "aws-amplify";
import moment from 'moment';
import { ListMensagemMuralsQueryVariables } from "../../../../../API";
import { DataStoreQuery } from "../../../../../_metronic/helpers/crud-helper/data-store-query";
import { customSyncMensagemMurals } from '../../../../../graphql/my-queries';
import { MensagemMural, MensagemMuralStatusEnum, User } from '../../../../../models';
import { decrementarFalecimentoCidade } from '../../falecimentos/core/_requests';
import { CustomSyncMensagemMuralsQuery } from './_models';

const queryMensagens = async (falecimentoId: string, dataStoreQuery: DataStoreQuery): Promise<CustomSyncMensagemMuralsQuery> => {
  const variables: ListMensagemMuralsQueryVariables = {
    nextToken: dataStoreQuery.nextToken,
    limit: dataStoreQuery.limit,
    filter: {
      mensagemMuralFalecimentoId: { eq: falecimentoId },
      _deleted: { ne: true }
    }
  }

  const mensagens = await API.graphql<GraphQLQuery<CustomSyncMensagemMuralsQuery>>(
    {
      query: customSyncMensagemMurals,
      variables
    }
  )

  const enrichedMensagens = await Promise.all(
    mensagens.data?.syncMensagemMurals?.items.map(async (mensagem) => {
      if (mensagem.User && mensagem.User.imageKey) {
        mensagem.User.urlImage = await mapUserUrl(mensagem.User.imageKey);
      }
      return mensagem;
    }) || []
  );

  return {
    ...mensagens.data,
    syncMensagemMurals: {
      ...mensagens.data?.syncMensagemMurals,
      items: enrichedMensagens,
    },
  } as CustomSyncMensagemMuralsQuery;
}

const mapUserUrl = async(imageKey?: string): Promise<string|undefined> => {
  return imageKey ? await Storage.get(imageKey, { level: "public" } ) : undefined
}

const updateStatusMensagemById = async(mensagelMuralId: string, user: User, newStatus: MensagemMuralStatusEnum) => {
  const mensagemMural = await DataStore.query(MensagemMural, mensagelMuralId);

  if (mensagemMural) {
    await updateStatusMensagem(mensagemMural.id, user, newStatus)
  }
}

const updateStatusMensagem = async(mensagelMuralId: string, user: User, newStatus: MensagemMuralStatusEnum) => {
  const mensagemMural = await DataStore.query(MensagemMural, mensagelMuralId);

  if (mensagemMural) {
    if (newStatus === MensagemMuralStatusEnum.APPROVED) {
      await DataStore.save(
        MensagemMural.copyOf(mensagemMural, updated => {
          updated.AprovadoPor = user;
          updated.mensagemMuralAprovadoPorId = user.id;
          updated.dataAprovacao = moment().format();
          updated.status = newStatus;
      }))
    } else {
      await DataStore.save(
        MensagemMural.copyOf(mensagemMural, updated => {
          updated.status = newStatus;
      }))
    }

    if (newStatus === MensagemMuralStatusEnum.DELETED) {
      const falecimento = await mensagemMural.Falecimento;
      const cidade = await mensagemMural.Cidade;

      if (falecimento && cidade){
        await decrementarFalecimentoCidade(falecimento, cidade)
      }
    }
  }

  
}

export { queryMensagens, updateStatusMensagem, updateStatusMensagemById };

