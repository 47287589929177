export const criarResumo = (texto: string, tamanho: number) : string => {
  if (texto.length < tamanho) return texto;

  const extractedText = texto.substring(0, tamanho);
  const words = extractedText.match(/\b\w+\b/g);
  const charactersWithWholeWords = words ? words.join('').length : 0;

  if (charactersWithWholeWords > 0) {
    return texto.substring(0, charactersWithWholeWords) + "...";
  }

  return extractedText + "...";
}

export const removeQuotes = (text: string | null | undefined) : string | null | undefined => {
  if (text && text.startsWith('"') && text.endsWith('"')) {
      return text.slice(1, -1);
  }
  return text;
}