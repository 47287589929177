import { FC } from "react"
import { useLoginFlow } from "../LoginFlow"

type Props = {
  labelEmail?: string
}

const LoginStep1Email: FC<Props> = ({ labelEmail =  'Qual seu email?'}) => {
  const { isLoading, email, setEmail, isEmailValid } = useLoginFlow();
  
  return (
    <div className='w-100'>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>{labelEmail}</span>
        </label>
        <input
          type="text"
          className='form-control form-control-lg form-control-solid'
          name='email'
          placeholder='Ex: nome@dominio.com'
          autoComplete='off'
          onChange={(e) => setEmail( e.target.value )}
          disabled={isLoading}
          value={email}
        />
        { isEmailValid === false && email && email.includes("@") && email.includes(".") && (
          <div className='fv-plugins-message-container'>
            <div data-field='email' className='fv-help-block'>
              Informe um e-mail válido
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export { LoginStep1Email }

