import { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { IconType } from 'react-icons';
import { FaPaperPlane } from 'react-icons/fa';

export type Action = {
  name: string;
  handler: () => void;
  icon: IconType;
};

type Props = {
  mensagem: string;
  assistenteMensagemViewed?: boolean;
  setAssistenteMensagemViewed?: () => void;
  actions: Action[];
  onPostarClick: () => Promise<void>;
};

const LeitorMensagem: FC<Props> = ({ mensagem, assistenteMensagemViewed, setAssistenteMensagemViewed, actions, onPostarClick }) => {
  const [texto, setTexto] = useState('');
  const [mostrarBotoes, setMostrarBotoes] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const intervalo = 50;
  let contador = 0;

  const handleClick = () => {
    if (contador < mensagem.length) {
      contador = mensagem.length;
      setTexto(mensagem);
      setMostrarBotoes(true);

      if (setAssistenteMensagemViewed) {
        setAssistenteMensagemViewed();
      }
    }
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    const updateAssistenteMensagemViewed = () => {
      if (setAssistenteMensagemViewed) {
        setAssistenteMensagemViewed();
      }
    };

    if (!mostrarBotoes) {
      if (assistenteMensagemViewed === true) {
        setTexto(mensagem);
        setMostrarBotoes(true);
        updateAssistenteMensagemViewed();
        return;
      }

      const timer = setInterval(() => {
        if (contador <= mensagem.length) {
          setTexto(mensagem.substring(0, contador));
          contador++;
        } else {
          clearInterval(timer);
          setMostrarBotoes(true);
          updateAssistenteMensagemViewed();
        }
      }, intervalo);

      return () => clearInterval(timer);
    }

    if (mostrarBotoes) {
      setTexto(mensagem);
    }
  }, [contador, mensagem, mostrarBotoes, assistenteMensagemViewed, setAssistenteMensagemViewed]);

  return (
    <div className="d-flex flex-column" onClick={handleClick}>
      <div className="card flex-grow-1">
        <div className="card-body">
          <div className="d-flex text-center justify-content-center align-items-center vh-50">
            <p className="fs-2">{texto.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}</p>
          </div>
        </div>
      </div>
      {mostrarBotoes && (
        <div className="p-3 d-flex flex-stack mb-4">
          <Button variant="secondary" className='fs-3' onClick={handleShow}>
            Opções
          </Button>

          <Button variant="primary" className="d-flex align-items-center fs-3" onClick={onPostarClick}>
            <FaPaperPlane className="me-2" />
            Postar
          </Button>

          <Modal show={showModal} onHide={handleClose} className="modal-bottom">
            <Modal.Header closeButton>
              <Modal.Title>Opções</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul>
                {actions.map((action, index) => (
                  <li key={index}>
                    <Button
                      className="d-flex align-items-center fs-2 w-100"
                      onClick={() => {
                        action.handler();
                        handleClose();
                      }}
                      variant="secondary"
                    >
                      <action.icon className="me-2" />
                      {action.name}
                    </Button>
                  </li>
                ))}
              </ul>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button variant="secondary" onClick={handleClose} className="fs-3 px-5">
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>

        </div>
      )}
    </div>
  );
};

export { LeitorMensagem };

