import { Storage } from "@aws-amplify/storage"
import clsx from "clsx"
import { FC, useEffect, useState } from "react"
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers"
import { Falecimento } from "../../../../../../models"

type Props = {
  falecimento: Falecimento
  className?: string
}

const MemorialHeaderSM : FC<Props> = ( { falecimento, className } ) => {
  const [ profileUrl, setProfileUrl ] = useState<string>(toAbsoluteUrl('/media/avatars/blank.png'))

  const loadOriginalUrl = async (falecimento?: Falecimento) => {
    if (falecimento && falecimento.imageKey){
      const imgUrl = await Storage.get(falecimento.imageKey, { level: "public" } )
      
      setProfileUrl(imgUrl)
      return
    }

    setProfileUrl(toAbsoluteUrl('/media/avatars/blank.png'))
  }

  useEffect(() => {
    loadOriginalUrl(falecimento)
  }, [falecimento])

  function handleClick() {
    const mainContent = document.getElementById("main-content");

    if (mainContent) {
      mainContent.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  return  (
    <div className={clsx('card mb-5 mb-xl-10 card position-fixed top-5 start-0 w-100', className)} style={{ zIndex: 2 }} onClick={handleClick}>
      <div className='card-body pt-2 pb-2'>
        <div className='d-flex align-items-center ms-n2 justify-content-center'>
          <div className='symbol symbol-40px me-2'>
            <img src={profileUrl} alt='profile' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <label className='text-dark fw-bold text-hover-primary fs-5'>
              {`${falecimento.nome} ${falecimento.sobrenome} ${falecimento.apelido ? `(${falecimento.apelido})` : ''}`}
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export { MemorialHeaderSM }

