import { FC, useEffect, useState } from "react";
import { MensagemMural } from "../../../../../../models";
import { getMensagemMuralById } from "../../../mural/core/_requests";
import { useMensagemMural } from "../../../mural/hooks/useMensagemMural";
import { PostFeedModel, PostProps } from "../../core/_models";
import PostHomenagemView from "./PostHomenagemView";

const PostHomenagem : FC<PostProps> = ( { post } ) => {
  const [ mensagemMural, setMensagemMural ] = useState<MensagemMural>()

  useEffect(() => {
    const loadMensagemMural = async (post: PostFeedModel) => {
      const mensagem = await getMensagemMuralById(post.resourceId);
      setMensagemMural(mensagem);
    }

    loadMensagemMural(post)
  }, [post])

  const { coverPhotoUrl, profilePhotoUrl, nomeUsuario, cidadeEstado, mensagemMuralLink, dataMensagem, nomeHomenageado, urlMural, urlObituario } = useMensagemMural(mensagemMural);

  return (
    <>
      { mensagemMural &&
        <PostHomenagemView
          authorName={nomeUsuario}
          authorPhoto={profilePhotoUrl}
          location={cidadeEstado}
          moreLink={mensagemMuralLink}
          postDateTime={dataMensagem}
          receiverName={nomeHomenageado}
          tributePhoto={coverPhotoUrl}
          tributeText={mensagemMural.resumo ?? mensagemMural.mensagem}
          urlMural={urlMural}
          urlObituario={urlObituario}
        />
      }
    </>
  )
}

export { PostHomenagem };
