import { useEffect } from "react"
import { usePergunta } from "../PerguntaProvider"

const RespostaPoucasPalavras = () => {
  const { textoLivre, setTextoLivre, errorMessage, setIsFormValido } = usePergunta()

  useEffect(() => {
    setIsFormValido(false)
    
    if (textoLivre && textoLivre.trim().length > 0) {
      setIsFormValido(true)
    }

  }, [ textoLivre, setIsFormValido ])


  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <input
            type="text"
            autoComplete='off'
            placeholder=''
            className='form-control text-center fs-1 ls-4'
            onChange={(e) => setTextoLivre(e.target.value)}
            value={textoLivre}
          />
          { errorMessage && (
            <div className='fv-plugins-message-container'>
              <div data-field='mensagem' data-validator='notEmpty' className='fv-help-block'>
                {errorMessage}
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export { RespostaPoucasPalavras }

