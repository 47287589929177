export enum ResultTypeEnum {
  GENERATE = 0,
  MOCK_SUCCESS = 1,
  MOCK_ERROR = 2,
}

export const ResultType : ResultTypeEnum = ResultTypeEnum.GENERATE;
export interface IOpenAiAssistant {
  id: string;
  object: string;
  created_at: number;
  name: string | null;
  description: string | null;
  model: string;
  instructions: string;
}

export interface IOpenAiThread {
  id: string;
  object: string;
  created_at: number;
}

export interface IOpenAiRun {
  id: string;
  object: string;
  created_at: number;
  assistant_id: string;
  thread_id: string;
  status: string;
  started_at: number;
  expires_at: number | null;
  cancelled_at: number | null;
  failed_at: number | null;
  completed_at: number | null;
  last_error: OpenAiError | null;
  model: string;
  instructions: string | null;
  tools: OpenAiTool[];
  file_ids: string[];
}

interface OpenAiTool {
  type: string;
}

interface OpenAiError {
  code: string;
  message: string;
}

export interface IOpenAiMessages {
  object: string;
  data: ThreadMessage[]
  first_id: string;
  last_id: string;
  has_more: boolean;
}

interface ThreadMessage {
  id: string;
  object: string;
  created_at: number;
  thread_id: string;
  role: string;
  content: Content[];
  file_ids: string[];
  assistant_id: string | null;
  run_id: string | null;
}

interface Content {
  type: string;
  text: {
    value: string;
    annotations: any[];
  };
}

export const threadRunError = {
  "id": "run_4EgA7nfx2NJVipoRanEh125U",
  "object": "thread.run",
  "created_at": 1707929597,
  "assistant_id": "asst_aqciOeKPWIhCzqMFiNjZZd8B",
  "thread_id": "thread_DD3JVVLcXg0Crvi8AkqPIcDV",
  "status": "failed",
  "started_at": 1707929597,
  "expires_at": null,
  "cancelled_at": null,
  "failed_at": 1707929597,
  "completed_at": null,
  "last_error": {
    "code": "server_error",
    "message": "Sorry, something went wrong."
  },
  "model": "gpt-3.5-turbo-1106",
  "instructions": "Você é um assistente que ajuda as pessoas a escreverem mensagens para o memorial de falecimento de Floduardo. Você utiliza informações passadas pelo usuário para gerar a mensagem personalizada. O que sabemos de Floduardo: Nasceu em 1952-06-05; Faleceu em 2023-02-10; Residia na cidade de Leme/SP; Seu apelido era Flô.",
  "tools": [
    {
      "type": "retrieval"
    }
  ],
  "file_ids": [],
  "metadata": {},
  "usage": {
    "prompt_tokens": 0,
    "completion_tokens": 0,
    "total_tokens": 0
  }
} as IOpenAiRun

export const threadRunning = {
  "id": "run_OK0IgwoWsFyrYkPr1E7J8Eir",
  "object": "thread.run",
  "created_at": 1710017333,
  "assistant_id": "asst_o5n5scALSN2UUcmmwMP841KZ",
  "thread_id": "thread_QUDhf9Y9nXBRfeK9GMHj7DR2",
  "status": "running",
  "started_at": 1710017333,
  "expires_at": null,
  "cancelled_at": null,
  "failed_at": null,
  "completed_at": null,
  "required_action": null,
  "last_error": null,
  "model": "gpt-4",
  "instructions": "Você é um assistente que ajuda as pessoas a escreverem mensagens para o memorial de falecimento de Floduardo. Você utiliza informações passadas pelo usuário para gerar a mensagem personalizada. O que sabemos de Floduardo: Nasceu em 1952-06-05; Faleceu em 2023-02-10; Residia na cidade de Leme/SP; Seu apelido era Flô.",
  "tools": [],
  "file_ids": [],
  "metadata": {},
  "usage": {
      "prompt_tokens": 262,
      "completion_tokens": 298,
      "total_tokens": 560
  }
} as IOpenAiRun

export const threadRunCompleted = {
  "id": "run_OK0IgwoWsFyrYkPr1E7J8Eir",
  "object": "thread.run",
  "created_at": 1710017333,
  "assistant_id": "asst_o5n5scALSN2UUcmmwMP841KZ",
  "thread_id": "thread_QUDhf9Y9nXBRfeK9GMHj7DR2",
  "status": "completed",
  "started_at": 1710017333,
  "expires_at": null,
  "cancelled_at": null,
  "failed_at": null,
  "completed_at": 1710017342,
  "required_action": null,
  "last_error": null,
  "model": "gpt-4",
  "instructions": "Você é um assistente que ajuda as pessoas a escreverem mensagens para o memorial de falecimento de Floduardo. Você utiliza informações passadas pelo usuário para gerar a mensagem personalizada. O que sabemos de Floduardo: Nasceu em 1952-06-05; Faleceu em 2023-02-10; Residia na cidade de Leme/SP; Seu apelido era Flô.",
  "tools": [],
  "file_ids": [],
  "metadata": {},
  "usage": {
      "prompt_tokens": 262,
      "completion_tokens": 298,
      "total_tokens": 560
  }
} as IOpenAiRun

export const openAiMessageLorenIpsum = {
    "object": "list",
    "data": [
        {
            "id": "msg_l7W7AUuJUuUZnr1P2jCzKt4j",
            "object": "thread.message",
            "created_at": 1710017335,
            "assistant_id": "asst_o5n5scALSN2UUcmmwMP841KZ",
            "thread_id": "thread_QUDhf9Y9nXBRfeK9GMHj7DR2",
            "run_id": "run_OK0IgwoWsFyrYkPr1E7J8Eir",
            "role": "assistant",
            "content": [
                {
                    "type": "text",
                    "text": {
                        "value": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus luctus ex est, vel tempor augue pretium id. Duis ut tristique lacus, nec interdum felis. Duis venenatis velit in laoreet euismod. Nullam malesuada, dolor et hendrerit egestas, est dolor sodales ante, id viverra neque lorem at turpis. Donec eleifend eros felis, vel varius risus tempor eget. Curabitur eu sollicitudin ex. In aliquam malesuada consequat. Fusce egestas venenatis sodales. Maecenas sit amet quam ac turpis blandit sodales at eu quam. Morbi ligula orci, laoreet et lectus sed, elementum maximus nisi. Curabitur pellentesque mauris ac justo elementum gravida. Donec cursus leo vitae felis tincidunt congue. Duis vitae nisi faucibus, venenatis leo sit amet, rhoncus velit. Nunc sodales mollis aliquet. Quisque tempor blandit ante ut fermentum. Fusce neque est, rutrum quis pellentesque quis, sollicitudin quis mi. Cras a ipsum et tellus efficitur laoreet. Vivamus pharetra sodales metus non fringilla. Duis venenatis risus id varius mollis. Etiam mauris diam, iaculis id eros eu, elementum blandit arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Quisque ornare metus eget augue laoreet, at efficitur quam mollis.",
                        "annotations": []
                    }
                }
            ],
            "file_ids": [],
            "metadata": {}
        },
        {
            "id": "msg_Ng9UvcUwe5DBAPRFRDSZgaYA",
            "object": "thread.message",
            "created_at": 1710017333,
            "assistant_id": null,
            "thread_id": "thread_QUDhf9Y9nXBRfeK9GMHj7DR2",
            "run_id": null,
            "role": "user",
            "content": [
                {
                    "type": "text",
                    "text": {
                        "value": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus luctus ex est, vel tempor augue pretium id. Duis ut tristique lacus, nec interdum felis. Duis venenatis velit in laoreet euismod. Nullam malesuada, dolor et hendrerit egestas, est dolor sodales ante, id viverra neque lorem at turpis. Donec eleifend eros felis, vel varius risus tempor eget. Curabitur eu sollicitudin ex. In aliquam malesuada consequat. Fusce egestas venenatis sodales. Maecenas sit amet quam ac turpis blandit sodales at eu quam. Morbi ligula orci, laoreet et lectus sed, elementum maximus nisi. Curabitur pellentesque mauris ac justo elementum gravida. Donec cursus leo vitae felis tincidunt congue. Duis vitae nisi faucibus, venenatis leo sit amet, rhoncus velit. Nunc sodales mollis aliquet. Quisque tempor blandit ante ut fermentum. Fusce neque est, rutrum quis pellentesque quis, sollicitudin quis mi. Cras a ipsum et tellus efficitur laoreet. Vivamus pharetra sodales metus non fringilla. Duis venenatis risus id varius mollis. Etiam mauris diam, iaculis id eros eu, elementum blandit arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Quisque ornare metus eget augue laoreet, at efficitur quam mollis.",
                        "annotations": []
                    }
                }
            ],
            "file_ids": [],
        }
    ],
    "first_id": "msg_l7W7AUuJUuUZnr1P2jCzKt4j",
    "last_id": "msg_Ng9UvcUwe5DBAPRFRDSZgaYA",
    "has_more": false
} as IOpenAiMessages;