/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPostImage = /* GraphQL */ `query GetPostImage($id: ID!) {
  getPostImage(id: $id) {
    id
    imageKey
    context
    postID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPostImageQueryVariables,
  APITypes.GetPostImageQuery
>;
export const listPostImages = /* GraphQL */ `query ListPostImages(
  $filter: ModelPostImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPostImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      imageKey
      context
      postID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPostImagesQueryVariables,
  APITypes.ListPostImagesQuery
>;
export const syncPostImages = /* GraphQL */ `query SyncPostImages(
  $filter: ModelPostImageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPostImages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      imageKey
      context
      postID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPostImagesQueryVariables,
  APITypes.SyncPostImagesQuery
>;
export const postImagesByPostID = /* GraphQL */ `query PostImagesByPostID(
  $postID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPostImageFilterInput
  $limit: Int
  $nextToken: String
) {
  postImagesByPostID(
    postID: $postID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      imageKey
      context
      postID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PostImagesByPostIDQueryVariables,
  APITypes.PostImagesByPostIDQuery
>;
export const getPost = /* GraphQL */ `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    postType
    postAction
    text
    url
    datePost
    timePost
    User {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    PostImages {
      nextToken
      startedAt
      __typename
    }
    resourceId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    postUserId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPostQueryVariables, APITypes.GetPostQuery>;
export const listPosts = /* GraphQL */ `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      postType
      postAction
      text
      url
      datePost
      timePost
      resourceId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      postUserId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPostsQueryVariables, APITypes.ListPostsQuery>;
export const syncPosts = /* GraphQL */ `query SyncPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPosts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      postType
      postAction
      text
      url
      datePost
      timePost
      resourceId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      postUserId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncPostsQueryVariables, APITypes.SyncPostsQuery>;
export const getMensagemMuralEngajamento = /* GraphQL */ `query GetMensagemMuralEngajamento($id: ID!) {
  getMensagemMuralEngajamento(id: $id) {
    id
    MensagemMural {
      id
      mensagem
      dataPostagem
      horaPostagem
      status
      dataAprovacao
      cidade
      estado
      nomeUsuario
      tipoMensagem
      resumo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralCidadeId
      mensagemMuralFalecimentoId
      mensagemMuralUserId
      mensagemMuralAnonymousUserId
      mensagemMuralAprovadoPorId
      mensagemMuralMemorialId
      __typename
    }
    views
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mensagemMuralEngajamentoMensagemMuralId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMensagemMuralEngajamentoQueryVariables,
  APITypes.GetMensagemMuralEngajamentoQuery
>;
export const listMensagemMuralEngajamentos = /* GraphQL */ `query ListMensagemMuralEngajamentos(
  $filter: ModelMensagemMuralEngajamentoFilterInput
  $limit: Int
  $nextToken: String
) {
  listMensagemMuralEngajamentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      views
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralEngajamentoMensagemMuralId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMensagemMuralEngajamentosQueryVariables,
  APITypes.ListMensagemMuralEngajamentosQuery
>;
export const syncMensagemMuralEngajamentos = /* GraphQL */ `query SyncMensagemMuralEngajamentos(
  $filter: ModelMensagemMuralEngajamentoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMensagemMuralEngajamentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      views
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralEngajamentoMensagemMuralId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMensagemMuralEngajamentosQueryVariables,
  APITypes.SyncMensagemMuralEngajamentosQuery
>;
export const getMensagemMuralViews = /* GraphQL */ `query GetMensagemMuralViews($id: ID!) {
  getMensagemMuralViews(id: $id) {
    id
    User {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    MensagemMural {
      id
      mensagem
      dataPostagem
      horaPostagem
      status
      dataAprovacao
      cidade
      estado
      nomeUsuario
      tipoMensagem
      resumo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralCidadeId
      mensagemMuralFalecimentoId
      mensagemMuralUserId
      mensagemMuralAnonymousUserId
      mensagemMuralAprovadoPorId
      mensagemMuralMemorialId
      __typename
    }
    dataVisualizacao
    horaVisualizacao
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mensagemMuralViewsUserId
    mensagemMuralViewsMensagemMuralId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMensagemMuralViewsQueryVariables,
  APITypes.GetMensagemMuralViewsQuery
>;
export const listMensagemMuralViews = /* GraphQL */ `query ListMensagemMuralViews(
  $filter: ModelMensagemMuralViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  listMensagemMuralViews(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      dataVisualizacao
      horaVisualizacao
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralViewsUserId
      mensagemMuralViewsMensagemMuralId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMensagemMuralViewsQueryVariables,
  APITypes.ListMensagemMuralViewsQuery
>;
export const syncMensagemMuralViews = /* GraphQL */ `query SyncMensagemMuralViews(
  $filter: ModelMensagemMuralViewsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMensagemMuralViews(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      dataVisualizacao
      horaVisualizacao
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralViewsUserId
      mensagemMuralViewsMensagemMuralId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMensagemMuralViewsQueryVariables,
  APITypes.SyncMensagemMuralViewsQuery
>;
export const getMensagemMuralFalecimentoFoto = /* GraphQL */ `query GetMensagemMuralFalecimentoFoto($id: ID!) {
  getMensagemMuralFalecimentoFoto(id: $id) {
    id
    MensagemMural {
      id
      mensagem
      dataPostagem
      horaPostagem
      status
      dataAprovacao
      cidade
      estado
      nomeUsuario
      tipoMensagem
      resumo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralCidadeId
      mensagemMuralFalecimentoId
      mensagemMuralUserId
      mensagemMuralAnonymousUserId
      mensagemMuralAprovadoPorId
      mensagemMuralMemorialId
      __typename
    }
    FalecimentoFoto {
      id
      fileKey
      status
      tipo
      falecimentoID
      userID
      isCover
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tipoFoto
    isCover
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mensagemMuralFalecimentoFotoMensagemMuralId
    mensagemMuralFalecimentoFotoFalecimentoFotoId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMensagemMuralFalecimentoFotoQueryVariables,
  APITypes.GetMensagemMuralFalecimentoFotoQuery
>;
export const listMensagemMuralFalecimentoFotos = /* GraphQL */ `query ListMensagemMuralFalecimentoFotos(
  $filter: ModelMensagemMuralFalecimentoFotoFilterInput
  $limit: Int
  $nextToken: String
) {
  listMensagemMuralFalecimentoFotos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tipoFoto
      isCover
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralFalecimentoFotoMensagemMuralId
      mensagemMuralFalecimentoFotoFalecimentoFotoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMensagemMuralFalecimentoFotosQueryVariables,
  APITypes.ListMensagemMuralFalecimentoFotosQuery
>;
export const syncMensagemMuralFalecimentoFotos = /* GraphQL */ `query SyncMensagemMuralFalecimentoFotos(
  $filter: ModelMensagemMuralFalecimentoFotoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMensagemMuralFalecimentoFotos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tipoFoto
      isCover
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralFalecimentoFotoMensagemMuralId
      mensagemMuralFalecimentoFotoFalecimentoFotoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMensagemMuralFalecimentoFotosQueryVariables,
  APITypes.SyncMensagemMuralFalecimentoFotosQuery
>;
export const getFalecimentoFoto = /* GraphQL */ `query GetFalecimentoFoto($id: ID!) {
  getFalecimentoFoto(id: $id) {
    id
    fileKey
    status
    tipo
    falecimentoID
    userID
    isCover
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFalecimentoFotoQueryVariables,
  APITypes.GetFalecimentoFotoQuery
>;
export const listFalecimentoFotos = /* GraphQL */ `query ListFalecimentoFotos(
  $filter: ModelFalecimentoFotoFilterInput
  $limit: Int
  $nextToken: String
) {
  listFalecimentoFotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      fileKey
      status
      tipo
      falecimentoID
      userID
      isCover
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFalecimentoFotosQueryVariables,
  APITypes.ListFalecimentoFotosQuery
>;
export const syncFalecimentoFotos = /* GraphQL */ `query SyncFalecimentoFotos(
  $filter: ModelFalecimentoFotoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFalecimentoFotos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      fileKey
      status
      tipo
      falecimentoID
      userID
      isCover
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFalecimentoFotosQueryVariables,
  APITypes.SyncFalecimentoFotosQuery
>;
export const falecimentoFotosByFalecimentoID = /* GraphQL */ `query FalecimentoFotosByFalecimentoID(
  $falecimentoID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFalecimentoFotoFilterInput
  $limit: Int
  $nextToken: String
) {
  falecimentoFotosByFalecimentoID(
    falecimentoID: $falecimentoID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fileKey
      status
      tipo
      falecimentoID
      userID
      isCover
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FalecimentoFotosByFalecimentoIDQueryVariables,
  APITypes.FalecimentoFotosByFalecimentoIDQuery
>;
export const falecimentoFotosByUserID = /* GraphQL */ `query FalecimentoFotosByUserID(
  $userID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFalecimentoFotoFilterInput
  $limit: Int
  $nextToken: String
) {
  falecimentoFotosByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fileKey
      status
      tipo
      falecimentoID
      userID
      isCover
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FalecimentoFotosByUserIDQueryVariables,
  APITypes.FalecimentoFotosByUserIDQuery
>;
export const getOpenAiGeneratedMessage = /* GraphQL */ `query GetOpenAiGeneratedMessage($id: ID!) {
  getOpenAiGeneratedMessage(id: $id) {
    id
    falecimentoId
    anonymousUserId
    messageId
    runId
    threadId
    created_at
    contentTextValue
    assistantId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOpenAiGeneratedMessageQueryVariables,
  APITypes.GetOpenAiGeneratedMessageQuery
>;
export const listOpenAiGeneratedMessages = /* GraphQL */ `query ListOpenAiGeneratedMessages(
  $filter: ModelOpenAiGeneratedMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listOpenAiGeneratedMessages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      falecimentoId
      anonymousUserId
      messageId
      runId
      threadId
      created_at
      contentTextValue
      assistantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOpenAiGeneratedMessagesQueryVariables,
  APITypes.ListOpenAiGeneratedMessagesQuery
>;
export const syncOpenAiGeneratedMessages = /* GraphQL */ `query SyncOpenAiGeneratedMessages(
  $filter: ModelOpenAiGeneratedMessageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOpenAiGeneratedMessages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      falecimentoId
      anonymousUserId
      messageId
      runId
      threadId
      created_at
      contentTextValue
      assistantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOpenAiGeneratedMessagesQueryVariables,
  APITypes.SyncOpenAiGeneratedMessagesQuery
>;
export const getOpenAiRun = /* GraphQL */ `query GetOpenAiRun($id: ID!) {
  getOpenAiRun(id: $id) {
    id
    OpenAiThread {
      id
      threadId
      object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      openAiThreadOpenAiAssistantId
      openAiThreadAnonymousUserId
      __typename
    }
    OpenAiAssistant {
      id
      assistantId
      object
      model
      instructions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    runId
    object
    created_at
    status
    expires_at
    started_at
    cancelled_at
    failed_at
    completed_at
    model
    instructions
    assistant_id
    thread_id
    last_error {
      code
      message
      __typename
    }
    AnonymousUser {
      id
      nome
      avatar
      email
      ip
      sexo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      anonymousUserCidadeId
      __typename
    }
    assistentemensagemID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    openAiRunOpenAiThreadId
    openAiRunOpenAiAssistantId
    openAiRunAnonymousUserId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOpenAiRunQueryVariables,
  APITypes.GetOpenAiRunQuery
>;
export const listOpenAiRuns = /* GraphQL */ `query ListOpenAiRuns(
  $filter: ModelOpenAiRunFilterInput
  $limit: Int
  $nextToken: String
) {
  listOpenAiRuns(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      runId
      object
      created_at
      status
      expires_at
      started_at
      cancelled_at
      failed_at
      completed_at
      model
      instructions
      assistant_id
      thread_id
      assistentemensagemID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      openAiRunOpenAiThreadId
      openAiRunOpenAiAssistantId
      openAiRunAnonymousUserId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOpenAiRunsQueryVariables,
  APITypes.ListOpenAiRunsQuery
>;
export const syncOpenAiRuns = /* GraphQL */ `query SyncOpenAiRuns(
  $filter: ModelOpenAiRunFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOpenAiRuns(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      runId
      object
      created_at
      status
      expires_at
      started_at
      cancelled_at
      failed_at
      completed_at
      model
      instructions
      assistant_id
      thread_id
      assistentemensagemID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      openAiRunOpenAiThreadId
      openAiRunOpenAiAssistantId
      openAiRunAnonymousUserId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOpenAiRunsQueryVariables,
  APITypes.SyncOpenAiRunsQuery
>;
export const openAiRunsByAssistentemensagemID = /* GraphQL */ `query OpenAiRunsByAssistentemensagemID(
  $assistentemensagemID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOpenAiRunFilterInput
  $limit: Int
  $nextToken: String
) {
  openAiRunsByAssistentemensagemID(
    assistentemensagemID: $assistentemensagemID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      runId
      object
      created_at
      status
      expires_at
      started_at
      cancelled_at
      failed_at
      completed_at
      model
      instructions
      assistant_id
      thread_id
      assistentemensagemID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      openAiRunOpenAiThreadId
      openAiRunOpenAiAssistantId
      openAiRunAnonymousUserId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OpenAiRunsByAssistentemensagemIDQueryVariables,
  APITypes.OpenAiRunsByAssistentemensagemIDQuery
>;
export const getOpenAiAssistant = /* GraphQL */ `query GetOpenAiAssistant($id: ID!) {
  getOpenAiAssistant(id: $id) {
    id
    assistantId
    object
    model
    instructions
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOpenAiAssistantQueryVariables,
  APITypes.GetOpenAiAssistantQuery
>;
export const listOpenAiAssistants = /* GraphQL */ `query ListOpenAiAssistants(
  $filter: ModelOpenAiAssistantFilterInput
  $limit: Int
  $nextToken: String
) {
  listOpenAiAssistants(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      assistantId
      object
      model
      instructions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOpenAiAssistantsQueryVariables,
  APITypes.ListOpenAiAssistantsQuery
>;
export const syncOpenAiAssistants = /* GraphQL */ `query SyncOpenAiAssistants(
  $filter: ModelOpenAiAssistantFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOpenAiAssistants(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      assistantId
      object
      model
      instructions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOpenAiAssistantsQueryVariables,
  APITypes.SyncOpenAiAssistantsQuery
>;
export const getAssistenteMensagem = /* GraphQL */ `query GetAssistenteMensagem($id: ID!) {
  getAssistenteMensagem(id: $id) {
    id
    OpenAiThread {
      id
      threadId
      object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      openAiThreadOpenAiAssistantId
      openAiThreadAnonymousUserId
      __typename
    }
    Falecimento {
      id
      nome
      sobrenome
      dataFalecimento
      searchName
      idade
      tempoDeVida
      meses
      dataInicioVelorio
      horaInicioVelorio
      dataFimVelorio
      horaFimVelorio
      dataSepultamento
      horaSepultamento
      salaVelorio
      sobre
      imageKey
      apelido
      cidade
      estado
      sexo
      dataNascimento
      faixaEtaria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      falecimentoFunerariaId
      falecimentoVelorioId
      falecimentoCemiterioId
      falecimentoCidadeId
      falecimentoOpenAiAssistantId
      __typename
    }
    AnonymousUser {
      id
      nome
      avatar
      email
      ip
      sexo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      anonymousUserCidadeId
      __typename
    }
    OpenAiAssistant {
      id
      assistantId
      object
      model
      instructions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Respostas {
      nextToken
      startedAt
      __typename
    }
    RelUsuarioFalecimento {
      id
      nivelRelacionamento
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      relUsuarioFalecimentoFalecimentoId
      relUsuarioFalecimentoAnonymousUserId
      relUsuarioFalecimentoUserId
      relUsuarioFalecimentoTipoRelacionamentoId
      __typename
    }
    tamanhoMensagem
    tipoPessoa
    emNomeDe
    OpenAiRuns {
      nextToken
      startedAt
      __typename
    }
    nomeUsuario
    User {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    TipoRelacionamento {
      id
      nivelRelaciomento
      titulo
      sexo
      prioridade
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mensagemUsuario
    Cidade {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    OpenAiGeneratedMessage {
      id
      falecimentoId
      anonymousUserId
      messageId
      runId
      threadId
      created_at
      contentTextValue
      assistantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    instrucao
    Step
    Status
    Memorial {
      id
      url
      emailSuperAdmin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      memorialSuperAdminId
      memorialCriadorId
      memorialFalecimentoId
      __typename
    }
    mensagemReescrita
    mensagemGerada
    mensagemCorrigida
    mensagemEditada
    tipoMensagemSelecionada
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    assistenteMensagemOpenAiThreadId
    assistenteMensagemFalecimentoId
    assistenteMensagemAnonymousUserId
    assistenteMensagemOpenAiAssistantId
    assistenteMensagemRelUsuarioFalecimentoId
    assistenteMensagemUserId
    assistenteMensagemTipoRelacionamentoId
    assistenteMensagemCidadeId
    assistenteMensagemOpenAiGeneratedMessageId
    assistenteMensagemMemorialId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssistenteMensagemQueryVariables,
  APITypes.GetAssistenteMensagemQuery
>;
export const listAssistenteMensagems = /* GraphQL */ `query ListAssistenteMensagems(
  $filter: ModelAssistenteMensagemFilterInput
  $limit: Int
  $nextToken: String
) {
  listAssistenteMensagems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tamanhoMensagem
      tipoPessoa
      emNomeDe
      nomeUsuario
      mensagemUsuario
      instrucao
      Step
      Status
      mensagemReescrita
      mensagemGerada
      mensagemCorrigida
      mensagemEditada
      tipoMensagemSelecionada
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      assistenteMensagemOpenAiThreadId
      assistenteMensagemFalecimentoId
      assistenteMensagemAnonymousUserId
      assistenteMensagemOpenAiAssistantId
      assistenteMensagemRelUsuarioFalecimentoId
      assistenteMensagemUserId
      assistenteMensagemTipoRelacionamentoId
      assistenteMensagemCidadeId
      assistenteMensagemOpenAiGeneratedMessageId
      assistenteMensagemMemorialId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAssistenteMensagemsQueryVariables,
  APITypes.ListAssistenteMensagemsQuery
>;
export const syncAssistenteMensagems = /* GraphQL */ `query SyncAssistenteMensagems(
  $filter: ModelAssistenteMensagemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAssistenteMensagems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tamanhoMensagem
      tipoPessoa
      emNomeDe
      nomeUsuario
      mensagemUsuario
      instrucao
      Step
      Status
      mensagemReescrita
      mensagemGerada
      mensagemCorrigida
      mensagemEditada
      tipoMensagemSelecionada
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      assistenteMensagemOpenAiThreadId
      assistenteMensagemFalecimentoId
      assistenteMensagemAnonymousUserId
      assistenteMensagemOpenAiAssistantId
      assistenteMensagemRelUsuarioFalecimentoId
      assistenteMensagemUserId
      assistenteMensagemTipoRelacionamentoId
      assistenteMensagemCidadeId
      assistenteMensagemOpenAiGeneratedMessageId
      assistenteMensagemMemorialId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAssistenteMensagemsQueryVariables,
  APITypes.SyncAssistenteMensagemsQuery
>;
export const getOpenAiThread = /* GraphQL */ `query GetOpenAiThread($id: ID!) {
  getOpenAiThread(id: $id) {
    id
    threadId
    object
    OpenAiAssistant {
      id
      assistantId
      object
      model
      instructions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    AnonymousUser {
      id
      nome
      avatar
      email
      ip
      sexo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      anonymousUserCidadeId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    openAiThreadOpenAiAssistantId
    openAiThreadAnonymousUserId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOpenAiThreadQueryVariables,
  APITypes.GetOpenAiThreadQuery
>;
export const listOpenAiThreads = /* GraphQL */ `query ListOpenAiThreads(
  $filter: ModelOpenAiThreadFilterInput
  $limit: Int
  $nextToken: String
) {
  listOpenAiThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      threadId
      object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      openAiThreadOpenAiAssistantId
      openAiThreadAnonymousUserId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOpenAiThreadsQueryVariables,
  APITypes.ListOpenAiThreadsQuery
>;
export const syncOpenAiThreads = /* GraphQL */ `query SyncOpenAiThreads(
  $filter: ModelOpenAiThreadFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOpenAiThreads(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      threadId
      object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      openAiThreadOpenAiAssistantId
      openAiThreadAnonymousUserId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOpenAiThreadsQueryVariables,
  APITypes.SyncOpenAiThreadsQuery
>;
export const getPerguntaFaixaEtaria = /* GraphQL */ `query GetPerguntaFaixaEtaria($id: ID!) {
  getPerguntaFaixaEtaria(id: $id) {
    id
    faixaEtaria
    Pergunta {
      id
      pergunta
      feminino
      tipoPergunta
      tipoResposta
      respostaTipoViviamJuntos
      limitaTipoRelacionamento
      limitaFaixaEtaria
      limitaNivelRelacionamento
      modeloResposta
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    active
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    perguntaFaixaEtariaPerguntaId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPerguntaFaixaEtariaQueryVariables,
  APITypes.GetPerguntaFaixaEtariaQuery
>;
export const listPerguntaFaixaEtarias = /* GraphQL */ `query ListPerguntaFaixaEtarias(
  $filter: ModelPerguntaFaixaEtariaFilterInput
  $limit: Int
  $nextToken: String
) {
  listPerguntaFaixaEtarias(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      faixaEtaria
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      perguntaFaixaEtariaPerguntaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPerguntaFaixaEtariasQueryVariables,
  APITypes.ListPerguntaFaixaEtariasQuery
>;
export const syncPerguntaFaixaEtarias = /* GraphQL */ `query SyncPerguntaFaixaEtarias(
  $filter: ModelPerguntaFaixaEtariaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPerguntaFaixaEtarias(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      faixaEtaria
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      perguntaFaixaEtariaPerguntaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPerguntaFaixaEtariasQueryVariables,
  APITypes.SyncPerguntaFaixaEtariasQuery
>;
export const getPerguntaTipoRelacionamento = /* GraphQL */ `query GetPerguntaTipoRelacionamento($id: ID!) {
  getPerguntaTipoRelacionamento(id: $id) {
    id
    TipoRelacionamento {
      id
      nivelRelaciomento
      titulo
      sexo
      prioridade
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Pergunta {
      id
      pergunta
      feminino
      tipoPergunta
      tipoResposta
      respostaTipoViviamJuntos
      limitaTipoRelacionamento
      limitaFaixaEtaria
      limitaNivelRelacionamento
      modeloResposta
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    active
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    perguntaTipoRelacionamentoTipoRelacionamentoId
    perguntaTipoRelacionamentoPerguntaId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPerguntaTipoRelacionamentoQueryVariables,
  APITypes.GetPerguntaTipoRelacionamentoQuery
>;
export const listPerguntaTipoRelacionamentos = /* GraphQL */ `query ListPerguntaTipoRelacionamentos(
  $filter: ModelPerguntaTipoRelacionamentoFilterInput
  $limit: Int
  $nextToken: String
) {
  listPerguntaTipoRelacionamentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      perguntaTipoRelacionamentoTipoRelacionamentoId
      perguntaTipoRelacionamentoPerguntaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPerguntaTipoRelacionamentosQueryVariables,
  APITypes.ListPerguntaTipoRelacionamentosQuery
>;
export const syncPerguntaTipoRelacionamentos = /* GraphQL */ `query SyncPerguntaTipoRelacionamentos(
  $filter: ModelPerguntaTipoRelacionamentoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPerguntaTipoRelacionamentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      perguntaTipoRelacionamentoTipoRelacionamentoId
      perguntaTipoRelacionamentoPerguntaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPerguntaTipoRelacionamentosQueryVariables,
  APITypes.SyncPerguntaTipoRelacionamentosQuery
>;
export const getRelUsuarioFalecimento = /* GraphQL */ `query GetRelUsuarioFalecimento($id: ID!) {
  getRelUsuarioFalecimento(id: $id) {
    id
    nivelRelacionamento
    Falecimento {
      id
      nome
      sobrenome
      dataFalecimento
      searchName
      idade
      tempoDeVida
      meses
      dataInicioVelorio
      horaInicioVelorio
      dataFimVelorio
      horaFimVelorio
      dataSepultamento
      horaSepultamento
      salaVelorio
      sobre
      imageKey
      apelido
      cidade
      estado
      sexo
      dataNascimento
      faixaEtaria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      falecimentoFunerariaId
      falecimentoVelorioId
      falecimentoCemiterioId
      falecimentoCidadeId
      falecimentoOpenAiAssistantId
      __typename
    }
    AnonymousUser {
      id
      nome
      avatar
      email
      ip
      sexo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      anonymousUserCidadeId
      __typename
    }
    User {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    TipoRelacionamento {
      id
      nivelRelaciomento
      titulo
      sexo
      prioridade
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    relUsuarioFalecimentoFalecimentoId
    relUsuarioFalecimentoAnonymousUserId
    relUsuarioFalecimentoUserId
    relUsuarioFalecimentoTipoRelacionamentoId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRelUsuarioFalecimentoQueryVariables,
  APITypes.GetRelUsuarioFalecimentoQuery
>;
export const listRelUsuarioFalecimentos = /* GraphQL */ `query ListRelUsuarioFalecimentos(
  $filter: ModelRelUsuarioFalecimentoFilterInput
  $limit: Int
  $nextToken: String
) {
  listRelUsuarioFalecimentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      nivelRelacionamento
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      relUsuarioFalecimentoFalecimentoId
      relUsuarioFalecimentoAnonymousUserId
      relUsuarioFalecimentoUserId
      relUsuarioFalecimentoTipoRelacionamentoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRelUsuarioFalecimentosQueryVariables,
  APITypes.ListRelUsuarioFalecimentosQuery
>;
export const syncRelUsuarioFalecimentos = /* GraphQL */ `query SyncRelUsuarioFalecimentos(
  $filter: ModelRelUsuarioFalecimentoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRelUsuarioFalecimentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nivelRelacionamento
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      relUsuarioFalecimentoFalecimentoId
      relUsuarioFalecimentoAnonymousUserId
      relUsuarioFalecimentoUserId
      relUsuarioFalecimentoTipoRelacionamentoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRelUsuarioFalecimentosQueryVariables,
  APITypes.SyncRelUsuarioFalecimentosQuery
>;
export const getResposta = /* GraphQL */ `query GetResposta($id: ID!) {
  getResposta(id: $id) {
    id
    pergunta
    resposta
    AnonymousUser {
      id
      nome
      avatar
      email
      ip
      sexo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      anonymousUserCidadeId
      __typename
    }
    User {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    Falecimento {
      id
      nome
      sobrenome
      dataFalecimento
      searchName
      idade
      tempoDeVida
      meses
      dataInicioVelorio
      horaInicioVelorio
      dataFimVelorio
      horaFimVelorio
      dataSepultamento
      horaSepultamento
      salaVelorio
      sobre
      imageKey
      apelido
      cidade
      estado
      sexo
      dataNascimento
      faixaEtaria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      falecimentoFunerariaId
      falecimentoVelorioId
      falecimentoCemiterioId
      falecimentoCidadeId
      falecimentoOpenAiAssistantId
      __typename
    }
    tipoPergunta
    viviamJuntos
    Pergunta {
      id
      pergunta
      feminino
      tipoPergunta
      tipoResposta
      respostaTipoViviamJuntos
      limitaTipoRelacionamento
      limitaFaixaEtaria
      limitaNivelRelacionamento
      modeloResposta
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    PerguntaOpcaoResposta {
      id
      opcaoResposta
      viviamJuntos
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      perguntaOpcaoRespostaPerguntaId
      __typename
    }
    assistentemensagemID
    skiped
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    respostaAnonymousUserId
    respostaUserId
    respostaFalecimentoId
    respostaPerguntaId
    respostaPerguntaOpcaoRespostaId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRespostaQueryVariables,
  APITypes.GetRespostaQuery
>;
export const listRespostas = /* GraphQL */ `query ListRespostas(
  $filter: ModelRespostaFilterInput
  $limit: Int
  $nextToken: String
) {
  listRespostas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pergunta
      resposta
      tipoPergunta
      viviamJuntos
      assistentemensagemID
      skiped
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      respostaAnonymousUserId
      respostaUserId
      respostaFalecimentoId
      respostaPerguntaId
      respostaPerguntaOpcaoRespostaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRespostasQueryVariables,
  APITypes.ListRespostasQuery
>;
export const syncRespostas = /* GraphQL */ `query SyncRespostas(
  $filter: ModelRespostaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRespostas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      pergunta
      resposta
      tipoPergunta
      viviamJuntos
      assistentemensagemID
      skiped
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      respostaAnonymousUserId
      respostaUserId
      respostaFalecimentoId
      respostaPerguntaId
      respostaPerguntaOpcaoRespostaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRespostasQueryVariables,
  APITypes.SyncRespostasQuery
>;
export const respostasByAssistentemensagemID = /* GraphQL */ `query RespostasByAssistentemensagemID(
  $assistentemensagemID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRespostaFilterInput
  $limit: Int
  $nextToken: String
) {
  respostasByAssistentemensagemID(
    assistentemensagemID: $assistentemensagemID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pergunta
      resposta
      tipoPergunta
      viviamJuntos
      assistentemensagemID
      skiped
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      respostaAnonymousUserId
      respostaUserId
      respostaFalecimentoId
      respostaPerguntaId
      respostaPerguntaOpcaoRespostaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RespostasByAssistentemensagemIDQueryVariables,
  APITypes.RespostasByAssistentemensagemIDQuery
>;
export const getPerguntaOpcaoResposta = /* GraphQL */ `query GetPerguntaOpcaoResposta($id: ID!) {
  getPerguntaOpcaoResposta(id: $id) {
    id
    opcaoResposta
    Pergunta {
      id
      pergunta
      feminino
      tipoPergunta
      tipoResposta
      respostaTipoViviamJuntos
      limitaTipoRelacionamento
      limitaFaixaEtaria
      limitaNivelRelacionamento
      modeloResposta
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    viviamJuntos
    active
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    perguntaOpcaoRespostaPerguntaId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPerguntaOpcaoRespostaQueryVariables,
  APITypes.GetPerguntaOpcaoRespostaQuery
>;
export const listPerguntaOpcaoRespostas = /* GraphQL */ `query ListPerguntaOpcaoRespostas(
  $filter: ModelPerguntaOpcaoRespostaFilterInput
  $limit: Int
  $nextToken: String
) {
  listPerguntaOpcaoRespostas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      opcaoResposta
      viviamJuntos
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      perguntaOpcaoRespostaPerguntaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPerguntaOpcaoRespostasQueryVariables,
  APITypes.ListPerguntaOpcaoRespostasQuery
>;
export const syncPerguntaOpcaoRespostas = /* GraphQL */ `query SyncPerguntaOpcaoRespostas(
  $filter: ModelPerguntaOpcaoRespostaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPerguntaOpcaoRespostas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      opcaoResposta
      viviamJuntos
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      perguntaOpcaoRespostaPerguntaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPerguntaOpcaoRespostasQueryVariables,
  APITypes.SyncPerguntaOpcaoRespostasQuery
>;
export const getPerguntaNivelRelacionamento = /* GraphQL */ `query GetPerguntaNivelRelacionamento($id: ID!) {
  getPerguntaNivelRelacionamento(id: $id) {
    id
    nivelRelacionamento
    Pergunta {
      id
      pergunta
      feminino
      tipoPergunta
      tipoResposta
      respostaTipoViviamJuntos
      limitaTipoRelacionamento
      limitaFaixaEtaria
      limitaNivelRelacionamento
      modeloResposta
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    active
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    perguntaNivelRelacionamentoPerguntaId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPerguntaNivelRelacionamentoQueryVariables,
  APITypes.GetPerguntaNivelRelacionamentoQuery
>;
export const listPerguntaNivelRelacionamentos = /* GraphQL */ `query ListPerguntaNivelRelacionamentos(
  $filter: ModelPerguntaNivelRelacionamentoFilterInput
  $limit: Int
  $nextToken: String
) {
  listPerguntaNivelRelacionamentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      nivelRelacionamento
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      perguntaNivelRelacionamentoPerguntaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPerguntaNivelRelacionamentosQueryVariables,
  APITypes.ListPerguntaNivelRelacionamentosQuery
>;
export const syncPerguntaNivelRelacionamentos = /* GraphQL */ `query SyncPerguntaNivelRelacionamentos(
  $filter: ModelPerguntaNivelRelacionamentoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPerguntaNivelRelacionamentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nivelRelacionamento
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      perguntaNivelRelacionamentoPerguntaId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPerguntaNivelRelacionamentosQueryVariables,
  APITypes.SyncPerguntaNivelRelacionamentosQuery
>;
export const getPergunta = /* GraphQL */ `query GetPergunta($id: ID!) {
  getPergunta(id: $id) {
    id
    pergunta
    feminino
    tipoPergunta
    tipoResposta
    respostaTipoViviamJuntos
    limitaTipoRelacionamento
    limitaFaixaEtaria
    limitaNivelRelacionamento
    modeloResposta
    active
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPerguntaQueryVariables,
  APITypes.GetPerguntaQuery
>;
export const listPerguntas = /* GraphQL */ `query ListPerguntas(
  $filter: ModelPerguntaFilterInput
  $limit: Int
  $nextToken: String
) {
  listPerguntas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pergunta
      feminino
      tipoPergunta
      tipoResposta
      respostaTipoViviamJuntos
      limitaTipoRelacionamento
      limitaFaixaEtaria
      limitaNivelRelacionamento
      modeloResposta
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPerguntasQueryVariables,
  APITypes.ListPerguntasQuery
>;
export const syncPerguntas = /* GraphQL */ `query SyncPerguntas(
  $filter: ModelPerguntaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPerguntas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      pergunta
      feminino
      tipoPergunta
      tipoResposta
      respostaTipoViviamJuntos
      limitaTipoRelacionamento
      limitaFaixaEtaria
      limitaNivelRelacionamento
      modeloResposta
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPerguntasQueryVariables,
  APITypes.SyncPerguntasQuery
>;
export const getTipoRelacionamento = /* GraphQL */ `query GetTipoRelacionamento($id: ID!) {
  getTipoRelacionamento(id: $id) {
    id
    nivelRelaciomento
    titulo
    sexo
    prioridade
    active
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTipoRelacionamentoQueryVariables,
  APITypes.GetTipoRelacionamentoQuery
>;
export const listTipoRelacionamentos = /* GraphQL */ `query ListTipoRelacionamentos(
  $filter: ModelTipoRelacionamentoFilterInput
  $limit: Int
  $nextToken: String
) {
  listTipoRelacionamentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      nivelRelaciomento
      titulo
      sexo
      prioridade
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTipoRelacionamentosQueryVariables,
  APITypes.ListTipoRelacionamentosQuery
>;
export const syncTipoRelacionamentos = /* GraphQL */ `query SyncTipoRelacionamentos(
  $filter: ModelTipoRelacionamentoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTipoRelacionamentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nivelRelaciomento
      titulo
      sexo
      prioridade
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTipoRelacionamentosQueryVariables,
  APITypes.SyncTipoRelacionamentosQuery
>;
export const getFalecimentoCidade = /* GraphQL */ `query GetFalecimentoCidade($id: ID!) {
  getFalecimentoCidade(id: $id) {
    id
    Falecimento {
      id
      nome
      sobrenome
      dataFalecimento
      searchName
      idade
      tempoDeVida
      meses
      dataInicioVelorio
      horaInicioVelorio
      dataFimVelorio
      horaFimVelorio
      dataSepultamento
      horaSepultamento
      salaVelorio
      sobre
      imageKey
      apelido
      cidade
      estado
      sexo
      dataNascimento
      faixaEtaria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      falecimentoFunerariaId
      falecimentoVelorioId
      falecimentoCemiterioId
      falecimentoCidadeId
      falecimentoOpenAiAssistantId
      __typename
    }
    Cidade {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    QtdMensagens
    nomeCidade
    siglaEstado
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    falecimentoCidadeFalecimentoId
    falecimentoCidadeCidadeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFalecimentoCidadeQueryVariables,
  APITypes.GetFalecimentoCidadeQuery
>;
export const listFalecimentoCidades = /* GraphQL */ `query ListFalecimentoCidades(
  $filter: ModelFalecimentoCidadeFilterInput
  $limit: Int
  $nextToken: String
) {
  listFalecimentoCidades(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      QtdMensagens
      nomeCidade
      siglaEstado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      falecimentoCidadeFalecimentoId
      falecimentoCidadeCidadeId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFalecimentoCidadesQueryVariables,
  APITypes.ListFalecimentoCidadesQuery
>;
export const syncFalecimentoCidades = /* GraphQL */ `query SyncFalecimentoCidades(
  $filter: ModelFalecimentoCidadeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFalecimentoCidades(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      QtdMensagens
      nomeCidade
      siglaEstado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      falecimentoCidadeFalecimentoId
      falecimentoCidadeCidadeId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFalecimentoCidadesQueryVariables,
  APITypes.SyncFalecimentoCidadesQuery
>;
export const getConviteAdministracaoMemorial = /* GraphQL */ `query GetConviteAdministracaoMemorial($id: ID!) {
  getConviteAdministracaoMemorial(id: $id) {
    id
    memorialID
    email
    status
    dataEnvio
    dataAceite
    Convidado {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conviteAdministracaoMemorialConvidadoId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConviteAdministracaoMemorialQueryVariables,
  APITypes.GetConviteAdministracaoMemorialQuery
>;
export const listConviteAdministracaoMemorials = /* GraphQL */ `query ListConviteAdministracaoMemorials(
  $filter: ModelConviteAdministracaoMemorialFilterInput
  $limit: Int
  $nextToken: String
) {
  listConviteAdministracaoMemorials(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      memorialID
      email
      status
      dataEnvio
      dataAceite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conviteAdministracaoMemorialConvidadoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConviteAdministracaoMemorialsQueryVariables,
  APITypes.ListConviteAdministracaoMemorialsQuery
>;
export const syncConviteAdministracaoMemorials = /* GraphQL */ `query SyncConviteAdministracaoMemorials(
  $filter: ModelConviteAdministracaoMemorialFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncConviteAdministracaoMemorials(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      memorialID
      email
      status
      dataEnvio
      dataAceite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conviteAdministracaoMemorialConvidadoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncConviteAdministracaoMemorialsQueryVariables,
  APITypes.SyncConviteAdministracaoMemorialsQuery
>;
export const conviteAdministracaoMemorialsByMemorialID = /* GraphQL */ `query ConviteAdministracaoMemorialsByMemorialID(
  $memorialID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelConviteAdministracaoMemorialFilterInput
  $limit: Int
  $nextToken: String
) {
  conviteAdministracaoMemorialsByMemorialID(
    memorialID: $memorialID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      memorialID
      email
      status
      dataEnvio
      dataAceite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conviteAdministracaoMemorialConvidadoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConviteAdministracaoMemorialsByMemorialIDQueryVariables,
  APITypes.ConviteAdministracaoMemorialsByMemorialIDQuery
>;
export const getMemorial = /* GraphQL */ `query GetMemorial($id: ID!) {
  getMemorial(id: $id) {
    id
    url
    Administradores {
      nextToken
      startedAt
      __typename
    }
    SuperAdmin {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    ConviteAdministracaoMemorials {
      nextToken
      startedAt
      __typename
    }
    Criador {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    Falecimento {
      id
      nome
      sobrenome
      dataFalecimento
      searchName
      idade
      tempoDeVida
      meses
      dataInicioVelorio
      horaInicioVelorio
      dataFimVelorio
      horaFimVelorio
      dataSepultamento
      horaSepultamento
      salaVelorio
      sobre
      imageKey
      apelido
      cidade
      estado
      sexo
      dataNascimento
      faixaEtaria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      falecimentoFunerariaId
      falecimentoVelorioId
      falecimentoCemiterioId
      falecimentoCidadeId
      falecimentoOpenAiAssistantId
      __typename
    }
    emailSuperAdmin
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    memorialSuperAdminId
    memorialCriadorId
    memorialFalecimentoId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMemorialQueryVariables,
  APITypes.GetMemorialQuery
>;
export const listMemorials = /* GraphQL */ `query ListMemorials(
  $filter: ModelMemorialFilterInput
  $limit: Int
  $nextToken: String
) {
  listMemorials(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      url
      emailSuperAdmin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      memorialSuperAdminId
      memorialCriadorId
      memorialFalecimentoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMemorialsQueryVariables,
  APITypes.ListMemorialsQuery
>;
export const syncMemorials = /* GraphQL */ `query SyncMemorials(
  $filter: ModelMemorialFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMemorials(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      url
      emailSuperAdmin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      memorialSuperAdminId
      memorialCriadorId
      memorialFalecimentoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMemorialsQueryVariables,
  APITypes.SyncMemorialsQuery
>;
export const getAnonymousUser = /* GraphQL */ `query GetAnonymousUser($id: ID!) {
  getAnonymousUser(id: $id) {
    id
    nome
    avatar
    email
    ip
    Cidade {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    sexo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    anonymousUserCidadeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAnonymousUserQueryVariables,
  APITypes.GetAnonymousUserQuery
>;
export const listAnonymousUsers = /* GraphQL */ `query ListAnonymousUsers(
  $filter: ModelAnonymousUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listAnonymousUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nome
      avatar
      email
      ip
      sexo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      anonymousUserCidadeId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAnonymousUsersQueryVariables,
  APITypes.ListAnonymousUsersQuery
>;
export const syncAnonymousUsers = /* GraphQL */ `query SyncAnonymousUsers(
  $filter: ModelAnonymousUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAnonymousUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nome
      avatar
      email
      ip
      sexo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      anonymousUserCidadeId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAnonymousUsersQueryVariables,
  APITypes.SyncAnonymousUsersQuery
>;
export const getMensagemMural = /* GraphQL */ `query GetMensagemMural($id: ID!) {
  getMensagemMural(id: $id) {
    id
    mensagem
    dataPostagem
    horaPostagem
    Cidade {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    Falecimento {
      id
      nome
      sobrenome
      dataFalecimento
      searchName
      idade
      tempoDeVida
      meses
      dataInicioVelorio
      horaInicioVelorio
      dataFimVelorio
      horaFimVelorio
      dataSepultamento
      horaSepultamento
      salaVelorio
      sobre
      imageKey
      apelido
      cidade
      estado
      sexo
      dataNascimento
      faixaEtaria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      falecimentoFunerariaId
      falecimentoVelorioId
      falecimentoCemiterioId
      falecimentoCidadeId
      falecimentoOpenAiAssistantId
      __typename
    }
    User {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    status
    AnonymousUser {
      id
      nome
      avatar
      email
      ip
      sexo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      anonymousUserCidadeId
      __typename
    }
    AprovadoPor {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    dataAprovacao
    cidade
    estado
    nomeUsuario
    tipoMensagem
    Memorial {
      id
      url
      emailSuperAdmin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      memorialSuperAdminId
      memorialCriadorId
      memorialFalecimentoId
      __typename
    }
    resumo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mensagemMuralCidadeId
    mensagemMuralFalecimentoId
    mensagemMuralUserId
    mensagemMuralAnonymousUserId
    mensagemMuralAprovadoPorId
    mensagemMuralMemorialId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMensagemMuralQueryVariables,
  APITypes.GetMensagemMuralQuery
>;
export const listMensagemMurals = /* GraphQL */ `query ListMensagemMurals(
  $filter: ModelMensagemMuralFilterInput
  $limit: Int
  $nextToken: String
) {
  listMensagemMurals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      mensagem
      dataPostagem
      horaPostagem
      status
      dataAprovacao
      cidade
      estado
      nomeUsuario
      tipoMensagem
      resumo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralCidadeId
      mensagemMuralFalecimentoId
      mensagemMuralUserId
      mensagemMuralAnonymousUserId
      mensagemMuralAprovadoPorId
      mensagemMuralMemorialId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMensagemMuralsQueryVariables,
  APITypes.ListMensagemMuralsQuery
>;
export const syncMensagemMurals = /* GraphQL */ `query SyncMensagemMurals(
  $filter: ModelMensagemMuralFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMensagemMurals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      mensagem
      dataPostagem
      horaPostagem
      status
      dataAprovacao
      cidade
      estado
      nomeUsuario
      tipoMensagem
      resumo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mensagemMuralCidadeId
      mensagemMuralFalecimentoId
      mensagemMuralUserId
      mensagemMuralAnonymousUserId
      mensagemMuralAprovadoPorId
      mensagemMuralMemorialId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMensagemMuralsQueryVariables,
  APITypes.SyncMensagemMuralsQuery
>;
export const getCemiterio = /* GraphQL */ `query GetCemiterio($id: ID!) {
  getCemiterio(id: $id) {
    id
    nome
    logradouro
    cep
    bairro
    Cidade {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    Owner {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    Funerarias {
      nextToken
      startedAt
      __typename
    }
    Velorios {
      nextToken
      startedAt
      __typename
    }
    cidade
    estado
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    cemiterioCidadeId
    cemiterioOwnerId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCemiterioQueryVariables,
  APITypes.GetCemiterioQuery
>;
export const listCemiterios = /* GraphQL */ `query ListCemiterios(
  $filter: ModelCemiterioFilterInput
  $limit: Int
  $nextToken: String
) {
  listCemiterios(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nome
      logradouro
      cep
      bairro
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cemiterioCidadeId
      cemiterioOwnerId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCemiteriosQueryVariables,
  APITypes.ListCemiteriosQuery
>;
export const syncCemiterios = /* GraphQL */ `query SyncCemiterios(
  $filter: ModelCemiterioFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCemiterios(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nome
      logradouro
      cep
      bairro
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cemiterioCidadeId
      cemiterioOwnerId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCemiteriosQueryVariables,
  APITypes.SyncCemiteriosQuery
>;
export const getMesorregiao = /* GraphQL */ `query GetMesorregiao($id: ID!) {
  getMesorregiao(id: $id) {
    id
    nome
    idIbge
    Estado {
      id
      nome
      sigla
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      estadoRegiaoId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mesorregiaoEstadoId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMesorregiaoQueryVariables,
  APITypes.GetMesorregiaoQuery
>;
export const listMesorregiaos = /* GraphQL */ `query ListMesorregiaos(
  $filter: ModelMesorregiaoFilterInput
  $limit: Int
  $nextToken: String
) {
  listMesorregiaos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mesorregiaoEstadoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMesorregiaosQueryVariables,
  APITypes.ListMesorregiaosQuery
>;
export const syncMesorregiaos = /* GraphQL */ `query SyncMesorregiaos(
  $filter: ModelMesorregiaoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMesorregiaos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mesorregiaoEstadoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMesorregiaosQueryVariables,
  APITypes.SyncMesorregiaosQuery
>;
export const getMicrorregiao = /* GraphQL */ `query GetMicrorregiao($id: ID!) {
  getMicrorregiao(id: $id) {
    id
    nome
    idIbge
    Mesorregiao {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mesorregiaoEstadoId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    microrregiaoMesorregiaoId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMicrorregiaoQueryVariables,
  APITypes.GetMicrorregiaoQuery
>;
export const listMicrorregiaos = /* GraphQL */ `query ListMicrorregiaos(
  $filter: ModelMicrorregiaoFilterInput
  $limit: Int
  $nextToken: String
) {
  listMicrorregiaos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      microrregiaoMesorregiaoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMicrorregiaosQueryVariables,
  APITypes.ListMicrorregiaosQuery
>;
export const syncMicrorregiaos = /* GraphQL */ `query SyncMicrorregiaos(
  $filter: ModelMicrorregiaoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMicrorregiaos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      microrregiaoMesorregiaoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMicrorregiaosQueryVariables,
  APITypes.SyncMicrorregiaosQuery
>;
export const getRegiao = /* GraphQL */ `query GetRegiao($id: ID!) {
  getRegiao(id: $id) {
    id
    sigla
    nome
    idIbge
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRegiaoQueryVariables, APITypes.GetRegiaoQuery>;
export const listRegiaos = /* GraphQL */ `query ListRegiaos(
  $filter: ModelRegiaoFilterInput
  $limit: Int
  $nextToken: String
) {
  listRegiaos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sigla
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRegiaosQueryVariables,
  APITypes.ListRegiaosQuery
>;
export const syncRegiaos = /* GraphQL */ `query SyncRegiaos(
  $filter: ModelRegiaoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRegiaos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sigla
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRegiaosQueryVariables,
  APITypes.SyncRegiaosQuery
>;
export const getEstado = /* GraphQL */ `query GetEstado($id: ID!) {
  getEstado(id: $id) {
    id
    nome
    sigla
    idIbge
    Regiao {
      id
      sigla
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    estadoRegiaoId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEstadoQueryVariables, APITypes.GetEstadoQuery>;
export const listEstados = /* GraphQL */ `query ListEstados(
  $filter: ModelEstadoFilterInput
  $limit: Int
  $nextToken: String
) {
  listEstados(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nome
      sigla
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      estadoRegiaoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEstadosQueryVariables,
  APITypes.ListEstadosQuery
>;
export const syncEstados = /* GraphQL */ `query SyncEstados(
  $filter: ModelEstadoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEstados(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nome
      sigla
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      estadoRegiaoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEstadosQueryVariables,
  APITypes.SyncEstadosQuery
>;
export const getCidade = /* GraphQL */ `query GetCidade($id: ID!) {
  getCidade(id: $id) {
    id
    nome
    Estado {
      id
      nome
      sigla
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      estadoRegiaoId
      __typename
    }
    idIbge
    Microrregiao {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      microrregiaoMesorregiaoId
      __typename
    }
    Mesorregiao {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mesorregiaoEstadoId
      __typename
    }
    Regiao {
      id
      sigla
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    cidadeEstadoId
    cidadeMicrorregiaoId
    cidadeMesorregiaoId
    cidadeRegiaoId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCidadeQueryVariables, APITypes.GetCidadeQuery>;
export const listCidades = /* GraphQL */ `query ListCidades(
  $filter: ModelCidadeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCidades(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCidadesQueryVariables,
  APITypes.ListCidadesQuery
>;
export const syncCidades = /* GraphQL */ `query SyncCidades(
  $filter: ModelCidadeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCidades(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCidadesQueryVariables,
  APITypes.SyncCidadesQuery
>;
export const getVelorio = /* GraphQL */ `query GetVelorio($id: ID!) {
  getVelorio(id: $id) {
    id
    nome
    logradouro
    cep
    Cidade {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    bairro
    Owner {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    Funerarias {
      nextToken
      startedAt
      __typename
    }
    cemiterios {
      nextToken
      startedAt
      __typename
    }
    cidade
    estado
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    velorioCidadeId
    velorioOwnerId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVelorioQueryVariables,
  APITypes.GetVelorioQuery
>;
export const listVelorios = /* GraphQL */ `query ListVelorios(
  $filter: ModelVelorioFilterInput
  $limit: Int
  $nextToken: String
) {
  listVelorios(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nome
      logradouro
      cep
      bairro
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      velorioCidadeId
      velorioOwnerId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVeloriosQueryVariables,
  APITypes.ListVeloriosQuery
>;
export const syncVelorios = /* GraphQL */ `query SyncVelorios(
  $filter: ModelVelorioFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncVelorios(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nome
      logradouro
      cep
      bairro
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      velorioCidadeId
      velorioOwnerId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncVeloriosQueryVariables,
  APITypes.SyncVeloriosQuery
>;
export const getFalecimento = /* GraphQL */ `query GetFalecimento($id: ID!) {
  getFalecimento(id: $id) {
    id
    nome
    sobrenome
    dataFalecimento
    Funeraria {
      id
      cnpj
      nome
      logradouro
      cep
      telefone1
      telefone2
      email
      site
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      funerariaCidadeId
      __typename
    }
    searchName
    idade
    tempoDeVida
    meses
    Velorio {
      id
      nome
      logradouro
      cep
      bairro
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      velorioCidadeId
      velorioOwnerId
      __typename
    }
    dataInicioVelorio
    horaInicioVelorio
    dataFimVelorio
    horaFimVelorio
    dataSepultamento
    horaSepultamento
    salaVelorio
    Cemiterio {
      id
      nome
      logradouro
      cep
      bairro
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cemiterioCidadeId
      cemiterioOwnerId
      __typename
    }
    Cidade {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    sobre
    imageKey
    apelido
    cidade
    estado
    sexo
    dataNascimento
    faixaEtaria
    OpenAiAssistant {
      id
      assistantId
      object
      model
      instructions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    FalecimentoFotos {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    falecimentoFunerariaId
    falecimentoVelorioId
    falecimentoCemiterioId
    falecimentoCidadeId
    falecimentoOpenAiAssistantId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFalecimentoQueryVariables,
  APITypes.GetFalecimentoQuery
>;
export const listFalecimentos = /* GraphQL */ `query ListFalecimentos(
  $filter: ModelFalecimentoFilterInput
  $limit: Int
  $nextToken: String
) {
  listFalecimentos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nome
      sobrenome
      dataFalecimento
      searchName
      idade
      tempoDeVida
      meses
      dataInicioVelorio
      horaInicioVelorio
      dataFimVelorio
      horaFimVelorio
      dataSepultamento
      horaSepultamento
      salaVelorio
      sobre
      imageKey
      apelido
      cidade
      estado
      sexo
      dataNascimento
      faixaEtaria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      falecimentoFunerariaId
      falecimentoVelorioId
      falecimentoCemiterioId
      falecimentoCidadeId
      falecimentoOpenAiAssistantId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFalecimentosQueryVariables,
  APITypes.ListFalecimentosQuery
>;
export const syncFalecimentos = /* GraphQL */ `query SyncFalecimentos(
  $filter: ModelFalecimentoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFalecimentos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nome
      sobrenome
      dataFalecimento
      searchName
      idade
      tempoDeVida
      meses
      dataInicioVelorio
      horaInicioVelorio
      dataFimVelorio
      horaFimVelorio
      dataSepultamento
      horaSepultamento
      salaVelorio
      sobre
      imageKey
      apelido
      cidade
      estado
      sexo
      dataNascimento
      faixaEtaria
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      falecimentoFunerariaId
      falecimentoVelorioId
      falecimentoCemiterioId
      falecimentoCidadeId
      falecimentoOpenAiAssistantId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFalecimentosQueryVariables,
  APITypes.SyncFalecimentosQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    Funerarias {
      nextToken
      startedAt
      __typename
    }
    nome
    status
    avatar
    imageKey
    Cidade {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    role
    memorials {
      nextToken
      startedAt
      __typename
    }
    sexo
    lastIpAddress
    anonymousUserId
    FalecimentoFotos {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userCidadeId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getFuneraria = /* GraphQL */ `query GetFuneraria($id: ID!) {
  getFuneraria(id: $id) {
    id
    cnpj
    nome
    logradouro
    cep
    telefone1
    telefone2
    email
    site
    users {
      nextToken
      startedAt
      __typename
    }
    Cidade {
      id
      nome
      idIbge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cidadeEstadoId
      cidadeMicrorregiaoId
      cidadeMesorregiaoId
      cidadeRegiaoId
      __typename
    }
    velorios {
      nextToken
      startedAt
      __typename
    }
    cemiterios {
      nextToken
      startedAt
      __typename
    }
    cidade
    estado
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    funerariaCidadeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFunerariaQueryVariables,
  APITypes.GetFunerariaQuery
>;
export const listFunerarias = /* GraphQL */ `query ListFunerarias(
  $filter: ModelFunerariaFilterInput
  $limit: Int
  $nextToken: String
) {
  listFunerarias(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cnpj
      nome
      logradouro
      cep
      telefone1
      telefone2
      email
      site
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      funerariaCidadeId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFunerariasQueryVariables,
  APITypes.ListFunerariasQuery
>;
export const syncFunerarias = /* GraphQL */ `query SyncFunerarias(
  $filter: ModelFunerariaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFunerarias(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      cnpj
      nome
      logradouro
      cep
      telefone1
      telefone2
      email
      site
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      funerariaCidadeId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFunerariasQueryVariables,
  APITypes.SyncFunerariasQuery
>;
export const getMemorialUser = /* GraphQL */ `query GetMemorialUser($id: ID!) {
  getMemorialUser(id: $id) {
    id
    memorialId
    userId
    memorial {
      id
      url
      emailSuperAdmin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      memorialSuperAdminId
      memorialCriadorId
      memorialFalecimentoId
      __typename
    }
    user {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMemorialUserQueryVariables,
  APITypes.GetMemorialUserQuery
>;
export const listMemorialUsers = /* GraphQL */ `query ListMemorialUsers(
  $filter: ModelMemorialUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listMemorialUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      memorialId
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMemorialUsersQueryVariables,
  APITypes.ListMemorialUsersQuery
>;
export const syncMemorialUsers = /* GraphQL */ `query SyncMemorialUsers(
  $filter: ModelMemorialUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMemorialUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      memorialId
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMemorialUsersQueryVariables,
  APITypes.SyncMemorialUsersQuery
>;
export const memorialUsersByMemorialId = /* GraphQL */ `query MemorialUsersByMemorialId(
  $memorialId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMemorialUserFilterInput
  $limit: Int
  $nextToken: String
) {
  memorialUsersByMemorialId(
    memorialId: $memorialId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      memorialId
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MemorialUsersByMemorialIdQueryVariables,
  APITypes.MemorialUsersByMemorialIdQuery
>;
export const memorialUsersByUserId = /* GraphQL */ `query MemorialUsersByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMemorialUserFilterInput
  $limit: Int
  $nextToken: String
) {
  memorialUsersByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      memorialId
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MemorialUsersByUserIdQueryVariables,
  APITypes.MemorialUsersByUserIdQuery
>;
export const getCemiterioFuneraria = /* GraphQL */ `query GetCemiterioFuneraria($id: ID!) {
  getCemiterioFuneraria(id: $id) {
    id
    cemiterioId
    funerariaId
    cemiterio {
      id
      nome
      logradouro
      cep
      bairro
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cemiterioCidadeId
      cemiterioOwnerId
      __typename
    }
    funeraria {
      id
      cnpj
      nome
      logradouro
      cep
      telefone1
      telefone2
      email
      site
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      funerariaCidadeId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCemiterioFunerariaQueryVariables,
  APITypes.GetCemiterioFunerariaQuery
>;
export const listCemiterioFunerarias = /* GraphQL */ `query ListCemiterioFunerarias(
  $filter: ModelCemiterioFunerariaFilterInput
  $limit: Int
  $nextToken: String
) {
  listCemiterioFunerarias(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cemiterioId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCemiterioFunerariasQueryVariables,
  APITypes.ListCemiterioFunerariasQuery
>;
export const syncCemiterioFunerarias = /* GraphQL */ `query SyncCemiterioFunerarias(
  $filter: ModelCemiterioFunerariaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCemiterioFunerarias(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      cemiterioId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCemiterioFunerariasQueryVariables,
  APITypes.SyncCemiterioFunerariasQuery
>;
export const cemiterioFunerariasByCemiterioId = /* GraphQL */ `query CemiterioFunerariasByCemiterioId(
  $cemiterioId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCemiterioFunerariaFilterInput
  $limit: Int
  $nextToken: String
) {
  cemiterioFunerariasByCemiterioId(
    cemiterioId: $cemiterioId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cemiterioId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CemiterioFunerariasByCemiterioIdQueryVariables,
  APITypes.CemiterioFunerariasByCemiterioIdQuery
>;
export const cemiterioFunerariasByFunerariaId = /* GraphQL */ `query CemiterioFunerariasByFunerariaId(
  $funerariaId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCemiterioFunerariaFilterInput
  $limit: Int
  $nextToken: String
) {
  cemiterioFunerariasByFunerariaId(
    funerariaId: $funerariaId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cemiterioId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CemiterioFunerariasByFunerariaIdQueryVariables,
  APITypes.CemiterioFunerariasByFunerariaIdQuery
>;
export const getCemiterioVelorio = /* GraphQL */ `query GetCemiterioVelorio($id: ID!) {
  getCemiterioVelorio(id: $id) {
    id
    cemiterioId
    velorioId
    cemiterio {
      id
      nome
      logradouro
      cep
      bairro
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cemiterioCidadeId
      cemiterioOwnerId
      __typename
    }
    velorio {
      id
      nome
      logradouro
      cep
      bairro
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      velorioCidadeId
      velorioOwnerId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCemiterioVelorioQueryVariables,
  APITypes.GetCemiterioVelorioQuery
>;
export const listCemiterioVelorios = /* GraphQL */ `query ListCemiterioVelorios(
  $filter: ModelCemiterioVelorioFilterInput
  $limit: Int
  $nextToken: String
) {
  listCemiterioVelorios(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cemiterioId
      velorioId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCemiterioVeloriosQueryVariables,
  APITypes.ListCemiterioVeloriosQuery
>;
export const syncCemiterioVelorios = /* GraphQL */ `query SyncCemiterioVelorios(
  $filter: ModelCemiterioVelorioFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCemiterioVelorios(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      cemiterioId
      velorioId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCemiterioVeloriosQueryVariables,
  APITypes.SyncCemiterioVeloriosQuery
>;
export const cemiterioVeloriosByCemiterioId = /* GraphQL */ `query CemiterioVeloriosByCemiterioId(
  $cemiterioId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCemiterioVelorioFilterInput
  $limit: Int
  $nextToken: String
) {
  cemiterioVeloriosByCemiterioId(
    cemiterioId: $cemiterioId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cemiterioId
      velorioId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CemiterioVeloriosByCemiterioIdQueryVariables,
  APITypes.CemiterioVeloriosByCemiterioIdQuery
>;
export const cemiterioVeloriosByVelorioId = /* GraphQL */ `query CemiterioVeloriosByVelorioId(
  $velorioId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCemiterioVelorioFilterInput
  $limit: Int
  $nextToken: String
) {
  cemiterioVeloriosByVelorioId(
    velorioId: $velorioId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cemiterioId
      velorioId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CemiterioVeloriosByVelorioIdQueryVariables,
  APITypes.CemiterioVeloriosByVelorioIdQuery
>;
export const getVelorioFuneraria = /* GraphQL */ `query GetVelorioFuneraria($id: ID!) {
  getVelorioFuneraria(id: $id) {
    id
    velorioId
    funerariaId
    velorio {
      id
      nome
      logradouro
      cep
      bairro
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      velorioCidadeId
      velorioOwnerId
      __typename
    }
    funeraria {
      id
      cnpj
      nome
      logradouro
      cep
      telefone1
      telefone2
      email
      site
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      funerariaCidadeId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVelorioFunerariaQueryVariables,
  APITypes.GetVelorioFunerariaQuery
>;
export const listVelorioFunerarias = /* GraphQL */ `query ListVelorioFunerarias(
  $filter: ModelVelorioFunerariaFilterInput
  $limit: Int
  $nextToken: String
) {
  listVelorioFunerarias(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      velorioId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVelorioFunerariasQueryVariables,
  APITypes.ListVelorioFunerariasQuery
>;
export const syncVelorioFunerarias = /* GraphQL */ `query SyncVelorioFunerarias(
  $filter: ModelVelorioFunerariaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncVelorioFunerarias(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      velorioId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncVelorioFunerariasQueryVariables,
  APITypes.SyncVelorioFunerariasQuery
>;
export const velorioFunerariasByVelorioId = /* GraphQL */ `query VelorioFunerariasByVelorioId(
  $velorioId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelVelorioFunerariaFilterInput
  $limit: Int
  $nextToken: String
) {
  velorioFunerariasByVelorioId(
    velorioId: $velorioId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      velorioId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VelorioFunerariasByVelorioIdQueryVariables,
  APITypes.VelorioFunerariasByVelorioIdQuery
>;
export const velorioFunerariasByFunerariaId = /* GraphQL */ `query VelorioFunerariasByFunerariaId(
  $funerariaId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelVelorioFunerariaFilterInput
  $limit: Int
  $nextToken: String
) {
  velorioFunerariasByFunerariaId(
    funerariaId: $funerariaId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      velorioId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VelorioFunerariasByFunerariaIdQueryVariables,
  APITypes.VelorioFunerariasByFunerariaIdQuery
>;
export const getUserFuneraria = /* GraphQL */ `query GetUserFuneraria($id: ID!) {
  getUserFuneraria(id: $id) {
    id
    userId
    funerariaId
    user {
      id
      email
      nome
      status
      avatar
      imageKey
      role
      sexo
      lastIpAddress
      anonymousUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userCidadeId
      __typename
    }
    funeraria {
      id
      cnpj
      nome
      logradouro
      cep
      telefone1
      telefone2
      email
      site
      cidade
      estado
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      funerariaCidadeId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserFunerariaQueryVariables,
  APITypes.GetUserFunerariaQuery
>;
export const listUserFunerarias = /* GraphQL */ `query ListUserFunerarias(
  $filter: ModelUserFunerariaFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserFunerarias(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserFunerariasQueryVariables,
  APITypes.ListUserFunerariasQuery
>;
export const syncUserFunerarias = /* GraphQL */ `query SyncUserFunerarias(
  $filter: ModelUserFunerariaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserFunerarias(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserFunerariasQueryVariables,
  APITypes.SyncUserFunerariasQuery
>;
export const userFunerariasByUserId = /* GraphQL */ `query UserFunerariasByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFunerariaFilterInput
  $limit: Int
  $nextToken: String
) {
  userFunerariasByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserFunerariasByUserIdQueryVariables,
  APITypes.UserFunerariasByUserIdQuery
>;
export const userFunerariasByFunerariaId = /* GraphQL */ `query UserFunerariasByFunerariaId(
  $funerariaId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFunerariaFilterInput
  $limit: Int
  $nextToken: String
) {
  userFunerariasByFunerariaId(
    funerariaId: $funerariaId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      funerariaId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserFunerariasByFunerariaIdQueryVariables,
  APITypes.UserFunerariasByFunerariaIdQuery
>;
