import { useEffect, useState } from "react";
import { BackAndNextButtons } from "../../../../../_lapide/components/buttons/BackAndNextButtons";
import { AssistenteMensagemStepEnum, useAssistenteMensagem } from "../AssistenteMensagemProvider";

const StepMensagemUsuario = () => {
  const { handler, salvarMensagemUsuario, goToPage, isEditing, closeAssistente, isLoading } = useAssistenteMensagem();
  const [ textoValido, setTextoValido ] = useState(false);
  const [ textoAvancar, setTextoAvancar ] = useState('5 Palavras pra Avançar')
  const [ textoLivre, setTextoLivre ] = useState(handler.mensagemUsuario ?? '')
  
  const handleNext = async() => {
    if (textoLivre) {
      await salvarMensagemUsuario(textoLivre)

      if (isEditing) {
        closeAssistente()
        return;
      }

      goToPage(AssistenteMensagemStepEnum.ASSISTENTE_MENSAGEM_USUARIO, handler)
    }
  }

  const handleBack = async () => {
    if (isEditing) {
      closeAssistente()
    }

    goToPage(AssistenteMensagemStepEnum.INICIO_ASSISTENTE, handler)
  }

  const formatarTextoAvancar = (textoLivre: string) => {
    const regex = /\S+/g;
    const numPalavras = (textoLivre.trim().match(regex) || []).length;
    setTextoValido(numPalavras >= 5);

    setTextoAvancar(numPalavras >= 5 ? 'Avançar' : numPalavras === 4 ? `1 Palavra` : `${5 - numPalavras} Palavras`);
  }

  useEffect(() => {
    formatarTextoAvancar(textoLivre);
  }, [textoLivre])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title text-center fs-3 px-3 mb-4">{`Escreva sua homenagem para ${handler?.getApelidoOuNomeFalecido()}`}</h2>
          <div className='w-100'>
            <div className='fv-row mb-10'>
              <textarea
                className='form-control form-control-lg form-control-solid fs-3'
                name='mensagem'
                placeholder='Clique aqui e escreva sua homenagem'
                rows={10}
                onChange={(e) => setTextoLivre(e.target.value)}
                value={textoLivre}
              />
            </div>
          </div>
        </div>
      </div>
      <BackAndNextButtons
        className="p-3"
        nextText={textoAvancar}
        nextEnabled={textoValido}
        onGoNext={handleNext}
        isNextLoading={isLoading}
        
        backVisible={true}
        isBackLoading={false}
        onGoBack={handleBack}
      />
    </>
  )
}

export { StepMensagemUsuario };

