import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { MainLayout } from '../../../_metronic/layout/MainLayout';
import { MasterLayout } from '../../../_metronic/layout/MasterLayout';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Falecimento, Memorial, MensagemMural } from '../../../models';
import { MemorialHeader } from '../../modules/apps/memorial/header/MemorialHeader';
import { getMensagemMuralById } from '../../modules/apps/mural/core/_requests';
import { MensagemMuralCarousel } from '../../modules/apps/mural/mensagem-mural-view/MensagemMuralCarousel';
import { useAuth } from '../../modules/auth';

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Homenagem',
    path: '/homenagem',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const HomenagemPage = () => {
  const { isDataStoreConnected } = useAuth()
  const { id } = useParams()
  const navigate = useNavigate()
  const [ mensagemMural, setMensagemMural ] = useState<MensagemMural>()
  const [ falecimento, setFalecimento ] = useState<Falecimento>()
  const [ memorial, setMemorial ] = useState<Memorial>()

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
  }
  
  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'none')
    }
  }
  

  const loadMensagemMural = async () => {
    const mensagemMural = await getMensagemMuralById(id as string)

    if (!mensagemMural && isDataStoreConnected){
      navigate('/');
      return
    }

    const falecimento = await mensagemMural?.Falecimento
    const memorial = await mensagemMural?.Memorial

    if (!falecimento && isDataStoreConnected){
      navigate('/');
      return
    }

    if (!memorial && isDataStoreConnected){
      navigate('/');
      return
    }

    return { falecimento, mensagemMural, memorial }
  }

  useEffect(() => {
    enableSplashScreen()
  },[]);

  useEffect(() => {
    if (isDataStoreConnected) {
      loadMensagemMural().then(result => {
        setFalecimento(result?.falecimento)
        setMensagemMural(result?.mensagemMural)
        setMemorial(result?.memorial)
        disableSplashScreen()
      }).catch(error => {
        disableSplashScreen()
        navigate('/');
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isDataStoreConnected]);

  return (
    <>
      { falecimento && mensagemMural && memorial &&
        <Routes>
          <Route index element={<Navigate to='homenagem' />} />
          <Route element={<MasterLayout />}>
            <Route
              element={
                <MainLayout main={
                    <>
                      <MemorialHeader falecimento={falecimento} memorial={memorial}/>
                      <Outlet />
                    </>
                  }
                search={<></>}
                />
              }
            >
              <Route
                path='homenagem'
                element={
                  <>
                    <PageTitle breadcrumbs={profileBreadCrumbs}>{`${falecimento.nome}`}</PageTitle>
                    <MensagemMuralCarousel mensagemMuralInit={mensagemMural}/>
                  </>
                }
              />
            </Route>
          </Route>
        </Routes>
      }
    </>
    
  );
}

export { HomenagemPage };

