/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Falecimento, Memorial } from '../../../../../models';
import { MemorialHeaderMD } from './components/MemorialHeaderMD';
import { MemorialHeaderSM } from './components/MemorialHeaderSM';

type Props = {
  falecimento: Falecimento
  memorial : Memorial
}

const MemorialHeader: React.FC<Props> = ({ falecimento, memorial }) => {
  const [ scrollTop, setScrollTop ] = useState(0);

  useEffect(() => {
    function handleScroll(element: HTMLElement) {
      if (element.scrollTop === 0 || element.scrollTop >= 250) {
        setScrollTop(element.scrollTop);
      }
    }

    const mainContent = document.getElementById("main-content");

    if (mainContent) {
      mainContent.addEventListener('scroll', () => handleScroll(mainContent));
      
      return () => {
        mainContent.removeEventListener('scroll', () => handleScroll(mainContent));
      };
    }
  }, [scrollTop]);
  
  return (
    <div style={{ marginBottom: isMobile ? '0px' : '170px'}}>
      { !isMobile && <MemorialHeaderMD falecimento={falecimento} memorial={memorial} className='position-fixed top-10 start-50 translate-middle-x z-index-2' />}
      
      { scrollTop <= 200 && isMobile && <MemorialHeaderMD falecimento={falecimento} memorial={memorial} />}
      { scrollTop >= 250 && isMobile && <MemorialHeaderSM falecimento={falecimento} />}
    </div>
  )
}

export { MemorialHeader };

