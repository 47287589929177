export const findEnumKeyByValue = (enumObj: any, value: string): string | undefined | null => {
  const numValue = Number(value);
  if (isNaN(numValue)) {
    return null;
  }

  for (const [key, enumValue] of Object.entries(enumObj)) {
    if (enumValue === numValue) {
      return key;
    }
  }

  return;
};

export function findEnumValueByString<T extends Record<string, number | string>>(enumObj: T, value: string): T[keyof T] | undefined {
  const numValue = Number(value);
  if (isNaN(numValue)) {
    return undefined;
  }

  for (const key in enumObj) {
    if (enumObj[key] === numValue) {
      return enumObj[key] as T[keyof T];
    }
  }
  return undefined;
}