import { useEffect } from 'react'
import { useAuth } from './core/Auth'
import { useNavigate } from 'react-router'

export function Logout() {
  const { logout } = useAuth()
  const navigate = useNavigate()
  
  useEffect(() => {
    logout()
    navigate('/login')
    
  }, [logout, navigate])

  return (
    <></>
  )
}
