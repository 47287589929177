import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { useAssistenteMensagem } from "../../../assistente-mensagem/AssistenteMensagemProvider";
import { MensagemMuralLayout } from "../../mensagem-mural-view/MensagemMuralLayout";

const StepRevisao = () => {
  const { 
    handler,
    profilePhoto,
    coverPhoto,
    editarFoto,
    editarCidade,
    editarNome,
    editarMensagem,
    isLoading,
    postarMensagemMural,
  } = useAssistenteMensagem()
  

  const handleNextClick = async () => {
    await postarMensagemMural();
  }

  return (
    <div className='w-100'>
      <div className='fv-row'>
        <label className='d-flex align-items-center text-center fs-3 fw-semibold mb-3 justify-content-center'>
          <span>Sua mensagem será visualizada assim:</span>
        </label>
        {/*begin::Form Group */}
        {handler?.nomeUsuario && (handler.mensagem) && (
          <div className='fv-row mb-10'>
            <MensagemMuralLayout
              className=''
              name={handler.nomeUsuario}
              message={handler.mensagem ?? ''}
              avatarUrl={profilePhoto?.url}
              coverPhotoUrl={coverPhoto?.url}
              dataPostagem={moment().format('YYYY-MM-DD')}
              horaPostagem={moment().format('HH:mm')}
              cidade={handler.cidadeEstado?.nomeCidade ?? ''}
              estado={handler.cidadeEstado?.siglaEstado ?? ''}
            />
          </div>
        )}
        {/*end::Form Group */}
      </div>

      <div className='fv-row mt-n5 mb-5'>
        <div className='d-flex flex-stack mx-3'>
          <div className="me-2">
            <Dropdown>
              <Dropdown.Toggle variant="light-primary" id="dropdown-editar" className="btn-lg fs-3 me-3" disabled={isLoading}>
                Editar
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="fs-3" onClick={editarNome}>Nome</Dropdown.Item>
                <Dropdown.Item className="fs-3" onClick={editarMensagem}>Mensagem</Dropdown.Item>
                <Dropdown.Item className="fs-3" onClick={editarFoto}>Foto</Dropdown.Item>
                <Dropdown.Item className="fs-3" onClick={editarCidade}>Cidade</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <button
              type='button'
              className='btn btn-lg btn-primary fs-3'
              onClick={handleNextClick}
              disabled={isLoading}
            >
              { isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Postar
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}

              { !isLoading && (
                <>
                  Postar
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-1 me-0'
                  />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { StepRevisao };

