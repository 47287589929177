import { KTSVG } from '../../../helpers';
import { MenuItems } from '../menu/MenuItems';

const Sidebar = () => {
  const handleSidebarMenuClose = () => {
    const menu = document.getElementById('lapide_mobile_sidebar');
    if (menu){
      menu.classList.remove('drawer-on')
    }
  }
  
  return (
    <div
      id="lapide_mobile_sidebar"
      className="app-sidebar flex-column drawer drawer-start z-index-200 bg-body"
      style={{width: '225px !important'}}
      >
      <div className='card shadow-none w-100'>
        <div className='card-header py-0'>
          <h5 className="card-title fw-bold text-gray-600">NAVEGAÇÃO</h5>
          <div className='card-toolbar'>
          <button type="button" className="btn btn-sm btn-icon explore-btn-dismiss me-n5" onClick={handleSidebarMenuClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
          </button>
          </div>
        </div>
        <div className='card-body p-0'>
          <div className="menu menu-rounded menu-sub-indention fw-semibold fs-3 mt-5 ms-3 menu-light">
            <MenuItems />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Sidebar };

