import { Response } from "../../../../../_metronic/helpers";
import { Cidade, Estado } from "../../../../../models";
import { IbgeEstado } from "../../estados/core/_models";

export type CidadesQueryResponse = Response<Array<Cidade>>

export type CidadeForm = {
    id: string,
    nome: string,
    idIbge: string,
    createdAt: string,
    updatedAt: string
}

export const cidadeInit: Cidade = {
    id: '',
    nome: '',
    idIbge: '',
    createdAt: '',
    updatedAt: ''
} as Cidade;

export type IbgeMesorregiao = {
    id: string
    nome: string
    UF: IbgeEstado
}

export type IbgeMicrorregiao = {
    id: string
    nome: string
    mesorregiao: IbgeMesorregiao
}

export type IbgeCidade = {
    id: string
    nome: string
    microrregiao: IbgeMicrorregiao
}

export type CidadeSelect = {
    value: IbgeCidade,
    label: string
}

export interface ICidadeEstado {
    cidade: Cidade
    estado?: Estado
    nomeCidade: string
    siglaEstado?: string
    descricao: string
}

export const makeCidadeEstado =async (cidade: Cidade) : Promise<ICidadeEstado> => {
    const estado = await cidade.Estado;
    const descricaoEstado = estado ? `/${estado.sigla}` : '';

    return {
        cidade,
        estado,
        nomeCidade: cidade.nome,
        siglaEstado: estado?.sigla,
        descricao: `${cidade.nome}${descricaoEstado}`,
    }
}