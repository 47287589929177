import { FC, useEffect, useState } from 'react';

import Select from 'react-select';
import { IbgeEstado } from '../estados/core/_models';
import { CidadeSelect } from './core/_models';
import { getIbgeCidadePorEstado } from './core/_requests';

type Props = {
    ibgeEstado: IbgeEstado | undefined
    className: string
    isDisabled: boolean | false
    onSelectCidade: (value: CidadeSelect) => void
}

const CidadeSelector: FC<Props> = ({className, isDisabled, onSelectCidade, ibgeEstado}) => {
  const [ cidades, setCidades ] = useState<CidadeSelect[]>([]);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const loadCidades = async (ibgeEstado: IbgeEstado | undefined) => {
    setCidades([])
    
    if (ibgeEstado){
        setIsLoading(true)
        
        await getIbgeCidadePorEstado(ibgeEstado.id).then(result => {
        if (result.error === false) {
            const cidadesData = result.data!.map(item => ({
                value: item,
                label: `${item.nome}`
            }));
            setCidades(cidadesData)
        }
        }).catch(error => {
        console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }
  }

  const handleSelected = (newValue: CidadeSelect) => {
    onSelectCidade(newValue)
  }

  useEffect(() => {
    loadCidades(ibgeEstado);
        
  }, [isDisabled, ibgeEstado])

  return (
    <>
      <Select
        className={`basic-single ${className}`}
        classNamePrefix="select"
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        options={cidades}
        onChange={(newValue) => handleSelected(newValue as CidadeSelect) }
      />
    </>
  );
};

export { CidadeSelector };
