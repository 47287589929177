/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { isMobile } from 'react-device-detect'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { App } from '../App'
import { Logout, useAuth } from '../modules/auth'
import { ComingSoonPage } from '../modules/coming-soon/ComingSoonPage'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { HomePage } from '../pages/home/HomePage'
import { HomenagemPage } from '../pages/homenagem/HomenagemPage'
import { MemorialPage } from '../pages/memorial/MemorialPage'
import { PrivateRoutes } from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
    const { cognitoUser } = useAuth()

    return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          { isMobile &&
            <>
              <Route path='memorial/:id/*' element={<MemorialPage />} />
              <Route path='home/*' element={<HomePage />} />
              <Route path='homenagem/:id/*' element={<HomenagemPage />} />
            </>
          }
          <Route path='coming-soon/*' element={<ComingSoonPage />} />
          
          {cognitoUser && (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
            </>
          )}

          {!cognitoUser && (
            <>
              <Route path='/*' element={<PublicRoutes />} />
            </>
          )}

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }

