import { FC } from "react"
import { PostView } from "../PostViews/PostView"
import { PostFeedModel } from "../core/_models"

type Props = {
  post: PostFeedModel
}

const PostFeedItem : FC<Props> = ({ post }) => {
  return (
    <PostView post={post} />
  )
}

export { PostFeedItem }
