import axios from "axios"
import { IbgeEstado } from "./_models"

const URL_IBGE_ESTADOS = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome'

const getAllEstados = async () : Promise<{ data?: IbgeEstado[], error: boolean, message?: string }> => {
  const result = await axios.get<IbgeEstado[]>(URL_IBGE_ESTADOS)
    .then(response => {
        
      return { data: response.data, error: false }
    }).catch(error => {
       console.log("error", JSON.stringify(error))

       return { error: true, message: 'Não foi possível obter os Estados'}
    })

  return result
}

export { getAllEstados }