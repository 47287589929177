import 'bootstrap-icons/font/bootstrap-icons.css'; // Certifique-se de que os ícones do Bootstrap estejam incluídos
import { BackAndNextButtons } from '../../../../../_lapide/components/buttons/BackAndNextButtons';
import { AssistenteMensagemStepEnum, useAssistenteMensagem } from '../AssistenteMensagemProvider';

const StepInicioAssistente = () => {
  const { goToPage, perguntasHandler, handler} = useAssistenteMensagem()

  const handleBack = async () => {
    goToPage(AssistenteMensagemStepEnum.RELACIONAMENTO_FALECIDO, handler)
  }

  const handleEscrever = () => {
    goToPage(AssistenteMensagemStepEnum.MENSAGEM_USUARIO, handler)
  }

  const handleAssistente = () => {
    goToPage(AssistenteMensagemStepEnum.PERGUNTAS, handler)
    
    if (perguntasHandler) {
      perguntasHandler.iniciar();
    }
  }

  return (
    <div>
      <div className='card mb-3'>
        <div className='card-body p-3'>
          {/* Primeiro card */}
          <div className='card bg-transparent mb-3 text-center py-0'>
            <div className='card-body p-2'>
              <p className='card-subtitle m-3 fs-3'>Escreva sua homenagem como quiser. Você poderá optar por usar nossa Inteligência Artificial para efetuar correções e melhorias no seu texto.</p>
              <button className='btn btn-primary fs-4 mb-3' onClick={handleEscrever}>
                <i className='bi bi-pencil'></i> Escrever
              </button>
            </div>
          </div>

          {/* Segundo card */}
          <div className='card bg-transparent mb-3 text-center py-0'>
            <div className='card-body p-4'>
              <p className='card-subtitle mb-3 fs-3'>Responda a algumas perguntas para que nossa Inteligência Artificial possa gerar uma mensagem personalizada.</p>
              <button className='btn btn-primary fs-4' onClick={handleAssistente}>
                <i className='bi bi-question-circle'></i> Assistente
              </button>
            </div>
          </div>
        </div>
      </div>

      <BackAndNextButtons
        onGoBack={handleBack}
        nextVisible={false}
      />
    </div>
  )
}

export { StepInicioAssistente };

