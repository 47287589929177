import { Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const MenuItems = () => {
  const location = useLocation();

  return (
    <Nav className="flex-column mr-sm-2">
      <Nav.Link href="/home" className={location.pathname === '/home' ? 'active' : ''}>
        <i className={'fas fa-home fs-3 me-3'}></i>
        <span className={location.pathname === '/home' ? 'fw-bold' : ''}>Página Inicial</span>
      </Nav.Link>
    </Nav>
  );
};

export { MenuItems };

