import { ISignUpResult } from 'amazon-cognito-identity-js';
import { AnonymousUser, User } from '../../../../models';
import { ICidadeEstado, IbgeCidade } from '../../apps/cidades/core/_models';
export interface AuthModel {
  api_token: string
  refreshToken?: string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

export interface UserModel {
  id: number
  username: string
  password: string | undefined
  email: string
  first_name: string
  last_name: string
  fullname?: string
  occupation?: string
  companyName?: string
  phone?: string
  roles?: Array<number>
  pic?: string
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  timeZone?: string
  website?: 'https://keenthemes.com'
  emailSettings?: UserEmailSettingsModel
  auth?: AuthModel
  communication?: UserCommunicationModel
  address?: UserAddressModel
  socialNetworks?: UserSocialNetworksModel
}

export interface CognitoAuthModel {
  username: string
  pool: Pool
  Session: any
  client: Client2
  signInUserSession: SignInUserSession
  authenticationFlowType: string
  storage: Storage2
  keyPrefix: string
  userDataKey: string
  attributes: Attributes
  preferredMFA: string
  challengeName: string
}

export interface Pool {
  userPoolId: string
  clientId: string
  client: Client
  advancedSecurityDataCollectionFlag: boolean
  storage: Storage
}

export interface Client {
  endpoint: string
  fetchOptions: FetchOptions
}

export interface FetchOptions {}

export interface Storage {  
  kt_theme_mode_menu: string
  kt_theme_mode_value: string  
  "amplify-signin-with-hostedUI": string
  "kt-auth-react-v": string
}

export interface Client2 {
  endpoint: string
  fetchOptions: FetchOptions2
}

export interface FetchOptions2 {}

export interface SignInUserSession {
  idToken: IdToken
  refreshToken: RefreshToken
  accessToken: AccessToken
  clockDrift: number
}

export interface IdToken {
  jwtToken: string
  payload: CognitoUser
}

export interface CognitoUser {
  username: string
  pool: Pool
  Session: any
  client: Client2
  signInUserSession: SignInUserSession
  authenticationFlowType: string
  storage: Storage2
  keyPrefix: string
  userDataKey: string
  attributes: Attributes
  preferredMFA: string
}

export interface RefreshToken {
  token: string
}

export interface AccessToken {
  jwtToken: string
  payload: Payload2
}

export interface Payload2 {
  sub: string
  iss: string
  client_id: string
  origin_jti: string
  event_id: string
  token_use: string
  scope: string
  auth_time: number
  exp: number
  iat: number
  jti: string
  username: string
  'cognito:groups': string[]
}

export interface Storage2 {
  kt_theme_mode_menu: string
  kt_theme_mode_value: string  
  "amplify-signin-with-hostedUI": string
  "kt-auth-react-v": string
}

export interface Attributes {
  sub: string
  email_verified: boolean
  updated_at: string
  name: string
  email: string
  picture: string
}

export interface IRegisterUserEmail {
  email: string
}

export interface IRegisterUser {
  isLoading: boolean,
  nome?: string,
  email?: string
  password?: string,
  validationCode?: string,
  signupResult?: ISignUpResult,
  cidadeEstado?: ICidadeEstado,
  isCodeValidated: boolean,
  blobImage?: Blob,
  imageFileName?: string,
  user?: User,
}

export interface IForgotPassword {
  isLoading: boolean,
  email?: string
  password?: string,
  validationCode?: string,
  isCodeValidated: boolean,
}

export const initRegisterUser: IRegisterUser = {
  isLoading: false,
  nome: '',
  email: '',
  password: '',
  validationCode: '',
  signupResult: undefined,
  cidadeEstado: undefined,
  isCodeValidated: false,
  blobImage: undefined,
  imageFileName: '',
  user: undefined,
}

export const initForgotPassword: IForgotPassword = {
  isLoading: false,
  email: '',
  password: '',
  validationCode: '',
  isCodeValidated: false,
}

export type RegisterUserStepProps = {
  data: IRegisterUser
  updateData: (fieldsToUpdate: Partial<IRegisterUser>) => void
  hasError: boolean,
  errorMessage?: string
}

export type ForgotPasswordStepProps = {
  data: IForgotPassword
  updateData: (fieldsToUpdate: Partial<IForgotPassword>) => void
  hasError: boolean,
  errorMessage?: string
}

export enum UserTypeEnum {
  USER = 'User',
  ANONYMOUS = 'AnonymousUser'
} 

export interface IUser {
  id: string
  nome?: string | null
  email?: string
  avatar?: string | null
  ip?: string | null
  ibgeCidade?: IbgeCidade
  userCidadeId?: string | null
  userType?: UserTypeEnum
  original?: User | AnonymousUser | undefined
}

export const initAnonymousUser: IUser = {
  id: '',
  nome: undefined,
  email: '',
  avatar: '/media/avatars/blank.png',
  ip: '',
  userCidadeId: undefined,
  ibgeCidade: undefined,
  userType: UserTypeEnum.ANONYMOUS,
  original: undefined,
}

