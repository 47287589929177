import { DataStore, Predicates } from "aws-amplify";
import axios from "axios";
import { Cidade, Estado, Mesorregiao, Microrregiao, Regiao } from "../../../../../models";
import { IbgeEstado, IbgeRegiao } from "../../estados/core/_models";
import { CidadesQueryResponse, IbgeCidade, IbgeMesorregiao, IbgeMicrorregiao } from "./_models";

const URL_IBGE_MUNICIPIOS_POR_ESTADO = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/{ibgeEstadoId}/municipios?orderBy=nome'

const getCidadeById = async (id: string) : Promise<Cidade | undefined> => {
  return await DataStore.query(Cidade, id)
}

const getIbgeCidadePorEstado = async (ibgeEstadoId: string) : Promise<{ data?: IbgeCidade[], error: boolean, message?: string }> => {
  const url = URL_IBGE_MUNICIPIOS_POR_ESTADO.replace('{ibgeEstadoId}', ibgeEstadoId)
  
  const result = await axios.get<IbgeCidade[]>(url)
    .then(response => {
        
      return { data: response.data, error: false }
    }).catch(error => {
        console.log("error", JSON.stringify(error))

        return { error: true, message: 'Não foi possível obter as cidades'}
    })

  return result
}

const getFirstCidades = async () : Promise<CidadesQueryResponse> => {
    const results = await DataStore.query(Cidade, Predicates.ALL, {
        sort: s => ( s.updatedAt("DESCENDING") ),
        page: 0,
        limit: 10
      });
  
      return {
        data: results
      } as CidadesQueryResponse;
}

const getFirstIbgeCidades = async () : Promise<IbgeCidade[]> => {
    const cidades = await getFirstCidades()

    if (cidades.data) {
        const result = await Promise.all(cidades.data.map(async item => {
            const ibgeCidade = await convertCidadeToIbgeCidade(item);

            return ibgeCidade;
        }))

        return result;
    }

    return [];
}

const findOrCreateCidade = async (ibgeCidade: IbgeCidade) : Promise<Cidade | undefined> => {
  const cidadeList = await DataStore.query(Cidade, t => t.idIbge.eq(ibgeCidade.id));

  if (cidadeList && cidadeList.length > 0){
    return  cidadeList[0];
  }
  
  const regiaoCidade = ibgeCidade.microrregiao.mesorregiao.UF.regiao;
  const estadoCidade = ibgeCidade.microrregiao.mesorregiao.UF;
  const mesorregiaoCidade = ibgeCidade.microrregiao.mesorregiao;
  const microrregiaoCidade = ibgeCidade.microrregiao;
    
  const regiao = await findOrCreateRegiao(regiaoCidade);
  const estado = await findOrCreateEstado(estadoCidade, regiao);
  const mesorregiao = await findOrCreateMesorregiao(mesorregiaoCidade, estado);
  const microrregiao = await findOrCreateMicrorregiao(microrregiaoCidade, mesorregiao);

  const cidade = await DataStore.save(new Cidade({
    idIbge: ibgeCidade.id.toString(),
    nome: ibgeCidade.nome,
    Estado: estado,
    Mesorregiao: mesorregiao,
    Microrregiao: microrregiao,
    Regiao: regiao
  }));

  return cidade;
}

const findOrCreateRegiao = async (regiaoCidade: IbgeRegiao) : Promise<Regiao | undefined> => {
  const regiaoList = await DataStore.query(Regiao, t => t.idIbge.eq(regiaoCidade.id));

  if (regiaoList && regiaoList.length > 0){
    return regiaoList[0];
  }

  const regiao = await DataStore.save(new Regiao({
    idIbge: regiaoCidade.id.toString(),
    nome: regiaoCidade.nome,
    sigla: regiaoCidade.sigla
  }));

  const result = await DataStore.query(Regiao, regiao.id)

  return result;
}

const findOrCreateEstado = async (estadoCidade: IbgeEstado, regiao: Regiao | undefined) : Promise<Estado | undefined> => {
  const estadoList = await DataStore.query(Estado, t => t.idIbge.eq(estadoCidade.id));

  if (estadoList && estadoList.length > 0){
    return estadoList[0];
  }
  
  const estado = await DataStore.save(new Estado({
    idIbge: estadoCidade.id.toString(),
    nome: estadoCidade.nome,
    sigla: estadoCidade.sigla,
    Regiao: regiao,
    estadoRegiaoId: regiao?.id
  }));

  const result = await DataStore.query(Estado, estado.id)

  return result;
}

const findOrCreateMesorregiao = async (mesorregiaoCidade: IbgeMesorregiao, estado: Estado | undefined) : Promise<Mesorregiao | undefined> => {
  const mesorregiaoList = await DataStore.query(Mesorregiao, t => t.idIbge.eq(mesorregiaoCidade.id));

  if (mesorregiaoList && mesorregiaoList.length > 0){
    return mesorregiaoList[0];
  }
  
  const mesorregiao = await DataStore.save(new Mesorregiao({
    idIbge: mesorregiaoCidade.id.toString(),
    nome: mesorregiaoCidade.nome,
    Estado: estado,
    mesorregiaoEstadoId: estado?.id
  }));

  const result = await DataStore.query(Mesorregiao, mesorregiao.id)

  return result;
}

const findOrCreateMicrorregiao = async (microrregiaoCidade: IbgeMicrorregiao, mesorregiao: Mesorregiao | undefined) : Promise<Microrregiao | undefined> => {
  const microrregiaoList = await DataStore.query(Microrregiao, t => t.idIbge.eq(microrregiaoCidade.id));

  if (microrregiaoList && microrregiaoList.length > 0){
    return microrregiaoList[0];
  }
  
  const microrregiao = await DataStore.save(new Microrregiao({
    idIbge: microrregiaoCidade.id.toString(),
    nome: microrregiaoCidade.nome,
    Mesorregiao: mesorregiao,
    microrregiaoMesorregiaoId: mesorregiao?.id
  }));

  const result = await DataStore.query(Microrregiao, microrregiao.id)

  return result;
}

const convertCidadeToIbgeCidade = async (item: Cidade) : Promise<IbgeCidade> => {
  const regiao = await item.Regiao;
  const mesorregiao = await item.Mesorregiao;
  const microrregiao = await item.Microrregiao;
  const uf = await item.Estado;

  const ibgeCidade = {
    id: item.idIbge,
    nome: item.nome,
    microrregiao: {
      id: microrregiao?.idIbge,
      nome: microrregiao?.nome,
      mesorregiao: {
        id: mesorregiao?.idIbge,
        nome: mesorregiao?.nome,
        UF: {
          id: uf?.idIbge,
          nome: uf?.nome,
          sigla: uf?.sigla,
          regiao: {
            id: regiao?.idIbge,
            nome: regiao?.nome,
            sigla: regiao?.sigla
          }
        }
      }
    }
  } as IbgeCidade;

  return ibgeCidade;
}

export { convertCidadeToIbgeCidade, findOrCreateCidade, getCidadeById, getFirstIbgeCidades, getIbgeCidadePorEstado };

