import { API, GraphQLQuery } from "@aws-amplify/api";
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import moment from 'moment';
import { ListPostsQueryVariables } from "../../../../../API";
import { customSyncPostsFeed } from "../../../../../graphql/my-queries";
import { Post, PostActionEnum, PostTypeEnum, User } from "../../../../../models";
import { CustomSyncPostsFeedQuery } from "./_models";

export const getPostsFeed = async (page: number, limit: number) : Promise<Post[]> => {
  const results = await DataStore.query(Post, Predicates.ALL, {
    page,
    limit,
    sort: s => s.createdAt(SortDirection.DESCENDING),
  });

  return results
}



export const queryPostsFeed = async (nextToken: string|null, limit: number): Promise<CustomSyncPostsFeedQuery> => {
  const variables: ListPostsQueryVariables = {
    nextToken: nextToken,
    limit: limit,
    filter: {
      _deleted: { ne: true }
    }
  }

  const mensagens = await API.graphql<GraphQLQuery<CustomSyncPostsFeedQuery>>(
    {
      query: customSyncPostsFeed,
      variables
    }
  )

  return {
    ...mensagens.data,
    syncPosts: {
      ...mensagens.data?.syncPosts,
      items: mensagens.data?.syncPosts?.items,
    },
  } as CustomSyncPostsFeedQuery
}

export const criarPostHomenagem = async (user: User, memorialId: string, nomeUsuario: string, homenageado: string) => {
  await DataStore.save(
    new Post({
      datePost: moment().format('YYYY-MM-DD'),
      timePost: moment().format('HH:mm:ss'),
      postAction: PostActionEnum.CREATED,
      postType: PostTypeEnum.HOMENAGEM,
      postUserId: user.id,
      resourceId: memorialId,
      text: `${nomeUsuario} escreveu uma homenagem para ${homenageado}`,
      url: `/homenagem/${memorialId}`,
      User: user
    })
  )
}