import { BackAndNextButtons } from "../../../../../_lapide/components/buttons/BackAndNextButtons"
import { Photo } from "../../../../../_lapide/components/photos/PhotoModels"
import { PhotoUpload } from "../../../../../_lapide/components/photos/PhotoUpload"
import { AssistenteMensagemStepEnum, useAssistenteMensagem } from "../AssistenteMensagemProvider"

const StepUploadFoto = () => {
  const { goToPage, closeAssistente, handler, coverPhoto, setCoverPhoto, profilePhoto, setProfilePhoto } = useAssistenteMensagem()

  const handleNext = async() => {
    closeAssistente()
  }

  const handleCoverChange = (photos: Photo[])  => {
    setCoverPhoto(photos.length > 0 ? photos[0] : null);
  }

  const handleProfileChange = (photos: Photo[])  => {
    setProfilePhoto(photos.length > 0 ? photos[0] : null);
  }

  const handleBack = async() => {
    goToPage(AssistenteMensagemStepEnum.SELECIONAR_CIDADE, handler)
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card" style={{ minHeight: '70vh' }}>
            <div className="card-title px-5 pt-5">
              <h2 className="card-title text-center">{`Fotos da Homenagem`}</h2>
            </div>
            <div className="card-body d-flex flex-column justify-content-center">

              <h2 className="card-title text-center">{`Foto de Perfil`}</h2>
              <div className="mb-3">
                <PhotoUpload
                  maxPhotos={1}
                  onPhotosChange={handleProfileChange}
                  initialPhotos={profilePhoto ? [profilePhoto] : []}
                  dropZoneText="Clique aqui e selecione sua foto de perfil"
                  cropShape="round"
                  heightPx={100}
                  widthPx={100}
                />
              </div>
              <hr />
              <h2 className="card-title text-center">{`Foto de Capa`}</h2>
              <div>
                <PhotoUpload
                  maxPhotos={1}
                  onPhotosChange={handleCoverChange}
                  initialPhotos={coverPhoto ? [coverPhoto] : []}
                  dropZoneText={`Clique aqui e selecione uma foto com ${handler.getApelidoOuNomeFalecido()}`}
                  heightPx={400}
                  widthPx={800}
                />
              </div>
            </div>
          </div>

          <BackAndNextButtons
            className="p-2 my-3"
            nextVisible={true}
            nextText={ coverPhoto || profilePhoto ? "Avançar" : "Pular" }
            onGoNext={handleNext}

            backVisible={true}
            onGoBack={handleBack}
          />
        </div>
      </div>
    </>
  )
}

export { StepUploadFoto }

