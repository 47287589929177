import { Navigate, Route, Routes } from "react-router-dom"
import { MainLayout } from "../../../_metronic/layout/MainLayout"
import { MasterLayout } from "../../../_metronic/layout/MasterLayout"
import { PostFeed } from "../../modules/apps/post/PostFeed/PostFeed"

const HomePage = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="/" element={ <MainLayout main={<PostFeed />} search={<></>} /> }></Route>
      </Route>

      <Route path='*' element={<Navigate to='/home' />} />
    </Routes>
  )
}

export { HomePage }

