import { Storage } from "@aws-amplify/storage"
import { Auth, DataStore, Hub } from 'aws-amplify'
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { WithChildren } from '../../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AnonymousUser, User } from '../../../../models'
import { createUserPending, findAnonymousUser, findUserByEmail, updateUserStatusCompleted } from '../../apps/users/core/_requests'
import * as anonymousUserHelper from './AnonymousUserHelpers'
import * as authHelper from './AuthHelpers'
import * as userHelper from './UserHelpers'
import { AccessToken, CognitoUser } from './_models'
import { getCurrentAuthenticatedUser, signIn } from './_requests'

type AuthContextProps = {
  auth: AccessToken | undefined
  user: User | undefined
  anonymousUser: AnonymousUser | undefined
  cognitoUser: CognitoUser | undefined
  currentUser: User | undefined
  avatarUrl: string | undefined
  login: (username: string, password: string, user?: User) => Promise<{success: boolean, data: any}>
  logout: () => Promise<void>,
  saveUser: (user: User) => Promise<void>,
  saveAnonymousUser: (anonymousUser: AnonymousUser) => Promise<void>,
  completeLogin: (cognitoUser: CognitoUser, user: User) => Promise<void>,
  isUserAdmin: boolean | false
  isDataStoreConnected: boolean | false,
  isRegistering: boolean | false,
  setIsRegistering: Dispatch<SetStateAction<boolean | false>>,
  isChangingPassword: boolean | false,
  setIsChangingPassword: Dispatch<SetStateAction<boolean | false>>,
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  user: userHelper.getUser(),
  anonymousUser: undefined,
  cognitoUser: undefined,
  currentUser: undefined,
  avatarUrl: undefined,
  login: async (username: string, password: string, user?: User) : Promise<{success: boolean, data: any}> => { return {success: false, data: undefined } },
  logout: async () => {},
  saveUser: async (user: User) => {},
  saveAnonymousUser: async(anonymousUser: AnonymousUser) => {},
  completeLogin: async (cognitoUser: CognitoUser, user: User) => {},
  isUserAdmin: false,
  isDataStoreConnected: false,
  isRegistering: false,
  setIsRegistering: () => {},
  isChangingPassword: false,
  setIsChangingPassword: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AccessToken | undefined>(initAuthContextPropsState.auth)
  const [user, setUser] = useState<User | undefined>(initAuthContextPropsState.user)
  const [anonymousUser, setAnonymousUser] = useState<AnonymousUser | undefined>(initAuthContextPropsState.anonymousUser)
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>()
  const [currentUser, setCurrentUser] = useState<User | undefined>()
  const [isUserAdmin, setIsUserAdmin] = useState<boolean | false>(false)
  const [isDataStoreConnected, setIsDataStoreConnected] = useState<boolean>(false)
  const [isRegistering, setIsRegistering] = useState<boolean>(false)
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false)
  const [avatarUrl, setAvatarUrl] = useState<string>()
  

  const saveAuth = async (auth: AccessToken | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const saveUser = async (user?: User) => {
    setUser(undefined)
    if (user) {
      userHelper.setUser(user)
      setUser(user)
    } else {
      userHelper.removeUser()
      setUser(undefined)
    }
  }

  const saveAnonymousUser = async (anonymousUser?: AnonymousUser) => {
    setAnonymousUser(undefined)
    if (anonymousUser) {
      anonymousUserHelper.setAnonymousUserId(anonymousUser.id)
      setAnonymousUser(anonymousUser)
    } else {
      anonymousUserHelper.removeAnonymousUser()
      setAnonymousUser(undefined)
    }
  }

  const setAdmin  = async (cognitoUser: CognitoUser) : Promise<boolean> => {
    if (cognitoUser.signInUserSession.accessToken.payload['cognito:groups']){
      const isAdmin = cognitoUser.signInUserSession.accessToken.payload['cognito:groups'].includes('ADMIN');
      setIsUserAdmin(isAdmin)
      return isAdmin;
    }

    return false;
  }

  const completeLogin = async (cognitoUser: CognitoUser, user: User) => {
    await setAdmin(cognitoUser)
    setCognitoUser(cognitoUser)
    
    const userFinal = await updateUserStatusCompleted(user)
    if (userFinal) {
      setCurrentUser(userFinal)
      await saveUser(userFinal)
    }
  }

  const login = async (username: string, password: string, user?: User) : Promise<{success: boolean, data: any}> => {
    const loginResult = await signIn(username, password)
  
    if (!loginResult.error && loginResult.data) {
      let localUser = user ?? await findUserByEmail(username);

      if (!localUser) {
        const { data } = await createUserPending(username, username);
        localUser = data;
      }

      setCurrentUser(localUser)
      await saveAuth(loginResult.data.signInUserSession.accessToken);
      const cognitoUser = await getCurrentAuthenticatedUser()
      
      if (cognitoUser && localUser) {
        completeLogin(cognitoUser, localUser)
        setIsRegistering(false)
        setIsChangingPassword(false)
        
        return { success: true, data: user}
      }
    }

    return { success: !loginResult.error, data: loginResult.error ?? loginResult.data }
  }

  const logout = async () => {
    setCognitoUser(undefined)
    setCurrentUser(undefined)
    await Auth.signOut()
    await saveAuth(undefined)
    await saveUser(undefined)
    setIsUserAdmin(false)
    setIsRegistering(false)
    setIsChangingPassword(false)
    setAvatarUrl(undefined)
  }

  //inicializando o DataStore
  useEffect(() => {
    const removeHubListener = Hub.listen('datastore', async (capsule) => {
      const {
        payload: { event },
      } = capsule
      
      if (event === 'ready' ) {
        setIsDataStoreConnected(true)
      }
    })
    
    DataStore.start()

    return () => {
      removeHubListener()
    }
  }, [])

  useEffect(() => {
    if (!anonymousUser) {
      const anonymousUserId = anonymousUserHelper.getAnonymousUserId();

      if (anonymousUserId) {
        findAnonymousUser(anonymousUserId)
        .then((result) => {
          setAnonymousUser(result)
        })
      }
    }
  }, [anonymousUser])

  const loadUserAvatar = async (user: User) => {
    if (user && user.imageKey){
      const imgUrl = await Storage.get(user.imageKey, { level: "public" } )
      setAvatarUrl(imgUrl)
      return
    }

    setAvatarUrl(undefined)
  }

  useEffect(() => {
    if (currentUser) {
      loadUserAvatar(currentUser)
    }

  }, [currentUser])

  return (
    <AuthContext.Provider value={
        {
          auth,
          user,
          anonymousUser,
          cognitoUser,
          currentUser,
          avatarUrl,
          login,
          logout,
          saveUser,
          saveAnonymousUser,
          completeLogin,
          isUserAdmin,
          isDataStoreConnected,
          isRegistering,
          setIsRegistering,
          isChangingPassword,
          setIsChangingPassword,
        }
      }>
          
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const { auth, logout, completeLogin } = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const didRequest = useRef(false)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const cognitoUser = await getCurrentAuthenticatedUser()
          if (cognitoUser) {
            const user = await findUserByEmail(cognitoUser.attributes.email)

            if (user) {
              await completeLogin(cognitoUser, user)
            }
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.jwtToken) {
      requestUser(auth.jwtToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthInit, AuthProvider, useAuth }

