import { DataStore } from "aws-amplify";
import { TamanhoMensagemEnu } from "../../../../../API";
import { AnonymousUser, AssistenteMensagem, AssistenteStatusEnum, Cidade, Falecimento, Memorial, OpenAiAssistant, OpenAiGeneratedMessage, RelUsuarioFalecimento, TipoMensagemSelecionadaEnum, TipoPessoaEnum, TipoRelacionamento, User } from "../../../../../models";
import { IOpenAiMessages } from "../../openai/core/_models";
import { AssistenteMensagemStepEnum } from "./_models";

export const findActiveAssistente = async (anonymousUser: AnonymousUser, falecimento: Falecimento, memorial: Memorial) : Promise<AssistenteMensagem | undefined> => {
  const assistentes = await DataStore.query(AssistenteMensagem, a =>
    a.and(am => [
      am.assistenteMensagemAnonymousUserId.eq(anonymousUser.id),
      am.Status.eq(AssistenteStatusEnum.IN_PROGRESS),
      am.assistenteMensagemFalecimentoId.eq(falecimento.id),
      am.assistenteMensagemMemorialId.eq(memorial.id)
    ]));
    
  return assistentes.length > 0 ? assistentes[0] : undefined;
}

export const createAssistenteMensagem = async (falecimento: Falecimento, anonymousUser: AnonymousUser, memorial: Memorial) : Promise<AssistenteMensagem> => {
  return await DataStore.save(
    new AssistenteMensagem({
      AnonymousUser: anonymousUser,
      assistenteMensagemAnonymousUserId: anonymousUser.id,
      assistenteMensagemFalecimentoId: falecimento.id,
      Falecimento: falecimento,
      assistenteMensagemMemorialId: memorial.id,
      Memorial: memorial,
      nomeUsuario: anonymousUser.nome,
      Status: AssistenteStatusEnum.IN_PROGRESS,
      Step: `${AssistenteMensagemStepEnum.IDENTIFICACAO_USUARIO}`,
  }))
}

export const updateAssistenteMensagemNomeUsuario = async (assistenteMensagem: AssistenteMensagem, anonymousUser: AnonymousUser) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.nomeUsuario = anonymousUser.nome;
    })
  )
}

export const updateAssistenteMensagemGeneratedMessage = async (assistenteMensagem: AssistenteMensagem, openAiGeneratedMessage: OpenAiGeneratedMessage) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.OpenAiGeneratedMessage = openAiGeneratedMessage;
      updated.assistenteMensagemOpenAiGeneratedMessageId = openAiGeneratedMessage.id;
    })
  )
}

export const updateAssistenteMensagemStep = async (assistenteMensagem: AssistenteMensagem, step: AssistenteMensagemStepEnum) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.Step = `${step}`;
    })
  )
}

export const updateAssistenteMensagemStatus = async (assistenteMensagem: AssistenteMensagem, status: AssistenteStatusEnum) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.Status = status;
    })
  )
}

export const updateAssistenteMensagemRelacionamentoUsuario = async (assistenteMensagem: AssistenteMensagem, tipoRelacionamento: TipoRelacionamento, relUsuarioFalecimento: RelUsuarioFalecimento) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.TipoRelacionamento = tipoRelacionamento;
      updated.assistenteMensagemTipoRelacionamentoId = tipoRelacionamento.id;
      updated.RelUsuarioFalecimento = relUsuarioFalecimento;
      updated.assistenteMensagemRelUsuarioFalecimentoId = relUsuarioFalecimento.id;
    })
  )
}

export const updateAssistenteMensagemModeloTexto = async (
  assistenteMensagem: AssistenteMensagem,
  tamanhoMensagem: TamanhoMensagemEnu,
  tipoPessoa: TipoPessoaEnum,
  emNomeDe: string | undefined,
  instrucao: string | undefined,
  ) : Promise<AssistenteMensagem> => {
  
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.tamanhoMensagem = tamanhoMensagem;
      updated.tipoPessoa = tipoPessoa;
      updated.emNomeDe = emNomeDe;
      updated.instrucao = instrucao;
    })
  )
}

export const updateAssistenteMensagemMensagemUsuario = async (assistenteMensagem: AssistenteMensagem, mensagemUsuario: string) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.mensagemUsuario = mensagemUsuario;
    })
  )
}

export const updateAssistenteMensagemMensagemCorrigida = async (assistenteMensagem: AssistenteMensagem, mensagemCorrigida: string) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.mensagemCorrigida = mensagemCorrigida;
    })
  )
}

export const updateAssistenteMensagemMensagemReescrita = async (assistenteMensagem: AssistenteMensagem, mensagemReescrita: string) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.mensagemReescrita = mensagemReescrita;
    })
  )
}

export const updateAssistenteMensagemMensagemGerada = async (assistenteMensagem: AssistenteMensagem, mensagemGerada: string) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.mensagemGerada = mensagemGerada;
    })
  )
}

export const updateAssistenteMensagemMensagemEditadaManual = async (assistenteMensagem: AssistenteMensagem, mensagemEditadaManual: string) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.mensagemEditada = mensagemEditadaManual;
    })
  )
}

export const updateAssistenteMensagemCidade = async (assistenteMensagem: AssistenteMensagem, cidade: Cidade) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.Cidade = cidade;
      updated.assistenteMensagemCidadeId = cidade.id;
    })
  )
}

export const updateAssistenteMensagemUser = async (assistenteMensagem: AssistenteMensagem, user: User) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.User = user;
      updated.assistenteMensagemUserId = user.id;
    })
  )
}

export const updateAssistenteMensagemTipoMensagemSelecionada = async (assistenteMensagem: AssistenteMensagem, tipo: TipoMensagemSelecionadaEnum) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.tipoMensagemSelecionada = tipo;
    })
  )
}

export const updateAssistenteMensagemOpenAiAssistant = async (assistenteMensagem: AssistenteMensagem, openAiAssistant: OpenAiAssistant) : Promise<AssistenteMensagem> => {
  const origem = await DataStore.query(AssistenteMensagem, assistenteMensagem.id);

  if (!origem) {
    throw new Error("Assistente inválido");
  }
  
  return await DataStore.save(
    AssistenteMensagem.copyOf(origem, updated => {
      updated.OpenAiAssistant = openAiAssistant;
      updated.assistenteMensagemOpenAiAssistantId = openAiAssistant.id;
    })
  )
}

export const salvarOpenAiGeneratedMessage = async (falecimento: Falecimento, anonymousUser: AnonymousUser, openAiMessages: IOpenAiMessages) : Promise<OpenAiGeneratedMessage | undefined> => {
  const textData = openAiMessages.data.find(i => i.role === 'assistant' && i.object === 'thread.message');

  if (!textData) return;

  if (!textData.assistant_id || !textData.run_id) return;

  const textMessage = textData.content.find(i => i.type === 'text');
  
  if (!textMessage) return;
  
  return await DataStore.save(new OpenAiGeneratedMessage({
    anonymousUserId: anonymousUser.id,
    assistantId: textData.assistant_id,
    contentTextValue: textMessage.text.value,
    created_at: textData.created_at,
    falecimentoId: falecimento.id,
    messageId: textData.id,
    runId: textData.run_id,
    threadId: textData.thread_id,
  }));
}