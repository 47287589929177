import { Dispatch, SetStateAction } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { DataStoreQuery, initDataStoreQuery } from './data-store-query'

export type ID = undefined | null | number | string

export type ParentEntityState<T> = {
  entity: T | undefined
}

export type PaginationState = {
  page: number
  items_per_page: 5 | 10 | 30 | 50 | 100
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export type SyncPaginationState = {
  nextToken: string | null
  previousToken: string[]
  startedAt: number | null
  items_per_page: 5 | 10 | 30 | 50 | 100
  page: number
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type SyncResponse<T> = {
  items?: T
  nextToken: string | null
  startedAt: number | null
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type ParentQueryState<T> = ParentEntityState<T> & PaginationState & SortState & FilterState & SearchState

export type ParentSyncState<T> = ParentEntityState<T> & SyncPaginationState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export type ParentQueryRequestContextProps<T> = {
  state: ParentQueryState<T>
  updateState: (updates: Partial<QueryState>) => void
}

export type ParentSyncRequestContextProps<T> = {
  state: ParentSyncState<T>
  updateState: (updates: Partial<ParentSyncState<T>>) => void
  getNext?: () => void
}

export const initialQueryState: QueryState = {
  page: 1,
  items_per_page: 10,
}
export const initialSyncState = <T>() : ParentSyncState<T> => {
  return {
    entity: undefined,
    items_per_page: 10,
    nextToken: null,
    previousToken: [],
    startedAt: null,
    page: 1
  }
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export const initialSyncRequest = <T>() : ParentSyncRequestContextProps<T> => {
  return {
    state: initialSyncState<T>(),
    updateState: () => {},
  }
}

export const initialParentQueryRequest = <T>(): ParentQueryRequestContextProps<T> => {
  return {
    state: { ...initialQueryState, entity: undefined },
    updateState: () => {},
  }
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type SyncResponseContextProps<T> = {
  response?: T | undefined
  refetch: () => void
  isLoading: boolean
  query: DataStoreQuery
  queryId: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: initDataStoreQuery, queryId: uuidv4()}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
}
