import React from 'react';
import { FaBookOpen, FaFileAlt } from 'react-icons/fa';

interface TributePostProps {
  authorName: string;
  receiverName: string;
  authorPhoto: string;
  tributePhoto?: string;
  tributeText: string;
  moreLink: string;
  location?: string;
  postDateTime: string;
  urlObituario: string;
  urlMural: string;
}

const PostHomenagemView: React.FC<TributePostProps> = ({
  authorName,
  receiverName,
  authorPhoto,
  tributePhoto,
  tributeText,
  moreLink,
  location,
  postDateTime,
  urlObituario,
  urlMural,
}) => {
  return (
    <div className="card mb-3 shadow-sm">
      <div className="row g-0 align-items-center p-3">
        <div className="col-auto">
          <img src={authorPhoto} className="img-fluid rounded-circle" alt={`${authorName}`} style={{ width: '60px', height: '60px', objectFit: 'cover' }} />
        </div>
        <div className="col">
          <div className="card-body p-0">
            <h5 className="card-title mb-0 ps-3">
              <strong>{authorName}</strong> escreveu uma homenagem para <strong>{receiverName}</strong>
            </h5>
          </div>
        </div>
      </div>
      {tributePhoto &&
        <div className='px-3'>
          <img src={tributePhoto} className="img-fluid rounded w-100" alt={`Homenagem para ${receiverName}`} style={{ objectFit: 'cover' }} />
        </div>
      }
      <div className="card-body">
        <p className="card-text fs-5">
          {tributeText}
          <a href={moreLink} className="text-primary"> ver mais</a>
        </p>
        <div className="d-flex justify-content-end">
          <small className="text-muted">{location} – {new Date(postDateTime).toLocaleDateString('pt-BR', { day: 'numeric', month: 'long', year: 'numeric' })}</small>
        </div>
        <hr />
        <div className="d-flex justify-content-around">
          <a href={urlObituario} className="btn btn-dark d-flex align-items-center">
            <FaFileAlt className="me-2" />Obituário
          </a>
          <a href={urlMural} className="btn btn-dark d-flex align-items-center">
            <FaBookOpen className="me-2" />Mural
          </a>
        </div>
      </div>
    </div>
  );
};

export default PostHomenagemView;
