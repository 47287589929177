import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react"
import { Pergunta, PerguntaOpcaoResposta, TipoRespostaEnum } from "../../../../models"
import { AssistenteMensagemStepEnum, useAssistenteMensagem } from "../assistente-mensagem/AssistenteMensagemProvider"
import { PerguntasHandler } from "./PerguntasHandler"
import { getOpcoesRespostas } from "./core/_requests"

type PerguntaContextProps = {
  isLoading: boolean,
  perguntaSelected: Pergunta | undefined
  setPerguntaSelected: Dispatch<SetStateAction<Pergunta | undefined>>,
  textoLivre: string | undefined,
  setTextoLivre: Dispatch<SetStateAction<string | undefined>>,
  errorMessage: string | undefined,
  showNextButton: boolean,
  showSkipButton: boolean,
  isFormValido: boolean,
  setIsFormValido: Dispatch<SetStateAction<boolean>>,
  opcoesRespostas: PerguntaOpcaoResposta[],
  salvarRespostaOpcao: (opcao: PerguntaOpcaoResposta) => Promise<void>,
  salvarRespostaTexto: () => Promise<void>,
  pularPergunta: () => Promise<void>,
  voltar: () => void
}

const initPerguntaContextPropsState = {
  isLoading: false,
  falecimento: undefined,
  setFalecimento: () => {},
  anonymoysUser: undefined,
  setAnonymousUser: () => {},
  tipoRelacionamento: undefined,
  setTipoRelacionamento: () => {},
  perguntaSelected: undefined,
  setPerguntaSelected: () => {},
  textoLivre: undefined,
  setTextoLivre: () => {},
  errorMessage: undefined,
  showNextButton: false,
  showSkipButton: true,
  isFormValido: false,
  setIsFormValido: () => {},
  runProximaPergunta: async() => {},
  opcoesRespostas: [],
  salvarRespostaOpcao: async () => {},
  salvarRespostaTexto: async () => {},
  pularPergunta: async () => {},
  voltar: () => {},
}

const PerguntaContext = createContext<PerguntaContextProps>(initPerguntaContextPropsState)

const usePergunta = () => {
  return useContext(PerguntaContext)
}

type PerguntaProviderProps = {
  children?: ReactNode
  handler: PerguntasHandler
}

const PerguntaProvider: FC<PerguntaProviderProps> = ({children, handler}) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(initPerguntaContextPropsState.isLoading)
  const [ perguntaSelected, setPerguntaSelected ] = useState<Pergunta | undefined>(initPerguntaContextPropsState.perguntaSelected)
  const [ textoLivre, setTextoLivre ] = useState<string|undefined>(initPerguntaContextPropsState.textoLivre)
  const [ errorMessage, setErrorMessage ] = useState<string|undefined>(initPerguntaContextPropsState.errorMessage)
  const [ showNextButton, setShowNextButton ] = useState<boolean>(initPerguntaContextPropsState.showNextButton)
  const [ showSkipButton, setShowSkipButton ] = useState<boolean>(initPerguntaContextPropsState.showSkipButton)
  const [ isFormValido, setIsFormValido ] = useState<boolean>(initPerguntaContextPropsState.isFormValido)
  const [ opcoesRespostas, setOpcoesRespostas ] = useState<PerguntaOpcaoResposta[]>(initPerguntaContextPropsState.opcoesRespostas)

  const { goToPage, handler: assistenteHandler } = useAssistenteMensagem()
  
  const pularPergunta = async () => {
    setIsLoading(true);
    handler.pularPergunta()
    .then((result) => {
      if (!result) {
        goToPage(AssistenteMensagemStepEnum.MODELO_TEXTO, assistenteHandler)
      }

      setTextoLivre(undefined)
    })
    .finally(() => {
      setIsLoading(false);
    })
  }

  const voltar = () => {
    const resposta = handler.voltar();

    if (resposta) {
      setPerguntaSelected(resposta.pergunta);
      return;
    }
    
    goToPage(AssistenteMensagemStepEnum.INICIO_ASSISTENTE, assistenteHandler);
  }

  useEffect(() => {
    if (handler.ultimaResposta?.resposta) {
      if (handler.ultimaResposta.pergunta.tipoResposta === TipoRespostaEnum.POUCAS_PALAVRAS ||
        handler.ultimaResposta.pergunta.tipoResposta === TipoRespostaEnum.TEXTO_ABERTO ||
        handler.ultimaResposta.pergunta.tipoResposta === TipoRespostaEnum.UMA_PALAVRA) {
          setTextoLivre(handler.ultimaResposta.resposta.resposta);
        }
    }
  }, [handler.ultimaResposta])

  const salvarRespostaTexto = async () => {
    setErrorMessage(undefined)

    switch (handler.perguntaSelected?.tipoResposta) {
      case TipoRespostaEnum.TEXTO_ABERTO:
      case TipoRespostaEnum.SIM_NAO:
        await salvarRespostaTextoLivre(handler.perguntaSelected, textoLivre)
        break
      case TipoRespostaEnum.UMA_PALAVRA:
        await salvarRespostaUmaPalavra(handler.perguntaSelected, textoLivre)
        break
      case TipoRespostaEnum.POUCAS_PALAVRAS:
        await salvarRespostaPoucasPalavras(handler.perguntaSelected, textoLivre)
        break
      default:
        break
    }
  }

  const salvarRespostaTextoLivre = async (pergunta: Pergunta, texto?: string) => {
    if (!texto || texto.trim().length < 2) {
      setErrorMessage('Informe um texto válido')
      return;
    }

    await salvar(pergunta, texto.trim())
  }

  const salvarRespostaOpcao = async (opcao: PerguntaOpcaoResposta) => {
    if (!handler.perguntaSelected) return;
    
    await salvar(handler.perguntaSelected, opcao.opcaoResposta, opcao)
  }

  const salvarRespostaUmaPalavra = async (pergunta: Pergunta, texto?: string) => {
    if (!texto || texto.trim().length < 2 || texto.trim().includes(' ')) {
      setErrorMessage('Informe somente uma palavra')
      return;
    }

    await salvar(pergunta, texto.trim())
  }

  const salvarRespostaPoucasPalavras = async (pergunta: Pergunta, texto?: string) => {
    if (!texto || texto.trim().length < 2) {
      setErrorMessage('Informe um texto válido')
      return;
    }

    await salvar(pergunta, texto.trim())
  }

  const salvar = async (pergunta: Pergunta, texto: string, opcaoResposta?: PerguntaOpcaoResposta) => {
    setIsLoading(true);
    await handler.salvarResposta(pergunta, texto, opcaoResposta)
    .then(() => {
      setTextoLivre(undefined)
    })
    .finally(() => {
      setIsLoading(false);
    })
  }

  const selectPergunta = (pergunta: Pergunta) => {
    setPerguntaSelected(pergunta)
  }

  useEffect(() => {
    if (!handler.perguntaSelected && !handler.perguntasFinalizadas) {
      setIsLoading(true)

      handler.getProximaPergunta()
      .finally(() => {
        setIsLoading(false)
      })
      
    }
  }, [ handler, handler.perguntaSelected ])

  useEffect(() => {
    if (handler.perguntaSelected && !handler.perguntasFinalizadas) {
      selectPergunta(handler.perguntaSelected)
    }
  }, [handler.perguntaSelected, handler.perguntasFinalizadas, isFormValido])

  useEffect(() => {
    if (handler.perguntasFinalizadas) {
      goToPage(AssistenteMensagemStepEnum.MODELO_TEXTO, assistenteHandler)
    }
  }, [ handler.perguntasFinalizadas, handler.respostas.length, goToPage, assistenteHandler ])

  const exibirBotoes = (isFormValido: boolean, perguntaSelected?: Pergunta) => {
    setShowNextButton(false);
    setShowSkipButton(false)
    
    if (perguntaSelected) {
      if (perguntaSelected.tipoResposta === TipoRespostaEnum.TEXTO_ABERTO ||
      perguntaSelected.tipoResposta === TipoRespostaEnum.POUCAS_PALAVRAS ||
      perguntaSelected.tipoResposta === TipoRespostaEnum.UMA_PALAVRA
      ) {
        if ( isFormValido ) {
          setShowNextButton(true)
          setShowSkipButton(false)
        } else {
          setShowNextButton(false)
          setShowSkipButton(true)
        }
      } else {
        setShowNextButton(false)
        setShowSkipButton(true)
      }
    }
  }

  useEffect(() => {
    exibirBotoes(isFormValido, perguntaSelected)
  }, [ perguntaSelected, isFormValido ])

  useEffect(() => {
    if (perguntaSelected && perguntaSelected.tipoResposta === TipoRespostaEnum.OPCOES) {
      setOpcoesRespostas([])
      setIsLoading(true)
      getOpcoesRespostas(perguntaSelected)
      .then((response) => {
        setOpcoesRespostas(response)
      })
      .finally(() => {
        setIsLoading(false)
      })
    }
  }, [perguntaSelected])

  return (
    <PerguntaContext.Provider value={
        {
          isLoading,
          perguntaSelected,
          setPerguntaSelected,
          textoLivre,
          setTextoLivre,
          errorMessage,
          showNextButton,
          showSkipButton,
          isFormValido,
          setIsFormValido,
          opcoesRespostas,
          salvarRespostaOpcao,
          salvarRespostaTexto,
          pularPergunta,
          voltar,
        }
      }>
        {children}
    </PerguntaContext.Provider>
  )
}

export { PerguntaProvider, usePergunta }

