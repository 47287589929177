import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import { MensagemMural } from '../../../../../models';
import { useMensagemMural } from '../hooks/useMensagemMural';
import { MensagemMuralLayout } from './MensagemMuralLayout';

moment.locale('pt-br');

type Props = {
  mensagemMural: MensagemMural,
  className?: string,
};

const MensagemMuralView: React.FC<Props> = ({ mensagemMural, className }) => {
  const { user, profilePhotoUrl, localPostagem, coverPhotoUrl } = useMensagemMural(mensagemMural);

  return (
    <MensagemMuralLayout
      className={`mensagem-mural ${className}`}
      name={mensagemMural.nomeUsuario ?? user?.nome ?? 'Anônimo'}
      message={mensagemMural.mensagem}
      avatarUrl={profilePhotoUrl}
      coverPhotoUrl={coverPhotoUrl}
      dataPostagem={mensagemMural.dataPostagem}
      horaPostagem={mensagemMural.horaPostagem}
      cidade={mensagemMural.cidade ?? localPostagem?.nome ?? ''}
      estado={mensagemMural.estado ?? localPostagem?.microrregiao.mesorregiao.UF.sigla ?? ''}
    />
  );
};

export { MensagemMuralView };

