import { BackAndNextButtons } from "../../../../../_lapide/components/buttons/BackAndNextButtons";
import { ComponentLoading } from "../../../../../_lapide/components/component-loading/ComponentLoading";
import { TipoRelacionamento } from "../../../../../models";
import { TipoRelaciomentoSelector } from "../../tipo-relacionamento/TipoRelaciomentoSelector";
import { AssistenteMensagemStepEnum, useAssistenteMensagem } from "../AssistenteMensagemProvider";

const StepRelacionamentoFalecido = () => {
  const { runSelectTipoRelacionamento, isLoading, handler, goToPage } = useAssistenteMensagem()
  
  const handleTipoRelacionamentoClick = async (tipo: TipoRelacionamento) => {
    await runSelectTipoRelacionamento(tipo);
  };

  const handleBackButton = async () => {
    goToPage(AssistenteMensagemStepEnum.IDENTIFICACAO_USUARIO, handler)
  }
  
  return (
    <>
      {
        handler &&
        <>
          <div className="card" style={{  maxHeight: '70vh' }}>
            <div className="card-body">

              { !isLoading &&
                <>
                  <h2 className="card-title text-center mb-4 mt-3">{ handler.getPerguntaTipoRelacionamento() }</h2>
                  <TipoRelaciomentoSelector onSelectTipoRelacionamento={ handleTipoRelacionamentoClick } />
                </>
              }
              

              { isLoading &&
                <div className="text-center mb-5 mt-3 p-20">
                  <ComponentLoading />
                </div>
              }
            </div>
          </div>

          <BackAndNextButtons
            className="p-3"
            backText="Voltar"
            nextVisible={false}
            onGoBack={handleBackButton}
          />
        </>
      }
    </>
  );
};

export { StepRelacionamentoFalecido };

