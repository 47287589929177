import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";

const StepGerarMensagem = () => {
  const [index, setIndex] = useState(0);
  const [showLogo, setShowLogo] = useState(false);
  
  const phrases = [
    "Por favor, aguarde um momento...",
    "Estamos preparando tudo para você...",
    "Sua mensagem está sendo gerada com muito carinho...",
    "Aguarde só mais um instante...",
    "Seu texto está ficando incrível!"
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % phrases.length);
      setShowLogo(prevShowLogo => !prevShowLogo);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [phrases.length]);

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-md-9">
          <div className="card text-center" style={{ minHeight: '75vh' }}>
            <div className="card-body d-flex flex-column justify-content-center">
              <div className="carrossel mb-5 mt-5">
                {showLogo ? (
                  <div className="content mt-2">
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/default-small.svg')} className='h-70px' />
                  </div>
                ) : (
                  <div className="content">
                    <p className="mensagem-mural text-gray-800" style={{ fontSize: '2.5rem' }}>{phrases[index]}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { StepGerarMensagem };

