import { Storage } from "@aws-amplify/storage";
import { API, DataStore } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { compressImage } from "../../../../../_metronic/helpers/ImageUtils";
import { AnonymousUser, SexoEnum, User, UserRoleEnum, UserStatusEnum } from "../../../../../models";
import { ICidadeEstado } from "../../cidades/core/_models";

export const findUserByEmail = async (email: string) : Promise<User | undefined> => {
  const result = await DataStore.query(User, u => u.email.eq(email))

  return result && result.length > 0 ? result[0] : undefined;
}

export const createUserPending = async (nome: string, email: string): Promise<{ error: boolean, message?: string, data?: User }> => {
  try {

    const result = await DataStore.save(
        new User({
          nome: nome,
          email: email,
          status: UserStatusEnum.PENDING,
          role: UserRoleEnum.USER,
        })
      );
    
      return { error: false, data: result as User };
  } catch (error) {
    console.log("createUserError", error)
    return { error: true, message: "Não foi possível completar a operação" };
  }
}

export const findAnonymousUser = async (id:string) : Promise<AnonymousUser | undefined> => {
  const anonymousUser = await DataStore.query(AnonymousUser, id);

  return anonymousUser;
}

export const createAnonymousUser = async (nome: string): Promise<AnonymousUser> => {
  const ip = await getUserIpAddress()

  return await DataStore.save(
    new AnonymousUser({
      nome,
      ip
    })
  );
}

export const updateAnonymousUserNome = async (anonymousUser: AnonymousUser, nome: string) : Promise<AnonymousUser> => {
  const user = await DataStore.query(AnonymousUser, anonymousUser.id);

  if (user) {
    return await DataStore.save(
        AnonymousUser.copyOf(anonymousUser, updated => {
        updated.nome = nome;
      })
    )
  }

  return anonymousUser;
}

export const updateAnonymousUserSexo = async (anonymousUser: AnonymousUser, sexo: SexoEnum) : Promise<AnonymousUser> => {
  const user = await DataStore.query(AnonymousUser, anonymousUser.id);

  if (user) {
    return await DataStore.save(
        AnonymousUser.copyOf(anonymousUser, updated => {
        updated.sexo = sexo;
      })
    )
  }

  return anonymousUser;
}

export const updateUserCidade = async (user: User, userCidade: ICidadeEstado) => {
  try {
    if (user && userCidade) {
      await DataStore.save(
        User.copyOf(user, updated => {
          updated.Cidade = userCidade.cidade;
          updated.userCidadeId = userCidade.cidade.id;
        })
      );

      await updateUserStatusCompleted(user);
    }
  } catch (error) {
    console.log("error", JSON.stringify(error, null, 2))
  }
}

export const updateUserNome = async (user: User, nome: string) => {
  try {
    await DataStore.save(
      User.copyOf(user, updated => {
        updated.nome = nome;
      })
    );
  } catch (error) {
    console.log("error", JSON.stringify(error, null, 2))
  }
}

export const updateUserStatusCompleted = async (user: User) : Promise<User | null> => {
  try {
    if (user && user.status === UserStatusEnum.PENDING && user.userCidadeId) {
      const result = await DataStore.save(
        User.copyOf(user, updated => {
          updated.status = UserStatusEnum.COMPLETED;
        })
      )

      return result;
    }
  } catch (error) {
    console.log("error", JSON.stringify(error, null, 2))
  }

  return user;
}

export const uploadProfileImage = async (user: User, picFileBlob: Blob, filename: string): Promise<{ error: boolean, message?: string, data?: User }> => {
  try {
    await removeProfileImage(user);

    const lastDot = filename.lastIndexOf('.');
    const extension = filename.substring(lastDot + 1)

    const file = new File([picFileBlob], 'compressed-image.jpg', { type: 'image/jpeg' });
    const compressedBlob = await compressImage(file, {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 100,
      useWebWorker: true,
    });

    const uploadResult = await Storage.put(`user/${user.id}/profile-${uuidv4()}.${extension}`, compressedBlob, { level: "public" } )

    return await updateImageKey(user, uploadResult.key);
  } catch (error) {
    return { error: true, message: "Não foi possível completar a operação" };
  }
}

export const removeProfileImage = async (user: User): Promise<{ error: boolean, message?: string, data?: User }> => {
  try {
    const original = await DataStore.query(User, user.id);

    if (!original){
      return { error: true, message: "Usuario não encontrado" };
    }

    if (!original.imageKey){
      return { error: false, data: original }
    }

    await Storage.remove(original.imageKey, { level: "public" } )
    
    return await updateImageKey(user, undefined);
  } catch (error) {
    return { error: true, message: "Não foi possível completar a operação" };
  }
}

const getUserIpAddress = async() : Promise<string | undefined> => {

  return API.get("users", '/ip', {})
    .then((response: any) => {
      return response.toString()
    })
    .catch((error: any) => {
      console.log("error", JSON.stringify(error))
        return undefined
    })
}

const updateImageKey = async (user: User, imageKey?: string): Promise<{ error: boolean, message?: string, data?: User }> => {
  try {
    const original = await DataStore.query(User, user.id);
    if (original){
      const result = await DataStore.save(
        User.copyOf(original, updated => {
          updated.imageKey = imageKey;
        })
      );
  
      return { error: false, data: result as User };
    }
  } catch (error) {
    console.log("updateImageKeyError", error)
    return { error: true, message: "Não foi possível completar a operação" };
  }

  return { error: true, message: "Falecimento não encontrado" };
}