import { FC, useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Falecimento, Memorial } from "../../../models";
import { AssistenteMensagemConnectionError } from "../../modules/apps/assistente-mensagem/AssistenteMensagemConnectionError";
import { AssistenteMensagemProvider, AssistenteMensagemStepEnum } from "../../modules/apps/assistente-mensagem/AssistenteMensagemProvider";
import { AssistenteMensagemHandler } from "../../modules/apps/assistente-mensagem/handlers/AssistenteMensagemHandler";
import { AssistenteMensagemLoader } from "../../modules/apps/assistente-mensagem/handlers/AssistenteMensagemLoader";
import { useStepFromLocation } from "../../modules/apps/assistente-mensagem/hooks/useStepFromLocation";
import { StepAssistenteMensagemUsuario } from "../../modules/apps/assistente-mensagem/steps/StepAssistenteMensagemUsuario";
import { StepExibirMensagem } from "../../modules/apps/assistente-mensagem/steps/StepExibirMensagem";
import { StepGerarMensagem } from "../../modules/apps/assistente-mensagem/steps/StepGerarMensagem";
import { StepIdentificacaoUsuario } from "../../modules/apps/assistente-mensagem/steps/StepIdentificacaoUsuario";
import { StepInicioAssistente } from "../../modules/apps/assistente-mensagem/steps/StepInicioAssistente";
import { StepMensagemUsuario } from "../../modules/apps/assistente-mensagem/steps/StepMensagemUsuario";
import { StepModeloTexto } from "../../modules/apps/assistente-mensagem/steps/StepModeloTexto";
import { StepPergunta } from "../../modules/apps/assistente-mensagem/steps/StepPergunta";
import { StepPost } from "../../modules/apps/assistente-mensagem/steps/StepPost";
import { StepRelacionamentoFalecido } from "../../modules/apps/assistente-mensagem/steps/StepRelacionamentoFalecido";
import { StepSelecionarCidade } from "../../modules/apps/assistente-mensagem/steps/StepSelecionarCidade";
import { StepUploadFoto } from "../../modules/apps/assistente-mensagem/steps/StepUploadFoto";
import { MensagemMuralProvider } from "../../modules/apps/mural/MensagemMuralProvider";
import { useAuth } from "../../modules/auth";
import { AssistenteMensagemLayout } from "./AssistenteMensagemLayout";

type Props = {
  falecimento: Falecimento,
  memorial: Memorial
}

const AssitenteMensagemPage : FC<Props> = ({falecimento, memorial}) => {
  const { anonymousUser } = useAuth()
  const [ currentStep ] = useStepFromLocation();
  const [handler, setHandler] = useState<AssistenteMensagemHandler | null>(null);
  const loaded = useRef(false);

  useEffect(() => {
    const loadHandler = async () => {
      if (loaded.current === true) {
        return;
      }
      
      const newHandler = await new AssistenteMensagemLoader(falecimento, memorial).load(anonymousUser);
      setHandler(newHandler);
      loaded.current = true;
    }

    loadHandler();
  }, [anonymousUser, falecimento, memorial])
  
  return (
    <>
      { handler &&
        <MensagemMuralProvider>
          <AssistenteMensagemProvider handler={handler}>
            <Routes>
              <Route index element={<Navigate to="init" />} />
                <Route element={<AssistenteMensagemLayout />}>
                  <Route path="init" element={currentStep === AssistenteMensagemStepEnum.IDENTIFICACAO_USUARIO && <StepIdentificacaoUsuario />} />
                  <Route path="relacionamento" element={currentStep === AssistenteMensagemStepEnum.RELACIONAMENTO_FALECIDO && <StepRelacionamentoFalecido />} />
                  <Route path="selecao" element={currentStep === AssistenteMensagemStepEnum.INICIO_ASSISTENTE && <StepInicioAssistente />} />
                  <Route path="mensagem-escrita" element={currentStep === AssistenteMensagemStepEnum.MENSAGEM_USUARIO && <StepMensagemUsuario />} />
                  <Route path="perguntas" element={currentStep === AssistenteMensagemStepEnum.PERGUNTAS && <StepPergunta />} />
                  <Route path="assistente-mensagem-escrita" element={currentStep === AssistenteMensagemStepEnum.ASSISTENTE_MENSAGEM_USUARIO && <StepAssistenteMensagemUsuario />} />
                  <Route path="modelo-texto" element={currentStep === AssistenteMensagemStepEnum.MODELO_TEXTO && <StepModeloTexto />} />
                  <Route path="gerar-mensagem" element={currentStep === AssistenteMensagemStepEnum.GERAR_MENSAGEM && <StepGerarMensagem />} />
                  <Route path="exibir-mensagem" element={currentStep === AssistenteMensagemStepEnum.EXIBIR_MENSAGEM && <StepExibirMensagem />} />
                  <Route path="erro-conexao-ai" element={currentStep === AssistenteMensagemStepEnum.ERRO_CONEXAO_AI && <AssistenteMensagemConnectionError />} />
                  <Route path="cidade" element={currentStep === AssistenteMensagemStepEnum.SELECIONAR_CIDADE && <StepSelecionarCidade />} />
                  <Route path="foto" element={currentStep === AssistenteMensagemStepEnum.SELECIONAR_FOTO && <StepUploadFoto />} />
                  <Route path="post" element={currentStep === AssistenteMensagemStepEnum.POST && <StepPost />} />
                  <Route path="*" element={<Navigate to="init" />} />
                </Route>
            </Routes>
          </AssistenteMensagemProvider>
        </MensagemMuralProvider>
      }
    </>
  )
}

export { AssitenteMensagemPage };

