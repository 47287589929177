import { useNavigate } from "react-router-dom";
import { KTSVG } from "../../../../_metronic/helpers";
import { useAssistenteMensagem } from "./AssistenteMensagemProvider";


const AssistenteMensagemConnectionError = () => {
  const { memorial, isLoading } = useAssistenteMensagem();
  const navigate = useNavigate();
  
  const handleNext = () => {
    if (memorial) {
      navigate(`/memorial/${memorial.url}/mural`)
    }
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h1 className="card-title text-center mb-5 mt-3">Nosso robô anda meio preguiçoso</h1>
            </div>
            <div className="card-foot text-center">
              <div className='fv-row mb-10 mt-3'>
                <button type="button" className="btn btn-primary"  onClick={ handleNext }>
                  {!isLoading &&
                    <span className='indicator-label'>Voltar mais tarde{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                  </span>}
                  {(isLoading) && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Aguarde...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AssistenteMensagemConnectionError };

