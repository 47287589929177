import { FC, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Bs1Square, Bs3Square } from "react-icons/bs";
import { TipoPessoaEnum } from "../../../../models";

type Props = {
  currentTipo: TipoPessoaEnum;
  onTipoChange: (size: TipoPessoaEnum) => void;
}

const TipoPessoaMensagemSelector : FC<Props> = ( { currentTipo, onTipoChange } ) => {
  const [open, setOpen] = useState(false);
  const [ current, setCurrent ] = useState(currentTipo);

  const handleSizeChange = (tipo: TipoPessoaEnum) => {
    setCurrent(tipo);
    onTipoChange(tipo);
    setOpen(false);
  };
  
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <span className="fw-bold fs-4 me-3">Tipo de Pessoa:</span>
        <button className="btn btn-primary" onClick={() => setOpen(!open)} aria-controls="collapse-text" aria-expanded={open}>
          {current === TipoPessoaEnum.PRIMEIRA && <Bs1Square className="me-2 fs-4" /> }
          {current === TipoPessoaEnum.TERCEIRA && <Bs3Square className="me-2 fs-4" />}

          {current === TipoPessoaEnum.PRIMEIRA && 'PESSOAL'}
          {current === TipoPessoaEnum.TERCEIRA && 'COLETIVO'}
        </button>
      </div>
      <div className='d-flex justify-content-center' style={{ maxWidth: '100vw' }}>
        <Collapse in={open}>
          <div id="collapse-text">
            <div className='card mt-4'>
              <div className='card-body p-2 fs-3'>
                <div></div>
                <div
                  className={`d-flex justify-content-left align-items-center p-2 mb-2 ${current === TipoPessoaEnum.PRIMEIRA ? 'bg-secondary text-white' : 'bg-light'}`}
                  onClick={() => handleSizeChange(TipoPessoaEnum.PRIMEIRA)}
                  style={{ cursor: 'pointer' }}
                >
                  <Bs1Square className="me-3" /> Com muita honra venho escrever esta homenagem...
                </div>
                <div className='separator my-1'></div>
                <div
                  className={`d-flex justify-content-left align-items-center p-2 mb-2 ${current === TipoPessoaEnum.TERCEIRA ? 'bg-secondary text-white' : 'bg-light'}`}
                  onClick={() => handleSizeChange(TipoPessoaEnum.TERCEIRA)}
                  style={{ cursor: 'pointer' }}
                >
                  <Bs3Square className="me-3" /> Estamos aqui para homenagear esta pessoa...
                </div>
                <div className='separator my-1'></div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  )
}

export { TipoPessoaMensagemSelector };

