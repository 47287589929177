import { Storage } from "@aws-amplify/storage"
import clsx from "clsx"
import moment from 'moment'
import { FC, useEffect, useState } from "react"
import { KTSVG, toAbsoluteUrl } from "../../../../../../_metronic/helpers"
import { Falecimento, Memorial } from "../../../../../../models"
import { convertCidadeToIbgeCidade } from "../../../cidades/core/_requests"
import { MemorialHeaderNav } from "./MemorialHeaderNav"

type Props = {
  falecimento: Falecimento
  memorial: Memorial
  className?: string
}

const MemorialHeaderMD : FC<Props> = ( { falecimento, memorial, className } ) => {
  const [ profileUrl, setProfileUrl ] = useState<string>(toAbsoluteUrl('/media/avatars/blank.png'))
  const [ localidade, setLocalidade ] = useState<string|undefined>();
  const [ dataNascimento ] = useState(falecimento.dataNascimento ? moment(falecimento.dataNascimento).format('DD/MM/YYYY') : undefined)
  const [ dataFalecimento ] = useState(falecimento.dataFalecimento ? moment(falecimento.dataFalecimento).format('DD/MM/YYYY') : undefined)

  const loadOriginalUrl = async (falecimento?: Falecimento) => {
    if (falecimento && falecimento.imageKey){
      const imgUrl = await Storage.get(falecimento.imageKey, { level: "public" } )
      
      setProfileUrl(imgUrl)
      return
    }

    setProfileUrl(toAbsoluteUrl('/media/avatars/blank.png'))
  }
  const loadResidencia = async (falecimento?: Falecimento) => {
    if (falecimento?.cidade && falecimento.estado) {
      setLocalidade(`${falecimento.cidade}/${falecimento.estado}`)
      return;
    }

    if (falecimento) {
      const residencia = await falecimento.Cidade;

      if (residencia){
        const ibgeCidade = await convertCidadeToIbgeCidade(residencia);
        setLocalidade(`${ibgeCidade.nome}/${ibgeCidade.microrregiao.mesorregiao.UF.sigla}`)
      }
    }
  }

  useEffect(() => {
    loadOriginalUrl(falecimento)
    loadResidencia(falecimento)
  }, [falecimento])

  return  (
    <div className={clsx("card mb-4 mt-4 mb-xl-10", className)}>
      <div className='card-body pt-3 pb-0'>
        <div className='d-flex align-items-center ms-n2 ms-n2'>
          <div className='symbol symbol-75px me-2'>
            <img src={profileUrl} alt='profile' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <label className='text-dark fw-bold text-hover-primary fs-5'>
              {`${falecimento.nome} ${falecimento.sobrenome} ${falecimento.apelido ? `(${falecimento.apelido})` : ''}`}
            </label>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              { !!dataNascimento && !!dataFalecimento &&
                <>
                  <KTSVG
                      path='/media/icons/duotune/general/gen014.svg'
                      className='svg-icon-4 me-1'
                  />
                  {`${dataNascimento} - ${dataFalecimento}`}
                </>
              }
            </span>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              { !!localidade &&
                <>
                  <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1'
                  />
                  {localidade}
                </>
              }
            </span>
          </div>
        </div>

        <MemorialHeaderNav memorial={memorial} />
      </div>
    </div>
  )
}

export { MemorialHeaderMD }

