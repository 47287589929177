import { User } from "../../../../models"

const USER_LOCAL_STORAGE_KEY = 'condolencias-user'
const getUser = (): User | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(USER_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const user: User = JSON.parse(lsValue) as User
    if (user) {
      // You can easily check auth_token expiration also
      return user
    }
  } catch (error) {
    console.error('USER LOCAL STORAGE PARSE ERROR', error)
  }
}

const setUser = (user: User) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(user)
    localStorage.setItem(USER_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeUser = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export { getUser, removeUser, setUser }

