import { FC } from "react"
import { TipoRelacionamento } from "../../../../models"
import { TipoRelacionamentoProvider } from "./TipoRelaciomentoProvider"
import { TipoRelacionamentoFilter } from "./components/TipoRelacionamentoFilter"
import { TipoRelacionamentoItems } from "./components/TipoRelacionamentoItems"

type Props = {
  onSelectTipoRelacionamento(tipoRelacionamento: TipoRelacionamento) :  void
}

const TipoRelaciomentoSelector: FC<Props> = ( { onSelectTipoRelacionamento } ) => {
  return (
    <TipoRelacionamentoProvider>
      <TipoRelacionamentoFilter className="px-2" />
      <TipoRelacionamentoItems onSelectTipoRelacionamento={ onSelectTipoRelacionamento } />

    </TipoRelacionamentoProvider>
  )
}

export { TipoRelaciomentoSelector }

