import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { Outlet, useLocation } from 'react-router-dom'
import { MenuComponent } from '../assets/ts/components'
import { Content } from './components/content'
import AppHeader from './components/header/AppHeader'
import { Menu } from "./components/menu/Menu"
import { Sidebar } from './components/sidebar'
import { PageDataProvider } from './core'

const MasterLayout = () => {
  const location = useLocation()
  
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
}, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' data-kt-app-header-fixed='true' data-kt-app-header-fixed-mobile='true'>
        {/* begin::Page */}
        <div className='app-page flex-column flex-column-fluid'>
          <AppHeader />
          {/* begin::Wrapper */}
          <div className='app-wrapper flex-column flex-row-fluid'>
            <div className='app-main flex-column flex-row-fluid'>
              
              <Sidebar />
              
              <div className='d-flex flex-column flex-column-fluid'>
                <Content>
                  <div className='row'>
                    { !isMobile &&
                      <div className='col-xl-4 mt-3'>
                        <Menu />
                      </div>
                    }
                    <div className='col-xl-8'>
                      <Outlet />
                    </div>
                  </div>
                </Content>
              </div>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Page */}
      </div>
    </PageDataProvider>
  )
}

export { MasterLayout }

