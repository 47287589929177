import { removeQuotes } from "../../../../../_metronic/helpers";
import { AnonymousUser, AssistenteMensagem, OpenAiAssistant, OpenAiRun } from "../../../../../models";
import { OpenAiHandler } from "../../openai/OpenAiHandler";
import { IOpenAiMessages } from "../../openai/core/_models";

export interface IAssistenteResult {
  setIsGenerating: (value: boolean) => void,
  setConnectionError: (value: boolean) => void,
  setMessageResult: (value: string) => void,
  persistirMensagemGerada: (assistenteMensagem: AssistenteMensagem, anonymousUser: AnonymousUser, messages: IOpenAiMessages) => Promise<void>
}

class AssistenteOpenAiHandler {
  private _assistenteMensagem: AssistenteMensagem
  private  _anonymousUser: AnonymousUser
  private _openAiAssistant: OpenAiAssistant;

  private _openAiHandler: OpenAiHandler;
  private _openAiRun: OpenAiRun | null | undefined = null;
  private _openAiConnectionError: boolean | null | undefined = null;
  private _openAiMessages: IOpenAiMessages  | null | undefined = null;

  private _isGenerating: boolean = false;
  private _instrucoes: string[] = [];
  private _messageResult: string | null | undefined;
  
  private _assistenteResult: IAssistenteResult;
  
  constructor(assistenteMensagem: AssistenteMensagem, anonymousUser: AnonymousUser, openAiAssistant: OpenAiAssistant, assistenteResult: IAssistenteResult){
    this._assistenteMensagem = assistenteMensagem;
    this._anonymousUser = anonymousUser;
    this._openAiAssistant = openAiAssistant;
    this._assistenteResult = assistenteResult;

    this._openAiHandler = new OpenAiHandler();
  }

  public set isGenerating(value: boolean) {
    this._isGenerating = value;
    this._assistenteResult.setIsGenerating(value);
  }

  public get isGenerating(): boolean {
    return this._isGenerating;
  }

  public get openAiConnectionError(): boolean | null | undefined {
    return this._openAiConnectionError;
  }

  private set openAiConnectionError(value: boolean | null | undefined) {
    this._openAiConnectionError = value;

    if (value) {
      this._assistenteResult.setConnectionError(value);
      this.isGenerating = false;
    }
  }

  public get messageResult(): string | null | undefined {
    return this._messageResult;
  }

  private set messageResult(value: string | null | undefined) {
    this._messageResult = value;

    if (value) {
      this._assistenteResult.setMessageResult(value);
      this.isGenerating = false;
    }
  }

  public async executar (instrucoes: string[] ) {
    this._instrucoes = instrucoes;
    this.isGenerating = true;
    this._openAiRun = await this._openAiHandler.createAndRunOpenAiThreadMessage(this._assistenteMensagem.id, this._anonymousUser, this._openAiAssistant, instrucoes);

    if (this._openAiRun?.status === 'failed') {
      this.openAiConnectionError = true;
      return;
    }

    if (this._openAiRun) {
      await this.retrieveThreadRun(this._openAiRun);
    }
  }

  public async executarNovaInstrucao(novaInstrucao: string) {
    if (!this._openAiMessages) throw new Error("Execute a instrucao principal primeiro");
    
    const threadId = this.getThreadId();

    if (threadId) {
      this.isGenerating = true;
      this._instrucoes.push(novaInstrucao);
      const messageResult = await this._openAiHandler.createMessage(threadId, novaInstrucao);

      if (messageResult) {
        await this.runThreadMessages(this._assistenteMensagem.id, this._anonymousUser, this._openAiAssistant, threadId);
      }
    }
  }

  public async reiniciarExecucao(openAiRun: OpenAiRun) {
    this.isGenerating = true;

    await this.retrieveThreadRun(openAiRun);
  }

  private async runThreadMessages (assistentemensagemID: string, anonymousUser: AnonymousUser, assistant: OpenAiAssistant, threadId: string) {
    const newRun = await this._openAiHandler.createRun(assistentemensagemID, anonymousUser, threadId, assistant, "");

    if (newRun?.status === 'failed') {
      this.openAiConnectionError = true;
      return;
    }

    if (newRun) {
      await this.retrieveThreadRun(newRun);
    }
  }

  private getThreadId() : string | undefined {
    if (this._openAiMessages) {
      const textData = this._openAiMessages.data.find(i => i.role === 'assistant' && i.object === 'thread.message');

      if (textData) {
        return textData.thread_id;
      }
    }
  }

  private async retrieveThreadRun(openAiRun: OpenAiRun, tentativa: number = 1) {
    await new Promise(resolve => setTimeout(resolve, 3000));

    this._openAiRun = await this._openAiHandler.retrieveRun(this._assistenteMensagem.id, this._anonymousUser, openAiRun, this._openAiAssistant)

    if (!this._openAiRun) {
      this.openAiConnectionError = true;
      return;
    }

    if (this._openAiRun?.status === 'completed') {
      await this.loadOpenAiGeneratedMessage(this._openAiRun);
      return;
    }

    if (this._openAiRun?.status === 'failed') {
      this.openAiConnectionError = true;
      return;
    }

    if (this._openAiRun?.status === 'expired') {
      await this.criarThreadRun()
      return;
    }

    await this.retrieveThreadRun(this._openAiRun, tentativa + 1);
  }

  private async loadOpenAiGeneratedMessage(openAiRun: OpenAiRun) {
    this._openAiMessages = await this._openAiHandler.getThreadMessages(openAiRun);

    if (this._openAiMessages) {
      this.loadMessageResult(this._openAiMessages);

      this._assistenteResult.persistirMensagemGerada(this._assistenteMensagem, this._anonymousUser, this._openAiMessages);
    }
  }

  private loadMessageResult(openAiMessages: IOpenAiMessages){
    const textData = openAiMessages.data.find(i => i.role === 'assistant' && i.object === 'thread.message' && i.content.length > 0);

    if (!textData) return;
    if (!textData.assistant_id || !textData.run_id) return;

    const textMessage = textData.content.find(i => i.type === 'text');
  
    if (textMessage?.text.value) {
      this.messageResult = removeQuotes(textMessage.text.value);
    }
  }

  private async criarThreadRun() {
    if (this.isGenerating === true) return;
    
    await this.executar(this._instrucoes);
  }
  
  
}

export { AssistenteOpenAiHandler };
