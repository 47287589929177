import { FC } from "react"
import { FaArrowLeft, FaMagic } from "react-icons/fa"
import { ComponentLoading } from "../../../../_lapide/components/component-loading/ComponentLoading"
import { TipoRespostaEnum } from "../../../../models"
import { AssistenteMensagemStepEnum, useAssistenteMensagem } from "../assistente-mensagem/AssistenteMensagemProvider"
import { PerguntaProvider, usePergunta } from "./PerguntaProvider"
import { PerguntasHandler } from "./PerguntasHandler"
import { RespostaOpcoes } from "./components/RespostaOpcoes"
import { RespostaPoucasPalavras } from "./components/RespostaPoucasPalavras"
import { RespostaSimNao } from "./components/RespostaSimNao"
import { RespostaTextoLivre } from "./components/RespostaTextoLivre"
import { RespostaUmaPalavra } from "./components/RespostaUmaPalavra"

type Props = {
  handler: PerguntasHandler
}

const PerguntaSeletor: FC<Props> = ({ handler }) => {
  const { handler: assistenteHandler, goToPage } = useAssistenteMensagem();
  const { isLoading, perguntaSelected, showSkipButton, showNextButton, pularPergunta, salvarRespostaTexto, voltar } = usePergunta()

  const handlePularPergunta = async () => {
    await pularPergunta()
  }

  const handleNextButton = async () => {
    if (showSkipButton) {
      await handlePularPergunta()
      return
    }

    if (showNextButton) {
      await salvarRespostaTexto()
    }
  }

  const handleBack = async () => {
    voltar()
  }

  const handleGenerate = () => {
    goToPage(AssistenteMensagemStepEnum.MODELO_TEXTO, assistenteHandler)
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">

              {!isLoading && perguntaSelected &&
                <>
                  <h2 className="card-title text-center mb-4 mt-3">{perguntaSelected.pergunta.replace('{falecido}', handler.getApelidoOuNomeFalecido())}</h2>
                  {perguntaSelected.tipoResposta === TipoRespostaEnum.TEXTO_ABERTO && <RespostaTextoLivre />}
                  {perguntaSelected.tipoResposta === TipoRespostaEnum.UMA_PALAVRA && <RespostaUmaPalavra />}
                  {perguntaSelected.tipoResposta === TipoRespostaEnum.POUCAS_PALAVRAS && <RespostaPoucasPalavras />}
                  {perguntaSelected.tipoResposta === TipoRespostaEnum.SIM_NAO && <RespostaSimNao />}
                  {perguntaSelected.tipoResposta === TipoRespostaEnum.OPCOES && <RespostaOpcoes />}
                </>
              }

              {isLoading &&
                <div className="text-center mb-5 mt-3 p-20">
                  <ComponentLoading />
                </div>
              }
            </div>
          </div>

          <div className="button-container p-3">
            <button className="btn w-100 fs-3" onClick={handleNextButton}>
              <span>Outra Pergunta</span>
            </button>
            <button className="btn btn-primary w-100 mt-3 fs-3" onClick={handleGenerate}>
              <FaMagic className="me-2" />
              Gerar Texto
            </button>
            <button className="btn btn-secondary w-100 mt-3 fs-3" onClick={handleBack}>
              <FaArrowLeft className="me-2" />
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const PerguntaWrapper: FC<Props> = ({ handler }) => {
  return (
    <PerguntaProvider handler={handler} >
      <PerguntaSeletor handler={handler} />
    </PerguntaProvider>
  )
}

export { PerguntaWrapper }

