import { AssistenteMensagemStepEnum, useAssistenteMensagem } from "../../modules/apps/assistente-mensagem/AssistenteMensagemProvider"

import clsx from "clsx"
import { useEffect } from "react"
import { isMobile } from 'react-device-detect'
import { Outlet, useLocation } from "react-router-dom"
import { MemorialHeaderSM } from "../../modules/apps/memorial/header/components/MemorialHeaderSM"

const AssistenteMensagemLayout = () => {
  const { handler, goToPage } = useAssistenteMensagem()
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname.endsWith("/init") === false) {
      if (!handler.assistenteMensagem) {
        goToPage(AssistenteMensagemStepEnum.IDENTIFICACAO_USUARIO, handler)
      }
    } 
  }, [location, goToPage, handler]);
  
  return (
    <>
      <div style={{marginBottom: '70px'}}>
        <MemorialHeaderSM falecimento={handler.falecimento} className={clsx(!isMobile && "top-10 start-50 translate-middle-x")} />
      </div>
      <Outlet />
    </>
  )
}

export { AssistenteMensagemLayout }
