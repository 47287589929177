import clsx from "clsx";
import { FC, useState } from "react";
import { CidadeSelector } from "../../../apps/cidades/CidadeSelector";
import { EstadoSelector } from "../../../apps/estados/EstadoSelector";
import { EstadoSelect, IbgeEstado } from "../../../apps/estados/core/_models";
import { CidadeSelect, IbgeCidade } from "../core/_models";

type Props = {
  className?: string | ''
  isDisabled: boolean
	onSelectCidade: (ibgeCIdade: IbgeCidade) => void
  value?: IbgeCidade
}

const LocationSelector: FC<Props> = ({ className, isDisabled, onSelectCidade, value }) => {
  const [ isEstadoSelected, setIsEstadoSelected ] = useState<boolean>(false);
  const [ estadoSelected, setEstadoSelected ] = useState<IbgeEstado | undefined>(undefined);
  const [ ibgeCIdade, setIbgeCidade ] = useState(value)

  const handleSelectEstado = (newValue: EstadoSelect) => {
    setIsEstadoSelected(newValue !== null);
    setEstadoSelected(newValue ? newValue.value : undefined);
  };

  const handleSelectCidade = (cidadeSelect: CidadeSelect) => {
    if (cidadeSelect && cidadeSelect.value) {
      onSelectCidade(cidadeSelect.value)
      setIbgeCidade(cidadeSelect.value)
    }
  }

  const handleAlterar = () => {
    setIbgeCidade(undefined)
    setIsEstadoSelected(false)
  }
  
  return (
    <div className={className}>
      {
        ibgeCIdade && (
          <div className="d-flex justify-content-between">
            <span className='fw-semibold w-100 fs-4 form-control-lg bg-light text-gray-600 text-center'>
              {`${ibgeCIdade.nome}/${ibgeCIdade.microrregiao.mesorregiao.UF.sigla}`}
            </span>
            <button
              className="btn btn-sm btn-light-primary fs-4 ms-2 flex-shrink-0"
              onClick={handleAlterar}
            >
              <i className="bi bi-pencil-square fs-4"></i>
            </button>
          </div>
        )
      }

      {
        !ibgeCIdade && (
          <div className="fs-2">
            <div className='fv-row mb-5'>
              <label className='me-2'>Selecione o Estado:</label>
              <EstadoSelector className='form-control form-control-flush fs-3' isDisabled={isDisabled} onSelectEstado={handleSelectEstado} />
            </div>

            <div className={clsx('fv-row', {'d-none': !isEstadoSelected })}>
              <label className='me-2'>Selecione a Cidade:</label>
              <CidadeSelector className='form-control form-control-flush fs-3' isDisabled={isDisabled} onSelectCidade={handleSelectCidade} ibgeEstado={estadoSelected} />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default LocationSelector;