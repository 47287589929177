import { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaEye, FaRobot } from 'react-icons/fa';
import { TipoMensagemSelecionadaEnum } from '../../../../../models';
import { useAssistenteMensagem } from "../AssistenteMensagemProvider";
import { Action, LeitorMensagem } from "../components/LeitorMensagem";
import { StepEditarMensagem } from './StepEditarMensagem';

const StepExibirMensagem = () => {
  const { handler, mensagemViewed, setMensagemViewed, goToPostPage } = useAssistenteMensagem()
  const [ mensagemSelecionada, setMensagemSelecionada ] = useState<string | null | undefined>(handler.mensagemGerada);
  const [ actions, setActions ] = useState<Action[]>([]);
  const [ editor, setEditor ] = useState(false);
    
  const handleNext = async () => {
    goToPostPage();
  }
    
  const atribuirActions = useCallback(() => {
    const handleSelecionarMensagem = (tipo: TipoMensagemSelecionadaEnum, mensagem: string) => {
      handler.tipoMensagemSelecionada = tipo;
      setMensagemSelecionada(mensagem);
      atribuirActions();
    }

    const actionEditarManualmente = {
      name: 'Editar',
      handler: () => { setEditor(true) },
      icon: FaEdit,
    }

    const actionMensagemUsuario = {
      name: 'Ver mensagem original',
      handler: () => handleSelecionarMensagem(TipoMensagemSelecionadaEnum.ESCRITA_USUARIO, handler.mensagemUsuario ?? ''),
      icon: FaEye,
    }
  
    const actionMensagemGerada = {
      name: 'Ver mensagem gerada',
      handler: () => handleSelecionarMensagem(TipoMensagemSelecionadaEnum.GERADA_IA, handler.mensagemGerada ?? ''),
      icon: FaRobot,
    }
  
    const actionMensagemCorrigida = {
      name: 'Ver mensagem reescrita',
      handler: () => handleSelecionarMensagem(TipoMensagemSelecionadaEnum.CORRIGIDA_IA, handler.mensagemCorrigida ?? ''),
      icon: FaRobot,
    }
  
    const actionMensagemReescrita = {
      name: 'Ver mensagem reescrita',
      handler: () => handleSelecionarMensagem(TipoMensagemSelecionadaEnum.REESCRITA_IA, handler.mensagemReescrita ?? ''),
      icon: FaEye,
    }

    const actionMensagemEditadaManual = {
      name: 'Ver mensagem editada',
      handler: () => handleSelecionarMensagem(TipoMensagemSelecionadaEnum.EDITADA_MANUAL, handler.mensagemEditadaManual ?? ''),
      icon: FaEye,
    }
    
    const newActions: Action[] = [];

    if (handler.mensagemEditadaManual && handler.tipoMensagemSelecionada !== TipoMensagemSelecionadaEnum.EDITADA_MANUAL) {
      newActions.push(actionMensagemEditadaManual);
    }

    if (handler.mensagemCorrigida && handler.tipoMensagemSelecionada !== TipoMensagemSelecionadaEnum.CORRIGIDA_IA) {
      newActions.push(actionMensagemCorrigida);
      newActions.push(actionEditarManualmente);
    }

    if (handler.mensagemReescrita && handler.tipoMensagemSelecionada !== TipoMensagemSelecionadaEnum.REESCRITA_IA) {
      newActions.push(actionMensagemReescrita);
      newActions.push(actionEditarManualmente);
    }

    if (handler.mensagemGerada && !handler.mensagemReescrita && !handler.mensagemCorrigida  && handler.tipoMensagemSelecionada !== TipoMensagemSelecionadaEnum.GERADA_IA) {
      newActions.push(actionMensagemGerada);
      newActions.push(actionEditarManualmente);
    }

    if (handler.mensagemUsuario && handler.tipoMensagemSelecionada !== TipoMensagemSelecionadaEnum.ESCRITA_USUARIO) {
      newActions.push(actionMensagemUsuario);
      newActions.push(actionEditarManualmente);
    }

    setActions(newActions);
}, [handler, setActions]);

  useEffect(() => {
    atribuirActions()
  }, [atribuirActions])

  return (
    <>
      { !editor && mensagemSelecionada &&
        <>
          <LeitorMensagem
            actions={actions}
            mensagem={mensagemSelecionada}
            assistenteMensagemViewed={mensagemViewed}
            setAssistenteMensagemViewed={() => setMensagemViewed(true)}
            onPostarClick={handleNext}
          />
        </>
      }

      { editor &&
        <StepEditarMensagem onGoBack={() => setEditor(false)} />
      }
    </>
  )
}

export { StepExibirMensagem };

