import { FC, useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Fab } from "react-tiny-fab";
import { Falecimento, Memorial, MensagemMural } from "../../../../../models";
import MemorialCard from "../components/MensagemMuralNenhumaMensagem";
import { getMensagensFeed } from "../core/_requests";
import { MensagemMuralFeedItem } from "./MensagemMuralFeedItem";

type Props = {
  falecimento: Falecimento
  memorial: Memorial
}

const MensagemMuralFeed: FC<Props> = ({ falecimento, memorial }) => {
  const [ mensagens, setMensagens ] = useState<MensagemMural[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [noResult, setNoResult] = useState(false);
  const navigate = useNavigate();
  
  const itemsPerPage = 5;

  const fetchItems = async (page: number) => {
    setLoading(true);
      
    try {
      const result = await getMensagensFeed(memorial.id, falecimento.id, page, itemsPerPage);

      if (page === 1 && result.length < itemsPerPage) {
        setHasMore(false);
      }

      if (result.length === 0) {
        setHasMore(false);

        setNoResult(page === 1);
      } else {
        setMensagens(prevMensagens => {
          const allMessages = [...prevMensagens, ...result];
          const uniqueIds = new Set(allMessages.map(m => m.id));
          const uniqueMessages = Array.from(uniqueIds).map(id => allMessages.find(m => m.id === id)).filter(m => m !== undefined) as MensagemMural[];
          return uniqueMessages;
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const lastItemRef = (node: HTMLElement | null) => {
    if (loading || !hasMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  };

  const handleDeletePost = async(messageId: string) => {
    const novasMensagens = mensagens.filter(mensagem => mensagem.id !== messageId);
    setMensagens(novasMensagens);
  }

  useEffect(() => {
    fetchItems(page);
  }, [page]);

  const handleOpenAssistenteMensagem = async () => {
    navigate(`/memorial/${memorial.url}/assistente`);
  }

  return (
    <>
      { noResult &&
        <MemorialCard assistenteUrl={`/memorial/${memorial.url}/assistente`} />
      }
      {mensagens.map((mensagem, index) => (
        <div key={index} ref={index === mensagens.length - 1 ? lastItemRef : null}>
          <MensagemMuralFeedItem key={mensagem.id} className="mb-4" index={index} mensagemMural={mensagem} onDeletePost={handleDeletePost} />
        </div>
      ))}

      {loading &&
        <div className='d-flex justify-content-center align-items-center'>
          <span className='indicator-progress' style={{display: 'block'}}>
            <span className='spinner-border spinner-border-lg align-middle'></span>
          </span>
        </div>
      }

      { !noResult && mensagens.length > 0 &&
        <Fab
          mainButtonStyles={{backgroundColor: '#2c3e50'}}
          icon={<FaPlus size={20} />}
          onClick={handleOpenAssistenteMensagem}
        />
      }
    </>
  )
};

export { MensagemMuralFeed };

