import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from "react"
import { WithChildren } from "../../../../_metronic/helpers"
import { NivelRelacionamentoEnum, TipoRelacionamento } from "../../../../models"
import { getTiposRelacionamentos, getTiposRelacionamentosByNivel } from "./core/_requests"

type TipoRelacionamentoContextProps = {
  isLoading: boolean,
  tiposRelacionamentos: TipoRelacionamento[],
  tipoRelacionamentoSelected: TipoRelacionamento | undefined
  setTipoRelacionamentoSelected: Dispatch<SetStateAction<TipoRelacionamento | undefined>>,
  filtrarTiposRelacionamentosPorNivel: (nivelRelacionamentoEnum?: NivelRelacionamentoEnum) => Promise<void>,
  setFiltroTexto: Dispatch<SetStateAction<string|undefined>>,
}

const initTipoRelacionamentoContextPropsState = {
  isLoading: false,
  tiposRelacionamentos: [],
  tipoRelacionamentoSelected: undefined,
  setTipoRelacionamentoSelected: () => {},
  filtrarTiposRelacionamentosPorNivel: async () => {},
  setFiltroTexto: () => {}
}

const TipoRelacionamentoContext = createContext<TipoRelacionamentoContextProps>(initTipoRelacionamentoContextPropsState)

const useTipoRelacionamento = () => {
  return useContext(TipoRelacionamentoContext)
}

const TipoRelacionamentoProvider: FC<WithChildren> = ({children}) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(initTipoRelacionamentoContextPropsState.isLoading)
  const [ tiposRelacionamentos, setTiposRelacionamentos ] = useState<TipoRelacionamento[]>(initTipoRelacionamentoContextPropsState.tiposRelacionamentos)
  const [ tipoRelacionamentoSelected, setTipoRelacionamentoSelected ] = useState<TipoRelacionamento | undefined>(initTipoRelacionamentoContextPropsState.tipoRelacionamentoSelected)
  const [ tiposRelacionamentosInit, setTiposRelacionamentosInit ] = useState<TipoRelacionamento[]>(initTipoRelacionamentoContextPropsState.tiposRelacionamentos)
  const [ filtroTexto, setFiltroTexto ] = useState<string |undefined>()

  const filtrarTiposRelacionamentosPorNivel = async (nivelRelacionamentoEnum?: NivelRelacionamentoEnum) => {
    if (nivelRelacionamentoEnum) {
      const result = await getTiposRelacionamentosByNivel(nivelRelacionamentoEnum)
      setTiposRelacionamentosInit(result)
      setTiposRelacionamentos(result);
    } else {
      const result = await getTiposRelacionamentos()
      setTiposRelacionamentosInit(result)
      setTiposRelacionamentos(result);
    }
  }

  useEffect(() => {
    const filtro = filtroTexto && filtroTexto.replace(/[^\wÀ-ÿ]/g, '');

    if (filtro && filtro.length > 0) {
      const tiposFiltrados = tiposRelacionamentosInit.filter(tipo => tipo.titulo.toLowerCase().includes(filtro.toLowerCase()));
      setTiposRelacionamentos(tiposFiltrados);
    } else {
      setTiposRelacionamentos(tiposRelacionamentosInit);
    }
  }, [filtroTexto, tiposRelacionamentosInit])

  useEffect(() => {
    if (tiposRelacionamentos.length === 0) {
      if (!tiposRelacionamentosInit || tiposRelacionamentosInit.length === 0) {
        setIsLoading(true)
        getTiposRelacionamentos()
        .then((response) => {
          setTiposRelacionamentos(response);
          setTiposRelacionamentosInit(response)
        })
        .finally(() => {
          setIsLoading(false)
        })
      } else if (!filtroTexto || filtroTexto.length === 0) {
        setTiposRelacionamentos(tiposRelacionamentosInit);
      }
    }
  }, [tiposRelacionamentos.length, tiposRelacionamentosInit, filtroTexto])

  return (
    <TipoRelacionamentoContext.Provider value={
        {
          isLoading,
          tiposRelacionamentos,
          tipoRelacionamentoSelected,
          setTipoRelacionamentoSelected,
          filtrarTiposRelacionamentosPorNivel,
          setFiltroTexto,
        }
      }>
        {children}
    </TipoRelacionamentoContext.Provider>
  )
}

export { TipoRelacionamentoProvider, useTipoRelacionamento }

