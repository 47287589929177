import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { WithChildren } from '../../../../_metronic/helpers'
import { ICidadeEstado } from '../../apps/cidades/core/_models'
import { createUserPending, findUserByEmail, updateUserCidade, updateUserNome, uploadProfileImage } from '../../apps/users/core/_requests'
import { useAuth } from '../core/Auth'
import { confirmSignUp, fakeSignIn, forgotPassword, resendVerificationCodeApi, resetPassword, signUp } from '../core/_requests'
import { LoginStep1Email } from './login-steps/LoginStep1Email'
import { LoginStep2Validation } from './login-steps/LoginStep2Validation'

enum LoginFlowStepEnum {
  LOGIN,
  SIGNUP_CODE_VALIDATION,
  FORGOT_PASSWORD_CODE_VALIDATION,
  LOGED,
}

type LoginFlowContextProps = {
  email: string,
  setEmail: Dispatch<SetStateAction<string>>,
  isEmailValid: boolean | undefined,
  isLoading: boolean | false,
  setIsloading: Dispatch<SetStateAction<boolean | false>>,
  executeSignIn: () => Promise<void>,
  error: string | undefined;
  currentLoginFlowStep: LoginFlowStepEnum;
  setCurrentLoginFlowStep: Dispatch<SetStateAction<LoginFlowStepEnum>>,
  validationCode: string | undefined,
  setValidationCode: Dispatch<SetStateAction<string|undefined>>,
  executeCodeValidation: () => Promise<void>,
  isCodeValid: boolean | undefined,
  isSecondCode: boolean,
  countdown: number,
  executeResendValidationCode: () => Promise<void>,
  userName: string | undefined,
  setUserName: Dispatch<SetStateAction<string|undefined>>,
  setUserCidade: Dispatch<SetStateAction<ICidadeEstado|undefined>>,
  userBlobImage?: Blob,
  setUserBlobImage: Dispatch<SetStateAction<Blob|undefined>>,
  userImageFileName: string | undefined,
  setUserImageFileName: Dispatch<SetStateAction<string|undefined>>,
}

const initLoginFlowContextPropsState = {
  email: '',
  setEmail: () => {},
  isEmailValid: undefined,
  isLoading: false,
  setIsloading: () => {},
  executeSignIn: async () => {},
  error: undefined,
  currentLoginFlowStep: LoginFlowStepEnum.LOGIN,
  setCurrentLoginFlowStep: () => {},
  validationCode: undefined,
  setValidationCode: () => {},
  executeCodeValidation: async () => {},
  isCodeValid: undefined,
  isSecondCode: false,
  countdown: 180,
  executeResendValidationCode: async () => {},
  userName: undefined,
  setUserName: () => {},
  setUserCidade: () => {},
  userBlobImage: undefined,
  setUserBlobImage: () => {},
  userImageFileName: undefined,
  setUserImageFileName: () => {},
}

const LoginFlowContext = createContext<LoginFlowContextProps>(initLoginFlowContextPropsState)

const useLoginFlow = () => {
  return useContext(LoginFlowContext)
}

const LoginFlowProvider: FC<WithChildren> = ({children}) => {
  const { login } = useAuth()
  const [ isLoading, setIsloading ] = useState<boolean>(initLoginFlowContextPropsState.isLoading)
  const [ email, setEmail ] = useState<string>(initLoginFlowContextPropsState.email)
  const [ isEmailValid, setIsEmailValid ] = useState<boolean|undefined>(initLoginFlowContextPropsState.isEmailValid)
  const [ error, setError ] = useState<string|undefined>(initLoginFlowContextPropsState.error)
  const [ password, setPassword ] = useState<string>()
  const [ currentLoginFlowStep, setCurrentLoginFlowStep ] = useState<LoginFlowStepEnum>(initLoginFlowContextPropsState.currentLoginFlowStep)
  const [ validationCode, setValidationCode ] = useState<string|undefined>(initLoginFlowContextPropsState.validationCode)
  const [ isCodeValid, setIsCodeValid ] = useState<boolean|undefined>(initLoginFlowContextPropsState.isCodeValid)
  const [ isSecondCode, setIsSecondCode ] = useState<boolean>(false)
  const [ countdown, setCountdown ] = useState(initLoginFlowContextPropsState.countdown)
  const [ userName, setUserName ] = useState<string|undefined>(undefined)
  const [ userCidade, setUserCidade ] = useState<ICidadeEstado|undefined>(undefined)
  const [ userBlobImage, setUserBlobImage ] = useState<Blob | undefined>(initLoginFlowContextPropsState.userBlobImage)
  const [ userImageFileName, setUserImageFileName ] = useState<string| undefined>(initLoginFlowContextPropsState.userImageFileName)

  const executeSignIn =  async () => {
    setIsloading(true)
    setError(undefined)

    if (isEmailValid === false || !email) {
      setError('Informe um e-mail válido')
      setIsloading(false)
      return;
    }

    const response = await fakeSignIn(email);
    setPassword(response.generatedPassword);

    switch (response.errorCode) {
      case 'UserNotFoundException':
        await executeSignUp(email, response.generatedPassword)
        break;
      
      case 'NotAuthorizedException':
        await executeForgotPassword(email)
        break;
      
      case 'UserNotConfirmedException':
        await executeResendSignUp(email)
        break;
      case 'LimitExceededException':
        setError('O limite de tentativas de login foi excedido. Aguarde um momento e tente novamente.')
        setIsloading(false)
        break;
      
      default:
        setError('Não é possível fazer login com este e-mail')
        setIsloading(false)
        break;
    }

    setIsloading(false)
  }

  const executeSignUp = async (email: string, password: string) => {
    const response = await signUp(email, password);

    if (response.success === true){
      setCountdown(initLoginFlowContextPropsState.countdown)
      setValidationCode(undefined)
      setCurrentLoginFlowStep(LoginFlowStepEnum.SIGNUP_CODE_VALIDATION);
    }
  }

  const executeForgotPassword = async (email: string) => {
    const { success, data } = await forgotPassword(email)

    if (success ===  true) {
      setCountdown(initLoginFlowContextPropsState.countdown)
      setValidationCode(undefined)
      setCurrentLoginFlowStep(LoginFlowStepEnum.FORGOT_PASSWORD_CODE_VALIDATION)
    } else if (data.toString().includes('LimitExceededException')) {
      setError('O limite de tentativas de login foi excedido. Aguarde um momento e tente novamente.')
      setIsloading(false)
    }
  }

  const executeResendSignUp = async(email: string) => {
    const response = await resendVerificationCodeApi(email)

    if (response.success ===  true) {
      setCountdown(initLoginFlowContextPropsState.countdown)
      setValidationCode(undefined)
      setCurrentLoginFlowStep(LoginFlowStepEnum.SIGNUP_CODE_VALIDATION)
    }
  }

  const executeCodeValidation = async () => {
    setIsloading(true)
    setError(undefined)

    
    if (isCodeValid === false || !validationCode || !email || !password) {
      setError('Informe um código válido')
      setIsloading(false)
      return;
    }

    const { success } = 
      currentLoginFlowStep === LoginFlowStepEnum.SIGNUP_CODE_VALIDATION ?
        await confirmSignUp(email, validationCode) :
        await resetPassword(email, validationCode, password)

    if (success === true) {
      await executeLogin(email, password);
    } else {
      setError('O código informado é inválido ou já expirou')
      setIsloading(false)
    }
  }

  const executeResendValidationCode = async () => {
    if (!email) {
      return
    }

    setCountdown(initLoginFlowContextPropsState.countdown)
    setValidationCode(undefined)

    currentLoginFlowStep === LoginFlowStepEnum.SIGNUP_CODE_VALIDATION ?
        await resendVerificationCodeApi(email) :
        await forgotPassword(email)
    
  }

  const executeLogin = async (email: string, password: string) => {
    let user = await findUserByEmail(email)
    
    if (!user) {
      const { data } = await createUserPending(userName || email, email)
      user = data;
    }

    if (user && userCidade) {
      await updateUserCidade(user, userCidade)
    }

    if (user && userName) {
      await updateUserNome(user, userName)
    }

    if (user && userBlobImage && userImageFileName) {
      await uploadProfileImage(user, userBlobImage, userImageFileName)
    }
    
    const { success, data } = await login(email, password, user)

    if (success === true) {
      setCurrentLoginFlowStep(LoginFlowStepEnum.LOGED)
      setIsloading(false)
    } else if (data.toString().includes('NotAuthorizedException')) {
      setIsSecondCode(true)
      setIsloading(false)
      setValidationCode(undefined)
      
      await executeForgotPassword(email);
    }
  }

  useEffect(() => {
    const validateEmail = (email: string) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }

    if (email) {
      setIsEmailValid(validateEmail(email));
    }
  }, [email])

  useEffect(() => {
    const checkValidationCode = () => {
      const regex = /^\d{6}$/;
  
      if (validationCode && validationCode.length === 6) {
        setIsCodeValid(regex.test(validationCode))
      }
    }

    checkValidationCode();

  }, [validationCode])

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);

  }, []);

  return (
    <LoginFlowContext.Provider value={
        {
          isLoading,
          setIsloading,
          email,
          setEmail,
          isEmailValid,
          executeSignIn,
          error,
          currentLoginFlowStep,
          setCurrentLoginFlowStep,
          validationCode,
          setValidationCode,
          executeCodeValidation,
          isCodeValid,
          isSecondCode,
          countdown,
          executeResendValidationCode,
          userName,
          setUserName,
          setUserCidade,
          setUserImageFileName,
          userImageFileName,
          userBlobImage,
          setUserBlobImage
        }
      }>
          
      {children}
    </LoginFlowContext.Provider>
  )
}

const LoginFlow = () => {
  const { isLoading, isEmailValid, executeSignIn, currentLoginFlowStep, setCurrentLoginFlowStep, executeCodeValidation, isCodeValid, error } = useLoginFlow();
  const navigate = useNavigate()

  useEffect(() => {
    if (currentLoginFlowStep === LoginFlowStepEnum.LOGED) {
      
      navigate("/")
    }
  }, [currentLoginFlowStep, navigate])
    
  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-11' >
        <h1 className='fw-bolder mb-3'>Entrar</h1>
      </div>
      {/* begin::Heading */}
      { currentLoginFlowStep === LoginFlowStepEnum.LOGIN &&
        <>
          <div className='fv-row mb-10'>
            <LoginStep1Email labelEmail={'Qual seu e-mail?'} />
          </div>

          {error && (
            <div className="row text-center mt-0 mb-2">
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  {error}
                </div>
              </div>
            </div>
          )}
          
          <div className="d-grid mb-10">
            <button type="button" className="btn btn-primary"  onClick={executeSignIn} disabled={isEmailValid !== true}>
              {!isLoading && <span className='indicator-label'>Login</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Aguarde...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
            </button>
          </div>
        </>
      }

      { (currentLoginFlowStep === LoginFlowStepEnum.SIGNUP_CODE_VALIDATION ||
        currentLoginFlowStep === LoginFlowStepEnum.FORGOT_PASSWORD_CODE_VALIDATION) &&
        <>
          <div className='fv-row mb-5'>
            <LoginStep2Validation />
          </div>

          {error && (
            <div className="row text-center mt-0 mb-2">
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  {error}
                </div>
              </div>
            </div>
          )}
          
          <div className="d-grid mb-5">
            <button type="button" className="btn btn-primary"  onClick={executeCodeValidation} disabled={isCodeValid !== true}>
              {!isLoading && <span className='indicator-label'>Confirmar</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Aguarde...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
            </button>

            <button type="button" className="btn btn-secondary mt-2"  onClick={() => setCurrentLoginFlowStep(LoginFlowStepEnum.LOGIN)} disabled={isEmailValid !== true}>Voltar</button>
          </div>
        </>
      }
    </>
  )
}

export { LoginFlow, LoginFlowProvider, LoginFlowStepEnum, useLoginFlow }

