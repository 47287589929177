/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (

    <div className='d-flex flex-column flex-root'>

      <div className='d-flex flex-column flex-column-fluid flex-lg-row auth-layout'
        style={{
          backgroundColor: '#0c343d',
          backgroundSize: 'cover'
        }}
      >
        
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          {/* begin::Aside */}
          <div className="d-flex flex-center flex-lg-start flex-column">
            {/* begin::Logo */}
            <a href="#" className="mb-7 d-none d-lg-block">
              <img alt="Logo" src={toAbsoluteUrl('/media/logos/main.png')} />
            </a>
            {/* end::Logo */}
          </div>
          {/* begin::Aside */}
        </div>
        {/* begin::Content */}
        <div className='d-flex flex-center w-lg-50 p-10'>
          {/* begin::Wrapper */}
          <div className='card card-auth rounded-3 w-md-550px'
            style={{
              backgroundColor: 'white'
            }}
          >
            <div className='card-body p-10 p-lg-20'>
              <Outlet />
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>

    </div>
  )
}

export { AuthLayout }

