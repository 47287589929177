import { useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import { MensagemMural, MensagemMuralStatusEnum } from "../../../../../models"
import { MensagemMuralSteps } from "../../mural/mensagem-mural-form/MensagemMuralSteps"
import { useAssistenteMensagem } from "../AssistenteMensagemProvider"

const StepPost = () => {
  const { showAssistenteMensagem, handler  } = useAssistenteMensagem()
  const navigate = useNavigate();

  const handleMensagemMuralClose = () => {
    navigate(`/memorial/${handler.memorial.url}/mural?reload=true`)
  }

  const handleNewPost = (mensagemMural: MensagemMural) => {
    showAlert(mensagemMural)
  }

  const showAlert = (mensagemAdicionada: MensagemMural) => {
    if (mensagemAdicionada.status === MensagemMuralStatusEnum.APPROVED) {
      Swal.fire({
        title: "Mensagem Adicionada!",
        text: "Agradecemos profundamente sua homenagem. Ela se junta a um oceano de lembranças afetuosas por essa pessoa especial.",
        icon: "success",
        confirmButtonText: "Obrigado!",
      }).then(() => {
        handleMensagemMuralClose();
      });
      
    }
  
    if (mensagemAdicionada.status === MensagemMuralStatusEnum.PENDING) {
      Swal.fire({
        title: "Mensagem Enviada!",
        text: "Sua mensagem é uma valiosa adição ao tributo. Agradecemos por compartilhá-la. Ela será revisada pelo administrador do memorial antes de ser exibida no mural.",
        icon: "success",
        confirmButtonText: "Obrigado!",
      }).then(() => {
        handleMensagemMuralClose();
      });
    }
  }
    

  return (
    <>
      { !showAssistenteMensagem && <MensagemMuralSteps onClose={handleMensagemMuralClose} onNewPost={handleNewPost} />}
    </>
  )
}

export { StepPost }

