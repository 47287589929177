/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column text-center' id='kt_footer'>
      <div className={`${classes.footerContainer} d-flex flex-column`}>
        <div className='row'>
          <div className='text-dark order-2 order-md-1'>
            <span className='text-gray-400 fw-bold me-1'>Created by</span>
            <a
              href='https://www.lapide.online'
              target='_blank'
              className='text-muted text-hover-primary fw-bold me-2 fs-6'
            >
              Lápide Online
            </a>
          </div>
        </div>
        <div className='row'>
          {/* <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
            <li className='menu-item'>
              <a href='#' className='menu-link px-2'>
                Sobre
              </a>
            </li>
            <li className='menu-item'>
              <a href='#' className='menu-link px-2'>
                Contato
              </a>
            </li>
          </ul> */}
        </div>
        <div className='row text-center'>
          <span>v0.11.1</span>
        </div>
      </div>
    </div>
  )
}

export { Footer }

