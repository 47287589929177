const LOCAL_STORAGE_KEY = 'non-session'
const getAnonymousUserId = (): string | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  return lsValue;
}

const setAnonymousUserId = (lsValue: string) => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AU LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAnonymousUser = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AU LOCAL STORAGE REMOVE ERROR', error)
  }
}

export { getAnonymousUserId, removeAnonymousUser, setAnonymousUserId }

