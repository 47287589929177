import { PerguntaWrapper } from "../../pergunta/PerguntaSeletor"
import { useAssistenteMensagem } from "../AssistenteMensagemProvider"

const StepPergunta = () => {
  const { perguntasHandler } = useAssistenteMensagem()

  return (
    <>
      { perguntasHandler &&
        <PerguntaWrapper handler={perguntasHandler}/>
      }
    </>
  )
}

export { StepPergunta }
