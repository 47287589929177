import { Falecimento } from "../../../../models"

type Props = {
    falecimento: Falecimento
}

const MemorialObituario: React.FC<Props> = ({falecimento}) => {
  return (
    <div className='row g-5 g-xxl-8'>
      <div className='col-xl-12'>
        <div className='card'>
          <div className='card-body pb-0'>
            <div className='d-flex mb-5'>
              <p
                className='obituario text-gray-800'
                style={{ whiteSpace: 'pre-line' }}
              >
                {falecimento.sobre}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { MemorialObituario }

