import { EventEmitter } from 'events';
import { removeQuotes } from '../../../../../_metronic/helpers';
import { AnonymousUser, AssistenteMensagem, AssistenteStatusEnum, Falecimento, Memorial, OpenAiAssistant, OpenAiRun, Pergunta, RelUsuarioFalecimento, Resposta, SexoEnum, TamanhoMensagemEnu, TipoMensagemSelecionadaEnum, TipoPessoaEnum, TipoRelacionamento, User } from "../../../../../models";
import { ICidadeEstado } from "../../cidades/core/_models";
import { updateFalecimentoOpenAiAssistant } from "../../falecimentos/core/_requests";
import { OpenAiHandler } from "../../openai/OpenAiHandler";
import { IOpenAiMessages } from "../../openai/core/_models";
import { createRelUsuarioFalecimento, updateRelUsuarioFalecimento } from "../../relacionamento-usuario-falecimento/core/_request";
import { createAnonymousUser, findAnonymousUser, updateAnonymousUserNome, updateAnonymousUserSexo } from "../../users/core/_requests";
import { AssistenteMensagemStepEnum, IAssistenteMensagemHandler } from "../core/_models";
import { createAssistenteMensagem, salvarOpenAiGeneratedMessage, updateAssistenteMensagemCidade, updateAssistenteMensagemGeneratedMessage, updateAssistenteMensagemMensagemCorrigida, updateAssistenteMensagemMensagemGerada, updateAssistenteMensagemMensagemReescrita, updateAssistenteMensagemMensagemUsuario, updateAssistenteMensagemModeloTexto, updateAssistenteMensagemNomeUsuario, updateAssistenteMensagemOpenAiAssistant, updateAssistenteMensagemRelacionamentoUsuario, updateAssistenteMensagemStatus, updateAssistenteMensagemStep, updateAssistenteMensagemTipoMensagemSelecionada, updateAssistenteMensagemUser } from "../core/_requests";
import { AssistenteOpenAiHandler, IAssistenteResult } from "./AssistenteOpenAiHandler";

class AssistenteEventEmmiter extends EventEmitter {
  private _isGerandoMensagem: boolean = false;

  get isGerandoMensagem() {
    return this._isGerandoMensagem;
  }

  set isGerandoMensagem(value: boolean) {
    this._isGerandoMensagem = value;
    this.emit('isGerandoMensagemChange', value);
  }

  private _isErroConexao: boolean = false;

  public get isErroConexao(): boolean {
    return this._isErroConexao;
  }

  public set isErroConexao(value: boolean) {
    this._isErroConexao = value;
    this.emit('isErroConexao', value);
  }

  private _isMensagemGerada: boolean = false;

  public get isMensagemGerada(): boolean {
    return this._isMensagemGerada;
  }

  public set isMensagemGerada(value: boolean) {
    this.emit('isMensagemGerada', value);
  }
}

class AssistenteMensagemHandler implements IAssistenteMensagemHandler, IAssistenteResult {
  private _assistenteMensagem: AssistenteMensagem | undefined;
  private _nomeUsuario: string | undefined;
  private _anonymousUser: AnonymousUser | null | undefined = null;
  private _tipoRelacionamento: TipoRelacionamento | null | undefined = null;
  private _relUsuarioFalecimento: RelUsuarioFalecimento | null | undefined = null;
  private _cidadeEstado: ICidadeEstado | null | undefined = null;
  private _tamanhoMensagem: TamanhoMensagemEnu;
  private _tipoPessoa: TipoPessoaEnum;
  private _emNomeDe: string | undefined = undefined;
  private _instrucao: string | undefined = undefined;
  private _step: AssistenteMensagemStepEnum = AssistenteMensagemStepEnum.IDENTIFICACAO_USUARIO;
  private _respostas: IPerguntaResposta[] = [];
  private _generatingMessage: boolean = false;
  private _isMensagemGeradaPorRespostas: boolean = false;

  private _mensagemUsuario: string | null | undefined = null;
  private _mensagemGerada: string | null | undefined = null;
  private _mensagemReescrita: string | null | undefined = null;
  private _mensagemCorrigida: string | null | undefined = null;
  private _mensagemEditadaManual: string | null | undefined = null;
  
  private _tipoMensagemSelecionada: TipoMensagemSelecionadaEnum = TipoMensagemSelecionadaEnum.ESCRITA_USUARIO;

  private assistenteOpenAiHandler : AssistenteOpenAiHandler | null = null;
  
  memorial: Memorial;
  falecimento: Falecimento;
  openAiAssistant: OpenAiAssistant | null | undefined = null;
  assistenteEventEmitter: AssistenteEventEmmiter = new AssistenteEventEmmiter();
    
  private openAiHandler: OpenAiHandler;
  private messages: string[];

  constructor(falecimento: Falecimento, memorial: Memorial) {
    this.falecimento = falecimento;
    this.memorial = memorial;
    this.messages = [];
    this.openAiHandler = new OpenAiHandler();
    this._tamanhoMensagem = TamanhoMensagemEnu.BALANCEADO;
    this._tipoPessoa = TipoPessoaEnum.PRIMEIRA;
  }
  
  public setIsGenerating = (value: boolean) => {
    this.generatingMessage = value;
    this.assistenteEventEmitter.isGerandoMensagem = value;
  };

  public setConnectionError = (value: boolean) => {
    this.assistenteEventEmitter.isErroConexao = value;
  };

  public setMessageResult = (value: string) => {
    this.mensagemGerada = removeQuotes(value);
    this.assistenteEventEmitter.isMensagemGerada = true;
  }

  public get assistenteMensagem() : AssistenteMensagem | undefined {
    return this._assistenteMensagem;
  }

  public set assistenteMensagem(assistenteMensagem: AssistenteMensagem | undefined) {
    this._assistenteMensagem = assistenteMensagem;
  }

  public get nomeUsuario(): string | undefined {
    return this._nomeUsuario;
  }

  public set nomeUsuario(nome: string | undefined ) {
    this._nomeUsuario = nome;
  }

  public get anonymousUser(): AnonymousUser | null | undefined {
    return this._anonymousUser;
  }

  public set anonymousUser(anonymousUser: AnonymousUser | null | undefined) {
    this._anonymousUser = anonymousUser;
  }

  public get tipoRelacionamento(): TipoRelacionamento | null | undefined {
    return this._tipoRelacionamento;
  }
  
  public set tipoRelacionamento(value: TipoRelacionamento | null | undefined) {
    this._tipoRelacionamento = value;
  }

  public get mensagemUsuario(): string | null | undefined {
    return this._mensagemUsuario;
  }
  public set mensagemUsuario(value: string | null | undefined) {
    this._mensagemUsuario = value;
  }

  public get cidadeEstado(): ICidadeEstado | null | undefined {
    return this._cidadeEstado;
  }
  public set cidadeEstado(value: ICidadeEstado | null | undefined) {
    this._cidadeEstado = value;
  }

  public get mensagemGerada(): string | null | undefined {
    return this._mensagemGerada;
  }
  public set mensagemGerada(value: string | null | undefined) {
    this._mensagemGerada = removeQuotes(value);
  }

  public get mensagem(): string | null | undefined {
    if (this.tipoMensagemSelecionada === TipoMensagemSelecionadaEnum.EDITADA_MANUAL) {
      return this.assistenteMensagem?.mensagemEditada;
    }
    
    if (this.tipoMensagemSelecionada === TipoMensagemSelecionadaEnum.CORRIGIDA_IA) {
      return this.assistenteMensagem?.mensagemCorrigida;
    }

    if (this.tipoMensagemSelecionada === TipoMensagemSelecionadaEnum.ESCRITA_USUARIO) {
      return this.assistenteMensagem?.mensagemUsuario;
    }

    if (this.tipoMensagemSelecionada === TipoMensagemSelecionadaEnum.GERADA_IA) {
      return this.assistenteMensagem?.mensagemGerada;
    }

    if (this.tipoMensagemSelecionada === TipoMensagemSelecionadaEnum.REESCRITA_IA) {
      return this.assistenteMensagem?.mensagemReescrita;
    }

    return null;
  }

  public get mensagemEditadaManual(): string | null | undefined {
    return this._mensagemEditadaManual;
  }
  public set mensagemEditadaManual(value: string | null | undefined) {
    this._mensagemEditadaManual = value;
  }

  public get tamanhoMensagem(): TamanhoMensagemEnu {
    return this._tamanhoMensagem;
  }
  public set tamanhoMensagem(value: TamanhoMensagemEnu) {
    this._tamanhoMensagem = value;
  }

  public get tipoPessoa(): TipoPessoaEnum {
    return this._tipoPessoa;
  }
  public set tipoPessoa(value: TipoPessoaEnum) {
    this._tipoPessoa = value;
  }

  public get emNomeDe(): string | undefined {
    return this._emNomeDe;
  }
  public set emNomeDe(value: string | undefined) {
    this._emNomeDe = value;
  }

  public get instrucao(): string | undefined {
    return this._instrucao;
  }
  public set instrucao(value: string | undefined) {
    this._instrucao = value;
  }

  public get step(): AssistenteMensagemStepEnum {
    return this._step;
  }
  public set step(value: AssistenteMensagemStepEnum) {
    this._step = value;
  }

  public get respostas(): IPerguntaResposta[] {
    return this._respostas;
  }
  public set respostas(value: IPerguntaResposta[]) {
    this._respostas = value;
  }

  public get generatingMessage(): boolean {
    return this._generatingMessage;
  }
  public set generatingMessage(value: boolean) {
    this._generatingMessage = value;
  }

  public get isMensagemGeradaPorRespostas(): boolean {
    return this._isMensagemGeradaPorRespostas;
  }

  public set isMensagemGeradaPorRespostas(value: boolean) {
    this._isMensagemGeradaPorRespostas = value;
  }

  public get mensagemReescrita(): string | null | undefined {
    return this._mensagemReescrita;
  }
  public set mensagemReescrita(value: string | null | undefined) {
    this._mensagemReescrita = removeQuotes(value);
  }
  
  public get mensagemCorrigida(): string | null | undefined {
    return this._mensagemCorrigida;
  }
  public set mensagemCorrigida(value: string | null | undefined) {
    this._mensagemCorrigida = removeQuotes(value);
  }

  public get tipoMensagemSelecionada(): TipoMensagemSelecionadaEnum {
    return this._tipoMensagemSelecionada;
  }
  public set tipoMensagemSelecionada(value: TipoMensagemSelecionadaEnum) {
    this._tipoMensagemSelecionada = value;
  }

  public async criarOuAtualizarAssistente() {
    if (!this._nomeUsuario) throw new Error("É necessário informar o nome do usuário");
    this._anonymousUser = await this.findOrCreateAnonymousUser(this._nomeUsuario);
    
    if (this._anonymousUser) {
      if (!this._assistenteMensagem) {
        this._assistenteMensagem = await createAssistenteMensagem(this.falecimento, this._anonymousUser, this.memorial);
        return;
      }
  
      if (this._assistenteMensagem.nomeUsuario !== this._anonymousUser.nome) {
        this._assistenteMensagem = await updateAssistenteMensagemNomeUsuario(this._assistenteMensagem, this._anonymousUser);
      }
    }
  }

  public async atualizarUsuarioTipoRelacionamento() {
    if (!this._tipoRelacionamento) throw new Error("É necessário informar o tipo de relacionamento");
    if (!this._anonymousUser) throw new Error("É necessário informar o usuário");

    await this.updateUserSexo(this._tipoRelacionamento, this._anonymousUser);
    await this.relacionarUsuarioFalecimento(this._tipoRelacionamento, this._anonymousUser);
  }

  public async salvarModeloTexto(tamanhoMensagem: TamanhoMensagemEnu, tipoPessoa: TipoPessoaEnum, emNomeDe: string | undefined, instrucao: string | undefined) {
    if (!this._assistenteMensagem) throw new Error("Assistente inválido");

    this._tamanhoMensagem = tamanhoMensagem;
    this._assistenteMensagem = await updateAssistenteMensagemModeloTexto(this._assistenteMensagem, tamanhoMensagem, tipoPessoa, emNomeDe, instrucao)
  }

  public async atualizarStatus(status: AssistenteStatusEnum) {
    if (!this._assistenteMensagem) throw new Error("Assistente inválido");
    
    this._assistenteMensagem = await updateAssistenteMensagemStatus(this._assistenteMensagem, status);
  }

  public async atualizarStep(step: AssistenteMensagemStepEnum){
    if (!this._assistenteMensagem) return;

    this.step = step;
    this._assistenteMensagem = await updateAssistenteMensagemStep(this._assistenteMensagem, step);
  }

  private async findOrCreateAnonymousUser(nome: string) : Promise<AnonymousUser> {
    if (!this._anonymousUser) {
      return await createAnonymousUser(nome);
    }

    const au = await findAnonymousUser(this._anonymousUser.id);
    
    if (au && au.nome !== nome) {
      return await updateAnonymousUserNome(au, nome);
    }

    if (!au) {
      throw new Error("Usuário inválido");
    }

    return au;
  }

  private async updateUserSexo(tipoRelacionamentoSelected: TipoRelacionamento, anonymousUser: AnonymousUser) {
    if (tipoRelacionamentoSelected.sexo) {
      await updateAnonymousUserSexo(anonymousUser, tipoRelacionamentoSelected.sexo as SexoEnum);
    }
  }

  private async relacionarUsuarioFalecimento(tipoRelacionamento: TipoRelacionamento, anonymousUser: AnonymousUser) {
    if (!this._relUsuarioFalecimento) {
      this._relUsuarioFalecimento = await createRelUsuarioFalecimento(anonymousUser, this.falecimento, tipoRelacionamento)
    } else {
      this._relUsuarioFalecimento = await updateRelUsuarioFalecimento(this._relUsuarioFalecimento, anonymousUser, this.falecimento, tipoRelacionamento)
    }

    await this.salvarTipoRelacionamento(tipoRelacionamento, this._relUsuarioFalecimento);
  }

  private async salvarTipoRelacionamento(tipoRelacionamento: TipoRelacionamento, relUsuarioFalecimento: RelUsuarioFalecimento) : Promise<void> {
    if (!this._assistenteMensagem) throw new Error("Assistente inválido");
    this._assistenteMensagem = await updateAssistenteMensagemRelacionamentoUsuario(this._assistenteMensagem, tipoRelacionamento, relUsuarioFalecimento)
  }

  public async salvarMensagemUsuario (mensagemUsuario: string) {
    if (!this._assistenteMensagem) throw new Error("Assistente inválido");
    this._mensagemUsuario = mensagemUsuario;
    this._assistenteMensagem = await updateAssistenteMensagemMensagemUsuario(this._assistenteMensagem, mensagemUsuario);
  }

  public async salvarCidade (cidadeEstado: ICidadeEstado) {
    if (!this._assistenteMensagem) throw new Error("Assistente inválido");
    this._cidadeEstado = cidadeEstado;
    this._assistenteMensagem = await updateAssistenteMensagemCidade(this._assistenteMensagem, cidadeEstado.cidade)
  }

  public async salvarUsuario(user: User) {
    if (!this._assistenteMensagem) throw new Error("Assistente inválido");
    
    this._assistenteMensagem = await updateAssistenteMensagemUser(this._assistenteMensagem, user)
  }


  private async findOrCreateAssistant() : Promise<OpenAiAssistant|undefined> {
    this.openAiAssistant = await this.findAssistant();

    if (!this.openAiAssistant) {
      const instructions = await this.makeAiInstructionsCreateAssistant();
      this.openAiAssistant = await this.openAiHandler.createAssistant(instructions, this.memorial.url);

      if (this.openAiAssistant && !this.falecimento.falecimentoOpenAiAssistantId) {
        await updateFalecimentoOpenAiAssistant(this.falecimento, this.openAiAssistant)
      }
    }

    if (this.openAiAssistant && this.assistenteMensagem && !this.assistenteMensagem.assistenteMensagemOpenAiAssistantId) {
      await updateAssistenteMensagemOpenAiAssistant(this.assistenteMensagem, this.openAiAssistant);
    }

    return this.openAiAssistant;
  }

  private async findAssistant() {
    this.openAiAssistant =
      this.openAiAssistant ||
      this.openAiHandler.assistant ||
      await this.falecimento.OpenAiAssistant ||
      await this.openAiHandler.getOpenAiAssistant(this.falecimento);

    return this.openAiAssistant;
  }

  public async gerarMensagemPelasRepostas() {
    if (this.isMensagemGeradaPorRespostas)  {
      await this.executarNovaInstrucao();
      return;
    }
    
    if (this.generatingMessage === true) return;
    if (!this._assistenteMensagem) return;
    if (!this._anonymousUser) return;

    const assistant = await this.findOrCreateAssistant();

    if (!assistant) return;

    this.assistenteOpenAiHandler = new AssistenteOpenAiHandler(this._assistenteMensagem, this._anonymousUser, assistant, this);

    this.tipoMensagemSelecionada = TipoMensagemSelecionadaEnum.GERADA_IA;
    this.reiniciarEmitter();
    this.messages = this.makeAiMessageList(this._respostas, this._anonymousUser);
        
    await this.assistenteOpenAiHandler.executar(this.messages);

    this.isMensagemGeradaPorRespostas = true;
  }

  public async reiniciarProcessamento(openAiRun: OpenAiRun) {
    if (!this._assistenteMensagem) return;
    if (!this._anonymousUser) return;

    const assistant = await this.findOrCreateAssistant();

    if (!assistant) return;

    this.assistenteOpenAiHandler = new AssistenteOpenAiHandler(this._assistenteMensagem, this._anonymousUser, assistant, this);

    this.reiniciarEmitter();

    await this.assistenteOpenAiHandler.reiniciarExecucao(openAiRun);
  }

  public async executarNovaInstrucao() {
    if (!this.assistenteOpenAiHandler) throw new Error("Nenhuma instrucao no historico");

    this.reiniciarEmitter();

    const novaInstrucao = this.makeInstrucoesModeloTexto();

    this.assistenteOpenAiHandler.executarNovaInstrucao(novaInstrucao)
  }

  private reiniciarEmitter() {
    this.assistenteEventEmitter.isErroConexao = false;
    this.assistenteEventEmitter.isGerandoMensagem = false;
    this.assistenteEventEmitter.isMensagemGerada = false;
  }

  public async fazerCorrecoesGramaticaOrtografia() {
    if (!this._assistenteMensagem) return;
    if (!this._anonymousUser) return;

    const assistant = await this.findOrCreateAssistant();

    if (!assistant) return;

    this.assistenteOpenAiHandler = new AssistenteOpenAiHandler(this._assistenteMensagem, this._anonymousUser, assistant, this);
    this.tipoMensagemSelecionada = TipoMensagemSelecionadaEnum.CORRIGIDA_IA;
    const instrucoes = this.makeAiMessageListCorrecoesTexto();
    
    await this.assistenteOpenAiHandler.executar(instrucoes);
    this.assistenteMensagem = await updateAssistenteMensagemTipoMensagemSelecionada(this._assistenteMensagem, TipoMensagemSelecionadaEnum.CORRIGIDA_IA);
  }

  public async reescreverMensagemUsuario() {
    if (!this._assistenteMensagem) return;
    if (!this._anonymousUser) return;

    const assistant = await this.findOrCreateAssistant();

    if (!assistant) return;

    this.assistenteOpenAiHandler = new AssistenteOpenAiHandler(this._assistenteMensagem, this._anonymousUser, assistant, this);
    this.tipoMensagemSelecionada = TipoMensagemSelecionadaEnum.REESCRITA_IA;
    const instrucoes = this.makeAiMessageListReescritaTexto();

    await this.assistenteOpenAiHandler.executar(instrucoes);
    this.assistenteMensagem = await updateAssistenteMensagemTipoMensagemSelecionada(this._assistenteMensagem, TipoMensagemSelecionadaEnum.REESCRITA_IA);
  }

  private makeAiMessageListCorrecoesTexto() : string[] {
    if (!this.mensagemUsuario) throw new Error("Não há mensagem do usuário");
    
    const instrucoes = Array(`Faça correções gramaticais e ortográficas no texto a seguir. Não é necessário explicar as correções. Apenas devolva o texto corrigido.`);
    instrucoes.push(`"${this.mensagemUsuario}"`);

    return instrucoes;
  }

  private makeAiMessageListReescritaTexto() : string[] {
    if (!this.mensagemUsuario) throw new Error("Não há mensagem do usuário");
    
    const instrucoes = Array(`Crie um novo texto a partir da mensagem a seguir. Acrescente novos elementos, mas mantenha o mesmo estilo, tempo verbal e essência.`);
    instrucoes.push(`"${this.mensagemUsuario}"`);

    return instrucoes;
  }

  private makeAiMessageList(respostas: IPerguntaResposta[], anonymousUser: AnonymousUser) : string[] {
    const instrucoes = Array(`Meu nome é ${anonymousUser.nome}.`);
    
    const textoTipoRelacionamento = this.makeAiInstructionsTipoRelacionamento();

    if (textoTipoRelacionamento) {
      instrucoes.push(textoTipoRelacionamento);
    }

    for (let index = 0; index < respostas.length; index++) {
      const resposta = respostas[index];

      if (!resposta.resposta || resposta.skiped === true) {
        continue;
      }

      if (resposta.pergunta.respostaTipoViviamJuntos === true && resposta.resposta.viviamJuntos === true) {
        instrucoes.push('Nós viviámos juntos na mesma casa.')
        continue;
      }

      instrucoes.push(`Se me fizessem esta pergunta: ${resposta.pergunta.pergunta.replace('{falecido}',this.getApelidoOuNomeFalecido())}, responderia: ${resposta.resposta.resposta}.`);
    }

    instrucoes.push('Considere sua data de falecimento em relação ao dia de hoje para gerar uma mensagem apontando para o presente ou para o passado.');
    instrucoes.push('Não é necessário informar datas específicas de nascimento e falecimento na resposta. Estas datas são somente referências que podem ser utilizadas indiretamente para incrementar o resultado');
    instrucoes.push(this.makeInstrucoesModeloTexto())

    return Array(instrucoes.join(' '));
  }

  private makeInstrucoesModeloTexto() : string {
    const instrucoes = Array('');

    instrucoes.push(this._instrucao ?? '');
    instrucoes.push(this.makeInstrucaoEmNomeDe());
    instrucoes.push(this.makeInstrucaoTamanhoMensagem());
    instrucoes.push(this.makeInstrucaoTipoPessoa());

    return instrucoes.toString();
  }

  private makeInstrucaoTamanhoMensagem() : string {
    if (this._tamanhoMensagem === TamanhoMensagemEnu.CONCISO) {
      return "A mensagem deve ser concisa e de um parágrafo."
    }

    if (this._tamanhoMensagem === TamanhoMensagemEnu.BALANCEADO) {
      return "A mensagem deve ser balanceada e de dois parágrafos."
    }

    return "A mensagem deve ser detalhada e de quatro parágrafos";
  }

  private makeInstrucaoTipoPessoa() : string {
    if (this._tipoPessoa === TipoPessoaEnum.PRIMEIRA) {
      return 'Gere uma mensagem personalizada em primeira pessoa.';
    }

    return 'Gere uma mensagem personalizada na primeira pessoa do plural.'
  }

  private makeInstrucaoEmNomeDe() : string {
    if (this._emNomeDe === this._nomeUsuario) {
      return "A mensagem deve ser assinada em meu nome";
    }

    if (!!this._emNomeDe) {
      return "A mensagem deve ser assinada em nome " + this._emNomeDe;
    }

    return "";
  }
  public async persistirMensagemGerada(assistenteMensagem: AssistenteMensagem, anonymousUser: AnonymousUser, messages: IOpenAiMessages) {
    const openAiGeneratedMessage = await salvarOpenAiGeneratedMessage(this.falecimento, anonymousUser, messages);

    if (openAiGeneratedMessage) {
      this.generatingMessage = false;
      this.assistenteMensagem = await updateAssistenteMensagemGeneratedMessage(assistenteMensagem, openAiGeneratedMessage);

      await this.salvarTextoMensagem(assistenteMensagem, openAiGeneratedMessage.contentTextValue);
    }
  }

  public async salvarMensagemEditadaManual(texto: string) {
    if (!this.assistenteMensagem) return;

    this.salvarTextoMensagem(this.assistenteMensagem, texto);
  }

  private async salvarTextoMensagem(assistenteMensagem: AssistenteMensagem, texto: string) {
    if (this.tipoMensagemSelecionada === TipoMensagemSelecionadaEnum.CORRIGIDA_IA) {
      this.mensagemCorrigida = texto;
      this.assistenteMensagem = await updateAssistenteMensagemMensagemCorrigida(assistenteMensagem, texto);
    } else if (this.tipoMensagemSelecionada === TipoMensagemSelecionadaEnum.REESCRITA_IA) {
      this.mensagemReescrita = texto;
      this.assistenteMensagem = await updateAssistenteMensagemMensagemReescrita(assistenteMensagem, texto);
    } else if (this.tipoMensagemSelecionada === TipoMensagemSelecionadaEnum.GERADA_IA) {
      this.mensagemGerada = texto;
      this.assistenteMensagem = await updateAssistenteMensagemMensagemGerada(assistenteMensagem, texto);
    } else if (this.tipoMensagemSelecionada === TipoMensagemSelecionadaEnum.EDITADA_MANUAL) {
      this.mensagemEditadaManual = texto;
      this.assistenteMensagem = await updateAssistenteMensagemMensagemGerada(assistenteMensagem, texto);
    }
  }

  private makeAiInstructionsTipoRelacionamento() : string | null {
    if (!this.tipoRelacionamento) return ""

    return `Sou ${this.tipoRelacionamento.titulo} de ${this.getApelidoOuNomeFalecido()}.`;
  }

  private makeAiMessage(pergunta: string, resposta: string) : string {
    return `Pergunta: ${pergunta} \n Resposta: ${resposta}`.replace('{falecido}', this.getApelidoOuNomeFalecido())
  }

  private async makeAiInstructionsCreateAssistant(): Promise<string> {
    let result = `Você é um assistente que ajuda as pessoas a escreverem mensagens para o memorial de falecimento de ${this.falecimento.nome}.`
    result += ` Você utiliza informações passadas pelo usuário para gerar a mensagem personalizada.`
    result += ` O que sabemos de ${this.falecimento.nome}:`
    result += ` Nasceu em ${this.falecimento.dataNascimento}`
    result += `; Faleceu em ${this.falecimento.dataFalecimento}`
    
    const residencia = await this.falecimento.Cidade;

    if (residencia) {
      const estado = await residencia.Estado;

      result += `; Residia na cidade de ${residencia.nome}/${estado?.sigla}`
    }
    
    if (this.falecimento.apelido) {
      result += `; Seu apelido era ${this.falecimento.apelido}`
    }

    result += '.';
    
    return result;
  }

  public getApelidoOuNomeFalecido() : string {
    return this.falecimento.apelido ?? this.falecimento.nome;
  }

  public getPerguntaTipoRelacionamento() : string {
    return  `O que você é de ${ this.getApelidoOuNomeFalecido() }?`
  }
}
export interface IPerguntaResposta {
  pergunta: Pergunta
  resposta?: Resposta
  skiped: boolean
}

export { AssistenteMensagemHandler };

