import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { KTSVG, toAbsoluteUrl } from "../../../helpers";

const AppHeader = () => {
  const [ showOverlay, setShowOverlay ] = useState(false);
  
  const handleSidebarMenu = () => {
    const menu = document.getElementById('lapide_mobile_sidebar');
    if (menu){
      menu.classList.add('drawer-on')
    }
  }

  useEffect(() => {
    const menu = document.getElementById('lapide_mobile_sidebar');
    if (menu) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const isDrawerOn = menu.classList.contains('drawer-on');
            setShowOverlay(isDrawerOn);
          }
        }
      });

      observer.observe(menu, {
        attributes: true,
        attributeFilter: ['class'],
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const menu = document.getElementById('lapide_mobile_sidebar');
      const target = event.target as Node;
      
      const findFirstDivParent = (element: HTMLElement): HTMLElement | null => {
        let parentElement : HTMLElement | null = element;
        while (parentElement) {
          if (parentElement.tagName.toLowerCase() === 'div') {
            return parentElement;
          }
          parentElement = parentElement.parentElement;
        }
        return null;
      };

      const parentDiv = findFirstDivParent(target as HTMLElement);

      if (menu && !menu.contains(target) && parentDiv && !parentDiv.classList.contains('lapide-sidebar')) {
        menu.classList.remove('drawer-on');
        setShowOverlay(false);
      }
    };

    // Adicionando event listener para o clique fora do menu
    window.addEventListener('click', handleOutsideClick);

    // Removendo event listener quando o componente é desmontado
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div className="app-header">
        <div className='col-sm-1'>
          { isMobile &&
            <div className='lapide-sidebar d-flex position-relative w-100 h-100'>
              <button className='btn' onClick={handleSidebarMenu}>
                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
              </button>
            </div>
          }
        </div>
        <div className='col-lg-11 p-4 w-75'>
          <div className={clsx("app-container container-fluid d-flex align-items-stretch justify-content-center", !isMobile && 'ms-n15')}>
            <img alt="Logo" src={toAbsoluteUrl('/media/logos/main.png')} className="h-30px h-lg-40px app-sidebar-logo-default" />
          </div>
        </div>
        {showOverlay && <div className='drawer-overlay'></div>}
      </div>
    </>
  );
}

export default AppHeader;