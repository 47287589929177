import { Navbar } from "react-bootstrap";
import { MenuItems } from "./MenuItems";

const Menu = () => {
  return (
    <header role="banner" className="app-menu overflow-visible">
      <div className="menu menu-rounded menu-sub-indention fw-semibold fs-3">
        <Navbar bg="light" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <MenuItems />
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>

    
  );
}

export { Menu };
