import { usePergunta } from "../PerguntaProvider"

const RespostaSimNao = () => {
  const { salvarRespostaTexto, setTextoLivre} = usePergunta()

  const handleRespostaSim = async () => {
    setTextoLivre("SIM")
    await salvarRespostaTexto()
  }

  const handleRespostaNao = async () => {
    setTextoLivre("NAO")
    await salvarRespostaTexto()
  }

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
            <div className="text-center">
              <div className="row mb-4">
                <button
                    className="btn btn-success"
                    onClick={handleRespostaSim}
                  >
                    SIM
                </button>
            </div>
            <div className="row">
              <button
                  className="btn btn-warning"
                  onClick={handleRespostaNao}
                >
                  NÃO
              </button>
            </div>
          </div>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export { RespostaSimNao }

