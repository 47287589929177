import { Storage } from "@aws-amplify/storage"
import { FC, useEffect, useState } from "react"
import { TipoFoto } from "../../../../../models"
import { useAuth } from "../../../auth"
import { MensagemMuralFeedModel } from "../core/_models"
import { getMensagemMuralFalecimentoFoto } from "../core/_requests"
import { MensagemMuralFeedView } from "../mensagem-mural-view/MensagemMuralFeedView"

type Props = {
  className: string
  mensagemMural: MensagemMuralFeedModel
  index: number,
  onDeletePost: (messageId: string) => void
}

const MensagemMuralFeedItem : FC<Props> = ({ mensagemMural, index, className, onDeletePost }) => {
  const [ fotoMensagemUrl, setFotoMensagemUrl ] = useState<string>()
  const { currentUser } = useAuth()

  const handleDeletePost = async () =>{
    onDeletePost(mensagemMural.id)
  }

  useEffect(() => {
    const loadFotoCrop = async (mensagem: MensagemMuralFeedModel) => {
      const mensagemFoto = await getMensagemMuralFalecimentoFoto(mensagem.id, TipoFoto.CROP);

      if (mensagemFoto) {
        const foto = await mensagemFoto.FalecimentoFoto;

        if (foto) {
          const imgUrl = await Storage.get(foto.fileKey, { level: "public" } )
          setFotoMensagemUrl(imgUrl)
        }
      }
    }

    loadFotoCrop(mensagemMural)
  }, [mensagemMural])

  return (
    <MensagemMuralFeedView
      key={index}
      className={ `mensagem-mural fs-2 ${className}`}
      name={mensagemMural.nomeUsuario ?? 'Anônimo'}
      message={mensagemMural.resumo ?? ''}
      avatarUrl={fotoMensagemUrl}
      dataPostagem={mensagemMural.dataPostagem}
      horaPostagem={mensagemMural.horaPostagem}
      cidade={mensagemMural.cidade ?? ''}
      estado={mensagemMural.estado ?? ''}
      mensagemMural={mensagemMural}
      user={currentUser}
      onDeletePost={handleDeletePost}
    />
  )
}

export { MensagemMuralFeedItem }

