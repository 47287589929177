import { Storage } from "@aws-amplify/storage";
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { generateStrongPassword } from "../../../../_metronic/helpers";
import { IProfileDetails } from '../../accounts/components/settings/SettingsModel';
import { CognitoAuthModel, CognitoUser } from './_models';

// Server should return AuthModel
export async function signIn(email: string, password: string) : Promise<{ data? : CognitoAuthModel, error? : string }> {
  try {
    const authModel = await Auth.signIn(email, password)

    return { data: authModel };
  } catch (error) {
    return { error: error as string }
  }
}

export async function fakeSignIn(email: string) : Promise<{ errorCode: string, generatedPassword: string }> {
  const generatedPassword = generateStrongPassword(10);

  try {
    await Auth.signIn(email, generatedPassword);

    return { generatedPassword, errorCode: '' };
  } catch (error) {
    const errorMessage = error as string;
    const [codigo] = errorMessage.toString().split(":").map(str => str.trim());

    return { errorCode: codigo, generatedPassword }
  }
}

// Server should return AuthModel
export const signUp = async ( email: string, password: string) : Promise<{ data: any, success: boolean }> => {
  return await Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
    },
  }).then(response => {
    return { data: response, success: true }
  }).catch(error => {
    return { data: error, success: false }
  })
}

export const resendVerificationCodeApi = async (email: string): Promise<{ data: any, success: boolean }> => {
  try {
    await Auth.resendSignUp(email);
    return { data: 'Verification code resent successfully', success: true };
  } catch (error) {
    return { data: error, success: false };
  }
}

export const confirmSignUp = async ( email: string, code: string) : Promise<{ data: any, success: boolean }> => {
  return await Auth.confirmSignUp(email, code)
  .then(response => {
    return { data: response, success: true }
  }).catch(error => {
    return { data: error, success: false }
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function forgotPassword(email: string) : Promise<{ data: any, success: boolean }> {
  return await Auth.forgotPassword(email)
      .then(data => {
        return { data, success: true }
      })
      .catch(err => {
        return { data: err, success: false }
      });
}

export async function getCurrentAuthenticatedUser() : Promise<CognitoUser | void>  {
  return Auth.currentAuthenticatedUser()
  .then(user => {
    return user as CognitoUser;
  })
}

export async function changePassword(oldPassword: string, newPassword: string) {
  const data = await Auth.currentAuthenticatedUser()
    .catch(err => { return { error: true, message: err }});;

  if (data.error){
    return data;
  }
  
  return Auth.changePassword(data, oldPassword, newPassword)
    .then(data => { return { error: false, message: data }})
    .catch(err => { return { error: true, message: err.name }});
}

export async function resetPassword(email: string, code: string, new_password: string)
  : Promise<{ data: any, success: boolean }> {

    return await Auth.forgotPasswordSubmit(email, code, new_password)
  .then(data => {
    return { data, success: true }
  })
  .catch(err => {
    return { data: err, success: false }
  });
}

export async function confirmPassword(email: string, password: string, newPassword: string, name: string, picture: string) :
  Promise<{ data: any, success: boolean } | void > {

  return Auth.signIn(email, password)
  .then(user => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          return Auth.completeNewPassword(
              user,
              newPassword,
              {
                name,
                picture
              }
          ).then(user => {
            return { data: user, success: true };
          }).catch(e => {
            console.log(e);
            return { success: false, data: e };
          });
      }
  }).catch(e => {
    return { success: false, data: e };
  });
}

export async function updateUserPicture( pictureKey: string ){
  return Auth.currentAuthenticatedUser()
  .then(user => {
    return Auth.updateUserAttributes(user, { picture: pictureKey })
  })
  .catch(err => console.log(err));
}

export async function updateUserAttributes(profileDetails: IProfileDetails){
  return Auth.currentAuthenticatedUser()
  .then(user => {
    return Auth.updateUserAttributes(user, { name: profileDetails.name })
  })
  .then(data => { return { error: false, message: data }})
  .catch(err => { return { error: true, message: err }});
}

export async function uploadCurrentUserAvatar(extension: string, picFileBlob: Blob | unknown, picFileBlobMnf: Blob | unknown){
  const data = await Auth.currentAuthenticatedUser()
    .catch(err => { return { error: true, message: err }});;

  if (data.error){
    return data;
  }

  const { attributes } = data;

  if (picFileBlob){
    const uploadResult = await Storage.put(`users/${attributes.email}/profile-${uuidv4}.${extension}`, picFileBlob, { level: "public" } );
    await updateUserPicture(uploadResult.key);
  }

  if (picFileBlobMnf){
    await Storage.put(`users/${attributes.email}/profile-mnf-${uuidv4}.${extension}`, picFileBlobMnf, { level: "public" } );
  }

  if(!picFileBlob && !picFileBlobMnf){
    await updateUserPicture('/media/avatars/blank.png');
  }
}

function calculateSize(img: HTMLImageElement, maxWidth : number, maxHeight : number) {
  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
}

const MAX_WIDTH : number = 125;
const MAX_HEIGHT : number = 125;
const MAX_WIDTH_MNF : number = 66;
const MAX_HEIGHT_MNF : number = 66;

export async function prepareAvatarChange(element: any) 
  : Promise<{ 
      picFile?: any, 
      picFileBlob: Blob | unknown, 
      picFileBlobMnf: Blob | unknown, 
      picture: string,
      extension: string 
    } | undefined> {
    
    if (element.target.files.length === 0){
      return;
    }

    const picFile = element.target.files[0];
    let picture: string;
    let extension: string;
    
    const lastDot = picFile.name.lastIndexOf('.');
            
    let reader = new FileReader();
    reader.readAsDataURL(picFile);

    return new Promise((resolve, reject)=>{
      reader.onloadend = async () => {
      if (reader.result){
        const picFileBlobResult  = await makeImgBlob(element, MAX_WIDTH, MAX_HEIGHT).then((result) => { return result } );
        const picFileBlobMnfResult = await makeImgBlob(element, MAX_WIDTH_MNF, MAX_HEIGHT_MNF).then((result) => { return result } );

			  picture = reader.result.toString()
        extension = picFile.name.substring(lastDot + 1)

        resolve({
          picFileBlob: picFileBlobResult?.picFileBlob,
          picFileBlobMnf: picFileBlobMnfResult?.picFileBlob,
          extension,
          picture
        });
      }
      resolve(undefined);
    };
  });
  }


async function makeImgBlob(element: any, maxWidth: number, maxHeight: number) {
  const picFile = element.target.files[0];
  const blobURL = window.URL.createObjectURL(picFile);
  const img = new Image();
  img.src = blobURL;

  img.onerror = function () {
    URL.revokeObjectURL(this.src);
  };

  return new Promise<{ picFileBlob: Blob | unknown; } | undefined>((resolve, reject) => {
    img.onload = async () => {
      URL.revokeObjectURL(img.src);
      const [newWidth, newHeight] = calculateSize(img, maxWidth, maxHeight);
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0, newWidth, newHeight);
      
      const blob = await new Promise<Blob | unknown>(resolve => canvas.toBlob(resolve));

      resolve({picFileBlob: blob});
    };
  });
}

