import 'bootstrap/dist/css/bootstrap.min.css';
import { FC } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';

type Props = {
  assistenteUrl: string
}

const MemorialCard: FC<Props> = ( { assistenteUrl } ) => {
  return (
    <div className="card text-center shadow">
      <div  style={{ backgroundColor: '#ede5d1'}}>
        <img
          src={toAbsoluteUrl('/media/illustrations/heart-1/heart2.png')}
          className="card-img-top img-fluid"
          alt="Desenho de uma pessoa segurando um coração"
          style={{ maxHeight: '200px', objectFit: 'contain' }}
        />
      </div>
      <div className="card-body">
        <h5 className="card-title fs-2">Nenhuma homenagem encontrada</h5>
        <p className="card-text fs-4">
          Seja o primeiro a escrever uma homenagem no mural.
        </p>
        <a href={assistenteUrl} className="btn btn-primary fs-5">Escrever Homenagem</a>
      </div>
    </div>
  );
};

export default MemorialCard;
