import { findEnumValueByString } from "../../../../../_metronic/helpers";
import { AnonymousUser, AssistenteMensagem, Falecimento, LazyAssistenteMensagem, Memorial, TamanhoMensagemEnu, TipoMensagemSelecionadaEnum, TipoPessoaEnum } from "../../../../../models";
import { ICidadeEstado, makeCidadeEstado } from "../../cidades/core/_models";
import { AssistenteMensagemStepEnum } from "../AssistenteMensagemProvider";
import { findActiveAssistente } from "../core/_requests";
import { AssistenteMensagemHandler, IPerguntaResposta } from "./AssistenteMensagemHandler";

class AssistenteMensagemLoader {
  private _falecimento: Falecimento;
  private _memorial: Memorial

  private _assistenteMensagemHandler: AssistenteMensagemHandler | null = null;
  
  constructor(falecimento: Falecimento, memorial: Memorial) {
    this._falecimento = falecimento;
    this._memorial = memorial;
  }

  public async load(anonymousUser: AnonymousUser | undefined) : Promise<AssistenteMensagemHandler> {
    if (!anonymousUser) {
      return new AssistenteMensagemHandler(this._falecimento, this._memorial);
    }
    
    const assistenteMensagem = await findActiveAssistente(anonymousUser, this._falecimento, this._memorial);

    this._assistenteMensagemHandler = new AssistenteMensagemHandler(this._falecimento, this._memorial);

    if (assistenteMensagem) {
      this._assistenteMensagemHandler.anonymousUser = anonymousUser;
      this._assistenteMensagemHandler.assistenteMensagem = assistenteMensagem;
      this._assistenteMensagemHandler.cidadeEstado = await this.loadCidadeEstado(assistenteMensagem);
      this._assistenteMensagemHandler.emNomeDe = assistenteMensagem.emNomeDe ?? undefined;
      this._assistenteMensagemHandler.instrucao = assistenteMensagem.instrucao ?? undefined;
      this._assistenteMensagemHandler.nomeUsuario = assistenteMensagem.nomeUsuario ?? anonymousUser.nome ?? undefined;
      this._assistenteMensagemHandler.openAiAssistant = await assistenteMensagem.OpenAiAssistant;
      this._assistenteMensagemHandler.step = this.loadStep(this._assistenteMensagemHandler, assistenteMensagem)
      this._assistenteMensagemHandler.tamanhoMensagem = (assistenteMensagem.tamanhoMensagem ?? TamanhoMensagemEnu.BALANCEADO) as TamanhoMensagemEnu;
      this._assistenteMensagemHandler.tipoPessoa = (assistenteMensagem.tipoPessoa ?? TipoPessoaEnum.PRIMEIRA) as TipoPessoaEnum;
      this._assistenteMensagemHandler.tipoRelacionamento = await assistenteMensagem.TipoRelacionamento;
      
      this._assistenteMensagemHandler.mensagemCorrigida = assistenteMensagem.mensagemCorrigida;
      this._assistenteMensagemHandler.mensagemGerada = assistenteMensagem.mensagemGerada;
      this._assistenteMensagemHandler.mensagemReescrita = assistenteMensagem.mensagemReescrita;
      this._assistenteMensagemHandler.mensagemUsuario = assistenteMensagem.mensagemUsuario;
      this._assistenteMensagemHandler.mensagemEditadaManual = assistenteMensagem.mensagemEditada;
      this._assistenteMensagemHandler.tipoMensagemSelecionada = (assistenteMensagem.tipoMensagemSelecionada ?? TipoMensagemSelecionadaEnum.ESCRITA_USUARIO) as TipoMensagemSelecionadaEnum;

      await this.loadGeneratedMessage(this._assistenteMensagemHandler, assistenteMensagem);
      await this.loadOpenAiLastRun(this._assistenteMensagemHandler, assistenteMensagem);
      await this.loadRespostas(this._assistenteMensagemHandler, assistenteMensagem);
    }

    return this._assistenteMensagemHandler;
  }
  async loadRespostas(assistenteMensagemHandler: AssistenteMensagemHandler, assistenteMensagem: LazyAssistenteMensagem) {
    const respostas = await assistenteMensagem.Respostas.toArray();

    const perguntaRespostas : IPerguntaResposta[] = [];

    for (const resposta of respostas) {
      const pergunta = await resposta.Pergunta

      if (pergunta) {
        perguntaRespostas.push({ pergunta, skiped: resposta.skiped ?? false, resposta: resposta })
      }
    }

    assistenteMensagemHandler.respostas = perguntaRespostas;
  }

  private loadStep(_assistenteMensagemHandler: AssistenteMensagemHandler, assistenteMensagem: LazyAssistenteMensagem): AssistenteMensagemStepEnum {
    if (assistenteMensagem.Step) {
      const enumValue = findEnumValueByString(AssistenteMensagemStepEnum, assistenteMensagem.Step);

      if (enumValue) {
        return enumValue
      }
    }

    return AssistenteMensagemStepEnum.IDENTIFICACAO_USUARIO;
  }

  private async loadOpenAiLastRun(assistenteMensagemHandler: AssistenteMensagemHandler, assistenteMensagem: LazyAssistenteMensagem) {
    const runs = await assistenteMensagem.OpenAiRuns.toArray();

    if (runs.length) {
      let openAiRun = runs.find(x => x.status === 'queued' || x.status === 'in_progress');

      if (!openAiRun && (
          assistenteMensagem.Step === AssistenteMensagemStepEnum.GERAR_MENSAGEM.toString() ||
          assistenteMensagem.Step === AssistenteMensagemStepEnum.MODELO_TEXTO.toString() ||
          assistenteMensagem.Step === AssistenteMensagemStepEnum.ASSISTENTE_MENSAGEM_USUARIO.toString()
      )) {
        
        openAiRun = runs.find(x => x.status === 'completed');
      }

      if (openAiRun) {
        await assistenteMensagemHandler.reiniciarProcessamento(openAiRun);
      }
    }
    
  }
  private async loadGeneratedMessage(assistenteMensagemHandler: AssistenteMensagemHandler, assistenteMensagem: LazyAssistenteMensagem) {
    const openAiGeneratedMessage = await assistenteMensagem.OpenAiGeneratedMessage;

    if (openAiGeneratedMessage) {
      assistenteMensagemHandler.mensagemGerada = openAiGeneratedMessage.contentTextValue
    }
  }
  
  private async loadCidadeEstado(assistenteMensagem: AssistenteMensagem): Promise<ICidadeEstado | null> {
    if (assistenteMensagem.assistenteMensagemCidadeId) {
      const cidade = await assistenteMensagem.Cidade;

      if (cidade) {
        return await makeCidadeEstado(cidade);
      }
    }

    return null;
  }

}

export { AssistenteMensagemLoader };

