import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { MainLayout } from '../../../_metronic/layout/MainLayout';
import { MasterLayout } from '../../../_metronic/layout/MasterLayout';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Falecimento, Memorial } from '../../../models';
import { getMemorialByUrl } from '../../modules/apps/memorial/core/_requests';
import { MemorialHeader } from '../../modules/apps/memorial/header/MemorialHeader';
import { MensagemMuralFeed } from '../../modules/apps/mural/mensagem-mural-feed/MensagemMuralFeed';
import { useAuth } from '../../modules/auth';
import { AssitenteMensagemPage } from '../assistente-mensagem/AssitenteMensagemPage';
import { MemorialObituario } from './components/MemorialObituario';
import { MemorialServicos } from './components/MemorialServicos';

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Obituário',
    path: '/obituario',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Serviços',
    path: '/servicos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Mural',
    path: '/mural',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MemorialPage = () => {
  const { isDataStoreConnected } = useAuth()
  const { id } = useParams()
  const navigate = useNavigate()
  const [ falecimento, setFalecimento ] = useState<Falecimento | undefined>()
  const [ memorial, setMemorial ] = useState<Memorial | undefined>()

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
  }
  
  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'none')
    }
  }
  

  const loadFalecimento = async () => {
    const _memorial = await getMemorialByUrl(id as string)

    if (!_memorial && isDataStoreConnected){
      navigate('/');
      return
    }

    const _falecimento = await _memorial?.Falecimento

    if (!_falecimento && isDataStoreConnected){
      navigate('/');
      return
    }

    return { falecimento: _falecimento, memorial: _memorial }
  }

  useEffect(() => {
    enableSplashScreen()
  },[]);

  useEffect(() => {
    if (isDataStoreConnected) {
      loadFalecimento().then(result => {
        setFalecimento(result?.falecimento)
        setMemorial(result?.memorial)
        disableSplashScreen()
      }).catch(error => {
        disableSplashScreen()
        navigate('/');
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isDataStoreConnected]);

  return (
    <>
      { falecimento && memorial &&
        <Routes>
          <Route index element={<Navigate to='obituario' />} />
          <Route element={<MasterLayout />}>
            <Route path='assistente/*' element={
              <MainLayout
                main={<AssitenteMensagemPage falecimento={falecimento} memorial={memorial} />}
                search={<></>}
              />
              
            }/>
          </Route>
          <Route element={<MasterLayout />}>
            <Route
              element={
                <MainLayout main={
                    <>
                      <MemorialHeader falecimento={falecimento} memorial={memorial}/>
                      <Outlet />
                    </>
                  }
                search={<></>}
                />
              }
            >
              <Route
                path='obituario'
                element={
                  <>
                    <PageTitle breadcrumbs={profileBreadCrumbs}>{`${falecimento.nome}`}</PageTitle>
                    <MemorialObituario falecimento={falecimento}/>
                  </>
                }
              />

              <Route
                path='servicos'
                element={
                  <>
                    <PageTitle breadcrumbs={profileBreadCrumbs}>{`${falecimento.nome}`}</PageTitle>
                    <MemorialServicos falecimento={falecimento}/>
                  </>
                }
              />

              <Route
                path='mural'
                element={
                  <>
                    <PageTitle breadcrumbs={profileBreadCrumbs}>{`${falecimento.nome}`}</PageTitle>
                    <MensagemMuralFeed memorial={memorial} falecimento={falecimento}/>
                  </>
                }
              />

            </Route>
          </Route>
        </Routes>
      }
    </>
    
  );
}

export { MemorialPage };

