import { Storage } from 'aws-amplify';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Photo } from '../../../../../_lapide/components/photos/PhotoModels';
import { ContentStatusEnum, Falecimento, FalecimentoFoto, TipoFoto, User } from '../../../../../models';
import { salvarFalecimentoFoto } from '../../falecimentos/core/_requests';

const usePhotoUpload = () => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [coverPhoto, setCoverPhoto] = useState<Photo | null>(null);
  const [profilePhoto, setProfilePhoto] = useState<Photo | null>(null);

  const savePhotosToS3 = async (falecimento: Falecimento, user: User): Promise<FalecimentoFoto[]> => {
    const savedPhotos: FalecimentoFoto[] = [];

    try {
      const photoPromises = photos.map(async (photo) => {
        const extension = photo.file.name.split('.').pop();
        const key = `falecimento/foto/${falecimento.id}/${uuidv4()}.${extension}`;
        await Storage.put(key, photo.file, {
          contentType: photo.file.type,
        });

        const savedPhoto = await salvarFalecimentoFoto(falecimento, key, ContentStatusEnum.APPROVED, TipoFoto.TOTAL, user, photo.isCropped);
        savedPhotos.push(savedPhoto);
      });

      await Promise.all(photoPromises);

      if (coverPhoto) {
        const extension = coverPhoto.file.name.split('.').pop();
        const key = `falecimento/foto/${falecimento.id}/cover-${uuidv4()}.${extension}`;
        await Storage.put(key, coverPhoto.file, {
          contentType: coverPhoto.file.type,
        });

        const savedCoverPhoto = await salvarFalecimentoFoto(falecimento, key, ContentStatusEnum.APPROVED, TipoFoto.COVER, user, true);
        savedPhotos.push(savedCoverPhoto);
      }

      if (profilePhoto) {
        const extension = profilePhoto.file.name.split('.').pop();
        const key = `falecimento/foto/${falecimento.id}/profile-${uuidv4()}.${extension}`;
        await Storage.put(key, profilePhoto.file, {
          contentType: profilePhoto.file.type,
        });

        const savedCoverPhoto = await salvarFalecimentoFoto(falecimento, key, ContentStatusEnum.APPROVED, TipoFoto.PROFILE, user, true);
        savedPhotos.push(savedCoverPhoto);
      }
    } catch (error) {
      console.error('Erro ao salvar fotos no S3:', error);
    }

    return savedPhotos;
  };

  return {
    savePhotosToS3,
    photos,
    setPhotos,
    setCoverPhoto,
    coverPhoto,
    profilePhoto,
    setProfilePhoto,
  };
};

export default usePhotoUpload;