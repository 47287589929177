import { FC, useEffect, useState } from "react"
import { Carousel, CarouselItem } from "react-bootstrap"
import { MensagemMural } from "../../../../../models"
import { useAuth } from "../../../auth"
import { LoginFlowCard } from "../../../auth/components/LoginFlowCard"
import { MensagemMuralNextCard } from "../components/MensagemMuralNextCard"
import { getNextMensagemMural } from "../core/_requests"
import { MensagemMuralView } from "./MensagemMuralView"

type Props = {
  mensagemMuralInit: MensagemMural
}

interface ICarouselItem {
  mensagem: MensagemMural,
  nextToken?: string|null,
  proximaMensagem?: MensagemMural
}

const MensagemMuralCarousel : FC<Props> = ( { mensagemMuralInit } ) => {
  const { currentUser } = useAuth()
  const [ itens, setItens ] = useState<ICarouselItem[]>([]);
  const [ itemAtual, setItemAtual ] = useState<ICarouselItem>();

  const [ index, setIndex ] = useState(0);
  
  const handleSelect = async (selectedIndex: number) => {
    setIndex(selectedIndex);

    const itemSelected = itens[selectedIndex]
    setItemAtual(itemSelected)

    if (selectedIndex > index) {
      await loadNextMensagem(itemSelected, selectedIndex);
      return;
    }
  };

  const handleNextCardClick = async() => {
    await handleSelect(index + 1)
  }

  const loadNextMensagem = async (itemSelected: ICarouselItem, selectedIndex: number) => {
    if (!currentUser) return;
    if (!itemSelected.nextToken) return;
    if (itemSelected.proximaMensagem) return;
    
    if (mensagemMuralInit.mensagemMuralMemorialId) {
      const proximaMensagem = await getNextMensagemMural(mensagemMuralInit.id, mensagemMuralInit.mensagemMuralMemorialId, itemSelected.nextToken);

      if (proximaMensagem) {
        const carouselItem : ICarouselItem = { mensagem: proximaMensagem.mensagemMural, nextToken: proximaMensagem.nextToken };
        const novoArray = [...itens]
        novoArray.push(carouselItem)

        const updateItem = novoArray[selectedIndex];
        updateItem.proximaMensagem = proximaMensagem.mensagemMural;

        setItens(novoArray);
        setItemAtual(updateItem);
        return
      }
    }
  }

  useEffect(() => {
    if (!currentUser) return;
    const loadNextMensagem = async (mensagemMural: MensagemMural) => {
      if (mensagemMural.mensagemMuralMemorialId) {
        const proximaMensagem = await getNextMensagemMural(mensagemMural.id, mensagemMural.mensagemMuralMemorialId);

        if (proximaMensagem) {
          const carouselItemZero : ICarouselItem = { mensagem: mensagemMural, proximaMensagem: proximaMensagem.mensagemMural };
          const carouselItemUm : ICarouselItem = { mensagem: proximaMensagem.mensagemMural, nextToken: proximaMensagem.nextToken };

          setItens([ carouselItemZero, carouselItemUm ]);
          setItemAtual(carouselItemZero);
        }
      }
    }

    setItens([{ mensagem: mensagemMuralInit }])
    setItemAtual({ mensagem: mensagemMuralInit })
    loadNextMensagem(mensagemMuralInit);
  }, [mensagemMuralInit, currentUser])
  
  return (
    <>
      { !currentUser &&
        <LoginFlowCard />
      }

      { currentUser &&
        <div className="d-flex flex-column align-items-center mb-5">
          <Carousel className="w-100" activeIndex={index} onSelect={handleSelect} wrap={false} touch={true} pause={"hover"} interval={null}>
            {itens.map((item) => {
              return (
                <CarouselItem key={item.mensagem.id}>
                  <MensagemMuralView mensagemMural={item.mensagem} />
                </CarouselItem>
              )
            })}
          </Carousel>
          { itemAtual?.proximaMensagem &&
            <div className="mt-3">
              <MensagemMuralNextCard mensagemMural={itemAtual.proximaMensagem} onClick={handleNextCardClick} />
            </div>
          }
        </div>
      }
    </>
  )
}

export { MensagemMuralCarousel }
