import { Storage } from "@aws-amplify/storage";
import { DataStore, SortDirection } from 'aws-amplify';
import { ID, compressBlob } from '../../../../../_metronic/helpers';
import { Cidade, ContentStatusEnum, Falecimento, FalecimentoCidade, FalecimentoFoto, Memorial, OpenAiAssistant, TipoFoto, User } from "../../../../../models";
import { ICidadeEstado, makeCidadeEstado } from '../../cidades/core/_models';
import { IFalecimentoFoto } from './_models';

const getFalecimentoById = async (id: ID): Promise<Falecimento | undefined> => {
  const result = await DataStore.query(Falecimento, id as string);
  return result as Falecimento;
}

const getFalecimentoByMemorialUrl = async (url: string): Promise<Falecimento | undefined> => {
  const result = await DataStore.query(Memorial, m => m.url.eq(url));
  
  if (result.length > 0) {
    return await result[0].Falecimento
  }
    
  return undefined;
}

const updateFalecimentoOpenAiAssistant =async (falecimento:Falecimento, openAiAssistant: OpenAiAssistant) => {
  const result = await DataStore.query(Falecimento, falecimento.id as string);

  if (result) {
    await DataStore.save(
      Falecimento.copyOf(result, updated => {
        updated.OpenAiAssistant = openAiAssistant;
        updated.falecimentoOpenAiAssistantId = openAiAssistant.id;
      })
    );
  }
}

const uploadFalecimentoFoto = async (falecimento: Falecimento, falecimentoFoto: IFalecimentoFoto, status: ContentStatusEnum, user: User) : Promise<FalecimentoFoto|undefined> => {
  const { compressedBlob, extension, uuid } = await compressBlob(falecimentoFoto.fileName, falecimentoFoto.blob);

  if (compressedBlob) {
    const uploadResult = await Storage.put(`falecimento/foto/${falecimento.id}/${uuid}.${extension}`, compressedBlob, { level: "public" } )
  
    return await salvarFalecimentoFoto(falecimento, uploadResult.key, status, falecimentoFoto.tipo, user, true);
  }

  return;
}

export const salvarFalecimentoFoto = async(falecimento: Falecimento, fileKey: string, status: ContentStatusEnum, tipo: TipoFoto, user: User, isCover: boolean) : Promise<FalecimentoFoto> => {
  const result = await DataStore.save(
    new FalecimentoFoto({
      falecimentoID: falecimento.id,
      fileKey,
      status,
      tipo,
      userID: user.id,
      isCover
    })
  )

  return result;
}

const incrementarFalecimentoCidade = async(falecimento: Falecimento, cidade: Cidade) => {
  const falecimentoCidades = await DataStore.query(FalecimentoCidade, fc => fc.falecimentoCidadeFalecimentoId.eq(falecimento.id) && fc.falecimentoCidadeCidadeId.eq(cidade.id));
  
  const estado = await cidade.Estado

  if (falecimentoCidades && falecimentoCidades.length > 0) {
    await DataStore.save(
      FalecimentoCidade.copyOf(falecimentoCidades[0], updated => {
        updated.QtdMensagens = falecimentoCidades[0].QtdMensagens + 1;
        updated.nomeCidade = cidade.nome;
        updated.siglaEstado = estado?.sigla;
      })
    );
  } else {
    await DataStore.save(new FalecimentoCidade({
      Cidade: cidade,
      Falecimento: falecimento,
      falecimentoCidadeCidadeId: cidade.id,
      falecimentoCidadeFalecimentoId: falecimento.id,
      QtdMensagens: 1,
      nomeCidade: cidade.nome,
      siglaEstado: estado?.sigla
    }))
  }
}

const getFalecimentoCidadeList = async (falecimento: Falecimento) : Promise<FalecimentoCidade[]> => {
  const falecimentoCidadeList = await DataStore.query(FalecimentoCidade, 
    fc => fc.falecimentoCidadeFalecimentoId.eq(falecimento.id) && fc.QtdMensagens.gt(0), {
    sort: s => (
      s.QtdMensagens(SortDirection.DESCENDING)
    )
  })

  return falecimentoCidadeList;
}

const carregarCidadesFalecimento = async (falecimento: Falecimento) : Promise<ICidadeEstado[]> => {
  let result: ICidadeEstado[] = [];

  const cidadeFalecimento = await falecimento.Cidade;
  const falecimentoCidadeList = await getFalecimentoCidadeList(falecimento);

  if (cidadeFalecimento) {
    result.push(await makeCidadeEstado(cidadeFalecimento));
  }

  const resultList = await Promise.all(falecimentoCidadeList.map(async (item) => {
    const cidade = await item.Cidade;
    
    if (!cidadeFalecimento || cidadeFalecimento.id !== cidade.id){
      return await makeCidadeEstado(cidade)
    }
  }));

  const resultListOk = resultList.filter(x => !!x) as ICidadeEstado[];

  result = [...result, ...resultListOk]
  

  return result;
}

const decrementarFalecimentoCidade = async(falecimento: Falecimento, cidade: Cidade) => {
  const falecimentoCidades = await DataStore.query(FalecimentoCidade, fc => fc.falecimentoCidadeFalecimentoId.eq(falecimento.id) && fc.falecimentoCidadeCidadeId.eq(cidade.id));

  if (falecimentoCidades && falecimentoCidades.length > 0) {
    await DataStore.save(
      FalecimentoCidade.copyOf(falecimentoCidades[0], updated => {
        updated.QtdMensagens = falecimentoCidades[0].QtdMensagens - 1 < 0 ? 0 : falecimentoCidades[0].QtdMensagens - 1
      })
    );
  }
}

export {
  carregarCidadesFalecimento,
  decrementarFalecimentoCidade,
  getFalecimentoById,
  getFalecimentoByMemorialUrl,
  incrementarFalecimentoCidade,
  updateFalecimentoOpenAiAssistant,
  uploadFalecimentoFoto
};

