import { LoginFlow, LoginFlowProvider } from "./LoginFlow"

const LoginFlowCard = () => {
  return (
    <LoginFlowProvider>
      <div className="card">
        <div className="card-body">
          <LoginFlow />
        </div>
      </div>
    </LoginFlowProvider>
  )
}

export { LoginFlowCard }
