import moment from "moment"
import { FC, useEffect, useState } from "react"
import { toAbsoluteUrl } from "../../../../../_metronic/helpers"
import { Cemiterio, Falecimento } from "../../../../../models"
import { convertCidadeToIbgeCidade } from "../../../../modules/apps/cidades/core/_requests"

type Props = {
  falecimento: Falecimento,
  cemiterio: Cemiterio
}

const ServicoSepultamentoCard: FC<Props> = ({ cemiterio, falecimento }) => {
  const [ localidade, setLocalidade ] = useState<string|undefined>();
  const [ dataSepultamento ] = useState(falecimento.dataSepultamento ? moment(falecimento.dataSepultamento).format('DD/MM/YYYY') : undefined)
  const [ cep ] = useState(cemiterio.cep ? `- CEP: ${cemiterio.cep.replace(/^(\d{5})(\d{3})$/, '$1-$2')}` : '')

  const loadCidade =async (cemiterio:Cemiterio) => {
    if (cemiterio?.cidade && cemiterio.estado) {
      setLocalidade(`${cemiterio.cidade}/${cemiterio.estado}`)
      return;
    }

    const cidade = await cemiterio.Cidade

    if (cidade) {
      const ibgeCidade = await convertCidadeToIbgeCidade(cidade)
      setLocalidade(`${ibgeCidade.nome}/${ibgeCidade.microrregiao.mesorregiao.UF.sigla}`)
    }
  }

  useEffect(() => {
    loadCidade(cemiterio)
  }, [cemiterio])

  return (
    <div className="card card-xl-stretch mb-xl-8">
      {/* begin::Header */}
      <div className="card-header">
        {/* begin::Heading */}
        <div className="d-flex flex-stack">
          {/* begin:Info */}
          <div className="d-flex align-items-center mt-3 mb-3">
            {/* begin:Image */}
            <div className="symbol symbol-40px me-3">
              <span className="symbol-label bg-danger-light">
                <img src={toAbsoluteUrl('/media/icons/duotune/maps/map008.svg')} className="h-75 align-self-center" alt="" />
              </span>
            </div>
            {/* end:Image */}
            {/* begin:Title */}
            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
              <label className="text-dark fw-bold fs-3">Local do Descanso Final</label>
            </div>
            {/* end:Title */}
          </div>
          {/* begin:Info */}
        </div>
        {/* end::Heading */}
      </div>
      {/* end::Header */}
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin:Image */}
            <div className="symbol symbol-45px me-3">
              <span className="symbol-label bg-danger-light">
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen001.svg')} className="h-75 align-self-center" alt="" />
              </span>
            </div>
            {/* end:Image */}
            <div className="d-flex flex-column">
              <label className="text-gray-900 text-hover-primary fs-4 fw-bold">{cemiterio.nome}</label>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin:Image */}
            <div className="symbol symbol-45px me-3">
              <span className="symbol-label bg-danger-light">
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen018.svg')} className="h-75 align-self-center" alt="" />
              </span>
            </div>
            {/* end:Image */}
            { localidade ? 
              <div className="d-flex flex-column">
                <label className="text-gray-900 text-hover-primary fs-6 fw-bold">{cemiterio.logradouro}</label>
                <span className="text-gray-400 fw-bold">
                  {`${localidade} ${cep}`}
                </span>
              </div>
              : null
            }
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin:Image */}
            <div className="symbol symbol-45px me-3">
              <span className="symbol-label bg-danger-light">
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen014.svg')} className="h-75 align-self-center" alt="" />
              </span>
            </div>
            {/* end:Image */}
            <div className="d-flex flex-column">
              <label className="text-gray-900 text-hover-primary fs-6 fw-bold">{`Horário: ${dataSepultamento} às ${falecimento.horaSepultamento}`}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ServicoSepultamentoCard }

