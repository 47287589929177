import clsx from 'clsx';
import { ChangeEvent, FC } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { BsFilter, BsSearch } from 'react-icons/bs';

type Props= {
  className?: string
  onTextChange: (text: string) => void
  onFilterClick?: () => void
}

const SearchInput : FC<Props> = ({ className, onTextChange, onFilterClick }) => {

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onTextChange(event.target.value);
  };

  const handleFilterClick = () => {
    if (onFilterClick){
      onFilterClick();
    }
  };
  
  return (
    <InputGroup className={clsx("mb-3", className)}>
      <InputGroup.Text className='bg-transparent fs-5'><BsSearch className='ms-2'/></InputGroup.Text>
      <FormControl
        placeholder="Procurar"
        aria-label="Pesquisar"
        aria-describedby="basic-addon2"
        className={clsx("rounded-end border-start-0 fs-4", onFilterClick && "border-end-0")}
        onChange={handleInputChange}
      />
      { onFilterClick && <InputGroup.Text className='bg-transparent fs-5 text-center ms-n2'  onClick={handleFilterClick}><BsFilter className='ms-2'/></InputGroup.Text> }
    </InputGroup>
  );
}

export { SearchInput };
