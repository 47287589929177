import { useEffect, useRef, useState } from "react";
import { Post } from "../../../../../models";
import { PostFeedModel } from "../core/_models";
import { getPostsFeed } from "../core/_requests";
import { PostFeedItem } from "./PostFeedItem";

const PostFeed = () => {
  const [ posts, setPosts ] = useState<PostFeedModel[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [noResult, setNoResult] = useState(false);
  
  const itemsPerPage = 5;
  const fetchItems = async (page: number) => {
    setLoading(true);

      
    try {
      const result = await getPostsFeed(page, itemsPerPage);

      if (page === 1 && result.length < itemsPerPage) {
        setHasMore(false);
      }

      if (result.length === 0) {
        setHasMore(false);

        setNoResult(page === 1);
      } else {
        setPosts(prevMensagens => {
          const allMessages = [...prevMensagens, ...result];
          const uniqueIds = new Set(allMessages.map(m => m.id));
          const uniqueMessages = Array.from(uniqueIds).map(id => allMessages.find(m => m.id === id)).filter(m => m !== undefined) as Post[];
          return uniqueMessages;
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const lastItemRef = (node: HTMLElement | null) => {
    if (loading || !hasMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  };

  useEffect(() => {
    fetchItems(page);
  }, [page]);

  

  return (
    <>
      <div className="mb-5">
        {posts.map((post, index) => (
          <div key={index} ref={index === posts.length - 1 ? lastItemRef : null}>
            <PostFeedItem key={post.id} post={post} />
          </div>
        ))}
      </div>

      {loading &&
        <div className='d-flex justify-content-center align-items-center'>
          <span className='indicator-progress' style={{display: 'block'}}>
            <span className='spinner-border spinner-border-lg align-middle'></span>
          </span>
        </div>
      }
    </>
    
  )
};

export { PostFeed };

