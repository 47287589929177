import { GraphQLQuery } from "@aws-amplify/api";
import { API, DataStore } from "aws-amplify";
import { FaixaEtariaEnum, ListPerguntaFaixaEtariasQueryVariables, SyncPerguntaFaixaEtariasQuery } from "../../../../../API";
import { syncPerguntaFaixaEtarias } from "../../../../../graphql/queries";
import { AnonymousUser, Falecimento, LazyFalecimento, NivelRelacionamentoEnum, Pergunta, PerguntaNivelRelacionamento, PerguntaOpcaoResposta, PerguntaTipoRelacionamento, Resposta, TipoRelacionamento, TipoRespostaEnum, User } from "../../../../../models";

export const getPerguntas = async (falecimento: Falecimento) : Promise<Pergunta[]> => {
  const result = await DataStore.query(Pergunta, p => 
    p.and(p => [ p.active.eq(true), p.tipoResposta.eq(TipoRespostaEnum.OPCOES)]));
    
  return result;
}

export const getOpcoesRespostas = async (pergunta: Pergunta) : Promise<PerguntaOpcaoResposta[]> => {
  if (pergunta.tipoResposta !== TipoRespostaEnum.OPCOES) {
    return [];
  }
  
  const opcoes = await DataStore.query(PerguntaOpcaoResposta, op =>
    op.and(op => [ op.perguntaOpcaoRespostaPerguntaId.eq(pergunta.id), op.active.ne(false) ]));
    
  return opcoes;
}

export const salvarResposta = async (assistentemensagemID: string, falecimento: Falecimento, pergunta: Pergunta, resposta: string, anonymousUser: AnonymousUser, user?: User, opcaoResposta?: PerguntaOpcaoResposta) : Promise<Resposta> => {
  const result = await DataStore.save(
    new Resposta({
      Falecimento: falecimento,
      pergunta: pergunta.pergunta,
      resposta,
      respostaFalecimentoId: falecimento.id,
      tipoPergunta: pergunta.tipoPergunta,
      AnonymousUser: anonymousUser,
      respostaAnonymousUserId: anonymousUser.id,
      User: user,
      respostaUserId: user?.id,
      viviamJuntos: opcaoResposta ? opcaoResposta.viviamJuntos : undefined,
      Pergunta: pergunta,
      respostaPerguntaId: pergunta.id,
      PerguntaOpcaoResposta: opcaoResposta,
      respostaPerguntaOpcaoRespostaId: opcaoResposta?.id,
      assistentemensagemID,
      skiped: false,
    })
  )

  return result;
}

export const salvarRespostaSkiped = async (assistentemensagemID: string, falecimento: Falecimento, pergunta: Pergunta, anonymousUser: AnonymousUser, user?: User) : Promise<Resposta> => {
  const result = await DataStore.save(
    new Resposta({
      Falecimento: falecimento,
      pergunta: pergunta.pergunta,
      resposta: 'skiped',
      respostaFalecimentoId: falecimento.id,
      tipoPergunta: pergunta.tipoPergunta,
      AnonymousUser: anonymousUser,
      respostaAnonymousUserId: anonymousUser.id,
      User: user,
      respostaUserId: user?.id,
      Pergunta: pergunta,
      respostaPerguntaId: pergunta.id,
      assistentemensagemID,
      skiped: true,
    })
  )

  return result;
}

export const atualizarResposta = async (falecimento: Falecimento, pergunta: Pergunta, resposta: Resposta, texto: string, anonymousUser: AnonymousUser, user?: User, opcaoResposta?: PerguntaOpcaoResposta) : Promise<Resposta> => {
  const respostaToUpdate = await DataStore.query(Resposta, resposta.id);

  if (!respostaToUpdate) {
    throw new Error('Resposta inválida');
  }
  
  const result = await DataStore.save(
    Resposta.copyOf(respostaToUpdate, updated => {
      updated.Falecimento = falecimento;
      updated.pergunta = pergunta.pergunta;
      updated.resposta = texto;
      updated.respostaFalecimentoId = falecimento.id;
      updated.tipoPergunta = pergunta.tipoPergunta;
      updated.AnonymousUser = anonymousUser;
      updated.respostaAnonymousUserId = anonymousUser.id;
      updated.User = user;
      updated.respostaUserId = user?.id;
      updated.viviamJuntos = opcaoResposta ? opcaoResposta.viviamJuntos : undefined;
      updated.Pergunta = pergunta;
      updated.respostaPerguntaId = pergunta.id;
      updated.PerguntaOpcaoResposta = opcaoResposta;
      updated.respostaPerguntaOpcaoRespostaId = opcaoResposta?.id;
    })
  )

  return result;
}

export const requestPrimeiraPergunta = async () : Promise<Pergunta|null> => {
  const perguntas = await DataStore.query(Pergunta, p =>
    p.and(p => [ p.active.eq(true), p.respostaTipoViviamJuntos.eq(true)]));

  if (perguntas.length > 1) {
    const numeroAleatorio = perguntaAleatoria(perguntas.length);

    if (numeroAleatorio >= 0 && numeroAleatorio < perguntas.length) {
      return perguntas[numeroAleatorio];
    }
  }

  return perguntas.length > 0 ? perguntas[0] : null;
}

export const getPerguntasViviamJuntos = async (falecimento: Falecimento, puladas: string[]): Promise<Pergunta[]> => {
  const perguntasNivelRelacionamento = await DataStore.query(PerguntaNivelRelacionamento, p =>
    p.and(p => [ p.active.eq(true), p.nivelRelacionamento.eq(NivelRelacionamentoEnum.VIVIAM_JUNTOS)]));

  const perguntasViviamJuntos = await getPerguntasFromNivelRelacionamento(perguntasNivelRelacionamento)
    
  const result = await filtrarPerguntaFaixaEtaria(perguntasViviamJuntos, falecimento, puladas)

  return result;
}

export const getPerguntasTipoRelacionamento = async (tiporelacionamento: TipoRelacionamento, falecimento: Falecimento, puladas: string[]): Promise<Pergunta[]> => {
  const perguntasTipoRelacionamento = await DataStore.query(PerguntaTipoRelacionamento, p =>
    p.and(p => [ p.active.eq(true), p.perguntaTipoRelacionamentoTipoRelacionamentoId.eq(tiporelacionamento.id)]));
    
  const perguntas = await getPerguntasFromTipoRelacionamento(perguntasTipoRelacionamento)

  const result = await filtrarPerguntaFaixaEtaria(perguntas, falecimento, puladas)

  return result;
}

export const getPerguntasNivelRelacionamento = async (nivelRelaciomento: NivelRelacionamentoEnum, falecimento: Falecimento, puladas: string[]): Promise<Pergunta[]> => {
  const perguntasNivelRelacionamento = await DataStore.query(PerguntaNivelRelacionamento, p =>
    p.and(p => [ p.active.eq(true), p.nivelRelacionamento.eq(nivelRelaciomento)]));
    
  const perguntas = await getPerguntasFromNivelRelacionamento(perguntasNivelRelacionamento)

  const result = await filtrarPerguntaFaixaEtaria(perguntas, falecimento, puladas)

  return result;
}

export const getPerguntasGenericas = async () : Promise<Pergunta[]> => {
  const perguntas = await DataStore.query(Pergunta, p =>
    p.and( p => [
      p.active.eq(true),
      p.limitaTipoRelacionamento.eq(false),
      p.limitaFaixaEtaria.eq(false),
      p.limitaNivelRelacionamento.eq(false),
      p.respostaTipoViviamJuntos.eq(false)
    ])
  )
  
  return perguntas;
}

const getPerguntasFromTipoRelacionamento = async (perguntasTipoRelacionamento: PerguntaTipoRelacionamento[]) => {
  const perguntas: Pergunta[] = []

  for (const p of perguntasTipoRelacionamento) {
    const pergunta = await p.Pergunta;
    perguntas.push(pergunta);
  }

  return perguntas;
};

const getPerguntasFromNivelRelacionamento = async (perguntasNivelRelacionamento: PerguntaNivelRelacionamento[]) => {
  const perguntas: Pergunta[] = []

  for (const p of perguntasNivelRelacionamento) {
    const pergunta = await p.Pergunta;
    perguntas.push(pergunta);
  }

  return perguntas;
};

const filtrarPerguntaFaixaEtaria = async (perguntas: Pergunta[], falecimento: Falecimento, puladas: string[]) : Promise<Pergunta[]> => {
  if (perguntas.length === 0) {
    return []
  }

  const perguntasComFaixaEtaria = perguntas.filter(p => p && p.limitaFaixaEtaria === true)
  const perguntasSemFaixaEtaria = perguntas.filter(p => p && p.limitaFaixaEtaria === false)
  
  const ids = await executarFiltroFaixaEtaria(perguntasComFaixaEtaria, puladas, falecimento);
  const result = ids.length > 0 ? perguntas.filter(item => ids.includes(item.id)) : [];
  
  return [...perguntasSemFaixaEtaria, ...result ];
}

const executarFiltroFaixaEtaria = async (perguntas: Pergunta[], puladas: string[], falecimento: LazyFalecimento) : Promise<string[]> => {
  if (perguntas.length === 0) {
    return []
  }

  const filterIn = criarFiltroFaixaEtaria(perguntas, puladas);

  if (filterIn.length === 0) {
    return []
  }

  const variables: ListPerguntaFaixaEtariasQueryVariables = criarVariaveisFiltroPorFaixaEtaria(falecimento, filterIn);

  const perguntasFaixaEtariaSync = await API.graphql<GraphQLQuery<SyncPerguntaFaixaEtariasQuery>>({
    query: syncPerguntaFaixaEtarias,
    variables
  });

  const items = perguntasFaixaEtariaSync.data?.syncPerguntaFaixaEtarias?.items;
  const result = items ? items.filter(i => i?.perguntaFaixaEtariaPerguntaId).map(i => i?.perguntaFaixaEtariaPerguntaId!) : []

  return result || [];
}

function criarVariaveisFiltroPorFaixaEtaria(falecimento: Falecimento, filterIn: ({ perguntaFaixaEtariaPerguntaId: { eq: string; }; } | null)[]): ListPerguntaFaixaEtariasQueryVariables {
  return {
    limit: 10,
    filter: {
      _deleted: { ne: true },
      faixaEtaria: { eq: falecimento.faixaEtaria as FaixaEtariaEnum },
      or: filterIn
    }
  };
}

function criarFiltroFaixaEtaria(perguntas: Pergunta[], puladas: string[]) {
  return perguntas.map(p => {
    if (!puladas.includes(p.id)) {
      return {
        perguntaFaixaEtariaPerguntaId: { eq: p.id }
      };
    }

    return null;
  })
    .filter(Boolean);
}

function perguntaAleatoria(max: number): number {
  const numeroAleatorio = Math.random() * max;
  
  return Math.floor(numeroAleatorio);
}