import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Memorial } from "../../../../../../models";

type Props = {
  memorial: Memorial
}

const MemorialHeaderNav : FC<Props> = ({ memorial }) => {
  const location = useLocation()
  
  return (
    <div className='d-flex flex-column flex-center text-center overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/memorial/${memorial.url}/obituario` && 'active')
                }
                to={`/memorial/${memorial.url}/obituario`}
              >
                Obituário
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/memorial/${memorial.url}/servicos` && 'active')
                }
                to={`/memorial/${memorial.url}/servicos`}
              >
                Serviços
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/memorial/${memorial.url}/mural` && 'active')
                }
                to={`/memorial/${memorial.url}/mural`}
              >
                Mural
              </Link>
            </li>
          </ul>
        </div>
  )
}

export { MemorialHeaderNav };

