import { NivelRelacionamentoEnum } from "../../../../API";
import { AnonymousUser, Pergunta, PerguntaOpcaoResposta, TipoRelacionamento } from "../../../../models";
import { AssistenteMensagemHandler, IPerguntaResposta } from "../assistente-mensagem/handlers/AssistenteMensagemHandler";
import { atualizarResposta, getPerguntasGenericas, getPerguntasNivelRelacionamento, getPerguntasTipoRelacionamento, getPerguntasViviamJuntos, requestPrimeiraPergunta, salvarResposta, salvarRespostaSkiped } from "./core/_requests";

class PerguntasHandler {
  assistenteMensagemHandler: AssistenteMensagemHandler;
  tipoRelacionamento: TipoRelacionamento;
  anonymousUser: AnonymousUser;
  qtdRespostas: number;

  perguntaSelected: Pergunta | null = null;
  ultimaResposta: IPerguntaResposta | null = null

  buffer: Pergunta[] = [];
  puladas: string[] = [];
  respondidas: string[] = [];

  perguntasViviamJuntos: boolean = false;
  perguntasTipoRelacionamento: boolean = false;
  perguntasNivelRelacionamento: boolean = false;
  perguntasGenericas: boolean = false;

  perguntasFinalizadas: boolean = false;

  viviamJuntos: boolean | null = null;

  respostas: IPerguntaResposta[] = [];
  indexAtual: number = -1;
  isEditing: boolean = false;

  constructor(assistenteMensagemHandler: AssistenteMensagemHandler, tipoRelacionamento: TipoRelacionamento, anonymousUser: AnonymousUser, qtdRespostas: number) {
    this.assistenteMensagemHandler = assistenteMensagemHandler;
    this.tipoRelacionamento = tipoRelacionamento;
    this.anonymousUser = anonymousUser;
    this.qtdRespostas = qtdRespostas;
  }

  async getProximaPergunta () {
    this.isEditing = false;

    if (this.perguntasFinalizadas === true) {
      this.perguntaSelected = null;
      return false;
    }
    
    this.perguntaSelected =
      this.getPerguntaBuffer() ||
      await this.getPrimeiraPergunta() ||
      await this.getPerguntaViviamJuntos() ||
      await this.getPerguntaPorTipoRelacionamento() ||
      await this.getPerguntaPorNivelRelacionamento() ||
      await this.getPerguntaGenerica();

    this.perguntasFinalizadas = !this.perguntaSelected;
    return this.perguntaSelected;
  }

  async getPerguntaViviamJuntos() : Promise<Pergunta|null> {
    if (this.perguntasViviamJuntos === true) return null;
    
    if (this.tipoRelacionamento.nivelRelaciomento !== NivelRelacionamentoEnum.PARENTE_PROXIMO) {
      this.perguntasViviamJuntos = true;
      return null;
    }
    
    if (this.viviamJuntos === true) {
      this.perguntasViviamJuntos = true;
      this.buffer = await getPerguntasViviamJuntos(this.assistenteMensagemHandler.falecimento, this.puladas)

      return this.getPerguntaBuffer()
    }

    return null;
  }

  async getPerguntaPorTipoRelacionamento() : Promise<Pergunta | null> {
    this.perguntasTipoRelacionamento = true;

    this.buffer = await getPerguntasTipoRelacionamento(this.tipoRelacionamento, this.assistenteMensagemHandler.falecimento, this.puladas)

    return this.getPerguntaBuffer()
  }

  async getPerguntaPorNivelRelacionamento() : Promise<Pergunta | null> {
    this.perguntasNivelRelacionamento = true;

    this.buffer = await getPerguntasNivelRelacionamento(this.tipoRelacionamento.nivelRelaciomento as NivelRelacionamentoEnum, this.assistenteMensagemHandler.falecimento, this.puladas)

    return this.getPerguntaBuffer()
  }

  async getPerguntaGenerica() : Promise<Pergunta | null> {
    this.perguntasGenericas = true;

    this.buffer = await getPerguntasGenericas()

    return this.getPerguntaBuffer()
  }

  async getPrimeiraPergunta() : Promise<Pergunta | null>  {
    if (this.perguntaSelected) return null;
    if (this.tipoRelacionamento.nivelRelaciomento !== NivelRelacionamentoEnum.PARENTE_PROXIMO) return null;

    const pergunta = await requestPrimeiraPergunta()

    return pergunta;
  }

  getApelidoOuNomeFalecido() : string {
    return this.assistenteMensagemHandler.falecimento.apelido ?? this.assistenteMensagemHandler.falecimento.nome;
  }

  async salvarResposta(pergunta: Pergunta, texto: string, opcaoResposta?: PerguntaOpcaoResposta) {
    if (!this.assistenteMensagemHandler.assistenteMensagem) return;
    
    const resposta = this.isEditing && this.ultimaResposta?.resposta?
    await atualizarResposta(this.assistenteMensagemHandler.falecimento, pergunta, this.ultimaResposta.resposta, texto, this.anonymousUser, undefined, opcaoResposta) :
    await salvarResposta(this.assistenteMensagemHandler.assistenteMensagem.id, this.assistenteMensagemHandler.falecimento, pergunta, texto, this.anonymousUser, undefined, opcaoResposta);

    if(this.viviamJuntos === null && opcaoResposta?.viviamJuntos) {
      this.viviamJuntos = opcaoResposta.viviamJuntos;
    }

    this.respondidas.push(pergunta.id);
    this.respostas.push({ pergunta, resposta, skiped: false });
    this.assistenteMensagemHandler.respostas = this.respostas;
    this.indexAtual++;

    this.perguntasFinalizadas = this.qtdRespostas === this.respondidas.length;

    await this.getProximaPergunta();
  }

  async pularPergunta() {
    this.isEditing = false;
    
    if (!this.perguntaSelected) return;
    if (!this.assistenteMensagemHandler.assistenteMensagem) return;

    await salvarRespostaSkiped(this.assistenteMensagemHandler.assistenteMensagem.id, this.assistenteMensagemHandler.falecimento, this.perguntaSelected, this.anonymousUser, undefined);
    this.puladas.push(this.perguntaSelected.id);
    this.respostas.push({ pergunta: this.perguntaSelected, skiped: true })
    this.assistenteMensagemHandler.respostas = this.respostas;
    this.indexAtual++;
    this.isEditing = true;
    
    return await this.getProximaPergunta();
  }

  iniciar() {
    this.isEditing = false;
    this.puladas = [];
    this.perguntasFinalizadas = false;
    this.perguntasTipoRelacionamento = false;
    this.perguntasViviamJuntos = false;
    this.perguntasNivelRelacionamento = false;
    this.perguntasGenericas = false;
    this.perguntaSelected = null;
    this.viviamJuntos = null;
    this.buffer = [];
    this.indexAtual = -1;
  }

  voltar() : IPerguntaResposta | null {
    if (this.indexAtual === -1) {
      return null;
    }

    const resposta = this.respostas.pop();
    
    if (!resposta) {
      return null;
    }

    this.perguntasFinalizadas = false;
    this.perguntaSelected = resposta.pergunta;
    this.ultimaResposta = resposta
    this.indexAtual--;
    this.isEditing = true;

    return resposta;
  }

  getPerguntaBuffer() : Pergunta | null {
    if (this.buffer.length === 0) {
      return null;
    }

    const perguntasBuffer = this.filtrarPerguntas(this.buffer);

    if (perguntasBuffer.length > 0) {
      return this.getPerguntaAleatoria(perguntasBuffer)
    }

    return null;
  }

  filtrarPerguntas(perguntas: Pergunta[]) : Pergunta[] {
    let result = this.filtrarPerguntasPuladas(perguntas);
    result = this.filtrarPerguntasRespondidas(result);

    return result;
  }

  filtrarPerguntasPuladas(perguntas: Pergunta[]) : Pergunta[] {
    return perguntas.filter(p => !this.puladas.includes(p.id))
  }

  filtrarPerguntasRespondidas(perguntas: Pergunta[]) : Pergunta[] {
    return perguntas.filter(p => !this.respondidas.includes(p.id))
  }

  getPerguntaAleatoria(perguntas: Pergunta[]): Pergunta {
    const numeroAleatorio = Math.random() * perguntas.length;
    const index = Math.floor(numeroAleatorio);

    if (index >= 0 && index < perguntas.length) {
      return perguntas[index]
    }

    return perguntas[0]
  }
}

export { PerguntasHandler };

