import { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaPaperPlane } from "react-icons/fa";
import { useAssistenteMensagem } from "../AssistenteMensagemProvider";

type Props = {
  onGoBack: () => void
}

const StepEditarMensagem : FC<Props> = ( { onGoBack } ) => {
  const { handler, goToPostPage } = useAssistenteMensagem();
  const [ textoValido, setTextoValido ] = useState(false);
  const [ textoLivre, setTextoLivre ] = useState(handler.mensagem ?? '')
  
  const handleNext = async() => {
    handler.salvarMensagemEditadaManual(textoLivre);

    goToPostPage();
  }

  const handleBack = async () => {
    onGoBack();
  }

  const validarTexto = (textoLivre: string) => {
    const regex = /\S+/g;
    const numPalavras = (textoLivre.trim().match(regex) || []).length;
    setTextoValido(numPalavras >= 5);
  }

  useEffect(() => {
    validarTexto(textoLivre);
  }, [textoLivre])

  
  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title text-center fs-3 px-3 mb-4">{`Editar mensagem`}</h2>
          <div className='w-100'>
            <div className='fv-row mb-10'>
              <textarea
                className='form-control form-control-lg form-control-solid fs-3'
                name='mensagem'
                rows={10}
                onChange={(e) => setTextoLivre(e.target.value)}
                value={textoLivre}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 d-flex flex-stack mb-4">
          <Button variant="secondary" className='fs-3' onClick={handleBack}>
            Voltar
          </Button>

          <Button variant="primary" className="d-flex align-items-center fs-3" onClick={handleNext} disabled={!textoValido} >
            <FaPaperPlane className="me-2" />
            Postar
          </Button>
        </div>
    </>
  )
}

export { StepEditarMensagem };

