import { GraphQLQuery } from '@aws-amplify/api';
import { API, DataStore, SortDirection } from 'aws-amplify';
import moment from 'moment';
import { ListMensagemMuralsQueryVariables, SyncMensagemMuralsQuery } from '../../../../../API';
import { criarResumo } from '../../../../../_metronic/helpers';
import { customSyncMensagemMuralsFeed } from '../../../../../graphql/my-queries';
import { syncMensagemMurals } from '../../../../../graphql/queries';
import { Cidade, Falecimento, FalecimentoFoto, Memorial, MensagemMural, MensagemMuralEngajamento, MensagemMuralFalecimentoFoto, MensagemMuralStatusEnum, MensagemMuralViews, TipoFoto, TipoMensagemEnum, User } from "../../../../../models";
import { convertCidadeToIbgeCidade } from '../../cidades/core/_requests';
import { incrementarFalecimentoCidade } from '../../falecimentos/core/_requests';
import { criarPostHomenagem } from '../../post/core/_requests';
import { CustomSyncMensagemMuralsFeedQuery } from './_models';

const getMensagemMuralById = async(id: string) : Promise<MensagemMural|undefined> => {
  return DataStore.query(MensagemMural, id);
}

const getMensagemMural = async (falecimento: Falecimento, pageValue: number, itemsPerPageValue: number) : Promise<MensagemMural[]> => {
  const results = await DataStore.query(MensagemMural, m => m.Falecimento.id.eq(falecimento.id) && m.status.eq(MensagemMuralStatusEnum.APPROVED), {
    sort: s => (
      s.updatedAt(SortDirection.DESCENDING)
    ),
    page: pageValue,
    limit: itemsPerPageValue
  });

  return results
}

const getNextMensagemMural = async (idInit: string, memorialId: string, nextToken?: string|null) : Promise<{ mensagemMural: MensagemMural, nextToken: string|undefined|null }|undefined> => {
  const variables: ListMensagemMuralsQueryVariables = {
    nextToken: nextToken,
    limit: 1,
    filter: {
      id: { ne: idInit },
      mensagemMuralMemorialId: { eq: memorialId },
      _deleted: { ne: true },
    }
  }

  const mensagens = await API.graphql<GraphQLQuery<SyncMensagemMuralsQuery>>(
    {
      query: syncMensagemMurals,
      variables
    }
  )

  if (mensagens.data?.syncMensagemMurals?.items) {
    if (mensagens.data.syncMensagemMurals.items.length > 0) {
      const item = mensagens.data.syncMensagemMurals.items[0];

      if (item) {
        const result = await getMensagemMuralById(item.id);

        if (result) {
          return { nextToken: mensagens.data.syncMensagemMurals.nextToken, mensagemMural: result }
        }
      }
    }
  }

  return;
}

const postMensagemMural = async (
    mensagem: string,
    memorial: Memorial,
    falecimento: Falecimento,
    cidade: Cidade,
    user: User,
    tipoMensagem: TipoMensagemEnum,
    nomeUsuario: string,
    status: MensagemMuralStatusEnum
  ) : Promise<{error: boolean, data?: MensagemMural}> => {
  
  try {

    const result = await DataStore.save(
      await makePostMensagemMural(
        mensagem,
        memorial,
        falecimento,
        cidade,
        user,
        tipoMensagem,
        nomeUsuario,
        status,
      )
    );

    await incrementarFalecimentoCidade(falecimento, cidade);
    await criarPostHomenagem(user, result.id, nomeUsuario, falecimento.apelido ?? falecimento.nome)
  
    return { error: false, data: result as MensagemMural };
  } catch (error) {
    console.log(error)
    return { error: true }
  }
}

const salvarMensagemMuralFalecimentoFoto = async (falecimentoFoto: FalecimentoFoto, mensagemMural: MensagemMural) => {
  await DataStore.save(
    new MensagemMuralFalecimentoFoto({
      FalecimentoFoto: falecimentoFoto,
      MensagemMural: mensagemMural,
      mensagemMuralFalecimentoFotoFalecimentoFotoId: falecimentoFoto.id,
      mensagemMuralFalecimentoFotoMensagemMuralId: mensagemMural.id,
      tipoFoto: falecimentoFoto.tipo,
      isCover: falecimentoFoto.isCover,
    })
  )
}

const getMensagemMuralFalecimentoFoto = async(mensagemMuralId: string, tipo: TipoFoto) : Promise<MensagemMuralFalecimentoFoto|undefined> => {
  const mensagens = await DataStore.query(MensagemMuralFalecimentoFoto,
    m => m.and(
      mens => [
        mens.mensagemMuralFalecimentoFotoMensagemMuralId.eq(mensagemMuralId),
        mens.tipoFoto.eq(tipo),
      ]
    )
  )

  if (mensagens.length > 0) {
    return mensagens[0];
  }

  return;
}

const makePostMensagemMural = async (
    mensagem: string,
    memorial: Memorial,
    falecimento: Falecimento,
    cidade: Cidade,
    user: User,
    tipoMensagem: TipoMensagemEnum,
    nomeUsuario: string,
    status: MensagemMuralStatusEnum,
  ) : Promise<MensagemMural> => {
  
  const ibgeCidade = await convertCidadeToIbgeCidade(cidade)
  
  return new MensagemMural({
    mensagem,
    dataPostagem: moment().format('YYYY-MM-DD'),
    horaPostagem: moment().format('HH:mm:ss'),
    Memorial: memorial,
    mensagemMuralMemorialId: memorial.id,
    Falecimento: falecimento,
    mensagemMuralFalecimentoId: falecimento.id,
    Cidade: cidade,
    mensagemMuralCidadeId: cidade.id,
    cidade: cidade.nome,
    estado: ibgeCidade.microrregiao.mesorregiao.UF.sigla,
    tipoMensagem,
    nomeUsuario,
    User: user,
    mensagemMuralUserId: user.id,
    status,
    resumo: criarResumo(mensagem, 300),
  });
}

export const getMensagensFeed = async (memorialId: string, falecimentoId: string, page: number, limit: number): Promise<MensagemMural[]> => {
  const mensagens = await DataStore.query(MensagemMural,
    m => m.and(
      mens => [
        mens.mensagemMuralFalecimentoId.eq(falecimentoId),
        mens.mensagemMuralMemorialId.eq(memorialId),
        mens.status.eq(MensagemMuralStatusEnum.APPROVED)
      ]
    ),
    {
      page,
      limit,
      sort: s => s.updatedAt(SortDirection.DESCENDING),
    }
  )

  return mensagens;
}

const queryMensagensFeed = async (memorialId: string, falecimentoId: string, nextToken: string|null, limit: number): Promise<CustomSyncMensagemMuralsFeedQuery> => {
  const variables: ListMensagemMuralsQueryVariables = {
    nextToken: nextToken,
    limit: limit,
    filter: {
      mensagemMuralFalecimentoId: { eq: falecimentoId },
      mensagemMuralMemorialId: { eq: memorialId},
      _deleted: { ne: true }
    }
  }

  const mensagens = await API.graphql<GraphQLQuery<CustomSyncMensagemMuralsFeedQuery>>(
    {
      query: customSyncMensagemMuralsFeed,
      variables
    }
  )

  return {
    ...mensagens.data,
    syncMensagemMurals: {
      ...mensagens.data?.syncMensagemMurals,
      items: mensagens.data?.syncMensagemMurals?.items,
    },
  } as CustomSyncMensagemMuralsFeedQuery;
}

const registrarEngajamentoView = async (mensagemMuralId:string, user: User) => {
  const mensagemMuralView = await DataStore.query(MensagemMuralViews, m =>
    m.and(mens => [
      mens.mensagemMuralViewsMensagemMuralId.eq(mensagemMuralId),
      mens.mensagemMuralViewsUserId.eq(user.id)
    ])
  )

  if (mensagemMuralView.length === 0) {
    const mensagemMural = await DataStore.query(MensagemMural, mensagemMuralId);

    if (!mensagemMural) return;

    await criarMensagemMuralViews(mensagemMural, user);
    await somarEngajamentoViews(mensagemMural);
  }
}

const criarMensagemMuralViews = async (mensagemMural: MensagemMural, user: User) => {
  await DataStore.save(
    new MensagemMuralViews(
      {
        dataVisualizacao: moment().format('YYYY-MM-DD'),
        horaVisualizacao: moment().format('HH:mm:ss'),
        MensagemMural: mensagemMural,
        mensagemMuralViewsMensagemMuralId: mensagemMural.id,
        mensagemMuralViewsUserId: user.id,
        User: user,
      }
    )
  )
}

const obterOuCriarMensagemMuralEngajamento =async (mensagemMural:MensagemMural) : Promise<MensagemMuralEngajamento> => {
  const engajamento = await DataStore.query(MensagemMuralEngajamento, 
    m => m.mensagemMuralEngajamentoMensagemMuralId.eq(mensagemMural.id)
  )

  if (engajamento.length > 0) return engajamento[0];

  return await DataStore.save(
    new MensagemMuralEngajamento({
      MensagemMural: mensagemMural,
      mensagemMuralEngajamentoMensagemMuralId: mensagemMural.id,
      views: 0
    })
  )
}

const somarEngajamentoViews = async (mensagemMural: MensagemMural) => {
  const itemEngajamento = await obterOuCriarMensagemMuralEngajamento(mensagemMural);

  await DataStore.save(
    MensagemMuralEngajamento.copyOf(itemEngajamento, updated => {
      updated.views = itemEngajamento.views + 1;
    })
  )
}

export { getMensagemMural, getMensagemMuralById, getMensagemMuralFalecimentoFoto, getNextMensagemMural, postMensagemMural, queryMensagensFeed, registrarEngajamentoView, salvarMensagemMuralFalecimentoFoto };

