import { Storage } from "@aws-amplify/storage";
import { useEffect, useState } from 'react';
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { MensagemMural, TipoFoto, User } from '../../../../../models';
import { useAuth } from "../../../auth";
import { IbgeCidade } from '../../cidades/core/_models';
import { convertCidadeToIbgeCidade } from '../../cidades/core/_requests';
import { getMensagemMuralFalecimentoFoto, registrarEngajamentoView } from '../core/_requests';

import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export const useMensagemMural = (mensagemMural?: MensagemMural) => {
  const { currentUser } = useAuth();
  const [ user, setUser ] = useState<User>();
  const [ profilePhotoUrl, setProfilePhotoUrl ] = useState<string>(toAbsoluteUrl('/media/avatars/blank.png'));
  const [ coverPhotoUrl, setCoverPhotoUrl ] = useState<string>();
  const [ localPostagem, setLocalPostagem ] = useState<IbgeCidade>();
  const [ nomeUsuario, setNomeUsuario ] = useState('')
  const [ cidadeEstado, setCidadeEstado ] = useState<string>('');
  const [ mensagemMuralLink, setMensgemMuralLink ] = useState('')
  const [ dataMensagem, setDataMensagem ] = useState<string>('')
  const [ nomeHomenageado, setNomeHomenageado ] = useState<string>('')
  const [ urlMural, setUrlMural ] = useState<string>('')
  const [ urlObituario, setUrlObituario ] = useState<string>('')

  useEffect(() => {
    if (!mensagemMural) return;

    const loadDadosMensagem = async (mensagemMural: MensagemMural) => {
      setMensgemMuralLink(`/homenagem/${mensagemMural.id}`)
    }

    const loadUser = async (mensagemMural: MensagemMural) => {
      const user = await mensagemMural.User;
      setUser(user);

      setNomeUsuario(mensagemMural.nomeUsuario ?? user?.nome ?? 'Anônimo')
    };

    const loadMemorialLinks = async (mensagemMural: MensagemMural) => {
      const memorial = await mensagemMural.Memorial;

      if (memorial) {
        setUrlMural(`/memorial/${memorial.url}/mural`);
        setUrlObituario(`/memorial/${memorial.url}/obituario`);
      }
    }

    const loadProfilePhoto = async (mensagem: MensagemMural) => {
      const mensagemFoto = await getMensagemMuralFalecimentoFoto(mensagem.id, TipoFoto.PROFILE);

      if (mensagemFoto) {
        const foto = await mensagemFoto.FalecimentoFoto;

        if (foto) {
          const imgUrl = await Storage.get(foto.fileKey, { level: "public" });
          setProfilePhotoUrl(imgUrl);
        }
      }
    };

    const loadCoverPhoto = async (mensagem: MensagemMural) => {
      const mensagemFoto = await getMensagemMuralFalecimentoFoto(mensagem.id, TipoFoto.COVER);

      if (mensagemFoto) {
        const foto = await mensagemFoto.FalecimentoFoto;

        if (foto) {
          const imgUrl = await Storage.get(foto.fileKey, { level: "public" });
          setCoverPhotoUrl(imgUrl);
        }
      }
    };

    const loadLocalPostagem = async (mensagem: MensagemMural) => {
      if (mensagem.cidade && mensagem.estado) return;

      const mensagemCidade = await mensagem.Cidade;

      if (mensagemCidade) {
        const ibgeCidade = await convertCidadeToIbgeCidade(mensagemCidade);
        setLocalPostagem(ibgeCidade);
      }
    };

    const loadCidadeEstado = async (mensagem: MensagemMural) => {
      if (mensagem.cidade && mensagem.estado) {
        setCidadeEstado(`${mensagem.cidade}/${mensagem.estado}`)
      }
    }

    const loadDataMensagem = async (mensagem: MensagemMural) => {
      const dataHoraPostagem = moment(`${mensagem.dataPostagem} ${mensagem.horaPostagem}`, 'YYYY-MM-DD HH:mm')
      const dataExtenso = moment(dataHoraPostagem).toString()

      setDataMensagem(dataExtenso);
    }

    const loadFalecimento = async (mensagem: MensagemMural) => {
      const falecimento = await mensagem.Falecimento;

      if (falecimento) {
        setNomeHomenageado(falecimento.apelido ?? falecimento.nome)
      }
    }

    loadDadosMensagem(mensagemMural);
    loadUser(mensagemMural);
    loadProfilePhoto(mensagemMural);
    loadCoverPhoto(mensagemMural);
    loadLocalPostagem(mensagemMural);
    loadCidadeEstado(mensagemMural);
    loadDataMensagem(mensagemMural);
    loadFalecimento(mensagemMural);
    loadMemorialLinks(mensagemMural);
  }, [mensagemMural]);

  useEffect(() => {
    const registrarView = async (mensagemMural: MensagemMural, user: User) => {
      await registrarEngajamentoView(mensagemMural.id, user);
    };

    if (mensagemMural && currentUser) {
      registrarView(mensagemMural, currentUser).then(() => { });
    }
  }, [mensagemMural, currentUser]);

  return { user, profilePhotoUrl, coverPhotoUrl, localPostagem, nomeUsuario, cidadeEstado, mensagemMuralLink, dataMensagem, nomeHomenageado, urlMural, urlObituario };
};