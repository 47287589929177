import moment from "moment"
import { FC, useEffect, useState } from "react"
import { toAbsoluteUrl } from "../../../../../_metronic/helpers"
import { Falecimento, Velorio } from "../../../../../models"
import { convertCidadeToIbgeCidade } from "../../../../modules/apps/cidades/core/_requests"

type Props = {
  falecimento: Falecimento,
  velorio: Velorio
}

const ServicoVelorioCard: FC<Props> = ({ velorio, falecimento }) => {
  const [ localidade, setLocalidade ] = useState<string|undefined>();
  const [ dataInicioVelorio ] = useState(falecimento.dataInicioVelorio ? moment(falecimento.dataInicioVelorio).format('DD/MM/YYYY') : undefined)
  const [ dataFimVelorio ] = useState(falecimento.dataFimVelorio ? moment(falecimento.dataFimVelorio).format('DD/MM/YYYY') : undefined)
  const [ cep ] = useState(velorio.cep ? `- CEP: ${velorio.cep.replace(/^(\d{5})(\d{3})$/, '$1-$2')}` : '')

  const loadCidade =async (velorio:Velorio) => {
    if (velorio?.cidade && velorio.estado) {
      setLocalidade(`${velorio.cidade}/${velorio.estado}`)
      return;
    }

    const cidade = await velorio.Cidade

    if (cidade) {
      const ibgeCidade = await convertCidadeToIbgeCidade(cidade)
      setLocalidade(`${ibgeCidade.nome}/${ibgeCidade.microrregiao.mesorregiao.UF.sigla}`)
    }
  }

  useEffect(() => {
    loadCidade(velorio)
  }, [velorio])

  return (
    <div className="card card-xl-stretch mb-xl-8">
      {/* begin::Header */}
      <div className="card-header">
        {/* begin::Heading */}
        <div className="d-flex flex-stack">
          {/* begin:Info */}
          <div className="d-flex align-items-center mt-3 mb-3">
            {/* begin:Image */}
            <div className="symbol symbol-40px me-3">
              <span className="symbol-label bg-danger-light">
                <img src={toAbsoluteUrl('/media/icons/duotune/maps/map008.svg')} className="h-75 align-self-center" alt="" />
              </span>
            </div>
            {/* end:Image */}
            {/* begin:Title */}
            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
              <label className="text-dark fw-bold fs-3">Local da Despedida</label>
            </div>
            {/* end:Title */}
          </div>
          {/* begin:Info */}
        </div>
        {/* end::Heading */}
      </div>
      {/* end::Header */}
      <div className="card-body">
        <div className="d-flex align-items-center mb-2">
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin:Image */}
            <div className="symbol symbol-45px me-3">
              <span className="symbol-label bg-danger-light">
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen001.svg')} className="h-75 align-self-center" alt="" />
              </span>
            </div>
            {/* end:Image */}
            <div className="d-flex flex-column">
              <label className="text-gray-900 text-hover-primary fs-4 fw-bold">{velorio.nome}</label>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mb-2">
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin:Image */}
            <div className="symbol symbol-45px me-3">
              <span className="symbol-label bg-danger-light">
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen018.svg')} className="h-75 align-self-center" alt="" />
              </span>
            </div>
            {/* end:Image */}
            { localidade ?
              <div className="d-flex flex-column">
                <label className="text-gray-900 text-hover-primary fs-6 fw-bold">{velorio.logradouro}</label>
                <span className="text-gray-400 fw-bold">
                  {`${localidade} ${cep}`}
                </span>
              </div>
              : null
            }
          </div>
        </div>

        <div className="d-flex align-items-center mb-2">
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin:Image */}
            <div className="symbol symbol-45px me-3">
              <span className="symbol-label bg-danger-light">
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen014.svg')} className="h-75 align-self-center" alt="" />
              </span>
            </div>
            {/* end:Image */}
            <div className="col">
              <div className="d-flex flex-stack row">
                <label className="text-gray-900 text-hover-primary fs-6 fw-bold">{`Início: ${dataInicioVelorio} às ${falecimento.horaInicioVelorio}`}</label>
              </div>
              <div className="d-flex flex-stack row">
                <label className="text-gray-900 text-hover-primary fs-6 fw-bold">{`Término: ${dataFimVelorio} às ${falecimento.horaFimVelorio}`}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ServicoVelorioCard }

