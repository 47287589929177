import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackAndNextButtons } from "../../../../../_lapide/components/buttons/BackAndNextButtons";
import { useAuth } from "../../../auth";
import { useAssistenteMensagem } from "../AssistenteMensagemProvider";

const StepIdentificacaoUsuario = () => {
  const { anonymousUser } = useAuth();
  const { runStepIdentificacaoUsuario, isLoading, memorial, handler, isEditing, closeAssistente } = useAssistenteMensagem()
  const [ nome, setNome ] = useState<string>('')
  const [ isOpen, setIsOpen ] = useState<boolean>(false)

  const navigate = useNavigate();

  const handleNomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNome(event.target.value);
  };

  const handleNext = async () => {
    if (nome) {
      await runStepIdentificacaoUsuario(nome)
    }
  }

  const handleBack = async () => {
    if (isEditing){
      closeAssistente();
      return;
    }

    if (memorial) {
      navigate(`/memorial/${memorial.url}/mural?reload=true`)
    }
  }

  useEffect(() => {
    if (anonymousUser?.nome) {
      setNome(anonymousUser.nome)
    }
  }, [anonymousUser])

  useEffect(() => {
    if (!nome && handler?.nomeUsuario && !isOpen) {
      setNome(handler.nomeUsuario)
    }

    setIsOpen(true)
  }, [handler?.nomeUsuario, nome, isOpen])


  return (
    <>
      <div className="card">
        <div className="card-body">
          <h1 className="card-title text-center mb-5 mt-3">Qual seu nome?</h1>
          <div className="form-group">
            <input
              type="text"
              autoComplete='off'
              placeholder=''
              className='form-control text-center fs-1 ls-4'
              value={nome}
              onChange={handleNomeChange}
            />
          </div>
        </div>
        <div className="card-foot text-center">
          <BackAndNextButtons
            className="p-3"
            nextText="Avançar"
            isNextLoading={isLoading}
            nextEnabled={!!nome && nome.length > 2}
            onGoNext={handleNext}
            onGoBack={handleBack}

            isBackLoading={false}
          />
        </div>
      </div>
    </>
  );
};

export { StepIdentificacaoUsuario };

