import { DataStore, SortDirection } from "aws-amplify";
import moment from "moment";
import { ConviteAdministracaoMemorial, ConviteAdministracaoMemorialStatusEnum, Memorial, User } from "../../../../../models";

export const getMemorialByUrl = async (url: string) : Promise<Memorial | undefined> => {
  const result = await DataStore.query(Memorial, m => m.url.eq(url))

  return result.length > 0 ? result[0] : undefined
}

export const queryMemorials = async (user: User, pageValue: number, itemsPerPageValue: number) : Promise<Memorial[]> => {
  const results = await DataStore.query(Memorial, m => m.SuperAdmin.id.eq(user.id), {
    sort: s => (
      s.url(SortDirection.DESCENDING)
    ),
    page: pageValue,
    limit: itemsPerPageValue
  });

  return results
}

export const aceitarConviteAdmin = async(user: User) => {
  const convites =
    await DataStore.query(
      ConviteAdministracaoMemorial,
        c =>
          c.email.eq(user.email) &&
          c.status.eq(ConviteAdministracaoMemorialStatusEnum.PENDENTE)
    )

  for (let index = 0; index < convites.length; index++) {
    const convite = convites[index];
    const memorial = await DataStore.query(Memorial, convite.memorialID)

    await DataStore.save(
      ConviteAdministracaoMemorial.copyOf(convite, updated => {
        updated.Convidado = user;
        updated.conviteAdministracaoMemorialConvidadoId = user.id;
        updated.dataAceite = moment().format();
        updated.status = ConviteAdministracaoMemorialStatusEnum.ACEITO;
      })
    );

    if (memorial) {
      await updateMemorialSuperUser(user, memorial)
    }
  }
}

const updateMemorialSuperUser = async(user: User, memorial: Memorial) => {
  if (memorial.emailSuperAdmin === user.email) {
    await DataStore.save(
      Memorial.copyOf(memorial, updated => {
        updated.SuperAdmin = user;
        updated.memorialSuperAdminId = user.id
      })
    );
  }
}