import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { MensagemMuralStatusEnum, User } from '../../../../../models';
import { useAuth } from '../../../auth';
import { updateStatusMensagem } from '../../mensagem-mural-editor/core/_requests';
import { MensagemMuralFeedModel } from '../core/_models';

moment.locale('pt-br');

type Props = {
  className: string;
  name: string;
  message: string;
  avatarUrl?: string;
  dataPostagem: string;
  horaPostagem: string;
  cidade: string;
  estado: string;
  user?: User;
  mensagemMural: MensagemMuralFeedModel;
  onDeletePost?: () => void
};

const MensagemMuralFeedView: React.FC<Props> = ({
  className,
  name,
  message,
  avatarUrl,
  dataPostagem,
  horaPostagem,
  cidade,
  estado,
  user,
  mensagemMural,
  onDeletePost,
}) => {
  const local = `${cidade}/${estado}`;
  const dataHoraPostagem = moment(`${dataPostagem} ${horaPostagem}`, 'YYYY-MM-DD HH:mm');
  const dataExtenso = moment(dataHoraPostagem).calendar();
  const { currentUser } = useAuth();
  const [ img, setImg ] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    if (avatarUrl) {
      setImg(avatarUrl)
    }
  }, [avatarUrl])

  const handleVerMais = () => {
    navigate(`/homenagem/${mensagemMural.id}`)
  }

  const handleDelete = () => {
    if(!mensagemMural || !user) {
      return;
    }

    Swal.fire({
      title: "Você tem certeza?",
      text: "Esta ação não poderá ser revertida!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, excluir mensagem!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateStatusMensagem(mensagemMural.id, user, MensagemMuralStatusEnum.DELETED)
        
        Swal.fire({
          title: "Excluído!",
          text: "Sua mensagem foi excluída.",
          icon: "success"
        });

        if (onDeletePost) {
          onDeletePost()
        }
      }
    });
  };

  return (
    <div className={`card ${className}`}>
      
      {/* begin::Body */}
      <div className='card-body px-3 pb-0 pt-3'>
        {/* begin::Header */}
        <div className='d-flex align-items-center justify-content-between mb-4'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div className='symbol symbol-75px me-3'>
              <img src={img ?? toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
            </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <span className='text-gray-800 text-hover-primary fs-2 fw-bold'>
                {name}
              </span>
              <span className='text-gray-400 fw-semibold fs-5 text-wrap'>
                {`${local}`}
              </span>
              <span className='text-gray-400 fw-semibold fs-5 text-wrap'>
                {`${dataExtenso}`}
              </span>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}

          { currentUser && user?.id === currentUser.id && (
            <div className='p-0'>
              <Dropdown className="d-inline-block" align={{lg: 'start'}}>
                <Dropdown.Toggle className='fs-2 p-2' variant='transparent' id='dropdown-basic'>
                </Dropdown.Toggle>

                <Dropdown.Menu className='fs-4' variant='dark' flip={true}>
                  <Dropdown.Item className='min-w-250px' onClick={handleDelete}>
                    <div className='d-flex align-items-center'>
                      <div className='me-3'>
                        <i className='fa fa-trash fs-5'></i>
                      </div>
                      <div className=''>Excluir</div>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        {/* end::Header */}

        <div className='separator my-6'></div>
        
        {/* begin::Post */}
        <div>
          {/* begin::Text */}
          <p
            className='mensagem-mural text-gray-800 text-center'
            style={{ whiteSpace: 'pre-line' }}
          >
            {message}
          </p>
          {/* end::Text */}
        </div>
        {/* end::Post */}

        <div className='separator'></div>
        
        <div>
          <div className="d-flex flex-center">
            <button className="btn btn-link fs-2 fw-bold w-100" onClick={handleVerMais}>Ver Mais</button>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export { MensagemMuralFeedView };

