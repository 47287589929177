import { TamanhoMensagemEnu, TipoPessoaEnum } from '../../../../../API';
import { BackAndNextButtons } from '../../../../../_lapide/components/buttons/BackAndNextButtons';
import { AssinaturaMensagemSelector } from '../../../ui-components/AssinaturaMensagemSelector/AssinaturaMensagemSelector';
import { MensagemInstrucao } from '../../../ui-components/MensagemInstrucao/MensagemInstrucao';
import { MessageSizeSelector } from '../../../ui-components/MessageSizeSelector/MessageSizeSelector';
import { TipoPessoaMensagemSelector } from '../../../ui-components/TipoPessoaMensagemSelector/TipoPessoaMensagemSelector';
import { AssistenteMensagemStepEnum, useAssistenteMensagem } from '../AssistenteMensagemProvider';

const StepModeloTexto = () => {
  const { handler, goToPage, perguntasHandler, isLoading, isEditing, closeAssistente } = useAssistenteMensagem();

  const handleTamanhoChange = (tamanho: TamanhoMensagemEnu) => {
    handler.tamanhoMensagem = tamanho;
  };

  const handleTipoPessoaChange = (tipo: TipoPessoaEnum) => {
    handler.tipoPessoa = tipo;
  };

  const handleEmNomeDeChange = (value: string | undefined) => {
    handler.emNomeDe = value;
  }

  const handleInstrucao = (value: string | undefined) => {
    handler.instrucao = value;
  }

  const handleNext = async () => {
    await handler.salvarModeloTexto(handler.tamanhoMensagem, handler.tipoPessoa, handler.emNomeDe, handler.instrucao);
    await handler.gerarMensagemPelasRepostas();
  }
  const handleBack = async () => {
    if (isEditing) {
      closeAssistente();
      return;
    }
    
    if (perguntasHandler) {
      perguntasHandler.voltar();
    }
    goToPage(AssistenteMensagemStepEnum.PERGUNTAS, handler)
  }

  return (
    <>
      <div className='card'>
        <div className='card-body p-3'>
          <h2 className="card-title text-center mb-4 mt-1">Modelo de Texto</h2>
          <div className='separator my-6'></div>
          <MessageSizeSelector currentSize={handler.tamanhoMensagem} onSizeChange={handleTamanhoChange} />
          <div className='separator my-6'></div>
          <TipoPessoaMensagemSelector currentTipo={handler.tipoPessoa} onTipoChange={handleTipoPessoaChange} />
          <div className='separator my-6'></div>
          <AssinaturaMensagemSelector currentValue={handler.emNomeDe} nome={handler.nomeUsuario!}  onValueChange={handleEmNomeDeChange} />
          <div className='separator my-6'></div>
          <MensagemInstrucao currentValue={handler.instrucao} onValueChange={handleInstrucao} />
          <div className='separator my-6'></div>
        </div>
      </div>
      <BackAndNextButtons
        className='mt-2 px-2'
        onGoBack={handleBack}
        nextText='Gerar'
        onGoNext={handleNext}
        isNextLoading={isLoading}
      />
    </>
  );
};

export { StepModeloTexto };

