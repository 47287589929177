export const generateStrongPassword = (length: number) =>{
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const numericChars = '0123456789';
  const specialChars = '!@#$%';

  // Crie uma string que contenha todos os caracteres permitidos
  const allChars = uppercaseChars + lowercaseChars + numericChars + specialChars;

  let password = '';

  // Garanta que a senha tenha pelo menos 1 de cada tipo de caractere
  password += uppercaseChars.charAt(Math.floor(Math.random() * uppercaseChars.length));
  password += lowercaseChars.charAt(Math.floor(Math.random() * lowercaseChars.length));
  password += numericChars.charAt(Math.floor(Math.random() * numericChars.length));
  password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

  // Preencha o restante da senha com caracteres aleatórios
  for (let i = 4; i < length; i++) {
    password += allChars.charAt(Math.floor(Math.random() * allChars.length));
  }

  // Embaralhe a senha para garantir aleatoriedade
  password = password.split('').sort(() => Math.random() - 0.5).join('');

  return password;
}