import { BackAndNextButtons } from '../../../../../_lapide/components/buttons/BackAndNextButtons';
import { AssistenteMensagemStepEnum, useAssistenteMensagem } from '../AssistenteMensagemProvider';

const StepAssistenteMensagemUsuario = () => {
  const { goToPage, handler, goToPostPage} = useAssistenteMensagem()

  const handleNext = async () => {
    goToPostPage()
  }
 
  const handleBack = async () => {
    goToPage(AssistenteMensagemStepEnum.MENSAGEM_USUARIO, handler)
  }

  const handleCorrecoes = async () => {
    await handler.fazerCorrecoesGramaticaOrtografia();
  }

  const handleReescrita = async () => {
    await handler.reescreverMensagemUsuario();
  }

  return (
    <div>
      <div className='card mb-3'>
        <div className='card-body p-3'>
          {/* Primeiro card */}
          <div className='card bg-transparent mb-3 text-center py-0'>
            <div className='card-body p-2'>
              <p className='card-subtitle m-3 fs-3'>Permita que nossa Inteligência Artificial faça as correções ortográficas e gramaticais para o seu texto.</p>
              <button className='btn btn-primary fs-4 mb-3' onClick={handleCorrecoes}>
                <i className='bi bi-pencil'></i> Fazer Correções
              </button>
            </div>
          </div>

          {/* Segundo card */}
          <div className='card bg-transparent mb-3 text-center py-0'>
            <div className='card-body p-4'>
              <p className='card-subtitle mb-3 fs-3'>Reescreva sua mensagem através de Inteligência Artificial, acrescentando conteúdo, mas mantendo sua essência.</p>
              <button className='btn btn-primary fs-4' onClick={handleReescrita}>
                <i className='bi bi-question-circle'></i> Reescrever
              </button>
            </div>
          </div>
        </div>
      </div>

      <BackAndNextButtons
        onGoBack={handleBack}
        onGoNext={handleNext}
        nextText='Postar meu texto'
      />
    </div>
  )
}

export { StepAssistenteMensagemUsuario };

