import { useEffect, useRef } from "react"
import { useLoginFlow } from "../LoginFlow"


const LoginStep2Validation = () => {
  const { email, setValidationCode, isLoading, isSecondCode, countdown, executeResendValidationCode } = useLoginFlow()
  const inputCodeRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isSecondCode === true && inputCodeRef.current) {
      inputCodeRef.current.value = '';
    }
  }, [isSecondCode])

  useEffect(() => {
    if (inputCodeRef.current) {
      inputCodeRef.current.focus();
    }
  }, []);

  return (
    <div className='w-100'>
      <div className='mb-10 text-center'>
          <h1 className='mb-3 text-gray-1000 fs-3'>{`Verifique${isSecondCode ? ' Novamente ' : ' '}seu E-mail`}</h1>
          <label className='text-gray-700 fw-bold fs-5'>
            {`Enviamos${isSecondCode ? ' novamente ' : ' '}um código de 6 dígitos para`}
          </label>
          <label className="fw-bold fs-4 mt-2">
            {email}
          </label>
        </div>

        <div className="mb-3 text-center">
          <label className="fw-bold text-start fs-6 mb-1 ms-1 text-gray-1000">
            {`Informe o${isSecondCode ? ' segundo ' : ' '}código de 6 dígitos`}
          </label>
        </div>

        <div className="d-flex flex-wrap flex-stack justify-content-center">
          <input
            ref={inputCodeRef}
            type="text"
            maxLength={6}
            autoComplete='off'
            placeholder=''
            className='form-control text-center fs-2 ls-5'
            onChange={(e) => { setValidationCode(e.target.value) }}
            disabled={isLoading}
          />
          <button className="btn btn-secondary mt-2" onClick={executeResendValidationCode} disabled={countdown > 0}>
            {countdown > 0 ? `Reenviar em ${countdown} s` : 'Reenviar'}
          </button>
        </div>
  </div>
  )
}

export { LoginStep2Validation }

