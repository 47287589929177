// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TipoMensagemSelecionadaEnum = {
  "ESCRITA_USUARIO": "ESCRITA_USUARIO",
  "REESCRITA_IA": "REESCRITA_IA",
  "CORRIGIDA_IA": "CORRIGIDA_IA",
  "GERADA_IA": "GERADA_IA",
  "EDITADA_MANUAL": "EDITADA_MANUAL"
};

const AssistenteStatusEnum = {
  "IN_PROGRESS": "IN_PROGRESS",
  "CANCELED": "CANCELED",
  "COMPLETED": "COMPLETED"
};

const TipoPessoaEnum = {
  "PRIMEIRA": "PRIMEIRA",
  "TERCEIRA": "TERCEIRA"
};

const TamanhoMensagemEnu = {
  "CONCISO": "CONCISO",
  "BALANCEADO": "BALANCEADO",
  "EXTENSO": "EXTENSO"
};

const PostActionEnum = {
  "CREATED": "CREATED",
  "UPDATED": "UPDATED"
};

const PostTypeEnum = {
  "HOMENAGEM": "HOMENAGEM",
  "MEMORIAL": "MEMORIAL"
};

const TipoMensagemEnum = {
  "GERADA": "GERADA",
  "ESCRITA": "ESCRITA",
  "GERADA_EDITADA": "GERADA_EDITADA"
};

const TipoFoto = {
  "PROFILE": "PROFILE",
  "COVER": "COVER",
  "NORMAL": "NORMAL",
  "CROP": "CROP",
  "TOTAL": "TOTAL"
};

const ContentStatusEnum = {
  "PENDING": "PENDING",
  "APPROVED": "APPROVED",
  "REMOVED": "REMOVED",
  "DELETED": "DELETED"
};

const FaixaEtariaEnum = {
  "INFANCIA_BEBE": "INFANCIA_BEBE",
  "INFANCIA_CRIANCA_PEQUENA": "INFANCIA_CRIANCA_PEQUENA",
  "INFANCIA_CRIANCA_GRANDE": "INFANCIA_CRIANCA_GRANDE",
  "ADOLESCENCIA_PRE_ADOLESCENTE": "ADOLESCENCIA_PRE_ADOLESCENTE",
  "ADOLESCENCIA_ADOLESCENTE": "ADOLESCENCIA_ADOLESCENTE",
  "ADULTO_JOVEM_ADULTO": "ADULTO_JOVEM_ADULTO",
  "ADULTO_ADULTO_JOVEM": "ADULTO_ADULTO_JOVEM",
  "ADULTO_MEIA_IDADE": "ADULTO_MEIA_IDADE",
  "TERCEIRA_IDADE_IDOSO_JOVEM": "TERCEIRA_IDADE_IDOSO_JOVEM",
  "TERCEIRA_IDADE_IDOSO": "TERCEIRA_IDADE_IDOSO",
  "TERCEIRA_IDADE_MUITO_IDOSO": "TERCEIRA_IDADE_MUITO_IDOSO"
};

const SexoEnum = {
  "MASCULINO": "MASCULINO",
  "FEMININO": "FEMININO"
};

const TipoRespostaEnum = {
  "OPCOES": "OPCOES",
  "TEXTO_ABERTO": "TEXTO_ABERTO",
  "UMA_PALAVRA": "UMA_PALAVRA",
  "POUCAS_PALAVRAS": "POUCAS_PALAVRAS",
  "SIM_NAO": "SIM_NAO"
};

const TipoPerguntaEnum = {
  "RELACIONAMENTO": "RELACIONAMENTO",
  "ESPECIFICA_FALECIDO": "ESPECIFICA_FALECIDO",
  "ESPECIFICA_USUARIO": "ESPECIFICA_USUARIO"
};

const NivelRelacionamentoEnum = {
  "PARENTE_PROXIMO": "PARENTE_PROXIMO",
  "PARENTE_DISTANTE": "PARENTE_DISTANTE",
  "AMIGO": "AMIGO",
  "TRABALHO": "TRABALHO",
  "COMUNIDADE": "COMUNIDADE",
  "AMIGO_PROXIMO": "AMIGO_PROXIMO",
  "VIVIAM_JUNTOS": "VIVIAM_JUNTOS"
};

const ConviteAdministracaoMemorialStatusEnum = {
  "ACEITO": "ACEITO",
  "PENDENTE": "PENDENTE",
  "RECUSADO": "RECUSADO"
};

const MensagemMuralStatusEnum = {
  "PENDING": "PENDING",
  "APPROVED": "APPROVED",
  "REMOVED": "REMOVED",
  "DELETED": "DELETED"
};

const UserRoleEnum = {
  "BACKOFFICE": "BACKOFFICE",
  "USER": "USER",
  "ADMIN": "ADMIN"
};

const UserStatusEnum = {
  "PENDING": "PENDING",
  "COMPLETED": "COMPLETED"
};

const { PostImage, Post, MensagemMuralEngajamento, MensagemMuralViews, MensagemMuralFalecimentoFoto, FalecimentoFoto, OpenAiGeneratedMessage, OpenAiRun, OpenAiAssistant, AssistenteMensagem, OpenAiThread, PerguntaFaixaEtaria, PerguntaTipoRelacionamento, RelUsuarioFalecimento, Resposta, PerguntaOpcaoResposta, PerguntaNivelRelacionamento, Pergunta, TipoRelacionamento, FalecimentoCidade, ConviteAdministracaoMemorial, Memorial, AnonymousUser, MensagemMural, Cemiterio, Mesorregiao, Microrregiao, Regiao, Estado, Cidade, Velorio, Falecimento, User, Funeraria, MemorialUser, CemiterioFuneraria, CemiterioVelorio, VelorioFuneraria, UserFuneraria, OpenAiError } = initSchema(schema);

export {
  PostImage,
  Post,
  MensagemMuralEngajamento,
  MensagemMuralViews,
  MensagemMuralFalecimentoFoto,
  FalecimentoFoto,
  OpenAiGeneratedMessage,
  OpenAiRun,
  OpenAiAssistant,
  AssistenteMensagem,
  OpenAiThread,
  PerguntaFaixaEtaria,
  PerguntaTipoRelacionamento,
  RelUsuarioFalecimento,
  Resposta,
  PerguntaOpcaoResposta,
  PerguntaNivelRelacionamento,
  Pergunta,
  TipoRelacionamento,
  FalecimentoCidade,
  ConviteAdministracaoMemorial,
  Memorial,
  AnonymousUser,
  MensagemMural,
  Cemiterio,
  Mesorregiao,
  Microrregiao,
  Regiao,
  Estado,
  Cidade,
  Velorio,
  Falecimento,
  User,
  Funeraria,
  MemorialUser,
  CemiterioFuneraria,
  CemiterioVelorio,
  VelorioFuneraria,
  UserFuneraria,
  TipoMensagemSelecionadaEnum,
  AssistenteStatusEnum,
  TipoPessoaEnum,
  TamanhoMensagemEnu,
  PostActionEnum,
  PostTypeEnum,
  TipoMensagemEnum,
  TipoFoto,
  ContentStatusEnum,
  FaixaEtariaEnum,
  SexoEnum,
  TipoRespostaEnum,
  TipoPerguntaEnum,
  NivelRelacionamentoEnum,
  ConviteAdministracaoMemorialStatusEnum,
  MensagemMuralStatusEnum,
  UserRoleEnum,
  UserStatusEnum,
  OpenAiError
};