import { API, DataStore } from "aws-amplify";
import { AnonymousUser, Falecimento, OpenAiAssistant, OpenAiRun, OpenAiThread } from "../../../../../models";
import { IOpenAiAssistant, IOpenAiMessages, IOpenAiRun, IOpenAiThread, ResultType, ResultTypeEnum, openAiMessageLorenIpsum, threadRunCompleted, threadRunError, threadRunning } from "./_models";

export const createOpenAiAssistant = async (instructions: string, assistantName: string) : Promise<IOpenAiAssistant | null> => {
  const apiInit = {
    body: {
      instructions: {
        message: instructions,
        assistantName,
      }
    },
  };

  const result = API.post('openai', '/assistants/create', apiInit)
  .then((response: IOpenAiAssistant) => {
    return response;
  })
  .catch((error: any) => {
    console.log("error", JSON.stringify(error))
    return null;
  })

  return result;
}

export const createOpenAiThread = async () : Promise<IOpenAiThread | null> => {
  const apiInit = {
    body: {},
  };

  const result = API.post('openai', '/threads/create', apiInit)
  .then((response: IOpenAiThread) => {
    return response;
  })
  .catch((error: any) => {
    console.log("error", JSON.stringify(error))
    return null;
  })

  return result;
}

export const createAndRunThreadMessage = async (assistantId: string, messages: string[]) : Promise<IOpenAiRun | null> => {
  const apiInit = {
    body: {
      instructions: {
        assistant: {
          id: assistantId
        },
        messages
      }
    }
  }

  if (ResultType === ResultTypeEnum.MOCK_ERROR) {
    return threadRunError;
  }

  if (ResultType === ResultTypeEnum.MOCK_SUCCESS) {
    return threadRunning;
  }
  
  const result = API.post('openai', '/threads/messages/createandrun', apiInit)
  .then((response: IOpenAiRun) => {
    return response;
  })
  .catch((error: any) => {
    console.log("error", JSON.stringify(error))
    return null;
  })

  return result;
}

export const createOpenAiThreadMessage = async (threadId: string, instructions: string) : Promise<string | null> => {
  const apiInit = {
    body: {
      instructions: {
        thread: {
          id: threadId
        },
        message: instructions
      }
    },
  };

  const result = API.post('openai', '/threads/messages/create', apiInit)
  .then((response) => {
    return JSON.stringify(response);
  })
  .catch((error: any) => {
    console.log("error", JSON.stringify(error))
    return null;
  })

  return result;
}

export const findOpenAiThread = async (anonymousUser: AnonymousUser) : Promise<OpenAiThread | null> => {
  const threads = await DataStore.query(OpenAiThread, t =>
    t.openAiThreadAnonymousUserId.eq(anonymousUser.id))

    return threads.length > 0 ? threads[0] : null;
}

export const findOpenAiAssistant =async (falecimento:Falecimento) : Promise<OpenAiAssistant | undefined> => {
  const falecimentoDb = await DataStore.query(Falecimento, falecimento.id);

  if (falecimentoDb) {
    return await falecimentoDb.OpenAiAssistant;
  }
}

export const createOpenAiThreadRun = async (threadId: string, assistantId: string, instructions: string) : Promise<IOpenAiRun | null> => {
  const apiInit = {
    body: {
      instructions: {
        message: instructions,
        thread: {
          id: threadId
        },
        assistant: {
          id: assistantId
        }
      }
    },
  };

  const result = API.post('openai', '/threads/run/create', apiInit)
  .then((response: IOpenAiRun) => {
    return response;
  })
  .catch((error: any) => {
    console.log("error", JSON.stringify(error))
    return null;
  })

  return result;
}


export const retrieveOpenAiThreadRun = async (threadId: string, runId: string) : Promise<IOpenAiRun | null> => {
  const apiInit = {
    body: {
      instructions: {
        thread: {
          id: threadId
        },
        run: {
          id: runId
        }
      }
    },
  };

  if (ResultType === ResultTypeEnum.MOCK_ERROR) {
    return threadRunError;
  }

  if (ResultType === ResultTypeEnum.MOCK_SUCCESS) {
    return threadRunCompleted;
  }

  const result = API.post('openai', '/threads/run/retrieve', apiInit)
  .then((response: IOpenAiRun) => {
    return response;
  })
  .catch((error: any) => {
    console.log("error", JSON.stringify(error))
    return null;
  })

  return result;
}

export const getOpenAiThreadMessageList = async (threadId: string) : Promise<IOpenAiMessages | null> => {
  const apiInit = {
    body: {
      instructions: {
        thread: {
          id: threadId
        }
      }
    },
  };

  if (ResultType === ResultTypeEnum.MOCK_SUCCESS) {
    return openAiMessageLorenIpsum;
  }

  const result = API.post('openai', '/threads/messages/list', apiInit)
  .then((response: IOpenAiMessages) => {
    return response;
  })
  .catch((error: any) => {
    console.log("error", JSON.stringify(error))
    return null;
  })

  return result;
}

export const saveOpenAiThread =async (openAiThread: IOpenAiThread, openAiAssistant: OpenAiAssistant, anonymousUser: AnonymousUser): Promise<OpenAiThread>=> {
  return await DataStore.save(new OpenAiThread({
    object: openAiThread.object,
    threadId: openAiThread.id,
    OpenAiAssistant: openAiAssistant,
    openAiThreadOpenAiAssistantId: openAiAssistant.id,
    AnonymousUser: anonymousUser,
    openAiThreadAnonymousUserId: anonymousUser.id,
  }))
}

export const saveOpenAiAssistant =async (openAiAssistant: IOpenAiAssistant): Promise<OpenAiAssistant>=> {
  return await DataStore.save(new OpenAiAssistant({
    instructions: openAiAssistant.instructions,
    model: openAiAssistant.model,
    object: openAiAssistant.object,
    assistantId: openAiAssistant.id,
  }))
}

const createOpenAiRun = async (assistentemensagemID: string , anonymousUser: AnonymousUser, openAiAssistant: OpenAiAssistant, run: IOpenAiRun): Promise<OpenAiRun>=> {
  return await DataStore.save(
    new OpenAiRun({
      runId: run.id,
      object: run.object,
      created_at: run.created_at,
      assistant_id: run.assistant_id,
      thread_id: run.thread_id,
      status: run.status,
      started_at: run.started_at,
      expires_at: run.expires_at,
      cancelled_at: run.cancelled_at,
      failed_at: run.failed_at,
      completed_at: run.completed_at,
      last_error: run.last_error,
      model: run.model,
      instructions: run.instructions,
      OpenAiAssistant: openAiAssistant,
      openAiRunOpenAiAssistantId: openAiAssistant.id,
      AnonymousUser: anonymousUser,
      openAiRunAnonymousUserId: anonymousUser.id,
      assistentemensagemID
    })
  )
}

const updateOpenAiRun = async (openAiRun: OpenAiRun, run: IOpenAiRun): Promise<OpenAiRun>=>{
  return await DataStore.save(
    OpenAiRun.copyOf(openAiRun, updated => {
      updated.status = run.status;
      updated.started_at = run.started_at;
      updated.cancelled_at = run.cancelled_at;
      updated.completed_at = run.completed_at;
      updated.expires_at = run.expires_at;
      updated.failed_at = run.failed_at;
      updated.last_error = run.last_error;
    })
  )
}

export const saveOpenAiRun = async (assistentemensagemID: string , anonymousUser: AnonymousUser, openAiAssistant: OpenAiAssistant, run: IOpenAiRun): Promise<OpenAiRun>=> {
  const runs = await DataStore.query(OpenAiRun, r =>
    r.and(
      q => [
        q.runId.eq(run.id),
        r.assistentemensagemID.eq(assistentemensagemID)
      ]
    )
  )

  if (runs.length === 0) {
    return createOpenAiRun(assistentemensagemID, anonymousUser, openAiAssistant, run);
  }

  return updateOpenAiRun(runs[0], run);
}