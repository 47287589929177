import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { WithChildren } from "../../../../_metronic/helpers";
import { useAuth } from "../../auth";
import { LoginFlowProvider } from "../../auth/components/LoginFlow";

enum MensagemMuralStepEnum {
  EMAIL = 1,
  CODE_VERIFICATION = 2,
  REVISAO = 3,
}

enum TipoMensagemEnum {
  TEXTO_LIVRE = 0,
  ASSISTENTE = 1,
}

type MensagemMuralContextProps = {
  errorMessage: string | undefined,
  setErrorMessage: Dispatch<SetStateAction<string|undefined>>,
  currentMensagemMuralStep: MensagemMuralStepEnum;
  setCurrentMensagemMuralStep: Dispatch<SetStateAction<MensagemMuralStepEnum>>,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  tipoMensagem: TipoMensagemEnum|undefined,
  setTipoMensagem: Dispatch<SetStateAction<TipoMensagemEnum|undefined>>,
}

const initMensagemMuralContextPropsState = {
  errorMessage: undefined,
  setErrorMessage: () => {},
  currentMensagemMuralStep: MensagemMuralStepEnum.EMAIL,
  setCurrentMensagemMuralStep: () => {},
  isLoading: false,
  setIsLoading: () => {},
  tipoMensagem: undefined,
  setTipoMensagem: () => {},
}

const MensagemMuralContext = createContext<MensagemMuralContextProps>(initMensagemMuralContextPropsState)

const useMensagemMural = () => {
  return useContext(MensagemMuralContext)
}

const MensagemMuralProvider: FC<WithChildren> = ({children}) => {
  const [ errorMessage, setErrorMessage ] = useState<string|undefined>(initMensagemMuralContextPropsState.errorMessage)
  const [ currentMensagemMuralStep, setCurrentMensagemMuralStep ] = useState<MensagemMuralStepEnum>(initMensagemMuralContextPropsState.currentMensagemMuralStep)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ tipoMensagem, setTipoMensagem ] = useState<TipoMensagemEnum|undefined>(initMensagemMuralContextPropsState.tipoMensagem);

  const { currentUser } = useAuth()

  useEffect(() => {
    if (currentUser && currentMensagemMuralStep !== MensagemMuralStepEnum.REVISAO) {
      setCurrentMensagemMuralStep(MensagemMuralStepEnum.REVISAO)
    }
  }, [currentUser, currentMensagemMuralStep])

  return (
    <MensagemMuralContext.Provider value={
        {
          errorMessage,
          setErrorMessage,
          currentMensagemMuralStep,
          setCurrentMensagemMuralStep,
          isLoading,
          setIsLoading,
          tipoMensagem,
          setTipoMensagem,
        }
      }>
        <LoginFlowProvider>
          {children}
        </LoginFlowProvider>
    </MensagemMuralContext.Provider>
  )
}

export { MensagemMuralProvider, MensagemMuralStepEnum, TipoMensagemEnum, useMensagemMural };

